import React, { useContext } from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MinimizeIcon from "@material-ui/icons/Minimize";
import MaximizeIcon from "@material-ui/icons/Maximize";

// Components
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import Device from "sccDevice";
import DeviceOverlay from "sccDeviceOverlay";
import HistoryOverlay from "sccHistoryOverlay";
import HistoryTrailOverlay from "sccHistoryTrailOverlay";
import Images from "sccImage";
import Language from "sccLanguage";
import { MinimizeContext } from "../../context/MinimizeContext";

const useStyles = makeStyles((theme) => ({
  DataDisplayHeader: {
    background: theme.palette.colors.gray.main,
    boxShadow: "none",
    color: theme.palette.colors.blue.dark,
  },

  DataDisplayHeaderToolbar: {
    minHeight: 30,
    padding: "1px 4px",
  },

  DataDisplayHeaderAvatar: {
    width: 30,
    height: 30,
    borderRadius: "inherit",
    backgroundColor: "transparent",
    justifyContent: "flex-start",
  },

  root: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    flexGrow: 1,
    fontSize: 18,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    paddingRight: 4,
    fontSize: 18,
  },

  titleIcon: {
    display: "inline-flex",
    alignItems: "center",
    paddingLeft: "4px",
  },

  lockSize: {
    fontSize: 13,
  },

  DataDisplayHeaderButton: {
    minWidth: 30,
    width: 30,
    height: 30,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      "& $DataDisplayHeaderIcon": {
        color: theme.palette.colors.blue.dark,
      },
    },
  },

  DataDisplayHeaderButtonLocked: {
    minWidth: 30,
    width: 30,
    height: 30,
    backgroundColor: theme.palette.colors.yellow.main,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.colors.yellow.main,
      "& $DataDisplayHeaderIcon": {
        color: theme.palette.colors.blue.dark,
      },
    },
  },

  DataDisplayHeaderIcon: {
    color: alpha(theme.palette.colors.black.main, 0.85),
    fontSize: 18,
  },

  DataDisplayHeaderImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

export default function DataDisplayHeader({
  device,
  history,
  isDeviceLocked,
  updateDeviceIsLocked,
  isHistoryTrail = false,
}) {
  var { state, toggleMinimize } = useContext(MinimizeContext);

  const classes = useStyles();

  const obj = device.id ? device : Object.values(device)[0];

  const handleClose = () => {
    if (isHistoryTrail) {
      HistoryTrailOverlay.deselectFeature();
    } else if (history === "1") {
      HistoryOverlay.deselectFeature();
    } else {
      DeviceOverlay.deselectFeature();
    }
    toggleMinimize = true;
  };

  const handleLock = () => {
    updateDeviceIsLocked(!isDeviceLocked);
    if (history === "1") {
      HistoryOverlay.toggleFeatureLock();
    } else {
      DeviceOverlay.toggleFeatureLock();
    }
  };

  const lockIcon = isDeviceLocked ? (
    <LockIcon className={classes.DataDisplayHeaderIcon} />
  ) : (
    <LockOpenIcon className={classes.DataDisplayHeaderIcon} />
  );

  return (
    <div className={classes.root}>
      <div className="olMapPopupArrow" />
      <AppBar position="static" className={classes.DataDisplayHeader}>
        <Toolbar className={classes.DataDisplayHeaderToolbar}>
          <Avatar className={classes.DataDisplayHeaderAvatar}>
            <img
              className={classes.DataDisplayHeaderImage}
              alt={Images.get(Device.getDeviceType(obj.type_id).image_id).name}
              src={Images.getImageSource(
                Device.getDeviceType(obj.type_id).image_id
              )}
            />
          </Avatar>
          <Typography variant="h6" className={classes.title}>
            <span className={classes.titleText}>{obj.name}</span>
            {!state.minimize && history !== "1" && lockIcon}
          </Typography>
          {state.minimize && history !== "1" && (
            <CustomTooltip
              title={
                !isDeviceLocked
                  ? Language.translate("Lock and Center")
                  : Language.translate("UnLock")
              }
              arrow
              placement="top"
            >
              <IconButton
                onClick={handleLock}
                className={
                  isDeviceLocked
                    ? classes.DataDisplayHeaderButtonLocked
                    : classes.DataDisplayHeaderButton
                }
              >
                {lockIcon}
              </IconButton>
            </CustomTooltip>
          )}

          <CustomTooltip
            title={
              state.minimize
                ? Language.translate("Maximize")
                : Language.translate("Minimize")
            }
            arrow
            placement="top"
          >
            <IconButton
              size="medium"
              color="inherit"
              onClick={toggleMinimize}
              className={classes.DataDisplayHeaderButton}
            >
              {state.minimize ? (
                <MaximizeIcon className={classes.DataDisplayHeaderIcon} />
              ) : (
                <MinimizeIcon className={classes.DataDisplayHeaderIcon} />
              )}
            </IconButton>
          </CustomTooltip>
          <CustomTooltip
            title={Language.translate("Close")}
            arrow
            placement="top"
          >
            <IconButton
              size="medium"
              color="inherit"
              onClick={handleClose}
              className={classes.DataDisplayHeaderButton}
            >
              <CloseIcon className={classes.DataDisplayHeaderIcon} />
            </IconButton>
          </CustomTooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}
