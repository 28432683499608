
var _= require("lodash");
var Device = require("sccDevice").default;
var Menu= require("sccMenu").default;
var Sa= require("sccSa").default;

/**
 * Situational Awareness menu class
 * 
 * @class SaMenu
 * @param {object} options - situational awareness menu information object
 */
class SaMenu extends Menu {
	constructor(){
		const options= {
			tab: "SA",
			shortTitle: "SA",
			title: "Situational Awareness",
			moduleName: "sa",
			mainClass: "SA",
			searchable: true,
			imageClass: "binoculars",
			module: Sa,
			showPagination: true
		};

		super(options);		
		
	}

	/* 
	* Return all the devices from SA Subscribers object and add them to array
	*/
	getAllSaDevicesSelected(){
		const arr = [];
		_.each(Sa.get(), (sa)=>{
			arr.push(sa.subscribers.devices);
		});
		return _.union(_.flatten(arr));
	}

	getAllSaRules(){
		return Sa.get();
	}

	/* 
	* Check if device is part of SA Subscribers array
	*/
	isDeviceAnSaSubscriber(id){
		const subscribers = this.getAllSaDevicesSelected();
		return subscribers.indexOf(id) > -1;
	}
	
	/**
	 * overriding the add method of the Menu class
	 * 
	 */	
	add(obj){
		obj= _.assign(obj, { members: { groups: [], devices: [] }, subscribers: { groups: [], devices: [] } });
		super.add(obj);	
	}

	updateSa(obj) {
		_.each(obj.subscribers.devices, (id) => {
			let device = Device.get(id);
			device.latest_sa_timestamp = Math.trunc(Date.now() / 1000);
			Device.onSocketUpdate("api/v1/device", device);
		});
	}

	save(obj) {
		
		const devices = Device.get();
		var $scope= this.$scope;
		// Checks if all the subscribers selected are in Devices and not deleted by the time a user saves SA rule
		if(!_.isUndefined(this.$scope.editObj)){
			var removeArray = _.difference($scope.editObj.subscribers.devices, _.keys(devices).map(Number));
			if(removeArray.length > 0){
				$scope.editObj.subscribers.devices = _.intersection($scope.editObj.subscribers.devices, _.keys(devices).map(Number));			
			}
		}
		var objCloned = _.cloneDeep(this.$scope.editObj);

		// Requirement: if members and subscribers have not changed do not set poll_sa to true
		if (objCloned) {
			const saMembers = Sa.get($scope.editObj.id).members;
			const editObjMembers = $scope.editObj.members;
			const checkMembers = _.isEqual(saMembers, editObjMembers);
			const saSubscribers = Sa.get($scope.editObj.id).subscribers;
			const editObjSubscribers = $scope.editObj.subscribers;
			const checkSubscribers = _.isEqual(saSubscribers, editObjSubscribers);
			// Check if changes have occurred in Members or Subscribers
			if (checkMembers && checkSubscribers) {
				//loop through the subscribers list, access each Device instance (using id's in subscribers list) from the db, update its latest_sa_timestamp and then update the db
				this.updateSa(objCloned);
				super.save(obj);
			} else {

				if (_.isUndefined(objCloned.id)) {
					if (obj)
					//user has clicked to update SA(send last report) immediately after creating an SA rule
					//loop through the subscribers list, access each Device instance (using id's in subscribers list) from the db, update its latest_sa_timestamp and then update the db
						this.updateSa(obj);
					super.save(obj);
				} else {
					objCloned["poll_sa"] = true;
					//loop through the subscribers list, access each Device instance (using id's in subscribers list) from the db, update its latest_sa_timestamp and then update the db
					this.updateSa(objCloned);
					super.save(objCloned);
				}
			}
		} else {
			//user has clicked to update SA(send last report), immediately. Did not attempt to access edit menus
			//loop through the subscribers list, access each Device instance (using id's in subscribers list) from the db, update its latest_sa_timestamp and then update the db
			this.updateSa(obj);
			super.save(obj);
		}
	}
}

export default new SaMenu();
