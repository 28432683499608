import React, { useState } from "react";

// Material-UI
import {
  List,
  ListItem,
  ListItemText,
  Grid,
  Tabs,
  Tab,
  Badge,
  makeStyles,
} from "@material-ui/core";

// Internal
import OlMap from "sccOlMapNew";
import Language from "sccLanguage";
import Clock from "sccClock";
import Device from "sccDevice";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  noDeviceTactical: {
    textAlign: "center",
  },
  deviceItem: {
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    padding: "4px 8px",
  },
}));

export default function PoiDetail({ data }) {
  const [value, setValue] = useState(0);
  const classes = useStyles();

  function handleTabs(e, val) {
    setValue(val);
  }
  function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div>
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }

  const location =
    (data &&
      data.latitude &&
      OlMap.formatCoordinate([data.longitude, data.latitude])) ||
    "N/A";

  return (
    <Grid item xs={12} md={12} className={classes.detailsContainer}>
      <Tabs
        value={value}
        onChange={handleTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          label={Language.translate("Info")}
          style={{ fontSize: 9 }}
          icon={<CustomIcon style={{ fontSize: 9 }} id="icon-info-circle" />}
        />
        <Tab
          label={Language.translate("Tactical")}
          style={{ fontSize: 9 }}
          icon={
            <Badge color="primary" badgeContent={data.sync.devices.length}>
              <CustomIcon style={{ fontSize: 9 }} id="icon-refresh" />
            </Badge>
          }
        />
      </Tabs>
      {value == 0 && (
        <List>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Created").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={Clock.formatTimestamp(data.created_timestamp)}
            />
          </ListItem>
          <ListItem>
            {data.creator_device_id && (
              <React.Fragment>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={Language.translate("Created By").toUpperCase() + ":"}
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={Device.get(data.creator_device_id).name}
                />
              </React.Fragment>
            )}
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("Annotation").toUpperCase() + ":"}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={data.note}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={
                Language.translate("Location").toUpperCase() +
                Language.translate("(Lat,Lon)").toUpperCase()
              }
            />
            <CustomTooltip
              title={window.olMap.formatCoordinate([
                data.longitude,
                data.latitude,
              ])}
              arrow
              placement="bottom"
            >
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={window.olMap.formatCoordinate([
                  data.longitude,
                  data.latitude,
                ])}
              />
            </CustomTooltip>
          </ListItem>
        </List>
      )}
      {value == 1 && (
        <List>
          {data.sync.devices?.length > 0 ? (
            data.sync.devices.map((deviceId) => {
              const deviceInfo = Device.get(deviceId);
              return (
                <ListItem key={deviceId}>
                  <ListItemText
                    md={12}
                    primary={deviceInfo.name}
                    className={classes.deviceItem}
                  />
                </ListItem>
              );
            })
          ) : (
            <p className={classes.noDeviceTactical}>
              {Language.translate("There are no devices in Tactical")}
            </p>
          )}
        </List>
      )}
    </Grid>
  );
}
