import React, { useState, useContext } from "react";

// External
import _ from "lodash";

// Internal
import { AppContext } from "../../AppContext";
import { CustomAssetSelectorContext } from "../../reusable-components/CustomAssetWrapper/CustomAssetSelectorContext";
import Language from "sccLanguage";
import Utils from "sccUtils";
import Permission from "sccPermission";
import SyncMenu from "sccSyncMenu";

let numberOfItems, itemsPerPage, currentPage, maxPages;

const ModuleWrapperContext = React.createContext([{}, () => {}]);

const DEFAULT_ITEMS_PER_PAGE = process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE;
const DEFAULT_MAIN_GROUP_TITLE = process.env.REACT_APP_DEFAULT_MAIN_GROUP_TITLE;
const MW_NOTHING = process.env.REACT_APP_MW_NOTHING;
const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;
const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;
const MW_SINGLEPAGE_MODE = process.env.REACT_APP_MW_SINGLEPAGE_MODE;
const MW_ALERTS_MODULE_MODE = process.env.REACT_APP_MW_ALERTS_MODULE_MODE;
const MW_ASSETS_MODULE_MODE = process.env.REACT_APP_MW_ASSETS_MODULE_MODE;
const MW_MESSAGE_MODULE_MODE = process.env.REACT_APP_MW_MESSAGE_MODULE_MODE;
const MW_MESSAGE_MODULE_LIST_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_LIST_MODE;
const MW_MESSAGE_MODULE_COMPOSE_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_COMPOSE_MODE;
const MW_MESSAGE_MODULE_READ_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_READ_MODE;
const MW_IRIDIUM_MODULE_MODE = process.env.REACT_APP_MW_IRIDIUM_MODULE_MODE;
const MW_SYNC_MODULE_MODE = process.env.REACT_APP_MW_SYNC_MODULE_MODE;

const ModuleWrapperContextProvider = (props) => {
  const [appState, setAppState] = useContext(AppContext);
  const [assetSelectorState, setAssetSelectorState] = useContext(
    CustomAssetSelectorContext
  );

  const openModuleWrapperPopper = (object, preFilteredItem = null) => {
    numberOfItems = object.module._data
      ? Object.keys(object.module._data).length
      : 0;
    itemsPerPage = DEFAULT_ITEMS_PER_PAGE;
    currentPage = 1;
    maxPages = 5;

    //  check if module is sync then manipulate the module data
    const isSyncModule = object.moduleName === "Sync";
    const isAssetModule = object.moduleName === "Device";
    const Group = require("sccGroup").default;
    const moduleData = isSyncModule
      ? SyncMenu.getDevicesSynced()
      : isAssetModule
      ? Group.getGroupTree().groups //we add the Group.getGroupTree() at the time of module load
      : object.module._data;
    setMwState((p) => ({
      ...p,
      popperId: "moduleWrapperPopper",
      popperOpen: true,
      popperAnchorEl: document.getElementById("menuButtonBoxGroup"),
      wrapperDisplayMode: object.wrapperDisplayMode,
      title: object.title || object.shortTitle,
      moduleName: object.moduleName,
      icon: object.imageClass,
      routeUrl: object.module.routeUrl,
      moduleData: moduleData,
      filteredModuleData: moduleData,
      preFilteredItem: preFilteredItem,
      moduleItemData: {},
      moduleItemDataOriginal: {},
      isList: object.isList == null ? true : object.isList,
      showPagination:
        object.showPagination == null ? false : object.showPagination,
      pagination: {
        currentPage: 1,
        itemsPerPage: itemsPerPage,
        numberOfItems: numberOfItems,
        maxPages: maxPages,
        startIndex: 0,
        endIndex: numberOfItems > itemsPerPage ? itemsPerPage : numberOfItems,
      },
      showFilter: object.showFilter == null ? false : object.showFilter,
      showSearch: object.showSearch == null ? false : object.showSearch,
      searchField: object.searchField,
      filterField: object.filterField || [],
      showFooter: object.showFooter == null ? false : object.showFooter,
      coordsForAddNewPOIctxMenuOption: object.coordinates,
      moduleFormHasChanges: false,
      isSaveInProgress: false,
      showOrderBy: object.showOrderBy == null ? false : object.showOrderBy,
      orderBySelection: object.orderBySelection || [],
      selectedOrderBy: object.selectedOrderBy || "",
      verifyAddPermission:
        object.verifyAddPermission == null ? false : object.verifyAddPermission,
      verifyEditPermission:
        object.verifyEditPermission == null
          ? false
          : object.verifyEditPermission,
      verifyDeletePermission:
        object.verifyDeletePermission == null
          ? false
          : object.verifyDeletePermission,
      verifyShownPermission:
        object.verifyShownPermission == null
          ? false
          : object.verifyShownPermission,
    }));
  };
  const openModuleWrapperPopperMessaging = (object) => {
    //This function has been seperated from the regular openpopper as it sets the data differently for the messaging module.
    setMwState((p) => ({
      ...p,
      popperId: "moduleWrapperPopper",
      popperOpen: true,
      popperAnchorEl: document.getElementById("menuButtonBoxGroup"),
      wrapperDisplayMode: object.wrapperDisplayMode,
      wrapperDisplaySubMode: MW_MESSAGE_MODULE_LIST_MODE,
      title: object.title || object.shortTitle,
      moduleName: object.moduleName,
      moduleData: {},
      filteredModuleData: [], //messaging uses this in array format as a special case
      icon: object.imageClass,
      routeUrl: object.module.routeUrl,
      isList: object.isList == null ? true : object.isList,
      searchExecuted: false,
      showPagination:
        object.showPagination == null ? false : object.showPagination,
      showFilter: object.showFilter == null ? false : object.showFilter,
      showSearch: object.showSearch == null ? false : object.showSearch,
      searchField: object.searchField,
      filterField: object.filterField || [],
      showFooter: object.showFooter == null ? false : object.showFooter,
      isSaveInProgress: false,
      pagination: {
        //an incomplete pagination is used here as it needs to be set from the loadmessages() in the module iteself
        currentPage: 1,
        itemsPerPage: 20,
      },
      verifyAddPermission:
        object.verifyAddPermission == null ? false : object.verifyAddPermission,
      verifyEditPermission:
        object.verifyEditPermission == null
          ? false
          : object.verifyEditPermission,
      verifyDeletePermission:
        object.verifyDeletePermission == null
          ? false
          : object.verifyDeletePermission,
      verifyShownPermission:
        object.verifyShownPermission == null
          ? false
          : object.verifyShownPermission,
    }));
  };

  const closeModuleWrapperPopper = () => {
    resetMWState();
    // todo: redo this after assetSelector is working
    // assetSelectorState.resetAssetSelectorState();
  };
  //for the roles dropdown
  const getPermittedRoles = () => {
    let permittedRoles = _.cloneDeep(Permission.getAllRoles());

    // alphabetical sorting of both admin and custom roles using hard-coded ids for conditional statement
    let adminRoles = _.filter(permittedRoles, function (a) {
      return a.id < 8;
    });
    adminRoles = _.sortBy(adminRoles, "title");
    let customRoles = _.filter(permittedRoles, function (c) {
      return c.id > 7;
    });
    customRoles = _.sortBy(customRoles, "title");
    permittedRoles = _.concat(adminRoles, customRoles);
    // end conditional sorting

    permittedRoles = _.omitBy(permittedRoles, function (role) {
      return role.title === "Customer Admin";
    });

    permittedRoles = _.map(permittedRoles, function (value) {
      var result = {};
      result.label = value.title;
      result.value = value.id;
      return result;
    });
    return permittedRoles;
  };
  const handleResendRegistration = (userId) => {
    //RE-SEND REGISTRATION FROM USER LIST
    var data = {
      id: userId,
    };
    fetch(Utils.apiUrlPrefix + "/user/resend_registration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        //we need to check for the 400 error here
        if (data.error) {
          appState.displaySnackbarMessage({
            title: Language.translate("User Registration"),
            message: data.error,
            variant: "error",
          });
          console.log(data.error);
        } else {
          appState.displaySnackbarMessage({
            title: Language.translate("User Registration"),
            message: data.message,
            subMessage: null,
            variant: "success",
          });
        }
      })
      .catch((e) => {
        appState.displaySnackbarMessage({
          title: Language.translate("User Registration"),
          message: e.message,
          variant: "error",
        });
        console.log("ERROR from catch", e);
      });
    //successfully created. a registration email has been sent to the user.
  };

  var logoutMessage = Language.translate(
    "Are you sure you want to logout now?"
  );
  let developerLogsCount = 0;

  const initialState = {
    //common reusable functions for this module only
    openModuleWrapperPopper,
    openModuleWrapperPopperMessaging,
    closeModuleWrapperPopper,
    emptyFormData,
    saveButtonState,
    updateModuleData,
    afterSave,
    getEntityTitle,
    closeEdit,
    getDisplayName,
    getPermittedRoles,
    edit,
    setDialogOpen,
    setDialogOkAction,
    setDialogCancelAction,
    setDialogMessage,
    inputDataHasChanged,
    handleDialogOkAction,
    handleDialogCancelAction,
    cancelAndBackToListingMode,
    cancelAndBackToAssetListingMode,
    cancelAndBackToMessageListingMode,
    handleSpecialSaveValidation,
    handleItemEdit,
    handleItemDelete,
    deleteModuleItemData,
    convertAndSort,
    differenceBetweenObjects,
    handleResendRegistration,
    loadMessages,
    backToMessagesList,
    changeToComposeMode,
    messageModuleLoading,
    moveMessagesToTrash,
    restoreDeletedMessages,
    deleteMessagesFromTrash,
    resendPendingMessages,
    markMessageAsRead,
    markMessagesToRead,
    markMessagesToUnread,
    refreshMessageCounts,
    refreshMessagesAndCounts,
    displayNewMessageAlert,
    pollCommands,
    minimizePollSettingsForOrion,
    maximizePollSettingsForOrion,
    arrayToObject,
    //shared flags and variables
    popperId: undefined,
    popperOpen: false,
    popperAnchorEl: null,
    wrapperDisplayMode: MW_NOTHING, //0-Nothing, 1-Listing, 2-Add, 3-Edit
    mainGroupName: DEFAULT_MAIN_GROUP_TITLE,
    title: "",
    moduleName: "",
    icon: null,
    showFooter: false,
    showAdd: false,
    showEdit: false,
    isSubMenu: false,
    isList: false,
    verifyAddPermission: false,
    verifyDeletePermission: false,
    showPagination: false,
    showSearch: false,
    searchField: [],
    filterField: [],
    preFilteredItem: null,
    dialogOpen: false,
    dialogMessage: null,
    routeUrl: null,
    showOrderBy: false,
    isSaveInProgress: false,
    orderBySelection: [],
    selectedOrderBy: "",
    error: false, //might be used to display the errors from backend - to be removed if not used
    errorMessage: null, //might be used to display the errors from backend - to be removed if not used
    //objects
    filters: {},
    pagination: {},
    moduleData: {},
    filteredModuleData: {},
    moduleItemData: {},
    moduleItemDataOriginal: {},
    moduleFormValidations: {},
    moduleSchema: {}, //to store the schema for the module
    listItemAction: {},
    moduleFormHasChanges: false,
    //this is being used in the scripts of the modules. Need to see why and what for
    $scope: {
      //might be able to remove it altogether if we do not use it at all
      showMenu: {},
      showEdit: {},
      showAdd: {},
      cancel: false,
      setCancel: function (cancelClicked) {
        this.cancel = cancelClicked;
      },
      subMenuClicked: function () {},
      // SUPPORT Menu
      openSupportWindow: function () {
        // 	window.open("https://nortacdefence.atlassian.net/servicedesk/customer/portals", "", "width=500,height=500");
      },
      showDeveloperLogs: function () {
        developerLogsCount++;
        if (developerLogsCount < 5) return;
        const Load = require("sccLoad");
        const logLevel = "trace";
        Load.setLogLevel(logLevel);
      },
    },
  };

  //initial state
  const [mwState, setMwState] = useState(initialState);

  const resetMWState = () => {
    setMwState((p) => ({
      ...initialState,
    }));
  };

  function emptyFormData() {
    setMwState((p) => ({
      ...p,
      moduleItemData: {},
      moduleItemDataOriginal: {},
    }));
    // todo: redo this after assetSelector is working
    // assetSelectorState.resetAssetSelectorState();
  }
  function cancelAndBackToListingMode() {
    setMwState((p) => ({
      ...p,
      wrapperDisplayMode: MW_LIST_MODE,
    }));
    emptyFormData();
  }
  function cancelAndBackToAssetListingMode() {
    setMwState((p) => ({
      ...p,
      wrapperDisplayMode: MW_ASSETS_MODULE_MODE,
      wrapperDisplaySubMode: 0,
    }));
    emptyFormData();
  }
  function cancelAndBackToMessageListingMode() {
    setMwState((p) => ({
      ...p,
      wrapperDisplayMode: MW_MESSAGE_MODULE_MODE,
      wrapperDisplaySubMode: MW_MESSAGE_MODULE_LIST_MODE,
    }));
    emptyFormData();
  }

  function edit(obj) {}
  function saveButtonState(isSave) {
    let isSaveInProgress = false;
    let saveButtonText = "";
    if (isSave) {
      isSaveInProgress = true;
      saveButtonText = Language.translate("Saving") + "...";
    } else {
      isSaveInProgress = false;
      saveButtonText = Language.translate("Save");
    }
  }

  function convertAndSort(data, searchField) {
    return _.isEmpty(searchField)
      ? data
      : _.values(data).sort((a, b) =>
          a[searchField[0]].toLowerCase() > b[searchField[0]].toLowerCase()
            ? 1
            : b[searchField[0]].toLowerCase() > a[searchField[0]].toLowerCase()
            ? -1
            : 0
        );
  }

  //check if the confirmation dialog needs to be triggered, if the input data has changed, the dialog should be triggered, vice versa
  function inputDataHasChanged(data1, data2) {
    let result = false;
    if (Object.keys(data2).length == 0) {
      result = false;
    } else {
      result = !_.isEqual(data1, data2);
    }
    return result;
  }
  //just a helper util function
  function differenceBetweenObjects(origObj, newObj) {
    const transform = require("lodash").transform;
    const isEqual = require("lodash").isEqual;
    const isArray = require("lodash").isArray;
    const isObject = require("lodash").isObject;
    function changes(newObj, origObj) {
      let arrayIndexCounter = 0;
      return transform(newObj, function (result, value, key) {
        if (!isEqual(value, origObj[key])) {
          let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
          result[resultKey] =
            isObject(value) && isObject(origObj[key])
              ? changes(value, origObj[key])
              : value;
        }
      });
    }
    return changes(newObj, origObj);
  }
  function handleItemEdit(data) {
    setMwState((p) => ({
      ...p,
      wrapperDisplayMode: MW_ADDEDIT_MODE,
      moduleItemData: data,
      moduleItemDataOriginal: data,
    }));
  }
  function handleItemDelete(
    routeUrl,
    title,
    moduleName,
    itemName,
    specialWrapperDisplayMode = MW_LIST_MODE
  ) {
    if (itemName != null && itemName != undefined) {
      setDialogMessage(
        Language.translate("Are you sure you want to delete") +
          " " +
          moduleName +
          " '" +
          itemName +
          "'?"
      );
    } else {
      setDialogMessage(
        Language.translate("Are you sure you want to delete") +
          " " +
          moduleName +
          "?"
      );
    }

    setDialogOkAction(() =>
      okActionDelete(
        routeUrl,
        title,
        moduleName,
        itemName,
        specialWrapperDisplayMode
      )
    );
    setDialogCancelAction(() => cancelAction(false));
    setDialogOpen(true);
  }
  function okActionDelete(
    routeUrl,
    title,
    moduleName,
    itemName,
    specialWrapperDisplayMode
  ) {
    mwState.setDialogMessage(null);
    mwState.setDialogOpen(false);
    mwState.deleteModuleItemData(routeUrl, title, moduleName, itemName);
    setMwState((p) => ({
      ...p,
      wrapperDisplayMode: specialWrapperDisplayMode,
    }));
    emptyFormData();
  }
  function cancelAction(emptyFormData = true) {
    mwState.setDialogMessage(null);
    mwState.setDialogOpen(false);
    if (emptyFormData) emptyFormData();
  }
  function setDialogOpen(val) {
    setMwState((p) => ({
      ...p,
      dialogOpen: val,
    }));
  }
  function setDialogMessage(val) {
    setMwState((p) => ({
      ...p,
      dialogMessage: val,
    }));
  }
  function setDialogOkAction(val) {
    setMwState((p) => ({
      ...p,
      handleDialogOkAction: val,
    }));
  }
  function setDialogCancelAction(val) {
    setMwState((p) => ({
      ...p,
      handleDialogCancelAction: val,
    }));
  }
  function handleDialogOkAction() {
    //function set at the time of calling
  }
  function handleDialogCancelAction() {
    //function set at the time of calling
  }
  function handleSpecialSaveValidation() {
    //function set at the time of calling
    //DO NOT DELETE
    return true;
  }

  function deleteModuleItemData(routeUrl, title, moduleName, itemName) {
    const options = {
      url: routeUrl,
      method: "DELETE",
    };
    const message = {
      title: title,
      text: Language.translate("Successfully Deleted"),
      subText: itemName ? itemName : null,
    };
    return fetch(options.url, {
      method: options.method,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        //we need to check for the 400 error here
        if (data.error) {
          appState.displaySnackbarMessage({
            message: data.error,
            variant: "error",
          });
          console.log(data.error);
        } else {
          appState.displaySnackbarMessage({
            title: message.title,
            message: message.text,
            subMessage: message.subText,
            variant: "success",
          });
          console.log("User DELETED Successful:", data);
        }
      })
      .catch((e) => {
        appState.displaySnackbarMessage({
          title: message.title,
          message: "Error encountered while deleting item",
          variant: "error",
        });
        console.log("ERROR from catch", e);
      });
  }

  function updateModuleData(data, routeUrl, message) {
    let errorSaving = false;
    const options = {
      url: routeUrl,
      method: data.id ? "PUT" : "POST",
      data: data,
    };
    return fetch(options.url, {
      method: options.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options.data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        //we need to check for the 400 error here
        if (data.error) {
          appState.displaySnackbarMessage({
            message: data.error,
            variant: "error",
          });
          errorSaving = true;
          console.log(data.error);
        } else {
          !message.suppressMessage &&
            appState.displaySnackbarMessage({
              title: message.title,
              message: message.text,
              subMessage: message.subText,
              variant: "success",
            });
          if (!message.noRefresh) {
            emptyFormData();
            //special fix for getting the geofence listing display correctly after add
            if (message.title === "Geo-Fence") {
              afterSaveForGeofence(
                message.closeModule,
                message.backToWrapperDisplayMode
              );
            } else {
              afterSave(message.closeModule, message.backToWrapperDisplayMode);
            }
          }
          setMwState((p) => ({
            ...p,
            moduleFormHasChanges: false,
          }));
        }
        return data;
      })
      .catch((e) => {
        const dataSizeLimit = 1000000; //size in bytes
        if (routeUrl.includes("image/platform") && data.size > dataSizeLimit) {
          //if adding a platform logo with size larger than 1 Mb
          appState.displaySnackbarMessage({
            message:
              Language.translate("File too large") +
              ": " +
              (data.size / dataSizeLimit).toFixed(1) +
              "MB. " +
              Language.translate("Maximum") +
              ": 1MB",
            variant: "error",
          });
          errorSaving = true;
          console.log("ERROR from catch", e);
        } else {
          appState.displaySnackbarMessage({
            message: Language.translate("Error") + ".",
            variant: "error",
          });
          errorSaving = true;
          console.log("ERROR from catch", e);
        }
      });
  }

  function afterSave(close = false, wrapperDisplayMode = MW_LIST_MODE) {
    if (close) {
      mwState.closeModuleWrapperPopper();
    } else
      setMwState((p) => ({
        ...p,
        wrapperDisplayMode: wrapperDisplayMode,
      }));
  }
  //SpecialCase afterSave for GeoFence module to add a delay
  function afterSaveForGeofence(
    close = false,
    wrapperDisplayMode = MW_LIST_MODE
  ) {
    document.getElementById("mwBody").style.visibility = "hidden";
    if (close) {
      mwState.closeModuleWrapperPopper();
    } else {
      setTimeout(() => {
        document.getElementById("mwBody").style.visibility = "visible";
        setMwState((p) => ({
          ...p,
          wrapperDisplayMode: wrapperDisplayMode,
        }));
      }, 10);
    }
  }

  // create object with id as object item key
  function arrayToObject(arr, key) {
    return (
      arr && Object.assign({}, ...arr.map((item) => ({ [item[key]]: item })))
    );
  }

  // function specifically written to load messages for messaging module
  function loadMessages(options) {
    const Message = require("sccMessage").default;
    //was added to fix the  first time extra load. Might need to just return null here, if it causes issues elsewhere
    if (!options[2]) {
      options[2] = "inbox";
      options[3] = true;
    }
    //always check for
    return Message.loadData(options).then((data) => {
      //getting actual data here
      //TO BE REMOVED LATER
      // setTimeout(() => { //removed the setTimeout as it was causing the flickering effect.
      //It was added as some data was not getting set timely but its been a while and many things have changed since then
      //removed the setTimeout for now. It looks to be working fine, need to revisit after QA testing
      const messageInfo = Message.getMessageInfo();
      const numberOfItems = Message.get().total_message_count;
      setMwState((p) => ({
        ...p,
        moduleData: {
          messages: Message._data.messages,
          messageInfo: messageInfo,
          checked: [],
          tabNamesIndexed: ["inbox", "sent", "trash", "monitor"],
          currentFolder: options[2],
          sortDesc: options[3],
          hideUnauthorized: false,
          reloading: false,
        },
        filteredModuleData: sortMessages(
          Object.values(Message._data.messages),
          options[3]
        ), //{ get: function () { return this.moduleData } },
        showSelectionDropdown: options[2] !== "monitor",
        showItemsPerPage: options[2] !== "monitor",
        pagination: { ...p.pagination, numberOfItems: numberOfItems },
      }));
      // }, 3);
      return Promise.resolve(data);
    });
  }
  function sortMessages(data, desc) {
    const multiplier = desc ? -1 : 1;
    return data.sort((a, b) => {
      return a.message_timestamp > b.message_timestamp
        ? 1 * multiplier
        : b.message_timestamp > a.message_timestamp
        ? -1 * multiplier
        : 0;
    });
  }
  function changeToComposeMode(e, messageId = 0) {
    e.preventDefault();
    console.log("mws", mwState);
    let recipients = { users: [], devices: [], groups: [] };
    //only in case of reply
    if (messageId != 0) {
      const Message = require("sccMessage").default;
      const messageObj = Message.get().messages[messageId];
      recipients.users = messageObj.senders.users;
      recipients.devices = messageObj.senders.devices;
    }
    setMwState((p) => ({
      ...p,
      wrapperDisplaySubMode: MW_MESSAGE_MODULE_COMPOSE_MODE,
      moduleItemData: {
        attachments: [],
        cannedmessage_id: 0,
        message: "",
        recipients: recipients,
        _attachmentTooBig: false,
        _file: null,
      },
      moduleItemDataOriginal: {
        attachments: [],
        cannedmessage_id: 0,
        message: "",
        recipients: recipients,
        _attachmentTooBig: false,
        _file: null,
      },
    }));
  }
  function backToMessagesList(
    wrapperDisplaySubMode = MW_MESSAGE_MODULE_LIST_MODE
  ) {
    setMwState((p) => ({
      ...p,
      wrapperDisplaySubMode: wrapperDisplaySubMode,
      moduleItemData: {},
      moduleItemDataOriginal: {},
    }));
  }
  function messageModuleLoading() {
    setMwState((p) => ({
      ...p,
      moduleData: { ...p.moduleData, reloading: true },
    }));
  }
  function resendPendingMessages(id) {
    const Message = require("sccMessage").default;
    let deviceId = [];
    let messageId = id;
    let messageObj = Message.get().messages[messageId];
    _.forEach(
      messageObj.recipients.device_message_status,
      function (value, key) {
        if (
          value.message_status === "pending" ||
          value.message_status === "fail"
        ) {
          deviceId.push(parseInt(key));
        }
      }
    );
    var data = {
      message_id: messageId,
      recipient_devices: deviceId,
    };
    return Message.resend(data).then(function () {
      appState.displaySnackbarMessage({
        title: Language.translate("Message"),
        message: Language.translate("Resent All PENDING messages"),
        subMessage: null,
        variant: "success",
      });
      mwState.backToMessagesList();
    });
  }
  function moveMessagesToTrash(selection, options) {
    if (!selection || selection.length == 0) return;
    var idArray = _.concat([], selection);
    var data = {
      id: idArray,
      folder: options[2],
      message_archived: true,
    };
    updateModuleData(data, "/api/v1/message", {
      title: Language.translate("MESSAGE"),
      text: Language.translate("Moved message to trash"),
      variant: "info",
      noRefresh: true,
    });
    loadMessages(options);
    backToMessagesList();
  }
  function deleteMessagesFromTrash(selection, options) {
    if (!selection || selection.length == 0) return;
    var idArray = _.concat([], selection);
    var data = {
      id: idArray,
      folder: options[2],
      message_deleted: true,
    };
    updateModuleData(data, "/api/v1/message", {
      title: Language.translate("MESSAGE"),
      text: Language.translate("Messages deleted from trash"),
      variant: "info",
      noRefresh: true,
    });
    loadMessages(options);
    backToMessagesList();
  }
  function restoreDeletedMessages(id, options) {
    if (!id || id.length == 0) return;
    var idArray = _.concat([], id);
    var data = {
      id: idArray,
      folder: options[2],
      message_archived: false,
    };
    updateModuleData(data, "/api/v1/message", {
      title: Language.translate("MESSAGE"),
      text: Language.translate("RESTORED messages"),
      variant: "info",
      noRefresh: true,
    });
    loadMessages(options);
    backToMessagesList();
  }
  function markMessageAsRead(messageId, options) {
    var data = {
      id: [messageId],
      folder: options[2],
      message_read: true,
    };
    updateModuleData(data, "/api/v1/message", {
      title: Language.translate("MESSAGE"),
      text: Language.translate("Marked messages as read"),
      variant: "info",
      noRefresh: true,
      suppressMessage: true,
    });
  }
  function markMessagesToRead(selection, options) {
    if (!selection || selection.length == 0) return;
    var idArray = _.concat([], selection);
    var data = {
      id: idArray,
      folder: options[2],
      message_read: true,
    };
    updateModuleData(data, "/api/v1/message", {
      title: Language.translate("MESSAGE"),
      text: Language.translate("Marked messages as read"),
      variant: "info",
      noRefresh: true,
    });
    loadMessages(options);
    backToMessagesList();
  }
  function markMessagesToUnread(selection, options) {
    if (!selection || selection.length == 0) return;
    var idArray = _.concat([], selection);
    var data = {
      id: idArray,
      folder: options[2],
      message_read: false,
    };
    updateModuleData(data, "/api/v1/message", {
      title: Language.translate("MESSAGE"),
      text: Language.translate("Marked messages as unread"),
      variant: "info",
      noRefresh: true,
    });
    loadMessages(options);
    backToMessagesList();
  }

  function refreshMessageCounts() {
    const Message = require("sccMessage").default;
    Message.loadMessageInfo().then((messageInfo) => {
      Message.setMessageInfo(messageInfo.data.result);
    });
  }
  function displayNewMessageAlert(data) {
    appState.displaySnackbarMessage({
      title: Language.translate(data[0]),
      message: Language.translate(data[1]),
      subMessage: data[2],
      variant: data[3],
    });
  }
  function refreshMessagesAndCounts(options) {
    refreshMessageCounts();
    loadMessages(options);
  }
  ////messaging specific ends here

  function getEntityTitle(obj) {}
  function closeEdit() {}

  function getDisplayName() {}

  function pollCommands(device, command, settings) {
    var successMessage, errorMessage, messageTitle;
    successMessage =
      Language.translate("Successfully sent command to") + " " + device.name;
    errorMessage =
      Language.translate("Failed to send command to") + " " + device.name;

    if (!device.assetId) {
      device.assetId = device.imei;
    }

    if (command === "poll_gps") {
      messageTitle = Language.translate("POLL GPS");
    } else if (command === "poll_settings") {
      messageTitle = Language.translate("POLL SETTINGS");
    } else if (command === "send_settings") {
      messageTitle = Language.translate("SETTINGS CODE");
    } else if (command === "clear_data") {
      messageTitle = Language.translate("CLEAR DATA");
    }
    var options;
    options = {
      url: Utils.apiUrlPrefix + "/device/command",
      body: {
        id: device.id,
        imei: device.assetId,
        comm_id: device.comm_id,
        command: command,
        settings_code: settings,
      },
      method: "PUT",
    };

    const DeviceMenu = require("sccDeviceMenu").default;
    if (device.type === "Hawkeye 5500") DeviceMenu.closeAfterHawkeyeConfig(); //close the edit menu

    return Utils.httpRequestHandler(options)
      .then(function () {
        //save the settings part when settings are sent to device only done for hawkeye
        if (
          command === "send_settings" &&
          (device.type === "Hawkeye 5500" || device.type === "NORTAC Orion")
        )
          updateModuleData(
            {
              ...device,
              imei: device.assetId,
              settings: minimizePollSettingsForOrion(JSON.parse(settings)),
            },
            Utils.apiUrlPrefix + "/device",
            {
              title: messageTitle,
              text:
                Language.translate("Settings configuration saved for device") +
                " " +
                device.name,
              variant: "info",
              noRefresh: true,
            }
          );
        appState.displaySnackbarMessage({
          title: messageTitle,
          message: successMessage,
          subMessage: null,
          variant: "success",
        });
      })
      .catch(function (err) {
        appState.displaySnackbarMessage({
          title: messageTitle,
          message: errorMessage,
          subMessage: null,
          variant: "error",
        });
      });
  }

  function minimizePollSettingsForOrion(settings) {
    const newSettings = JSON.stringify({
      report_i: settings.report_i + ", " + Number(settings.lock_report_i),
      alert_i: settings.alert_i + ", " + Number(settings.lock_alert_i),
      distance_i: settings.distance_i + ", " + Number(settings.lock_distance_i),
      distance_enabled:
        settings.distance_enabled +
        ", " +
        Number(settings.lock_distance_enabled),
      measurements:
        settings.measurements + ", " + Number(settings.lock_measurements),
    });
    return newSettings;
  }

  function maximizePollSettingsForOrion(settings) {
    const report_iArr = settings.report_i.split(",");
    const alert_iArr = settings.alert_i.split(",");
    const distance_iArr = settings.distance_i.split(",");
    const distance_enabledArr = settings.distance_enabled.split(",");
    const measurementsArr = settings.measurements.split(",");
    const newSettings = {
      report_i: report_iArr[0].trim(),
      lock_report_i: !_.isUndefined(report_iArr[1])
        ? Boolean(Number(report_iArr[1].trim()))
        : false,
      alert_i: alert_iArr[0].trim(),
      lock_alert_i: !_.isUndefined(alert_iArr[1])
        ? Boolean(Number(alert_iArr[1].trim()))
        : false,
      distance_i: distance_iArr[0].trim(),
      lock_distance_i: !_.isUndefined(distance_iArr[1])
        ? Boolean(Number(distance_iArr[1].trim()))
        : false,
      distance_enabled: distance_enabledArr[0].trim(),
      lock_distance_enabled: !_.isUndefined(distance_enabledArr[1])
        ? Boolean(Number(distance_enabledArr[1].trim()))
        : false,
      measurements: measurementsArr[0].trim(),
      lock_measurements: !_.isUndefined(measurementsArr[1])
        ? Boolean(Number(measurementsArr[1].trim()))
        : false,
    };
    return newSettings;
  }

  return (
    <ModuleWrapperContext.Provider value={[mwState, setMwState]}>
      {props.children}
    </ModuleWrapperContext.Provider>
  );
};

export { ModuleWrapperContext, ModuleWrapperContextProvider };
