import React, { useState } from "react";

// External
import _ from "lodash";

// Material-UI
import Popper from "@material-ui/core/Popper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import HeightIcon from "@material-ui/icons/Height";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/styles";

// Internal
import Language from "sccLanguage";
import CustomButton from "@Components/CustomButton";
import CustomTooltip from "@Components/CustomTooltip";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    minWidth: 300,
    maxWidth: 600,
    zIndex: 1000,
    maxHeight: 750,
  },

  button: {
    height: 30,
    width: 130,
  },

  orderByContainer: {
    padding: 0,
  },

  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
    whiteSpace: "nowrap",
    lineHeight: "3px",
  },
});

/**
 * This Component si used to display OrderBy button and manupulate data when user click one orderBy
 * @param {string} label: label to display, default is "ORDER BY",
 * @param {array} orderBySelection: define the orderBy list
 * @param {string} selectedOrderBy:   define the checked selected order
 * @param {function} onOrderBySelected: function that triggered when click on orderBy
 */
function CustomOrderBy({
  label = Language.translate("Order By").toUpperCase(),
  orderBySelection,
  selectedOrderBy,
  onOrderBySelected,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(Boolean(anchorEl));

  // display orderBy list popper
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  // close orderBy list popper
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <CustomTooltip title={label} arrow placement="top">
        <CustomButton
          className={classes.button}
          onClick={handleClick}
          size="medium"
          color="command"
          variant="contained"
          startIcon={<HeightIcon />}
          endIcon={<ArrowDropDownIcon />}
        >
          <div className={classes.labelContainer}>{label}</div>
        </CustomButton>
      </CustomTooltip>
      <Popper
        id="orderBy"
        disablePortal={false}
        open={open}
        className={classes.root}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="bottom-start"
      >
        <Paper elevation={8} className={classes.orderByContainer}>
          <ClickAwayListener onClickAway={handleClose}>
            <List>
              {orderBySelection.map((orderByItem) => (
                <ListItem key={orderByItem}>
                  <Radio
                    checked={
                      orderByItem.toLowerCase() ===
                      selectedOrderBy.toLowerCase()
                    }
                    onChange={() => onOrderBySelected(orderByItem)}
                    name={orderByItem}
                    value={orderByItem}
                    color="primary"
                  />
                  <span>{Language.translate(orderByItem)}</span>
                </ListItem>
              ))}
            </List>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </React.Fragment>
  );
}
export default CustomOrderBy;
