/* global google */
//import { Wrapper, Status } from "@googlemaps/react-wrapper";
var gMap = null;
var lastClickedCoordinates=null;
var geofences=[];
var lastMaxZoomNotifTimestamp=0;
const Language=require("sccLanguage").default;
var Utils=require("sccUtils").default;

function initGoogleMaps() {
	console.log("initting google maps now");
	gMap = new google.maps.Map(document.getElementById("googleMap"), {
		center: {lat: -34.397, lng: 150.644},
		zoom: 8,
		disableDefaultUI: true,
		restriction: {latLngBounds: {north: 85, south: -85, west: -179.9999, east: 179.9999}}
	});

	gMap.setOptions({
		minZoom: 3
	});

	gMap.setTilt(0); //disable 45 deg imagery

	setupMapCenter();

	return Promise.resolve();		
}

function checkForMaxZoom(coord, zoom){
	
	const UserSetting= require("sccUserSetting").default;
	const mapLayerVisibility= UserSetting.get("map_layers");

	if(mapLayerVisibility["Google Hybrid"]||mapLayerVisibility["Google Satellite"]){
		var mapCenter={lat: coord[1], lng: coord[0]};

		var maxZoomService = new google.maps.MaxZoomService();
		maxZoomService.getMaxZoomAtLatLng(mapCenter, function(response) {

			if (response.status == "OK" && response.zoom < zoom) {
				window.olMap.setZoom(response.zoom);
				notifyUserOfMaximumZoomLimit();
			}
		});
	}	
}


function notifyUserOfMaximumZoomLimit(){
	var currentTime=Date.now();
	const timeoutBeforeResendingNotif=5000;
	if((currentTime-lastMaxZoomNotifTimestamp)>timeoutBeforeResendingNotif){ //prevents notifications from being spammed to user's screen if user
		//attempts to zoom in multiple times in a short time.
		var notificationTitle=Language.translate("Max Zoom Reached");
		var message=Language.translate("Higher resolution satellite imagery not available in this region.");
		Utils.notify(
			notificationTitle,
			message,
			"",
			"error"
		);
		lastMaxZoomNotifTimestamp=currentTime;
	}
}

function setupMapCenter(){
	//log.debug("setting up map center - Google Maps");

	const UserSetting= require("sccUserSetting").default;		
	const longitude= Math.round(UserSetting.get("map_longitude")* 1000)/ 1000;
	const latitude= Math.round(UserSetting.get("map_latitude")* 1000)/1000;
	const zoomLevel= UserSetting.get("map_zoom_level");		
	gMap.setCenter(new google.maps.LatLng(latitude, longitude));
	gMap.zoom=zoomLevel;

}

function changeGoogleMapLayer(layer, map){
	if (!map || map===null){
		map=gMap;
	}

	switch(layer){
	case "Google Satellite":
		map.setMapTypeId("satellite");
		break;
	case "Google Street":
		map.setMapTypeId("roadmap");
		break;
	case "Google Hybrid":
		map.setMapTypeId("hybrid");
		break;
	case "Google Physical":
		map.setMapTypeId("terrain");
		break;
	default:
		map.setMapTypeId("roadmap");
	}
	
}

function getCenter(){ //formats it per openlayers class's coordinate object format
	let coord=[];
	coord.push(gMap.getCenter().lng());
	coord.push(gMap.getCenter().lat());
	return coord;
}

function getZoom(){
	return gMap.zoom;
}

function setCenter(coord){
	gMap.setCenter({lat: coord[1], lng: coord[0]});
}

function setCenterFromLatLngObj(coord){ //latlng object type is native to google maps API
	gMap.setCenter(coord);
}

function setZoom(zoom){
	gMap.setZoom(zoom);
}

function zoomIn(){
	gMap.setZoom(gMap.zoom+1);
}

function zoomOut(){
	gMap.setZoom(gMap.zoom-1);
}



module.exports={
	initGoogleMaps: initGoogleMaps,
	setupMapCenter: setupMapCenter,
	changeGoogleMapLayer: changeGoogleMapLayer,
	getCenter: getCenter,
	getZoom: getZoom,
	setCenter: setCenter,
	setCenterFromLatLngObj: setCenterFromLatLngObj,
	setZoom: setZoom,
	zoomIn: zoomIn,
	zoomOut: zoomOut,
	checkForMaxZoom: checkForMaxZoom,
	gMap: gMap,
	lastClickedCoordinates: lastClickedCoordinates,
	geofences: geofences,
};



