import React, { useState, useContext } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
// import Checkbox from "@material-ui/core/Checkbox";
import MapOverlayPoiCategory from "./MapOverlayPoiCategory";
import "./styles/MapOverlays.scss";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Language = require("sccLanguage").default;

export default function MapOverlays(props) {
  const OlMap =
    props.history == 1
      ? require("sccOlMapHistory").default
      : require("sccOlMapNew").default;

  const [assetOverlay, setAssetOverlay] = useState();
  const [poiOverlay, setPoiOverlay] = useState();
  const [geofenceOverlay, setGeofenceOverlay] = useState();

  const mapOverlays = OlMap.map.getLayers().getArray()[1];
  const overlays = mapOverlays.getLayers().getArray();

  // Change map layer
  const handleMapOverlaysChange = (e, layer, overlay) => {
    switch (e.target.name) {
      case "Assets":
        setAssetOverlay(e.target.checked);
        toggleLayerVisibility(layer, overlay);
        return;
      case "POI":
        setPoiOverlay(e.target.checked);
        toggleLayerVisibility(layer, overlay);
        return;
      case "Geofences":
        setGeofenceOverlay(e.target.checked);
        toggleLayerVisibility(layer, overlay);
        return;
      default:
        return;
    }
  };

  const toggleLayerVisibility = (layer, layerGroup) => {
    let visibility = true;
    visibility = !layer.getVisible();
    layer.setVisible(visibility);
  };

  const classes = useStyles();

  const handleToggle = (layer, overlay) => () => {
    switch (layer.get("title")) {
      case "Assets":
        setAssetOverlay(layer.getVisible());
        toggleLayerVisibility(layer, overlay);
        return;
      case "POI":
        setPoiOverlay(layer.getVisible());
        toggleLayerVisibility(layer, overlay);
        return;
      case "Geofences":
        setGeofenceOverlay(layer.getVisible());
        toggleLayerVisibility(layer, overlay);
        return;
      default:
        return;
    }
  };
  return (
    <div className="panel mapOverlays">
      <h5>{Language.translate("Map Layers")}</h5>

      <List className={`mapOverlaysListBox ${classes.root}`} dense={false}>
        {overlays.map((layer, id) => {
          const labelId = `checkbox-list-label-${layer.get("title")}`;
          return layer.get("title") ? (
            <ListItem
              key={id}
              className="mapOverlaysListBoxItem"
              role={undefined}
              dense
              button
              onClick={handleToggle(layer, mapOverlays)}
            >
              <ListItemIcon className="mapOverlaysListBoxItemCheckbox">
                <Checkbox
                  checked={layer.getVisible()}
                  color="primary"
                  name={layer.get("title")}
                />
              </ListItemIcon>
              <ListItemText
                className="mapOverlaysListBoxItemTitle"
                id={labelId}
                primary={Language.translate(layer.get("title"))}
              />

              {layer.get("title") === "POI" && layer.getVisible() ? (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="poi categories">
                    <MapOverlayPoiCategory
                      poiCategoryArr={props.poiCategoryArr}
                      setPoiCategoryArr={props.setPoiCategoryArr}
                      {...props}
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : (
                ""
              )}
            </ListItem>
          ) : (
            ""
          );
        })}
      </List>
    </div>
  );
}
