import React from "react";

// External
import _ from "lodash";

// Material-UI
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import Language from "sccLanguage";
import TimeUtils from "sccTimeUtils";
import Chat from "sccChat";
import Message from "sccMessage";

const useStyles = makeStyles((theme) => ({
  chatMessageFeed: {
    maxHeight: 300,
    overflowY: "auto",
    overflowX: "hidden",
    height: "auto",
    listStyle: "outside none none",
    clear: "both",
  },

  root: {
    flexGrow: 1,
    overflow: "hidden",
  },

  floatRight: {
    float: "right",
    borderRadius: 8,
    width: "65%",
    padding: 2,
    margin: 2,
    backgroundColor: theme.palette.colors.white.main,
  },

  floatLeft: {
    float: "left",
    borderRadius: 8,
    width: "65%",
    padding: 2,
    margin: 2,
  },

  cannedMessage: {
    fontWeight: "bold",
  },

  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    background: "#a1defd !important",
  },

  paperRight: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    backgroundColor: `${theme.palette.colors.green.light} !important`,
  },

  paperPending: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    color: "#8a6d3b!important",
    backgroundColor: `#fcf8e3 !important`,
  },

  paperFailed: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    color: `${theme.palette.background.warning.dark} !important`,
    backgroundColor: `${theme.palette.background.warning.main} !important`,
  },

  emptyDiv: {
    textAlign: "center",
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid grey",
    padding: 2,
    margin: 4,
    borderRadius: 6,
  },

  messageWord: {
    wordWrap: "break-word",
  },

  fullWidth: {
    width: "100%",
  },

  fileInfoContainer: {
    margin: "8px 4px",
  },

  uploadFileGrid: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  downloadFile: {
    cursor: "pointer",
  },
}));

export default function ChatMessageFeed({ device }) {
  const messages = Chat.chatMessages;

  const classes = useStyles();

  //  return className based on message status
  const renderSendingMessageStatusClass = (messageStatus) => {
    switch (messageStatus) {
      case "fail":
        return classes.paperFailed;
      case "pending":
        return classes.paperPending;
      case "success":
      default:
        return classes.paperRight;
    }
  };

  function didISendThis(message) {
    const Profile = require("sccProfile").default;
    var activeUserData = Profile.get();
    return message.senders.users.indexOf(activeUserData.id) > -1;
  }

  function cleanMessageKeys(messagesToClean) {
    var baseIndex = 0;
    var cleanedMessages = _.mapKeys(messagesToClean, () => {
      return baseIndex++;
    });
    return cleanedMessages;
  }

  const renderMessagesContent = () => {
    if (messages === null || messages === undefined) return null;
    if (messages && Object.keys(messages).length === 0) {
      return (
        <div className={classes.emptyDiv}>
          <Typography variant="h6">
            {Language.translate("NO MESSAGE")}
          </Typography>
        </div>
      );
    }
    if (messages && Object.keys(messages).length > 0) {
      return (
        <div>
          {_.map(cleanMessageKeys(messages), (message) => {
            const messageStatus =
              message.recipients?.device_message_status[`${device.id}`]
                ?.message_status;
            return didISendThis(message) ? (
              <li
                id={message.id}
                key={message.id}
                className={classes.floatRight}
              >
                <div className={classes.root}>
                  <Paper
                    className={renderSendingMessageStatusClass(messageStatus)}
                  >
                    <Grid container>
                      <Grid item className={classes.fullWidth}>
                        {_.isNull(message.cannedmessage_id) != true ? (
                          <Typography className={classes.cannedMessage}>
                            {Message.getCannedMessageById(
                              message.cannedmessage_id
                            )}
                          </Typography>
                        ) : (
                          ""
                        )}
                        <Typography className={classes.messageWord}>
                          {message.message}
                        </Typography>
                        {message.attachments?.length > 0 ? (
                          <Grid container className={classes.fileInfoContainer}>
                            <Grid
                              item
                              xs={1}
                              className={classes.uploadFileGrid}
                            >
                              <a
                                href={
                                  message.attachments[0].attachment_location
                                }
                                download
                                target="_blank"
                                className={classes.downloadFile}
                              >
                                <AttachFileIcon fontSize="medium" />
                              </a>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              className={classes.uploadFileGrid}
                            >
                              {message.attachments[0].attachment_name}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              className={classes.uploadFileGrid}
                            >
                              {message.attachments[0].attachment_size}KB
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Typography>
                          {TimeUtils.getTimeString(message.message_timestamp)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </li>
            ) : (
              <li
                id={message.id}
                key={message.id}
                className={classes.floatLeft}
              >
                <div className={classes.root}>
                  <Paper className={classes.paper}>
                    <Grid container>
                      <Grid item xs={10} className={classes.fullWidth}>
                        {_.isNull(message.cannedmessage_id) != true ? (
                          <Typography className={classes.cannedMessage}>
                            {Message.getCannedMessageById(
                              message.cannedmessage_id
                            )}
                          </Typography>
                        ) : (
                          ""
                        )}
                        <Typography className={classes.messageWord}>
                          {message.message}
                        </Typography>
                        {message.attachments?.length > 0 ? (
                          <Grid container className={classes.fileInfoContainer}>
                            <Grid
                              item
                              xs={1}
                              className={classes.uploadFileGrid}
                            >
                              <a
                                href={
                                  message.attachments[0].attachment_location
                                }
                                target="_blank"
                                download
                                className={classes.downloadFile}
                              >
                                <AttachFileIcon fontSize="medium" />
                              </a>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              className={classes.uploadFileGrid}
                            >
                              {message.attachments[0].attachment_name}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              className={classes.uploadFileGrid}
                            >
                              {message.attachments[0].attachment_size}KB
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <Typography>
                          {TimeUtils.getTimeString(message.message_timestamp)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </li>
            );
          })}
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <ul id="autoscroll" className={classes.chatMessageFeed}>
        {renderMessagesContent()}
      </ul>
    </React.Fragment>
  );
}
