var Utils= require("sccUtils").default;
var _= require("lodash");
var Language = require("sccLanguage").default;
//stores all objects instances of the class
const _objects= [];

//stores all objects instances of the class by their tab name
const _objectsByName = {};

const log = require("loglevel");
const $ = require("jquery");


/**
 * Menu Class
 * 
 * Generates a new menu item on the platform front-end.
 * 
 * The code for Menu class would go here in future.
 * 
 * @class Menu
 * 
 * @param {Object} options - menu options
 * options: {
 * 		title: {String} // menu title
 * 		shortTitle: {String} // menu title shown on navigation bar, "title"" if not porvided 
 * 		tab: {String} // [optional] title for the menu tab on navigation bar 
 * 			would use title if not provided. 
 * 		imageClass: {String} // [optional] name of the image class icon font
 * 		imageIcon: {String} // [optional] name of the image if there is no icon font
 * 		imageIconMenu: {String} // [optional] name of the image for the menu if there is no icon font
 * 		mainClass: {String} // gives unique class to each menu
 * 		moduleName: {String} // [optional] name of the menu module. 
 * 			lower case of the title if not porvided.
 * 		module: {Object} // [optional] object representing the module to which this menu 
 * 			is related, default is null
 * 		searchable: {Boolean} // [optional] indicates whether or not the menu is searchabe, 
 * 			defualt is true.
 * 		entityTitle: {String} // [optional] shows the key in editObj object to retreive 
 * 			the name/title of the entity being edited. Defualt value is "title".
 * 		displayName: {String} // [optional] the title to be used for messages displayed to users.
 * 			if not provided the title value is used.
 * 		showFooter: {Boolean} // [optional] indicates whether or not the menu footer is shown, 
 * 			defualt is true.
 * 		showAdd: {Boolean} // [optional] indicates whether or not the Add button 
 * 			should be shown, default is true.
 * 		showEdit: {Boolean} // [optional] indicates whether or not the Edit button 
 * 			should be shown, default is false.
 * 		verifyDeletePermission:{Boolean} // [optional] indicates whether or not permission 
 * 			for deleting an item should be verified, default is true.
 * 		verifyAddPermission: {Boolean} // [optional] indicates whether or not permission 
 * 			for adding a new item should be verified, default is true.
 *		isSubMenu: {Boolean} // [optional] indicates whether or not the menu is a sub-menu, 
 			default is false.
 *      isList: {Boolean} // [optional] indicates whether or not the menu is a list, 
 * 			default is true.
 * 		mapLayer: {Object} // [optional] the map layer name if module has one.
 * }
 */
class  Menu{	
	constructor(options){
		if(!options || !options.title || !options.tab) throw new Error("Menu class initialization requires options. received " + JSON.stringify(options));
	
		// storing the current object 
		_objects.push(this);
		_objectsByName[options.tab]= this;
		
		log.debug(options.title, "Menu Initialized.", this);
		
		options.shortTitle= options.shortTitle || options.title;	
		options.moduleName= options.moduleName || options.tab.toLowerCase();
		options.searchable= (options.searchable == null)? true : options.searchable;
		options.showPagination= (options.showPagination == null)? true : options.showPagination;
		options.entityTitle= (options.entityTitle == null)? "title" : options.entityTitle;
		options.displayName= (options.displayName== null)? options.title : options.displayName;
		options.showFooter= (options.showFooter == null)? true : options.showFooter;
		options.showAdd= (options.showAdd == null)? true : options.showAdd;
		options.showEdit= (options.showEdit == null)? false : options.showEdit;
		options.isSubMenu= (options.isSubMenu == null)? false : options.isSubMenu;
		options.isList= (options.isList == null)? true : options.isList;
		options.verifyAddPermission= (options.verifyAddPermission == null)? false : options.verifyAddPermission;
		options.verifyDeletePermission= (options.verifyDeletePermission == null)? false : options.verifyDeletePermission;
		options.module= options.module || null;
		options.filters= {};
		options.pagination= {
			currentPage: 1,
			itemsPerPage: 20,
			numberofItems: null
		};

		options.itemsPerPageOptions= {
			"10": 10,
			"20": 20,
			"50": 50,
			"100": 100
		};

		// shows whether or not menu is initialized
		this.menuInitialized= false;
		
		this._options= options;	
		this.$scope= null;	
		
	}

	init($scope){

		this.$scope= $scope;
		var tab = this._options.tab;
		$scope.showMenu[tab]= false;
		$scope.showEdit[tab]= false;
		$scope.showAdd[tab]= false;
		$scope.menu= this._options;			
		$scope.forms= {};	
		
		var $this= this;
		// click open menu button
		$scope.toggleMenu = function(){								
			$this.toggle();
		};
		
		// click add new button
		$scope.addNewClick= function(obj){
			$this.openAddMenu(obj);
		};
		
		// click edit button
		$scope.editClick= function(obj){
			$this.openEditMenu(obj);		
		};
		
		// click delete from the list 
		$scope.deleteClick= function(obj){
			$this.delete(obj);
		};
		
		
		// save update or insert
		$scope.saveEditClick= function(obj){
			$this.save(obj);		
		};
		
		$scope.toggle = false;
		$scope.profile = false;
		// Toggles active class
		$scope.isActive = false;
		
		//enabling save button to show
		$this.saveButtonState(false);
		
		//enabling delete button to show
		$this.delButtonState(false);
		
		$scope.activeButton = function() {	
			$scope.isActive = !$scope.isActive;
		};
		
		
		// delete object from the edit menu
		$scope.deleteEditClick= function(){
			var obj= $scope.editObj;
			$scope.deleteClick(obj); // TODO: change to a function call
		};
		
		// close menu
		$scope.closeMenuClick= function(){
			// the close button would first hide the edit menu if it is open 
			/*		var inEdit= _.find($scope.showEdit, function(value){
						return value
					});
					if(inEdit){
						$this.cancelEdit();
						return;
					}
			*/	
			var message = "";	
			//The following scope variable edits are specifically related to profile menu. To reset password fields and to close change password toggle
			// Check if Profile menu
			if($scope.menu.tab == "Profile"){
				if(!_.isEmpty($scope.editObj.passwords)){
					message = Language.translate("Your current changes will be lost. Do you want to continue?");
					return Utils.confirm(message, function(confirmed){
						if(confirmed){		
							$scope.cancelProfile();
							$this.close(true);
						}
					});
				}
			} else if ($scope.showAddCategory) {
				message = Language.translate("Your current changes will be lost. Do you want to continue?");
				return Utils.confirm(message, function(confirmed){
					if(confirmed){		
						$this.close(true);
					}
				});
			}
			$this.close(true);
		};
		
		
		// cancel edit
		$scope.cancelEditClick= function(){
			$this.cancelEdit();
		};
		
		$scope.listItemClicked= function(id){
			$this.toggleListInfo(id);		
		};
	
		var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
			//alert('DETECTED MOBILE DEVICE');
		}
		
		this.menuScrollHeight();
	
		$(window).resize(function() {
			$this.menuScrollHeight();
		});

		this.menuInitialized= true;
		return Promise.resolve();
	}

	/**
	 * gets all instances of menu that has been created
	 * @return {Array} instances of menu class created
	 */
	static getInstances(){
		return _objects;
	}
	
	menuScrollHeight(){
		
		var $height = $(window).height();
		var $width =$(window).width();
		var newHeight;
	
		if($width > 1400){
			newHeight = 158;
		}else{
			newHeight = 208;
		}
		var height = parseInt($height) - newHeight;
		
		var style = $("<style>.menuScrollHeight {max-height:"+height+"px;}</style>");
		$("html > head").append(style);
	}
	
	openAddMenu(obj){
		this.add(obj);	
	}	
	
	openEditMenu(obj){
		this.edit(obj);	
	}	
	
	
	getOptions(){
		return this._options;
	}
	
	open(){		
		var tab= this._options.tab;
		this.$scope.menu= this._options;
		var $this= this;
		
		return this.closeOpenMenu()
		.then(function(){
			if(!$this.$scope.menu.isSubMenu){
				if($("#profile_drop_down").collapse)
					$("#profile_drop_down").collapse("hide");
			}
			if (!$this.$scope.cancel) { //if the user has not clicked cancel, open the tab
				$this.$scope.showMenu[tab] = true;
			}
			
			// Check if users screen is less 1400 px
			// Automatically close navigation tabs when clicking a tab
			//var $windowSize = $(window).width();
			var $mobileMenuButton = $(".mobileMenuButton");	
			//if($windowSize <= 1400){
			if($mobileMenuButton.hasClass("active")){
				$mobileMenuButton.click();
			}
			//}
			
			// this function is used for any module that needs to run 
			// extra operations after menu opens. it is mainly used by modules 
			// that are not based on Angular. Therefore, it can be removed when
			// those modules are converted.
			if($this.$scope.loadMenu) $this.$scope.loadMenu();
			
		});		
	}
	
	
	closeOpenMenu(){
		var lastOpenMenu= this;
		_.each(this.$scope.showMenu, function(item, key){
			if(item == true){
				lastOpenMenu= _objectsByName[key];
			}
		});
	
		if(lastOpenMenu == this){
			return Promise.resolve();
		}

		return lastOpenMenu.close();
	
	}
	
	toggle(){
		var tab= this._options.tab;
		var state = this.$scope.showMenu[tab];
		if(!state){		
			this.open();
			// check if meesage tab is clicked and then refresh data
			// Temporary fix
			if(tab === "MSG"){
				const MessageMenu= require("sccMessageMenu");
				MessageMenu.refresh();
			}
		}else{
			this.close();
		}
	}	
	
	
	edit(obj){
		var tab= this._options.tab;	
		
		this.$scope.showEdit[tab]= true;
		this.$scope.showAdd[tab]= false;
	
		// assigning a clone of the original object to the editObj
		this.$scope.editObj= (obj)? _.cloneDeep(obj) : {};
	
		// storing the original data to keep track of changes
		this.$scope.editObjMaster= _.cloneDeep(this.$scope.editObj); 	
	}
	
	
	add(obj){
		var tab= this._options.tab;
		this.$scope.showEdit[tab]= true;
		this.$scope.showAdd[tab]= true;
		
		//assigning a clone of the original object to the editObj
		this.$scope.editObj= (obj)? _.cloneDeep(obj) : {};
	
		// storing the original data to keep track of changes
		this.$scope.editObjMaster= _.cloneDeep(this.$scope.editObj);
	}
	
	getEditObj(){
		return this.$scope.editObj;
	}
	
	
	save(editObj){
		var $this= this;
		var $scope= this.$scope;
		var obj= editObj || $scope.editObj;
		var moduleObj= this._options.module;
		
		//Disabling save button to avoid user sending multiple requests
		$this.saveButtonState(true);
	
		// sets device_id field on obj to pass validation
		obj.device_id = obj.id;

		if(obj.id){ // object has been edited
			return moduleObj.update(obj)
			.then(function(updatedObj){			
				log.debug("Object Updated:", updatedObj);
				var updateStr= Language.translate("Successfully Updated");
				return afterSave(updateStr);
			})
			.catch(function(error){
				Utils.notify({message: error.message, type: "error", title: error.name});
				//enabling save button to show
				$this.saveButtonState(false);
			});		
		}else{ // a new object is being added
			return moduleObj.add(obj)
			.then(function(postedObj){							
				log.debug("Object Inserted:", postedObj);
	
				var insertStr= Language.translate("Successfully Created");		

				if($this.getDisplayName(obj) == "User" && postedObj.data.result.username == null){
					insertStr = insertStr + ". " + Language.translate("A registration email has been sent to the user");
				}
	
				return afterSave(insertStr);
			})
			.catch(function(error){
				Utils.notify({message: error.message, type: "error", title: error.name});
				$this.saveButtonState(false);
			});
		}
	
		function afterSave(saveStr){
			var updatedTitle = $this.getEntityTitle(obj);
			var displayName =  $this.getDisplayName(obj);
			var displayTranslated = Language.translate(displayName);
			
			Utils.notify({ header: displayTranslated, title: saveStr, message: updatedTitle, type: "success"});

			// storing editObj in editObjMaster 
			// to prevent confirmation popup for cancelling changes  
			$scope.editObjMaster= _.cloneDeep($scope.editObj);
			
			$this.closeEdit();
			$this.clearFormFields();
			//enabling save button to show
			$this.saveButtonState(false);
			return Promise.resolve();
		}
	}
	
	
	delete(obj){
		var $this= this;
		var $scope= this.$scope;
		var moduleObj= this._options.module;
		var displayName = this.getDisplayName(obj);
		var displayTranslated = Language.translate(displayName);
		//$this.cancelEdit();
	
		//This code added to meet requirements in bug 1461 on planio. They wanted the name of the entity being deleted as opposed to just name of the module
		var deletedTitle = this.getEntityTitle(obj);
		var strConfirm= Language.translate("Are you sure you want to delete");
		return Utils.confirm(strConfirm+ " "+displayTranslated +" " +deletedTitle+"?", function(confirmed){
			if(!confirmed) return;
			
			// Added this code to fix Bug #2445
			// setting the editObj to its original state
			$scope.editObj= _.cloneDeep($scope.editObjMaster);

			//disabling delete button to restrict user from sending multiple delete requests to backend
			$this.delButtonState(true);
			return moduleObj.delete(obj)
			.then(function(deletedObj){
				log.debug("Object Deleted:", deletedObj);
				var msgDelete= Language.translate("Successfully Deleted");
	
				Utils.notify({ header: displayTranslated, title: msgDelete, message: deletedTitle, type: "success"});
	
				$this.closeEdit();
				//enabling delete button to show
				$this.delButtonState(false);
			})
			.catch(function(){
				var msgDeleteFailed = Language.translate("Failed to delete");
				Utils.notify({header: displayTranslated, title: msgDeleteFailed, message: deletedTitle, type: "error"});
				//enabling delete button to show
				$this.delButtonState(false);
			});
		});		
	}
	
	
	saveButtonState(isSave){
		var $scope = this.$scope;
		
		if(isSave){
			$scope.isSaveInProgress = true;
			$scope.saveButtonText = Language.translate("Saving") + "...";
		}
		else{
			$scope.isSaveInProgress = false;
			$scope.saveButtonText = Language.translate("Save");
		}		
	}
	
	/**
	 * This function returns name/title of the entity (users, poi, roles etc) based on the module name
	 */
	getEntityTitle(obj){
		if(obj[this._options.entityTitle]){
			return "'"+obj[this._options.entityTitle]+"'";
		}
		return "";
	}
	
	getDisplayName(){
		return this._options.displayName;	
	}
	
	delButtonState(isDelete){
		var $scope = this.$scope;
		
		if(isDelete){
			$scope.isDelInProgress = true;
			$scope.delButtonText = Language.translate("Deleting") + "...";
		}
		else{
			$scope.isDelInProgress = false;
			$scope.delButtonText = Language.translate("Delete");
		}
	}
	
	closeEdit(){
		var tab= this._options.tab;	
		this.$scope.showEdit[tab]= false;
	}
	
	
	close(force){
		var $this= this;
		var $scope= this.$scope;
	
		return this.cancelEdit()
		.then(function(){
			// the following code is a hack to hide the dropdown list when 
			// one of the non-submenu items is clicked.
			// Also, when the close button of the menu is clicked  
			// the dropdown list is forced to close so it does not stay on the screen.
			if(force){
				$("#profile_drop_down").collapse("hide");
			}
			if ($scope.cancelClose) {
				$scope.cancelClose = false;
			} else {
				//Empty the menu filter when menu is closed
				$this._options.filters = [];
				_.each($scope.showMenu, function(item, key){
					$scope.showMenu[key]= false;
				});
				$this.colapseList();
			}
		});
	}
	
	/**
	 * cancels user edits with confirmation if there has been any changes
	 * 
	 * @return {Object} promise object
	 */
	cancelEdit(){	
		var $this= this;
		var $scope= this.$scope;		
		return Utils.confirm(
			Language.translate("Your current changes will be lost. Do you want to continue?"), 
			function(confirmed){		
				if(confirmed){
					//This is an impromptu fix to clear fields from previous entry when user cancels and re-enters a form.
					$this.clearFormFields();					
					_.each($scope.showEdit, function(item, key){
						$scope.showEdit[key]= false;
					});
					$scope.cancelClose = false;
					// setting the editObj to its original state
					$scope.editObj= _.cloneDeep($scope.editObjMaster);
				} else { //user clicked cancel, change the value in the gobal scope to true
					$scope.cancelClose = true;
					if (!$this.$scope.cancel)
						$this.$scope.setCancel(true);
				}
			},		
			!_.isEqual($scope.editObj, $scope.editObjMaster)
		);
	}
	
	
	colapseList() {
		var $scope = this.$scope;
		_.each($scope.isListInfoOpen, function (item, id) {
			$scope.isListInfoOpen[id] = false;
		});
		if ($scope.cancel)
			$scope.setCancel(false);
	}
	
	/**
	 * programatically selects an item from the list 
	 * 
	 * @param {Integer} id - Id of the item to be selected
	 */
	toggleListInfo(id){
		var $scope=  this.$scope;
		$scope.isListInfoOpen= $scope.isListInfoOpen || {};
		if(!$scope.isListInfoOpen[id]){
			this.colapseList();
		}
		$scope.isListInfoOpen[id]= !$scope.isListInfoOpen[id];	
	}
	
	/**
	 * programatically selects an item from the list
	 * 
	 * @param {Integer} id - Id of the item to be selected
	 */
	openListInfo(id){
		var $scope=  this.$scope;
		$scope.isListInfoOpen= $scope.isListInfoOpen || {};
		if(!$scope.isListInfoOpen[id]){
			this.colapseList();
		}
		
		$scope.colapseList[id]= true;
	}
	
	//This is an impromptu fix to clear fields from previous enty when user cancels and re-enters a form
	// issue was persistent with password fields in user menu and all fields in POI menu.
	// The problem is, the view does not get reset when we set the editObj to {} if the field is not valid.
	// Setting each property of the editObj to "" would clear the error from the form and fix the issue.
	// This code needs to be revised so the fields are not hardcoded. 
	clearFormFields(){
		var $scope= this.$scope;
		if(!_.isEmpty($scope.forms)){
			$scope.forms.mainMenu.$setPristine();
			$scope.forms.mainMenu.$setUntouched();
		}	
	}
	
	static logout(){
		// calling logout route to blacklist the token in nodejs
		var options= {
			url: Utils.apiUrlPrefix+ "/auth/logout",
			method: "POST",
			data: {}
		};
		return Utils.httpRequestHandler(options)
		.then(function(){
			closePages();		
			return Promise.resolve();
		})
		.catch(function(err){
			log.error(err);
			alert(err)
			closePages();
		});
	}
}

function closePages() {
	window.location = "index.html";
	window.location.href= "index.html";	
	const ExtendedMapMenu= require("sccExtendedMapMenu").default;	
	const Historic= require("sccHistory").default;
	const Reports= require("sccReport").default;
	$.each([Historic, Reports, ExtendedMapMenu], function(i, item){
		if (item == null) return;
		item.closeWindow();
	});	
}

export function logout() {
	Menu.logout();
};

//module.exports= Menu;
export default Menu;