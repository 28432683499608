import React, { useState, useContext, useEffect } from "react";

// External
import { useFormikContext } from "formik";
import * as yup from "yup";

// Material-UI
import { makeStyles, Grid, Button, MenuItem } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";

// Components
import CustomButton from "@Components/CustomButton";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomIcon from "@Components/CustomIcon";
import CustomSwitch from "@Components/CustomSwitch";
import CustomTextField from "@Components/CustomTextField";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import AssetsFormInfo from "./AssetsFormInfo";
import Clock from "sccClock";
import Device from "sccDevice";
import Language from "sccLanguage";
import Permission from "sccPermission";
import schema from "@Validations/DeviceValidation";

const useStyles = makeStyles((theme) => ({
  assetsFormSettings: {
    margin: "60px 0 0 0",
    "& .settingsBtnFactoryReset": {
      float: "left",
      margin: "20px 0",
    },
    "& .settingsBtnPollSettings": {
      float: "right",
      margin: "20px 0",
    },
    "& .settingsBtnSendSettingsCode": {
      float: "right",
      margin: "20px 0",
    },
    "& .newSettingsWrapper": {
      width: "100%",
      marginTop: 20,
    },
    "& .itemTextFirst": {
      background: theme.palette.colors.gray.main,
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
      width: "50%",
      marginTop: -1,
      "& .MuiChip-sizeSmall": {
        height: 21,
      },
    },
    "& .itemTextSecond": {
      border: `1px solid ${theme.palette.colors.gray.dark}`,
      padding: "4px 8px",
      width: "50%",
      marginTop: -1,
      marginLeft: -1,
    },
  },
}));

export default function AssetsFormSettings(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const formik = useFormikContext();
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const deviceType = props.deviceType;
  const pollCommands = mwState.pollCommands;

  const handlePollCommands = () => {
    pollCommands(
      moduleItemData,
      "send_settings",
      moduleItemData.newSettingsCode
    );
    formik.setFieldValue("newSettingsCode", "");
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.assetsFormSettings}>
        <Grid item xs={12} md={12}>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("TIME")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={Clock.formatTimestamp(
                Device.get(moduleItemData.id).poll_settings_timestamp
              )}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("FIRMWARE")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={Device.showFirmware(moduleItemData)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              md={6}
              className="itemTextFirst"
              primary={Language.translate("SETTINGS CODE")}
            />
            <ListItemText
              md={6}
              className="itemTextSecond"
              primary={
                Device.get(moduleItemData.id).poll_settings_code !== null
                  ? Device.get(moduleItemData.id).poll_settings_code
                  : "N/A"
              }
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} className="newSettingsWrapper">
          <CustomTextField
            label={`${Language.translate("NEW SETTINGS")}*`}
            value={moduleItemData.newSettingsCode}
            name="newSettingsCode"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={
              formik.touched.newSettingsCode &&
              Boolean(formik.errors.newSettingsCode)
            }
            helperText={
              formik.touched.newSettingsCode && formik.errors.newSettingsCode
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            className="settingsBtnSendSettingsCode"
            onClick={handlePollCommands}
            disabled={
              Boolean(formik.errors.newSettingsCode) ||
              !formik.values.newSettingsCode
            }
            size="medium"
            color="command"
            variant="contained"
            startIcon={<SettingsIcon />}
          >
            {Language.translate("Send Settings Code")}
          </CustomButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
