import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import { makeStyles, Grid, Button, MenuItem } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

// Components
import CustomCheckbox from "@Components/CustomCheckbox/CustomCheckbox";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomTextField from "@Components/CustomTextField";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import Language from "sccLanguage";
import Permission from "sccPermission";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function MapsForm() {
  const formik = useFormikContext();
  //to be used in add edit
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  const newMap = {
    title: "",
    code: null,
    is_base_layer: null,
    url: null,
  };

  const codeNames = [
    {
      title: "XYZ",
      value: "custom_xyz",
    },
  ];

  const handleDateBlur = () => {};

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      const mapData = mwState.moduleItemData;
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...newMap,
          ...mapData,
        },
        moduleItemDataOriginal: { ...newMap, ...mapData },
      }));
    }
  }, [mwState.wrapperDisplayMode]);

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <p className="pullRight">
              {"*" + Language.translate("Required fields")}
            </p>
          </Grid>
          <Grid item xs="12">
            <CustomTextField
              label={`${Language.translate("Name")}*`}
              value={moduleItemData.title}
              name="title"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>
          <Grid item xs="12">
            <CustomDropdown
              label={Language.translate("Map Code")}
              name="code"
              value={String(moduleItemData.code)}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            >
              {codeNames.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </CustomDropdown>
          </Grid>
          <Grid item xs="12">
            <CustomTextField
              label={`${Language.translate("Map Server URL")}*`}
              value={moduleItemData.url}
              name="url"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.url && Boolean(formik.errors.url)}
              helperText={formik.touched.url && formik.errors.url}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
