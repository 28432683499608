// External
import _ from "lodash";

// Internal
import Utils from "sccUtils";
import Socket from "sccSocket";
import log from "loglevel";

log.setLevel(log.levels.DEBUG);

/**
 * Generic Module class
 *
 * @class Module
 */
class Module {
  constructor(options) {
    this.moduleName = options.moduleName;

    // the event name to be used in case it is not in the standard format
    this.eventName = options.eventName || this.moduleName;

    this.routeUrl =
      options.routeUrl || Utils.apiUrlPrefix + "/" + this.moduleName;
    this._data = null;
    this.initialized = false;

    // defining custom getters and setters
    options.getterSetter && this.defineGetterSetter(options.getterSetter);
  }

  addCustomSocket(event, func) {
    Socket.socket.on(event, function (socketData) {
      log.info("Socket:" + event, socketData.result);
      func(socketData.result);
    });
  }

  /**
   * gets the Module's _data object or value of a specific key
   *
   * @method get
   * @static
   * @memberof Module
   * @param {Integer|Array} keys - an array of keys to be used to get to the requested value recursively
   * @return {object} module's _data object
   */
  get(keys) {
    // Module.prototype.get= function(keys){
    return this.getData(this._data, keys);
  }
}

/**
 * intializes the class
 *
 * @method init
 * @memberof Module
 * @return {object} Promise
 */
Module.prototype.init = function () {
  var $this = this;
  this.defineSockets();

  return this.loadData().then((data) => {
    $this.initialized = true;
    return Promise.resolve(data);
  });
};

/**
 * loads the module data from the database
 *
 * @method loadData
 * @memberof Module
 * @param {object} data - object to be added
 * @return {object} Module data in a promise
 */
Module.prototype.getAll = function (params) {
  return this.loadData(params);
};

/**
 * loads the module data from the database
 *
 * @method loadData
 * @static
 * @memberof Module
 * @param {object} data - object to be added
 * @return {object} Module data in a promise
 */
Module.prototype.loadData = function (params) {
  params = params ? "/" + params.join("/") : "";
  var options = {
    url: this.routeUrl + params,
    method: "GET",
  };
  var $this = this;
  return Utils.httpRequestHandler(options)
    .then(function (response) {
      console.log("response received from options.url", response);
      return $this.set(response.data.result);
    })
    .catch((e) => log.info(e));
};

/**
 * adds a new object of the module
 *
 * @method add
 * @static
 * @memberof Module
 * @param {object} data - object to be added
 * @return {object} Information of the added object
 */
Module.prototype.add = function (data) {
  var options = {
    url: this.routeUrl,
    method: "POST",
    body: data,
  };
  return Utils.httpRequestHandler(options);
};

/**
 * updates data of a given module
 *
 * @method update
 * @static
 * @memberof Module
 * @param {object} data - object to be updated
 * @return {object} information of the updated module
 */
Module.prototype.update = function (data) {
  var options = {
    url: this.routeUrl,
    method: "PUT",
    body: data,
  };
  return Utils.httpRequestHandler(options);
};

/**
 * removes a given object of the module by ID
 *
 * @method removeById
 * @static
 * @memberof Module
 * @param {integer} id - ID of the object to be removed
 * @return {object} Information of the removed object
 */
Module.prototype.delete = function (data) {
  var options = {
    url: this.routeUrl + "/" + data.id,
    method: "DELETE",
    body: {},
  };
  return Utils.httpRequestHandler(options);
};

/**
 * gets data value of a given data object given the keys
 *
 * @param {Any} value - value to be assgined/merged with the current object
 * @param {Array} keys - array of keys to be followed to find the right sub-object
 * @param {Boolean} merge - whether or not to merge the value with current object
 *
 * @return {Object} modified data object
 */
Module.prototype.getData = function (data, keys) {
  if (keys) {
    return this.searchKeysIn(data, keys);
  }

  return data;
};

/**
 * sets the Module's _data object or value of a specific key.
 * users can specify whether or not to merge the corresponding object.
 * if merge is false, an assignment would be used. Note that this would
 * overwrite the existing object.
 *
 * @param {Any} value - value to be assgined/merged with the current object
 * @param {Array} keys - array of keys to be followed to find the right sub-object
 * @param {Boolean} merge - whether or not to merge the value with current object
 *
 * @return {Object} modified _data object
 */
Module.prototype.set = function (value, keys, merge) {
  this._data = this.setData(this._data, value, keys, merge);
  return this._data;
};

/**
 * sets values on a given data object given the keys
 * @param {Any} value - value to be assgined/merged with the current object
 * @param {Array} keys - array of keys to be followed to find the right sub-object
 * @param {Boolean} merge - whether or not to merge the value with current object
 *
 * @return {Object} modified data object
 */
Module.prototype.setData = function (data, value, keys, merge) {
  merge = merge === false ? false : true; // would merge objects if merge is true or null

  if (!keys) {
    data = value || {};
    return data;
  }

  keys = _.concat([], keys); // convert to an array

  // holding the last key
  var lastKey = keys.pop();

  // getting the last object in the tree
  var obj = this.searchKeysIn(data, keys);

  if (value == null) {
    delete obj[lastKey];
  } else {
    if (merge && _.isObject(value) && _.has(obj, lastKey)) {
      // merge if object or array and key exists
      _.assign(obj[lastKey], value);
    } else {
      // assignment if a value type
      if (obj) {
        obj[lastKey] = value;
      }
    }
  }

  return data;
};

/**
 * defines custom getters and setters for variable names provided
 *
 * @method defineGetterSetter
 * @memberof Module
 * @param {Array} varNames namges of variables to define getters and setters for *
 */
Module.prototype.defineGetterSetter = function (varNames) {
  var $this = this;
  varNames = _.concat([], varNames);
  _.each(varNames, function (varName) {
    // building the property name
    var propertyName = "_" + _.lowerFirst(varName);

    // building the getter function name
    var getterName = "get" + _.upperFirst(varName);

    // building the setter function name
    var setterName = "set" + _.upperFirst(varName);

    // define getter
    Object.defineProperty($this, getterName, {
      enumerable: true,
      value: (keys) => {
        return $this.getData($this[propertyName], keys);
      },
    });

    // define setter
    Object.defineProperty($this, setterName, {
      enumerable: true,
      value: (value, keys, merge) => {
        $this[propertyName] = $this.setData(
          $this[propertyName],
          value,
          keys,
          merge
        );
        return $this[propertyName];
      },
    });
  });
};

Module.prototype.defineSockets = function () {
  // defining sockets
  const eventName = this.eventName;
  console.log("Socket setup for: " + this.eventName);
  const sockets = this.sockets || [
    "post:/" + eventName,
    "put:/" + eventName,
    "delete:/" + eventName + "/:id",
  ];

  var $this = this;
  _.each(sockets, function (socket) {
    Socket.socket.on(socket, function (socketData) {
      log.info("Socket:" + socket, socketData);
      $this.onSocket(socket, socketData.result);
      const socketInfo = socket.split(":/");
      setTimeout(() => {
        let addIds = [];
        let deleteIds = [];
        if (socketInfo[1] === "alert") {
          Object.values(socketData.result).map((item) => {
            if (item.info?.end_timestamp == null) {
              addIds.push(item.id);
            } else if (item.acknowledgements != undefined) {
              deleteIds.push(item.id);
            }
          });
        } else {
          addIds.push(socketData.result.id);
        }
        const hiddenBtn = document.getElementById("hiddenBtn");
        hiddenBtn?.setAttribute("data-action", socketInfo[0]);
        hiddenBtn?.setAttribute("data-originator", socketInfo[1]);
        hiddenBtn?.setAttribute("data-addids", addIds.join(", "));
        hiddenBtn?.setAttribute("data-deleteids", deleteIds.join(", "));
        hiddenBtn?.click();
      }, 100);
    });
  });
};

/**
 * searches for a given list of keys and returns the corresponding object
 *
 * @param {Object} data - data object to be searched
 * @param {Array} keys - list of keys to be searched
 *
 * @return {Object} the object if found, or null otherwise
 */
Module.prototype.searchKeysIn = function (data, keys) {
  if (!data) {
    log.warn(this.moduleName, ": Data is not loaded", keys);
    return null;
  }

  keys = _.concat([], keys); // convert to an array

  return _.reduce(
    keys,
    function (result, key) {
      // return null if key or result is null/undefined
      if (!key || !result) return null;

      return result[key];
    },
    data
  );
};

/**
 * function that runs every time socket event is triggered
 *
 * @param {String} event - event name
 * @param {Object} data - object received on the socket
 */
Module.prototype.onSocket = function (event, data) {
  console.log("*** incoming socket event: " + JSON.stringify(event));
  var action = event.split(":")[0];
  var url = event.split(":")[1];

  switch (action) {
    case "delete":
      this.onSocketDelete(url, data);
      break;
    case "put":
      this.onSocketUpdate(url, data);
      break;
    case "post":
      this.onSocketAdd(url, data);
      break;

    default:
      log.error("Socket event is not recognized.");
      break;
  }
};

/**
 * function that runs when socket sends delete event
 *
 * @param {String} url - url of the route that has triggered the socket
 * @param {Object} data - object received on the socket (single object or an array of objects)
 */
Module.prototype.onSocketDelete = function (url, data) {
  /*if(!data.id) throw new Error("Socket received data must have 'id'.");
	this.set(null, data.id);*/
  log.debug("Socket Delete:", url, data);

  const dataObjArray = processSocketData(data);
  var $this = this;
  _.forEach(dataObjArray, function (obj) {
    $this.set(null, obj.id);
  });
};

/**
 * function that runs when socket sends update event
 *
 * @param {String} url - url of the route that has triggered the socket
 * @param {Object} data - object received on the socket (single object or an array of objects)
 */
Module.prototype.onSocketUpdate = function (url, data) {
  log.debug("Socket Update:", url, data);
  const dataObjArray = processSocketData(data);
  var $this = this;
  _.forEach(dataObjArray, function (obj) {
    $this.set(obj, obj.id);
  });
};

/**
 * function that runs when socket sends add event
 *
 * @param {String} url - url of the route that has triggered the socket
 * @param {Object} data - object received on the socket (single object or an array of objects)
 */
Module.prototype.onSocketAdd = function (url, data) {
  log.debug("Socket Add:", url, data);

  const dataObjArray = processSocketData(data);
  var $this = this;
  _.forEach(dataObjArray, function (obj) {
    $this.set(obj, obj.id);
  });
};

/**
 * function parses the data sent to the socket 
 * to allow list of objects (key=>value) or a single object
 * It then alters the data in session based on data sent via socket
 
 * @param {Object} data - object received on the socket
 */
function processSocketData(data) {
  var dataObjArray = [];
  if (data.id) {
    dataObjArray.push(data);
  } else {
    dataObjArray = _.map(data, function (value) {
      if (!value.id)
        throw new Error("Data received over socket must have 'id'.");
      return value;
    });
  }
  return dataObjArray;
}

//module.exports = Module;

export default { Module };
