//import { default as Menu } from "sccMenu";
var _ = require("lodash");
var Menu = require("sccMenu").default;
var Utils = require("sccUtils").default;
var Poi = require("sccPoi").default;
const PoiOverlay = require("sccPoiOverlay").default;
var Language = require("sccLanguage").default;

/**
 * POI menu class
 *
 * @class PoiMenuModule
 * @param {object} options - poi menu information object
 */
class PoiMenuModule extends Menu {
  constructor() {
    const options = {
      tab: "POI",
      title: "POI",
      mainClass: "POI",
      //imageClass: "fa-map-marker",
      imageClass: "map-marker",
      mapLayer: PoiOverlay,
      module: Poi,
      showPagination: true,
    };

    super(options);
    this.poiCategory = {};
  }

  /**
   * @override
   */
  add(obj) {
    obj = _.assign(obj, {
      sync: { groups: [], devices: [] },
      image_id: 0,
      category_id: 0,
    });
    super.add(obj);
  }

  /**
   * @override
   */
  openEditMenu(obj) {
    if (obj.image_id) {
      obj.category = "platform";
      this.$scope.showForNato = false;
    } else if (obj.nato_code) {
      obj.category = "nato";
      obj.areaCode = obj.nato_code.charAt(2);
      obj.affiliationCode = obj.nato_code.charAt(1);
      this.$scope.showForNato = true;
    } else {
      this.$scope.showForNato = false;
      throw new Error("Could not identify POI category.");
    }

    super.openEditMenu(obj);
  }

  poiSyncAccept(poi) {
    var options = {
      url: Utils.apiUrlPrefix + "/poi/accept",
      method: "PUT",
      data: { id: poi.id, approved: true },
    };

    return Utils.httpRequestHandler(options);
  }

  poiSyncReject(poi) {
    var options = {
      url: Utils.apiUrlPrefix + "/poi/decline/" + poi.id,
      method: "DELETE",
    };

    return Utils.httpRequestHandler(options).then(function () {
      poi.kill && poi.kill(true);
    });
  }

  getPoiCategoryList() {
    const newPoiCategory = [{ title: Language.translate("NATO"), value: null }];
    const PoiCategory = require("sccPoiCategory").default;
    _.each(PoiCategory.get(), (poiCat) => {
      newPoiCategory.push({ title: poiCat.title, value: poiCat.id });
    });
    if (!_.isEqual(this.poiCategory, newPoiCategory)) {
      this.poiCategory = newPoiCategory;
    }
    return this.poiCategory;
  }

  getPoiCategory() {
    const PoiCategory = require("sccPoiCategory").default;
    return PoiCategory.get();
  }
}

require("../styles/poi_styles.scss");

export default new PoiMenuModule();
//module.exports= New PoiMenuModule();
