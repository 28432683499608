import React, { useContext, useEffect, useState } from "react";

// External
import _ from "lodash";

// Material-UI
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import PauseCircleOutlineOutlinedIcon from "@material-ui/icons/PauseCircleOutlineOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import Alert from "sccAlert";
import Permission from "sccPermission";
import AlertMenu from "sccAlertMenu";
import DeviceOverlay from "sccDeviceOverlay";
import Language from "sccLanguage";
import Device from "sccDevice";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";

const MW_ALERTS_MODULE_MODE = process.env.REACT_APP_MW_ALERTS_MODULE_MODE;

const useStyles = makeStyles((theme) => ({
  playButton: {
    display: "none",
    position: "absolute",
    top: 0,
    left: 0,
    width: 16,
    height: 16,

    "& svg": {
      width: 16,
      height: 16,
    },
  },

  Alerts: {
    "& .AlertButtons": {
      height: 50,
      width: 50,
      float: "right",
      background: theme.palette.colors.white.main,
      borderRadius: "1px!important",
      minWidth: "initial!important",
      position: "relative",
    },
    "& .AlertButtonType-emergency": {
      background: theme.palette.alerts.emergency.main,
      "& :hover": {
        background: theme.palette.alerts.emergency.main,
      },
    },
    "& .AlertButtonType-geofence": {
      background: theme.palette.alerts.geofence.main,
      "& :hover": {
        background: theme.palette.alerts.geofence.main,
      },
    },
    "& .AlertButtonType-speed": {
      background: theme.palette.alerts.speed.main,
      "& :hover": {
        background: theme.palette.alerts.speed.main,
      },
    },
    "& .AlertButtonType-non-report": {
      background: theme.palette.alerts.nonreport.main,
      "& :hover": {
        background: theme.palette.alerts.nonreport.main,
      },
    },
    "& .AlertButtonType-cargo": {
      background: theme.palette.alerts.cargo.main,
      "& :hover": {
        background: theme.palette.alerts.cargo.main,
      },
    },
    "& .AlertButtonType-vehicle": {
      background: theme.palette.alerts.vehicle.main,
      "& :hover": {
        background: theme.palette.alerts.vehicle.main,
      },
    },
  },
  "&.small": {
    height: 25,
    width: 25,
    "& .CustomIcon": {
      height: "1em!important",
      width: "1em!important",
    },
  },
}));

export default function Alerts(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    alerts: Alert.get(),
    menuInitialized: false,
    count: [],
  });
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [playIcon, setPlayIcon] = useState(false);

  const alertTypes = [
    { id: 7, type: "vehicle" },
    { id: 6, type: "cargo" },
    { id: 4, type: "non-report" },
    { id: 3, type: "speed" },
    { id: 2, type: "geofence" },
    { id: 1, type: "emergency" },
  ];

  const setAlertState = () => {
    setState({
      alerts: Alert.get(),
      menuInitialized: true,
    });
  };

  useEffect(() => {
    setAlertState();
  }, []);

  const setAlertCount = (type, alerts) => {
    const filtered = _.filter(alerts, { device_id: props.deviceid });
    if (type == "non-report") {
      type = "Non-Report";
    }
    return _.filter(alerts, { type: _.upperFirst(type) }).length;
  };

  const handleChange = (alertTypeId) => {
    // Open Alert Menu

    const AlertModuleObject = {
      tab: "Alert",
      shortTitle: "Alerts",
      title: "Alerts",
      moduleName: "Alert",
      module: Alert,
      imageClass: "bell",
      extLink: false,
      isList: true,
      wrapperDisplayMode: MW_ALERTS_MODULE_MODE,
      showFilter: true,
      showPagination: true,
      showSearch: true,
      searchField: ["device_title"],
      filterField: [
        {
          title: "Device",
          list: Device.get(),
          id: "device_id",
          displayField: "name",
          searchField: "device_title",
        },
        {
          title: "Alert Type",
          list: Alert.getAlertType(),
          id: "alert_type_id",
          displayField: "type",
          searchField: "device_title",
        },
      ],
      showFooter: true,
      verifyAddPermission: false,
      verifyEditPermission: false,
      verifyDeletePermission: false,
      verifyShownPermission:
        Permission.verify("alarm", "view") &&
        !Permission.verify("client", "view"),
      mapLayer: DeviceOverlay,
    };

    mwState.openModuleWrapperPopper(AlertModuleObject, {
      alert_type_id: [alertTypeId],
    });
  };

  const getAllertIdArray = (alerts) => {
    const arr = [];
    _.each(alerts, (alert) => {
      arr.push(alert.alert_type_id);
    });
    return _.sortedUniq(arr);
  };

  const onPlayClick = (e, isPlaySound) => {
    e.stopPropagation();
    setPlayIcon((p) => !p);

    if (isPlaySound) {
      AlertMenu.pauseAlertSound();
    } else {
      AlertMenu.playAlertSound();
    }
  };

  const alerts = props.alerts ? props.alerts : Alert.get();

  return (
    <div className={classes.Alerts}>
      {state.menuInitialized && alerts && Object.values(alerts).length > 0
        ? alertTypes.map((alert) =>
            _.intersection([alert.id], getAllertIdArray(alerts)).length > 0 ? (
              <CustomTooltip
                title={Language.translate(_.upperFirst(`${alert.type}`))}
                placement="bottom"
              >
                <Button
                  className={`AlertButtons AlertButtonType-${alert.type} ${
                    props.deviceid ? "small" : ""
                  }`}
                  key={alert.id}
                  name={alert.type}
                  onClick={() => handleChange(alert.id)}
                >
                  <div
                    id={`alertPlayButton${alert.type}`}
                    className={classes.playButton}
                  >
                    {playIcon ? (
                      <PlayCircleFilledWhiteOutlinedIcon
                        onClick={(e) => onPlayClick(e, false)}
                      />
                    ) : (
                      <PauseCircleOutlineOutlinedIcon
                        onClick={(e) => onPlayClick(e, true)}
                      />
                    )}
                  </div>

                  <Badge
                    badgeContent={setAlertCount(alert.type, alerts)}
                    color="secondary"
                  >
                    <CustomIcon type={alert.type} id={`icon-${alert.type}`} />
                  </Badge>
                </Button>
              </CustomTooltip>
            ) : (
              ""
            )
          )
        : ""}
    </div>
  );
}
