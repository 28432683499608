import React, { useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EditIcon from "@material-ui/icons/Edit";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

// Internal
import Language from "sccLanguage";
import Poi from "sccPoi";
import PoiOverlay from "sccPoiOverlay";
import Permission from "sccPermission";
import PoiCategory from "sccPoiCategory";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

const useStyles = makeStyles((theme) => ({
  dataDisplayButtonGroup: {
    borderRadius: "90 !important",
    width: "100% !important",
    "& .dataDisplayButton": {
      padding: "5px 10px",
      borderRadius: "0",
      width: "30px",
      minWidth: "30px",
      height: "30px",
      marginRight: "3px !important",
      border: `1px solid ${theme.palette.colors.gray.dark} !important`,
      backgroundColor: alpha(theme.palette.colors.black.main, 0.2),
    },
    "& :hover": {
      bgcolor: theme.palette.colors.gray.dark,
    },
    "& .svg": {
      fontSize: "16px",
    },
  },
}));

export default function DataDisplayButtonGroup(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const obj = props.device;
  const poiNumber = Object.keys(Poi.get()).length;

  //  poiObject used to open right layer, check MenuButtonList.js
  const poiObject = {
    tab: "POI",
    shortTitle: "POI",
    title: "POI",
    moduleName: "Poi",
    module: Poi,
    imageClass: "map-marker",
    extLink: false,
    isList: true,
    wrapperDisplayMode: MW_LIST_MODE,
    showFilter: true,
    showPagination: poiNumber > 10 ? true : false, // only show pagination if number bigger than 10
    showSearch: true,
    searchField: ["title"],
    filterField: [
      {
        title: "Category",
        list: _.assign(
          { null: { id: null, title: Language.translate("NATO") } },
          PoiCategory.get()
        ),
        id: "category_id",
        displayField: "title",
        searchField: "title",
      },
    ],
    showFooter: true,
    verifyAddPermission: Permission.verify("poi", "add"),
    verifyEditPermission: Permission.verify("poi", "edit"),
    verifyDeletePermission: Permission.verify("poi", "delete"),
    verifyShownPermission: Permission.verify("poi", "view"),
    mapLayer: PoiOverlay,
  };

  const handleDialogOpenClose = (e) => {
    mwState.setDialogOkAction(() => okActionClose(e));
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  function okActionClose(e) {
    handleEdit(e);
    mwState.setDialogOpen(false);
  }
  function cancelAction() {
    mwState.setDialogOpen(false);
  }

  const handleEdit = () => {
    const poiId = PoiOverlay.getSelectedId();
    const poiData = Poi.get(poiId);

    // open right edit popup
    PoiOverlay.deselectFeature();
    mwState.openModuleWrapperPopper(poiObject);
    mwState.handleItemEdit(poiData);
  };

  const handleZoom = () => {
    PoiOverlay.centerAndZoomTo(PoiOverlay.getSelectedId());
  };
  const handlePoll = () => {
    Poi.pollCommands(obj, "poll_gps");
  };

  const classes = useStyles();

  const showButtonGroup = (
    <ButtonGroup
      className={classes.dataDisplayButtonGroup}
      aria-label="outlined primary button group"
    >
      {Permission.verify("poi", "edit") ? (
        <CustomTooltip title={Language.translate("Edit")} arrow placement="top">
          <Button
            className="dataDisplayButton"
            onClick={(e) => {
              !mwState.moduleFormHasChanges
                ? handleEdit(e)
                : handleDialogOpenClose();
            }}
          >
            <EditIcon />
          </Button>
        </CustomTooltip>
      ) : null}

      <CustomTooltip
        title={Language.translate("Zoom In")}
        arrow
        placement="top"
      >
        <Button className="dataDisplayButton" onClick={handleZoom}>
          <GpsFixedIcon />
        </Button>
      </CustomTooltip>
    </ButtonGroup>
  );

  return <div>{showButtonGroup}</div>;
}
