import * as olSphere from "ol/sphere";
//const ol= require("ol");
const olSourceVector= require("ol/source/Vector").default;
const source = new olSourceVector();
const olLayerVector= require("ol/layer/Vector").default;
const olStyleStyle= require("ol/style/Style").default;
const olStyleFill= require("ol/style/Fill").default;
const olStyleStroke= require("ol/style/Stroke").default;
const olStyleCircle= require("ol/style/Circle").default;
const log = require("loglevel");

class MapMeasure {
	constructor(olMap){		
		this.map= olMap.map;

		// holds the active/inactive status of the line/area measure tool
		this.lineActive= false;
		this.areaActive= false;

		// holds the value of line/area measure tool
		this.lineValue= 0;
		this.areaValue= 0;
		this.setLineValue("Draw line on map");		
		this.setAreaValue("Draw area on map");
		this.draw= null;		
		this.vector = new olLayerVector({
			source: source,
			style: new olStyleStyle({
				fill: new olStyleFill({
					color: "rgba(255, 255, 255, 0.2)"
				}),
				stroke: new olStyleStroke({
					color: "#ffcc33",
					width: 2
				}),
				image: new olStyleCircle({
					radius: 7,
					fill: new olStyleFill({
						color: "#ffcc33"
					})
				})
			})
		});		
	}

	addInteraction(measureType) {
		const $this= this;
		this.removeInteraction()
		.then(()=>{
			$this.setIntractionParameters(measureType);
		});
		
	}

	setIntractionParameters(measureType){
		const $this= this;
		const type = (measureType == "area" ? "Polygon" : "LineString");
		const olInteractionDraw= require("ol/interaction/Draw").default;
		this.draw = new olInteractionDraw({
			source: source,
			type: type,
			style: new olStyleStyle({
				fill: new olStyleFill({
					color: "rgba(255, 255, 255, 0.2)"
				}),
				stroke: new olStyleStroke({
					color: "rgba(0, 0, 0, 0.5)",
					lineDash: [10, 10],
					width: 2
				}),
				image: new olStyleCircle({
					radius: 7,
					stroke: new olStyleStroke({
						color: "rgba(0, 0, 0, 0.7)",
						width: 2
					}),
					fill: new olStyleFill({
						color: "rgba(255, 255, 255, 0.2)"
					})
				})
			})
		});
		this.map.addInteraction(this.draw);
		// this.listener;
		this.draw.on("drawstart",
			function (evt) {
				// set sketch
				sketch = evt.feature;

				$this.listener = sketch.getGeometry().on("change", function (evt) {
					var geom = evt.target;					
					const Polygon= require("ol/geom/Polygon").default;
					const LineString= require("ol/geom/LineString").default;
					
					if (geom instanceof Polygon) {
						$this.setAreaValue(formatArea(geom));	
					} else if (geom instanceof LineString) {
						$this.setLineValue(formatLength(geom));
					}			
				});
			}, this);
	
		this.draw.on("drawend",
			function () {
				//$this.lineActive= false;
				//$this.areaActive= false;
				//$this.removeInteraction();
				
				// unset sketch
				sketch = null;
				
			}, this);
	}

	setLineValue(value){
		this.lineValue= value;		
	}
	
	setAreaValue(value){
		this.areaValue= value;
	}

	removeInteraction(){
		const $this= this;
		this.setAreaValue(0);
		this.setLineValue(0);

		if (this.draw != null) {
			// removing the intraction with timeout to prevent
			// map from zooming in when double clicked to finish 
			// drawing			
			return new Promise((resolve) => setTimeout(()=>{
				$this.map.removeInteraction($this.draw);
				$this.draw= null;
				resolve();
			}, 251));
		}else{
			return Promise.resolve();
		}
		
	}

	resetMeasureControls(){
		this.removeInteraction();
		this.areaActive= false;
		this.lineActive= false;
	}
	
	toggleLineControl(){		
		this.areaActive= false;	
		this.lineActive= !this.lineActive;		
		(this.lineActive)? this.addInteraction("line"): this.removeInteraction();
	}

	toggleAreaControl(){
		log.info("testing area measure")		;
		this.lineActive= false;	
		this.areaActive= !this.areaActive;		
		(this.areaActive)? this.addInteraction("area"): this.removeInteraction();
	}
}




/**
 * Currently drawn feature.
 * @type {ol.Feature}
 */
var sketch;



/**
 * Format length output.
 * @param {ol.geom.LineString} line The line.
 * @return {string} The formatted length.
 */
var formatLength = function (line) {
	var length = olSphere.getLength(line);
	var output;
	if (length > 100) {
		output = (Math.round(length / 1000 * 100) / 100) +
			" " + "km";
	} else {
		output = (Math.round(length * 100) / 100) +
			" " + "m";
	}
	return output;
};


/**
 * Format area output.
 * @param {ol.geom.Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
var formatArea = function (polygon) {
	//const olSphere= require("ol/sphere").default;
	var area = olSphere.getArea(polygon);
	var output;
	if (area > 10000) {
		output = (Math.round(area / 1000000 * 100) / 100) +
			" " + "km²";
	} else {
		output = (Math.round(area * 100) / 100) +
			" " + "m²";
	}
	return output;
};

export default MapMeasure;
