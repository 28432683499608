import React, { Component, useContext } from "react";

// Material-UI
import Paper from "@material-ui/core/Paper";

// Internal
import Device from "sccDevice";
import Historic from "sccHistory";
import HistoryTrailOverlay from "sccHistoryTrailOverlay";
import DeviceInfoHistoryTrail from "../DataDisplay/DeviceInfoHistoryTrail";
import DataDisplayHeader from "../DataDisplay/DataDisplayHeader";
import MinimizeContextProvider from "../context/MinimizeContext.js";
import { HistoryContextProvider } from "../../../history/components/RightPanel/HistoryContext";
import DeviceHistoryTrailAlertBar from "../DataDisplay/DeviceHistoryTrailAlertBar";

export default class HistoryTrailDataDisplay extends Component {
  constructor() {
    super();
    this.state = {
      report: null,
      device: null,
      isDeviceLocked: HistoryTrailOverlay.centerLocked,
    };

    if (!HistoryTrailDataDisplay.publicInstance) {
      HistoryTrailDataDisplay.publicInstance = this;
    }
  }

  static publicInstance;

  static updateFeatureSelected() {
    setTimeout(() => {
      HistoryTrailDataDisplay.publicInstance.setFeatureSelected();
    });
  }

  setFeatureSelected() {
    if (this.props.history === "1" && HistoryTrailOverlay.getSelectedId()) {
        const report = Historic.getReportById(HistoryTrailOverlay.getSelectedId());
        const device = Device.get(report.device_id);
      this.setState({
        device: device,
        report: report,
      });
    }
  }

  updateDeviceIsLocked = (isDeviceLocked) => this.setState({ isDeviceLocked });

  render() {
    const { report, device, isDeviceLocked } = this.state;

    const history = this.props.history || 0;

    return (
      <MinimizeContextProvider>
        {/* <SelectedDeviceProvider> */}
        <Paper elevation={3} id="olMapHistoryTrailPopup">
          {report ? (
            <>
              <DeviceHistoryTrailAlertBar currentReport={report} isDeviceDataDisplay />
              <DataDisplayHeader
                device={device}
                history={history}
                isDeviceLocked={isDeviceLocked}
                updateDeviceIsLocked={this.updateDeviceIsLocked}
                isHistoryTrail
              />

              <HistoryContextProvider>
                <DeviceInfoHistoryTrail report={report} device={device} />
              </HistoryContextProvider>
            </>
          ) : null}
        </Paper>
        {/* added only to get the device cluster working. Needs to be checked and edited */}
      </MinimizeContextProvider>
    );
  }
}
