import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext, getIn } from "formik";
import { toLower } from "lodash";

//Material UI
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Button, MenuItem } from "@material-ui/core";
import CustomButton from "@Components/CustomButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomCheckbox from "@Components/CustomCheckbox";
import Paper from "@material-ui/core/Paper";
import CustomTextField from "@Components/CustomTextField";
import SearchIcon from "@material-ui/icons/Search";
import CustomPagination from "@Components/CustomPagination";

// Components
import InputAdornment from "@material-ui/core/InputAdornment";
import AssetSelectorDisplay from "@Components/CustomAssetWrapper/AssetSelectorListingDisplay/AssetSelectorDisplay";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import Clock from "sccClock";
import IconButton from "@material-ui/core/IconButton";
import MyLocationIcon from "@material-ui/icons/MyLocation";

// Styles
import { moduleListing } from "@Styles/ModuleListing";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Device from "sccDevice";
import DeviceOverlay from "sccDeviceOverlay";
import DeviceInfo from "../../device/components/DataDisplay/DeviceInfo";
import Group from "sccGroup";
import Language from "sccLanguage";
import TimeUtils from "sccTimeUtils";
import Utils from "sccUtils";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { ReportContext } from "../../report/components/ReportContext";
import Alert from "sccAlert";

const Historic = require("sccHistory").default;

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  ...moduleListing(theme),
  alertChips: {
    marginRight: 10,
  },
  table: {
    "& .MuiTableCell-root": {
      padding: 5,
    },
    "& .tableBody": {
      overflow: "auto",
      "& .textOverflow": {
        display: "block",
        width: 100,
        paddingLeft: 5,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  settingsBtnSendSettingsCode: {
    float: "right",
    margin: "35px 0 0 0",
  },
  reportRateDropdown: {
    margin: "20px 0",
  },
  alerts: {
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: ".5rem",
      marginLeft: 3,
      marginTop: 5,
    },
    "& .MuiListItemIcon-root": {
      maxWidth: "10px !important",
    },
  },
  emergency: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#d9534f",
      fontSize: "0.65rem",
    },
  },
  geofence: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#f9840f",
      fontSize: "0.65rem",
    },
  },
  report: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#3d3d3d",
      fontSize: "0.65rem",
    },
  },
  speed: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#c43cc4",
      fontSize: "0.65rem",
    },
  },
  "non-report": {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#67d06c",
      fontSize: "0.65rem",
    },
  },
  cargo: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#148c90",
      fontSize: "0.65rem",
    },
  },
  vehicle: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: "#587fab",
      fontSize: "0.65rem",
    },
  },
  noResults: {
    fontWeight: "bold",
    width: "100%",
  },
}));

export default function HermesGatewaysDetail(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const data = props.data;
  const [value, setValue] = useState(0);
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const buildCollectionToDisplay = assetSelectorState.buildCollectionToDisplay;
  const formik = useFormikContext();
  const pollCommands = mwState.pollCommands;
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const [showEmergencyReports, setShowEmergencyReports] = useState(false);
  const [searchFilterText, setSearchFilterText] = useState("");
  const [allRemoteRadioReports, setAllRemoteRadioReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [allEmergencyReports, setAllEmergencyReports] = useState([]);
  const [searchedReports, setSearchedReports] = useState([]);
  const [reportState, setReportState] = useContext(ReportContext);
  const [dataArraysInitialized, setDataArraysInitialized] = useState(false);
  const reportData = reportState.reportData;

  const [itemsPerPage, setItemsPerPage] = useState(10);
  //const numberOfItems = devices && devices.length;
  const numberOfItems = 55;
  const [pagination, setPagination] = useState({
    // currentPage: 1,
    // itemsPerPage: itemsPerPage,
    // numberOfItems: numberOfItems,
    // maxPages: 5,
    // startIndex: 0,
    // endIndex: numberOfItems > itemsPerPage ? itemsPerPage : numberOfItems,
  });

  const alerts = [
    "Emergency",
    "Geofence",
    "Speed",
    "Non-Report",
    "Cargo",
    "Vehicle",
  ];

  const handlePollCommands = () => {
    pollCommands(
      Device.get(data.id),
      "send_settings",
      moduleItemData.newSettingsCode
    );
    formik.setFieldValue("newSettingsCode", "");
  };

  const handleLocate = (e, id) => {
    e.stopPropagation();
    DeviceOverlay.centerAndZoomTo(id);
  };

  function handleTabs(e, val) {
    setValue(val);
  }

  const handleFilterChange = (e) => {
    setSearchFilterText(e.target.value);
    handleSearchFilter(e.target.value);
  };

  useEffect(() => {
    loadRemoteRadioReports();
    setDataArraysInitialized(true);
  }, []);

  const loadRemoteRadioReports = () => {
    // console.log("devices ", Object.values(Device.get()));
    // const devices = Object.values(Device.get());
    // const remoteReports = devices.filter((device) => {
    //   return device.report_timestamp != undefined;
    // });
    const remoteReports = createRemoteRadioList(data.reports);

    setAllRemoteRadioReports(remoteReports);
    setFilteredReports(remoteReports);
    setSearchedReports(remoteReports);
    var emergencyReports = [];
    _.each(remoteReports, (report) => {
      if (Alert.getDeviceAlert(report.device_id, "Emergency")) {
        emergencyReports.push(report);
      }
    });
    setAllEmergencyReports(emergencyReports);

    setPagination(() => ({
      currentPage: 1,
      itemsPerPage: 10,
      numberOfItems: remoteReports.length,
      maxPages: 5,
      startIndex: 0,
      endIndex: numberOfItems > itemsPerPage ? itemsPerPage : numberOfItems,
    }));
  };

  const onPageChange = (currentPage, startIndex, endIndex) => {
    //change list display to show the current index of records
    setPagination((prevs) => ({
      ...prevs,
      currentPage: currentPage,
      startIndex,
      endIndex,
    }));
  };

  const arrItemsPerPage = [
    {
      value: 10,
      label: `10 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 20,
      label: `20 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 50,
      label: `50 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 100,
      label: `100 ${Language.translate("PER PAGE")}`,
    },
  ];

  const classes = useStyles();

  const updateFilteredData = (showEmergencyReports) => {
    setShowEmergencyReports(showEmergencyReports);

    if (showEmergencyReports) {
      var updatedFilteredReports = [];
      updatedFilteredReports = _.filter(filteredReports, (report) => {
        return allEmergencyReports.includes(report);
      });

      setFilteredReports(updatedFilteredReports);
    } else {
      setFilteredReports(allRemoteRadioReports);
    }
  };

  useEffect(() => {
    if (dataArraysInitialized) handleSearchFilter(searchFilterText);
  }, [showEmergencyReports]);

  //  use debounce to avoid too many re-renders
  const handleSearchFilter = _.debounce((value) => {
    const filterText = value;
    if (filterText !== "") {
      const filteredSearchedReports = filteredReports.filter(
        (report) =>
          report.name.toLowerCase().includes(filterText.toLowerCase()) ||
          report.type.includes(filterText.toLowerCase())
      );
      setSearchedReports(filteredSearchedReports);
      setPagination((prevs) => ({
        ...prevs,
        numberOfItems: filteredSearchedReports.length,
      }));
    } else {
      setSearchedReports(filteredReports);
      setPagination((prevs) => ({
        ...prevs,
        numberOfItems: filteredReports.length,
      }));
    }
  }, 300);

  const onItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setPagination((prevs) => ({
      ...prevs,
      currentPage: 1, //reset the current page to 1
      itemsPerPage: e.target.value, //based on the selection
      numberOfItems: searchedReports.length, //based on the items in the list right now
      startIndex: 0,
      endIndex:
        searchedReports.length > e.target.value
          ? e.target.value
          : searchedReports.length,
    }));
  };

  const createRemoteRadioList = function (reports) {
    var reportsArray = reports;
    // var name;
    // var timeStamp;
    // var type;

    if (!_.isUndefined(reportsArray) && !_.isEmpty(reportsArray)) {
      var devices = Device.get();
      if (_.isUndefined(devices) || _.isEmpty(devices)) {
        return [];
      }

      for (let i = 0; i < reportsArray.length; i++) {
        if (_.isUndefined(devices[reportsArray[i].device_id.toString()])) {
          reportsArray.splice(i, 1);
          continue;
        }
        reportsArray[i].name =
          devices[reportsArray[i].device_id.toString()].name;
        reportsArray[i].timestamp = Clock.formatTimestamp(
          reportsArray[i].report_timestamp
        );
        reportsArray[i].type =
          devices[reportsArray[i].device_id.toString()].type;

        if (
          _.isNull(reportsArray[i].alerts) ||
          _.isUndefined(reportsArray[i].alerts)
        ) {
          reportsArray[i].alerts = [];

          var alertTypes = Alert.getAlertType();
          if (!_.isUndefined(reportsArray[i].historyAlerts)) {
            _.forEach(alertTypes, function (alert) {
              for (
                var reportAlertIterator = 0;
                reportAlertIterator < reportsArray[i].historyAlerts.length;
                reportAlertIterator++
              ) {
                if (
                  alert.id ==
                  reportsArray[i].historyAlerts[reportAlertIterator]
                    .alert_type_id
                ) {
                  reportsArray[i].alerts.push(alert.type);
                }
              }
            });
          }
        }
      }

      // reportsArray.forEach(report => {
      // 	report.name = devices[report.device_id.toString()].name;
      // 	report.timestamp = $scope.Clock.formatTimestamp(report.report_timestamp);
      // 	report.type = devices[report.device_id.toString()].type;
      // 	report.alerts = [];
      // 	alertsList.forEach(alert => {
      // 		if($scope.getDeviceAlert(report.device_id, alert)){
      // 			report.alerts.push(alert);
      // 		}
      // 	});
      // });

      return reportsArray;
    }

    return [];
  };

  return (
    <Grid container className={classes.detailsContainer}>
      <Grid item xs={12} md={12}>
        <Tabs
          value={value}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={Language.translate("Remote Radios")}
            icon={<CustomIcon fontSize="small" id="icon-group" />}
          />
          <Tab
            label={Language.translate("Info")}
            icon={<CustomIcon fontSize="small" id="icon-info-circle" />}
          />
        </Tabs>
        {value === 0 && (
          <Grid container item direction="row" spacing={1}>
            <Grid item xs="4">
              <CustomDropdown
                className={classes.reportRateDropdown}
                label={Language.translate("Report Rate")}
                name="newSettingsCode"
                value={Number(moduleItemData.newSettingsCode)}
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.non_report_threshold &&
                  Boolean(formik.errors.non_report_threshold)
                }
                helperText={
                  formik.touched.non_report_threshold &&
                  formik.errors.non_report_threshold
                }
              >
                {_.values(Device.getGatewayReportRate()).map((option) => (
                  <MenuItem key={option.value} value={Number(option.value)}>
                    {option.title} {option.type}
                  </MenuItem>
                ))}
              </CustomDropdown>
            </Grid>
            <Grid item xs="8">
              <Grid item xs={12}>
                <CustomButton
                  className={classes.settingsBtnSendSettingsCode}
                  onClick={handlePollCommands}
                  disabled={
                    Boolean(formik.errors.non_report_threshold) ||
                    !formik.values.newSettingsCode
                  }
                  size="medium"
                  color="command"
                  variant="contained"
                  startIcon={<SettingsIcon />}
                >
                  {Language.translate("Send Settings Code")}
                </CustomButton>
              </Grid>
            </Grid>

            <Grid item xs="12">
              <List>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "25%" }} align="center">
                          {Language.translate("Name").toUpperCase()}
                        </TableCell>
                        <TableCell style={{ width: "20%" }} align="center">
                          {Language.translate("Type").toUpperCase()}
                        </TableCell>
                        <TableCell style={{ width: "25%" }} align="center">
                          {Language.translate("Last Reported").toUpperCase()}
                        </TableCell>
                        <TableCell style={{ width: "20%" }} align="center">
                          {Language.translate("Alert").toUpperCase()}
                        </TableCell>
                        <TableCell style={{ width: "10%" }} align="center">
                          {Language.translate("Locate").toUpperCase()}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tableBody">
                      <TableRow>
                        <TableCell align="center">
                          <div className={"classes.orionSearch"}>
                            <CustomTextField
                              label=""
                              value={searchFilterText}
                              fullWidth={true}
                              onChange={handleFilterChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <CustomDropdown
                            id="pagingSelector"
                            className="dropdown"
                            value={itemsPerPage}
                            onChange={onItemsPerPageChange}
                          >
                            {arrItemsPerPage.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </CustomDropdown>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <CustomCheckbox
                            label={Language.translate("Emergency Only")}
                            name="showEmergencyReports"
                            checked={showEmergencyReports}
                            color="primary"
                            onChange={(e) => {
                              updateFilteredData(e.target.checked);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                      {searchedReports
                        .slice(pagination.startIndex, pagination.endIndex)
                        .map((item) => (
                          <TableRow>
                            <TableCell align="center">{item.name}</TableCell>
                            <TableCell align="center">{item.type}</TableCell>
                            <TableCell align="center">
                              {Clock.formatTimestamp(item.report_timestamp)}
                            </TableCell>
                            <TableCell align="center">
                              {alerts.map((alert) => {
                                if (
                                  Alert.getDeviceAlert(item.device_id, alert)
                                ) {
                                  return (
                                    <ListItemIcon>
                                      <CustomTooltip
                                        title={Language.translate(alert)}
                                        placement="top"
                                        className={classes[`${toLower(alert)}`]}
                                      >
                                        <span>
                                          <CustomIcon
                                            type={toLower(alert)}
                                            id={`icon-${toLower(alert)}`}
                                            fontSize="small"
                                          />
                                        </span>
                                      </CustomTooltip>
                                    </ListItemIcon>
                                  );
                                }
                              })}
                            </TableCell>
                            <TableCell align="center">
                              <CustomTooltip
                                title={Language.translate("Locate")}
                                placement="top"
                              >
                                <IconButton
                                  className="zoomToCoord"
                                  edge="end"
                                  aria-label="locate"
                                  size="small"
                                  onClick={(e) =>
                                    handleLocate(e, item.device_id)
                                  }
                                >
                                  <MyLocationIcon />
                                </IconButton>
                              </CustomTooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </List>
              <Grid item align="right" className="pagination" xs={12}>
                <CustomPagination
                  pagination={pagination}
                  onPageChange={onPageChange}
                />
              </Grid>
            </Grid>
            {!searchedReports.length && (
              <div align="center" className={classes.noResults}>
                {Language.translate("No Radios")}
              </div>
            )}
          </Grid>
        )}
        {value === 1 && <DeviceInfo device={data} />}
      </Grid>
    </Grid>
  );
}
