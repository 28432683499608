const OlMapClass= require("sccOlMapNew").OlMap;
const olExtent= require("ol/extent");
const Language = require("sccLanguage").default;
const options= {
	mapDiv: "mapW",
	coordinateDisplay: {
		elementId: "mainCoordDis"
	},
	layerSwitcherDisplay: {
		target: "HistoryMapLayerSwitcher",
		tipLabel: Language.translate("Map Layers") // Optional label for button
	},
	selectionInteraction: {
		mapOverlay: require("sccOlMapOverlay")
	},
	attributionDisplay: {
		elementId: "mapAttrDisplay"
	},
	zoomDisplay: {
		elementId: "olMapZoomButtons"
	},	
	scaleLineDisplay: {
		elementId: "scaleLineDIS"
	},
	events: [{
		name: "moveend",
		handlerFunction: function(){
			// this.$scope.safeApply();
			checkCornerOverflow(this);
		}
	}],
	mapCentering: true

};		

/*
 *	Check Corner Overlow
 * 
 *  If user drags map too far, or zooms out so that the edges of the map are overrun, adjust the map to prevent overflow
 */
function checkCornerOverflow(OlMap){
	var map = OlMap;
	var view = map.getView();
	var zoom = view.getZoom();
	var mapExtent = view.calculateExtent(map.getSize());
	var viewExtent = view.getProjection().getExtent();

	var mapMinX = olExtent.getBottomLeft(mapExtent)[0];
	var mapMinY = olExtent.getBottomLeft(mapExtent)[1];
	var mapMaxX = olExtent.getTopRight(mapExtent)[0];
	var mapMaxY = olExtent.getTopRight(mapExtent)[1];

	var viewMinX = olExtent.getBottomLeft(viewExtent)[0];
	var viewMinY = olExtent.getBottomLeft(viewExtent)[1];
	var viewMaxX = olExtent.getTopRight(viewExtent)[0];
	var viewMaxY = olExtent.getTopRight(viewExtent)[1];

	var newExtent = [ mapMinX, mapMinY, mapMaxX, mapMaxY];

	if (mapMinX < viewMinX) {
		newExtent[0] = viewMinX;
	}
	if (mapMinY < viewMinY) {
		newExtent[1] = viewMinY;
	}
	if (mapMaxX > viewMaxX) {
		newExtent[2] = viewMaxX;
	}
	if (mapMaxY > viewMaxY) {
		newExtent[3] = viewMaxY;
	}

	if (newExtent != mapExtent) {
		view.fit(newExtent);
		view.setZoom(zoom);
	}
}

export default new OlMapClass(options)
// const OlMapHistory = new OlMapClass(options);

// export default OlMapHistory;