import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const pwdRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,40}$/;

const schema = Yup.object().shape({
	client_id: baseSchema(Language).dropdownSelectNum,
	role_id: baseSchema(Language).dropdownSelectNum,
	first_name: baseSchema(Language).requiredStringMinMax,
	last_name: baseSchema(Language).requiredStringMinMax,
	email: baseSchema(Language).emailRequired,
	phone_number: baseSchema(Language).phoneNumber.nullable(true),
	email_registration: Yup.boolean(),
	username: Yup.string()
		.when("email_registration", {
			is: false,
			then: baseSchema(Language).requiredStringMinMax,
		}),
	password: Yup.string()
		.when("email_registration", {
			is: false,
			then: baseSchema(Language)
				.requiredStringPassword
				.matches(pwdRegex, Language.translate("Password must contain at least one number"))
		}),
	confirm: Yup.string()
		.when("email_registration", {
			is: false,
			then: baseSchema(Language)
				.requiredStringPassword
				.oneOf([Yup.ref('password'), null], Language.translate("Passwords are not identical"))
		})
});

export default schema;
