import React from "react";
import Typography from '@material-ui/core/Typography';
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles({
  root: {
    width: "90px",
    height: "50px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "0px",
    marginBottom: "10px",
  },
  track: {
    width: "40px",
    height: "20px",
    borderRadius: "10px",
  },
  switchBase: {
    "&$checked": {
      transform: "translateX(40px)",
    },
  },
  checked: {},

  thumb: {
    width: "32px",
    height: "32px",
    transform: "translateX(0px)",
  },
  label2: {
    color: "#2e6da4"
  }
});

export default function CustomSwitch(props) {
  const classes = useStyles();
  const {
    label,
    label2,
    checked,
    value,
    color,
    labelPlacement,
    error = null,
    helperText,
    onChange,
    ...other
  } = props;
  return (


    <Typography component="div">
      <Grid component="label" container alignItems="center" direction="column">
        <Grid item>{label}</Grid>
        <Grid item>
          <Switch
            label={label}
            checked={checked}
            onChange={onChange}
            value={value}
            name="switch"
            color={color}
            inputProps={{ "aria-label": "primary checkbox" }}
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track,
              thumb: classes.thumb,
            }}
            {...(error && { error, helperText })}
            {...other}
          />
        </Grid>
        <Grid className={checked && classes.label2 } item>{label2 && label2}</Grid>
      </Grid>
    </Typography>

  );
}
