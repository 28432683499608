import { default as Module } from "sccModule";
var Permission= require("sccPermission").default;
var _= require("lodash");
var log = require("loglevel");
/**
 * The base class for Groups
 * 
 * @class GroupModule
 */
class GroupModule extends Module.Module{
	constructor(){
		var options= {
			moduleName: "group",
			getterSetter: ["GroupTree", "GroupTactical", "GroupTreeTactical"]
		};
		super(options);
	}
	
}

/**
 * overrides the getGroupTree to find group node in the tree
 * 
 * @param {Object} parent the current parent node in the recursive search
 * @return {Object} node of tree if group is found and null otherwise
 */
GroupModule.prototype.getGroupTree= function(groupId){
	if(!groupId){
		return Module.Module.prototype.getGroupTree.call(this);
	}

	var group= this.findGroupTree(groupId);
	return group;
};


/**
 * finds and returns a node of the group tree by traversing in depth first order
 * 
 * @param {Number} groupId Id of the group to find
 * @param {Object} parent the current parent node in the recursive search
 * @return {Object} node of tree if group is found and null otherwise
 */
GroupModule.prototype.findGroupTree= function(groupId, parent){
	var $this= this;
	// starting from the whole tree 
	parent= parent || Module.Module.prototype.getGroupTree.call(this);
	if(parent.id == groupId) return parent;

	var foundGroup= null;
	_.each(parent.groups, function(group){
		foundGroup= foundGroup || $this.findGroupTree(groupId, group);
	});

	return foundGroup;
};


/**
 * Creates a tree structure from a flat list
 */
GroupModule.prototype.treeify= function(options){
	var groups= options && options.groups || this.get();
	var idAttr= options && options.idAttr || "id";
	var parentAttr= options && options.parentAttr || "parent_id";
	var childrenAttr= options && options.childrenAttr || "groups";

	var treeList = [];
	/*	
			var devices= options && options.devices || _.map(Device.get(), "id");
			var individualDevices={
			id: null,
			parent_id: 0,
			tier_level: 0,
			title: Language.translate("Individual Assets"),
			devices: devices,
			groups: []
		}

		treeList.push(individualDevices);
	*/
	var lookup = {};
	_.each(groups, function(obj) {
		lookup[obj[idAttr]] = obj;
		obj[childrenAttr] = [];
	});
	_.each(groups, function(obj) {
		// skip adding this child to the parent if 
		// parent is equal to 0 meaning it is root or
		// parent is null which has been added for compatibility with future development
		// parent does not exist in the list meaning user does not have access to viewing the parent
		if (obj[parentAttr] != 0 && obj[parentAttr] != null && lookup[obj[parentAttr]]) {
			lookup[obj[parentAttr]][childrenAttr].push(obj);
		} else {
			treeList.push(obj);
		}
	});
	return { groups: treeList } ;
};


GroupModule.prototype.getSubGroups= function(group, subGroups){
	var $this= this;
	subGroups= subGroups || [];
	
	subGroups.push(group);
	_.each(group.groups, function(subGroup){
		$this.getSubGroups(subGroup, subGroups);
	});

	return subGroups;
};

/**
 * returns the list of users that are permitted to view the group
 */
GroupModule.prototype.getPermittedUsers= function(group){
	if(!group) return [];

	var parents= this.getParentGroups(group);
	var userArrays= _.map(parents, "users");
		
	return _.uniq(_.flatten(userArrays));
};


GroupModule.prototype.getParentGroups= function(group, parentGroups){
	if(!group) return null;

	parentGroups= parentGroups || [];
	
	parentGroups.push(group);
	if(group.parent_id){
		this.getParentGroups(this.get(group.parent_id), parentGroups);
	}	
	
	return parentGroups;
};


/** 
 * gets list of groups and devices filtered by devices
 * 
 * @param {Object} filter filter object 
 * @return {Object} list of filtered groups/devices   
 */
GroupModule.prototype.getFilteredGroupDeviceList= function(filter){
	var Device= require("sccDevice").default;
	var groups= _.cloneDeep(this.get());
	var devices= Device.get();
	var filteredDeviceIds= _.map(_.filter(_.values(devices), filter), "id");
	var newGroupList= _.reduce(groups, function(result, group){
		group.devices= _.intersection(group.devices, filteredDeviceIds);
		if(group.devices.length){
			result[group.id]=group;
		}
		return result;
	}, {});
	return { groups: newGroupList, devices: filteredDeviceIds };
};

/**
 * returns the total number of devices in a given group/device selection Object
 * 
 * @param {Object} selection group/device selection object {groups: Array, devices: Array}
 * @param {Object} list Group list to be considered for calculation. Default is all groups.
 * 
 * @return total number of devices in the given selection object
 */
GroupModule.prototype.getDeviceCount= function(selection, list){
	var Device= require("sccDevice").default;
	var groups= list || this.get();
	var devices= Device.get();

	var totalDevices= 0;

	// counting all single devices
	if(selection && selection.devices){
		// making sure to only count device that user has permission to view 
		totalDevices+= _.intersection(_.map(devices, "id"), selection.devices).length;
	}

	// counting all devices in groups
	if(selection && selection.groups){
		_.each(selection.groups, function(groupId){
			// If group is not availabe to the user
			if(!_.has(groups, groupId)) return;
			
			var group= groups[groupId];
			totalDevices+= group.devices.length;
		});
	}	
	return totalDevices;
};

/*
Returns the total selected groups or, if none, the total devices. 

Used in Pagination Info display to account for group selects.
*/

GroupModule.prototype.getPaginationCount= function(selection, collectionName) {
	var groups = this.get();
	var deviceCountForPagination = 0;
	
	if(_.isUndefined(selection)) return deviceCountForPagination;

	if (collectionName != null && collectionName != "") {
		for(var groupIterator = 0; groupIterator < selection.groups.length; groupIterator++) {
			var selectionGroup = groups[selection.groups[groupIterator]];
			
			if (selectionGroup.title == collectionName) {
				deviceCountForPagination = selectionGroup.devices.length;
			}
		}
	}else{
		deviceCountForPagination = selection.devices.length;
	}

	return deviceCountForPagination;

};

/**
 * builds the tree structure of the groups
 * 
 */
GroupModule.prototype.buildGroupTrees= function(){
	this.setGroupTree(this.treeify());
		
	var groupsDevicesTactical= this.getFilteredGroupDeviceList({mode: 3});
	var groupTactical= groupsDevicesTactical.groups;
	this.setGroupTactical(groupTactical);

	var groupTreeTactical= this.treeify(groupsDevicesTactical);
	this.setGroupTreeTactical(groupTreeTactical);

	log.debug("Groups TREEIFY", this.getGroupTree());
};

GroupModule.prototype.reloadDevices= function(){
	var Device= require("sccDevice").default;
	const DeviceOverlay= require("sccDeviceOverlay").default;
	return Device.loadData()
	.then(function(){
		return DeviceOverlay.refresh();
	});
};

GroupModule.prototype.reloadAlerts= function(){
	var Alert= require("sccAlert").default;
	return Alert.loadData();
};

GroupModule.prototype.getGroupsForDevice= function(deviceId){
	console.log("++++++++ Device handle Change 3", deviceId)

	return this.get(4);
};

/*
* Expecting as argument an object with group ids and device ids
* Get the devices from the groups selected by the user
* Push all device ids into new array
* Remove all duplicate device ids
* Return the number of members selected by the user in Sa Menu
*/
GroupModule.prototype.getUniqueDeviceCount= function(obj){
	return this.getUniqueDeviceList(obj) ? this.getUniqueDeviceList(obj).length : 0;
};

/*
	Hijacking the above unique device count to create a unique device array list.
	Then, using that unique list to generate that device count in the above function.
*/

GroupModule.prototype.getUniqueDeviceList= function(obj){
	const total = [];
	var groups= this.get();
	
	if(_.isUndefined(obj)){
		obj = {};
	}
	
	_.each(obj.groups, function(groupID){
		if(_.isUndefined(groups[groupID])) return;
		_.each(groups[groupID].devices, (id) => {
			total.push(id);
		});
	});
	return _.union(total, obj.devices);
};


GroupModule.prototype.onSocketUpdate= function(url, data){
	var Profile = require("sccProfile").default;
	var userId= Profile.get("id");
	var group= this.get(data.parent_id);

	// getting the list of users that are permitted to view parents
	var users= this.getPermittedUsers(group);
	
	// adding the permitted users received from the socket to the permitted user list 
	users= _.concat(users, data.users);
	
	//This piece of code updates device info of all the subgroups of the group being edited
	//to ensure the devices assigned to the subgroup is a subset of the parent group at the top of the tree 
	_.each(this.getSubGroups(this.get(data.id)), function(group){
		var subGroupDevices = group.devices;
		var parentDevices = data.devices;
		group.devices = _.intersection(subGroupDevices, parentDevices);
	});


	// check if current user is permitted for the group
	if(_.indexOf(users, userId) < 0){
		// call remove if user is not permitted anymore
		data.groups= _.map(this.getSubGroups(this.get(data.id)), "id");
		this.onSocketDelete(url, data);		
	}else{
		// update group data normally
		Module.Module.prototype.onSocketUpdate.call(this, url, data);
		this.buildGroupTrees();
	}
	
	this.reloadDevices();
	this.reloadAlerts();
};


GroupModule.prototype.onSocketAdd= function(url, data){
	Module.Module.prototype.onSocketAdd.call(this, url, data);
	this.buildGroupTrees();

	this.reloadDevices();
	this.reloadAlerts();
};

GroupModule.prototype.onSocketDelete= function(url, data){
	var $this= this;
	Module.Module.prototype.onSocketDelete.call(this, url, data);

	// Remove this group from active SA rules.

	var Sa = require("sccSa").default;
	var saRules = Sa.get();

	for (var saRuleIncrementer = 0; saRuleIncrementer < Object.keys(saRules).length; saRuleIncrementer++) {
		var rule = saRules[Object.keys(saRules)[saRuleIncrementer]];
		var deletedGroupIndex = rule.members.groups.indexOf(data.id);

		if (deletedGroupIndex > -1) {
			log.debug("Updating active SA rule for removed group.");
			rule.members.groups.splice(deletedGroupIndex, 1);
		}
	}
	// removing sub-groups 
	_.each(data.groups, function(groupId){
		$this.set(null, groupId);
	});

	this.buildGroupTrees();

	this.reloadDevices();
	this.reloadAlerts();
};

GroupModule.prototype.init= function(){
	var $this= this;
	if(!Permission.verify("device", "view")){
		log.debug("User is not permitted for Group module");
		return Promise.resolve();
	}

	return Module.Module.prototype.init.call(this)
	.then(function(){		
		$this.buildGroupTrees();
		return Promise.resolve();	
	});
};

export default new GroupModule();
