import React, { useState, useEffect, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import MyLocationIcon from "@material-ui/icons/MyLocation";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import { AppContext } from "../../../AppContext";

import Device from "sccDevice";
import Language from "sccLanguage";
import HermesGateways from "sccHermesGateways";
import HermesGatewaysDetail from "./HermesGatewaysDetail";
import Utils from "sccUtils";
import Clock from "sccClock";
import DeviceOverlay from "sccDeviceOverlay";
import UserSetting from "sccUserSetting";
import TimeUtils from "sccTimeUtils";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

export default function HermesGatewaysList(props) {
  const data = props.data;
  const [mwState] = useContext(ModuleWrapperContext);
  const [appState] = useContext(AppContext);
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;
  const reportAge = UserSetting.get("report_age");
  const now = TimeUtils.getTimestamp();
  const lastReportedTimeDuration = now - data.report_timestamp;
  const reportAgeSeconds = reportAge * 60 * 60;

  const handleZoom = (e, id) => {
    e.stopPropagation();
    DeviceOverlay.centerAndZoomTo(id);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <CustomTooltip title={data.name} arrow placement="top">
          <ListItemText
            id={data.id}
            //className={classes.itemPrimaryText}
            primary={data.name}
            style={{ width: "20%" }}
          />
        </CustomTooltip>

        <CustomTooltip
          title={Language.translate("Last Reported")}
          arrow
          placement="top"
        >
          <ListItemText
            primary={
              Language.translate("Last Reported") +
              ": " +
              (data.report_timestamp && data.report_timestamp != undefined
                ? Clock.formatTimestamp(data.report_timestamp)
                : "N/A")
            }
            className="listItemText"
          />
        </CustomTooltip>
        {Device.get(data.id).report_timestamp &&
          lastReportedTimeDuration < reportAgeSeconds && (
            <ListItemSecondaryAction
              className={classes.itemActionButtonsContainer}
            >
              <CustomTooltip
                title={Language.translate("Locate")}
                placement="top"
              >
                <IconButton
                  className="zoomToCoord"
                  edge="end"
                  aria-label="locate"
                  onClick={(e) => handleZoom(e)}
                >
                  <MyLocationIcon />
                </IconButton>
              </CustomTooltip>
            </ListItemSecondaryAction>
          )}
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <HermesGatewaysDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
