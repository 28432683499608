import React, { useContext, useState, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import { Typography, Grid, makeStyles, alpha } from "@material-ui/core";
import Battery20Icon from "@material-ui/icons/Battery20";
import BatteryCharging80Icon from "@material-ui/icons/BatteryCharging80";

// Components
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Internal
import { MinimizeContext } from "../../context/MinimizeContext";
import { UserSettingContext } from "../../../../user_setting/context/UserSettingContext";
import Clock from "sccClock";
import Device from "sccDevice";
import GuiUtils from "sccGuiUtils";
import Language from "sccLanguage";
import OlMap from "sccOlMapNew";
import TimeUtils from "sccTimeUtils";
import Utils from "sccUtils";
import WaveLastSASent from "./WaveLastSASent";

const useStyles = makeStyles((theme) => ({
  deviceListContainer: {
    display: "flex",
    flexDirection: "column",
  },

  deviceContentLayer: {
    height: 23,
    borderTop: `1px solid ${theme.palette.colors.gray.main}`,
  },

  deviceContentLeft: {
    fontSize: 12,
    padding: "2px 6px",
    textAlign: "left",
    backgroundColor: theme.palette.colors.gray.main,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  deviceContentLeftRedBattery: {
    fontSize: 12,
    padding: "2px 6px",
    textAlign: "left",
    backgroundColor: theme.palette.colors.gray.main,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    backgroundColor: theme.palette.textbox.error.main,
    color: theme.palette.colors.white.main,
    display: "flex",
    alignItems: "center",
  },

  deviceContentRight: {
    fontSize: 13,
    padding: "2px 6px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "right",
  },

  deviceContentRightRedBattery: {
    fontSize: 13,
    padding: "2px 6px",
    textAlign: "right",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    backgroundColor: theme.palette.textbox.error.main,
    color: theme.palette.colors.white.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  darkBg: {
    backgroundColor: theme.palette.colors.gray.dark,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: `2px 2px 15px -1px ${theme.palette.colors.gray.main}`,
    border: `1px solid ${theme.palette.colors.gray.main}`,
  },

  speedFormat: {
    backgroundColor: alpha(theme.palette.colors.black.main, 0.5),
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.colors.white.main,
    minWidth: 10,
    padding: "3px 7px",
    borderRadius: 10,
  },

  alignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  deviceBattery: {
    color: theme.palette.colors.white.main,
    fontSize: 16,
  },
  locationOverflow: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default function DeviceInfo({ device }) {
  // const { state } = useContext(MinimizeContext)
  //   ? useContext(MinimizeContext)
  //   : useState(null);
  const { userSettingData } = useContext(UserSettingContext);
  const classes = useStyles();
  const deviceTypeFeatures = Device.getDeviceType(device.type_id);

  const [reportAge, setReportAge] = useState(
    TimeUtils.getAge(device?.report_timestamp) || "N/A"
  );

  // Return Device mode translated
  const getAssetMode = (mode) => {
    if (mode === "Standalone" || mode === "Open Protocol") {
      return Language.translate(mode);
    } else {
      return mode;
    }
  };

  const location =
    (device &&
      device.latitude &&
      OlMap.formatCoordinate([device.longitude, device.latitude])) ||
    "N/A";

  const deviceSpeed = () => {
    if (device.speed === null) return "N/A";
    switch (userSettingData["speed_format"]) {
      case "MPH":
        return Utils.transformSpeedFromKph(device.speed, "MPH");
      case "KNOTS":
        return Utils.transformSpeedFromKph(device.speed, "KNOTS");
      default:
        return device.speed;
    }
  };

  // update report age state
  const calcAge = (rt) => {
    setReportAge(TimeUtils.getAge(rt));
  };

  useEffect(() => {
    //  update report age every one second
    if (device.report_timestamp) {
      const interval = setInterval(
        () => calcAge(device.report_timestamp),
        1000
      );

      calcAge(device.report_timestamp);

      return () => {
        clearInterval(interval);
      };
    } else {
      setReportAge("N/A");
    }
  }, [device]);

  // define common list fields
  const commonRenderedList = () => {
    if (deviceTypeFeatures) {
      return (
        <>
          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("TYPE")}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {deviceTypeFeatures?.title === "Wave"
                ? "NORTAC Wave"
                : deviceTypeFeatures?.title}
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("Mode").toUpperCase()}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {getAssetMode(deviceTypeFeatures.modes[device.mode].title) ||
                "N/A"}
            </Grid>
          </Grid>
          {[
            "Sentry H6120 BM",
            "Sentry H6110 MP",
            "SAT-COM Leopard1",
            "Trellisware TSM TW-950",
            "NORTAC Orion",
          ].includes(deviceTypeFeatures.title) ? (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {deviceTypeFeatures.title == "NORTAC Orion"
                  ? `${Language.translate("IMEI")}`
                  : `${Language.translate("Serial Number").toUpperCase()}`}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {device.Serial_No || "N/A"}
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {Language.translate("IMEI")}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {device.imei || "N/A"}
              </Grid>
            </Grid>
          )}

          {deviceTypeFeatures.title === "NORTAC Orion" ? (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {`${Language.translate("Device ID")}`}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {device.imei || "N/A"}
              </Grid>
            </Grid>
          ) : null}

          {["Sentry H6120 BM", "Sentry H6110 MP", "SAT-COM Leopard1"].includes(
            deviceTypeFeatures.title
          ) ? (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {Language.translate("ALE Address").toUpperCase()}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {device.ale_address || "N/A"}
              </Grid>
            </Grid>
          ) : null}

          {Device.getDeviceMode(device) == "Gateway Device" && (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {Language.translate("IP Address").toUpperCase()}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {device.ip_address || "N/A"}
              </Grid>
            </Grid>
          )}

          {Device.getParentDevice(device) != null && (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {Language.translate("Gateway").toUpperCase()}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {Device.getParentDevice(device) || "N/A"}
              </Grid>
            </Grid>
          )}

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("COMM ID")}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {device.comm_id || "N/A"}
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {OlMap.getUserLonlatFormat().toUpperCase()}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              <CustomTooltip title={location} arrow placement="top">
                <div className={classes.locationOverflow}>{location}</div>
              </CustomTooltip>
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("Altitude").toUpperCase()}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {device.altitude || "N/A"}
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("Course").toUpperCase()}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {GuiUtils.ConvertHeadingToString(device.heading) || "N/A"}
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("SPEED")}
              <span className={classes.speedFormat}>
                {userSettingData["speed_format"]}
              </span>
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {deviceSpeed()}
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const renderedListByDeviceType = () => {
    if (deviceTypeFeatures) {
      const deviceType = deviceTypeFeatures?.title?.toLowerCase() || "";
      const isLowerBattery =
        Device.get(device.id)?.battery_level != null
          ? Device.get(device.id).battery_level <= 10
          : false;
      const isChargingBattery = Device.get(device.id)?.battery_charge === true;
      const isRedBattery = isLowerBattery || isChargingBattery;

      switch (true) {
        case deviceType.includes("shadow"):
          // for shadow device use
          const isShadowLowerBattery =
            Device.get(device.id)?.consignment?.status?.battery_charge != null
              ? Device.get(device.id)?.consignment?.status?.battery_charge <= 10
              : false;

          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Report Age").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {reportAge || "N/A"}
                </Grid>
              </Grid>
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("LAST GPS RPT")}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {Clock.formatTimestamp(device.report_timestamp)}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Tag Id").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.assetId || "N/A"}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Door").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.consignment?.status?.door_open
                    ? Language.translate("Open")
                    : device.consignment?.status?.door_open == null
                    ? "N/A"
                    : Language.translate("Closed").toUpperCase()}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Temperature").toUpperCase()}
                </Grid>
                <Grid
                  item
                  container
                  xs={7}
                  className={classes.deviceContentRight}
                >
                  <Grid item xs={4} className={classes.alignCenter}>
                    {device.consignment?.status?.temperature
                      ? device.consignment?.status?.temperature + "℃"
                      : "N/A"}
                  </Grid>
                  <Grid item xs={4} className={classes.darkBg}>
                    {device.consignment?.settings?.temp_low &&
                    device.consignment?.settings?.temp_low >= -45 &&
                    device.consignment?.settings?.temp_low <= 45
                      ? device.consignment?.settings?.temp_low + "℃"
                      : "N/A"}
                  </Grid>
                  <Grid item xs={4} className={classes.darkBg}>
                    {device.consignment?.settings?.temp_high &&
                    device.consignment?.settings?.temp_high >= -45 &&
                    device.consignment?.settings?.temp_high <= 45
                      ? device.consignment?.settings?.temp_high + "℃"
                      : "N/A"}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Humidity").toUpperCase()}
                </Grid>
                <Grid
                  item
                  container
                  xs={7}
                  className={classes.deviceContentRight}
                >
                  <Grid item xs={4} className={classes.alignCenter}>
                    {device.consignment?.status?.humidity
                      ? device.consignment?.status?.humidity + "%"
                      : "N/A"}
                  </Grid>
                  <Grid item xs={4} className={classes.darkBg}>
                    0%
                  </Grid>
                  <Grid item xs={4} className={classes.darkBg}>
                    {device.consignment?.settings?.humidity_high
                      ? device.consignment?.settings?.humidity_high + "%"
                      : "N/A"}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid
                  item
                  xs={5}
                  className={
                    isShadowLowerBattery
                      ? classes.deviceContentLeftRedBattery
                      : classes.deviceContentLeft
                  }
                >
                  {Language.translate("Battery").toUpperCase()}
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={
                    isShadowLowerBattery
                      ? classes.deviceContentRightRedBattery
                      : classes.deviceContentRight
                  }
                >
                  {Device.get(device.id)?.consignment?.status?.battery_charge
                    ? Device.get(device.id)?.consignment?.status
                        ?.battery_charge + "%"
                    : "N/A"}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Last Cargo Report").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {Clock.formatTimestamp(
                    device.consignment?.status?.last_status_reported
                  )}
                </Grid>
              </Grid>
            </div>
          );
        case deviceType.includes("hawkeye"):
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Report Age").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {reportAge || "N/A"}
                </Grid>
              </Grid>
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Last Reported").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.report_timestamp &&
                  device.report_timestamp != undefined
                    ? Clock.formatTimestamp(device.report_timestamp)
                    : "N/A"}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Precision").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.vehicle?.precision || "N/A"}
                </Grid>
              </Grid>

              {device.vehicle?.engine_on && (
                <Grid container className={classes.deviceContentLayer}>
                  <Grid item xs={5} className={classes.deviceContentLeft}>
                    {Language.translate("Engine On/Off")}
                  </Grid>
                  <Grid item xs={7} className={classes.deviceContentRight}>
                    {device.vehicle?.engine_on || "N/A"}
                  </Grid>
                </Grid>
              )}

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Odometer")}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.vehicle?.odometer || "N/A"}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Event(s)").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device?.vehicle?.hard_breaking &&
                    Language.translate("hard braking; ")}
                  {device?.vehicle?.coolant_overheat &&
                    Language.translate("coolant overheat; ")}
                  {device?.vehicle?.device_low_battery &&
                    Language.translate("low battery; ")}
                  {device?.vehicle?.oil_overheat &&
                    Language.translate("oil overheat; ")}
                  {!device?.vehicle?.oil_overheat &&
                    !device?.vehicle?.hard_breaking &&
                    !device?.vehicle?.coolant_overheat &&
                    !device?.vehicle?.device_low_battery &&
                    "N/A"}
                </Grid>
              </Grid>
            </div>
          );
        case deviceType.includes("wave"):
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
              <Grid container className={classes.deviceContentLayer}>
                <Grid
                  item
                  xs={5}
                  className={
                    isRedBattery
                      ? classes.deviceContentLeftRedBattery
                      : classes.deviceContentLeft
                  }
                >
                  {Language.translate("Battery").toUpperCase()}
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={
                    isRedBattery
                      ? classes.deviceContentRightRedBattery
                      : classes.deviceContentRight
                  }
                >
                  {device.battery_level || device.battery_level === 0
                    ? device.battery_level + "%"
                    : "N/A"}
                  {isLowerBattery && (
                    <Battery20Icon className={classes.deviceBattery} />
                  )}
                  {isChargingBattery && (
                    <BatteryCharging80Icon className={classes.deviceBattery} />
                  )}
                </Grid>
              </Grid>

              {device?.report_rate !== null && (
                <Grid container className={classes.deviceContentLayer}>
                  <Grid item xs={5} className={classes.deviceContentLeft}>
                    {Language.translate("Report Interval").toUpperCase()}
                  </Grid>
                  <Grid item xs={7} className={classes.deviceContentRight}>
                    {device?.report_rate || "N/A"}
                  </Grid>
                </Grid>
              )}

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Report Age").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {reportAge || "N/A"}
                </Grid>
              </Grid>

              <WaveLastSASent device={device} />

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Last Reported").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {Clock.formatTimestamp(device?.report_timestamp) || "N/A"}
                </Grid>
              </Grid>
            </div>
          );
        case deviceType.includes("nortac orion"):
        case deviceType.includes("whisper"):
        default:
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
              <Grid container className={classes.deviceContentLayer}>
                <Grid
                  item
                  xs={5}
                  className={
                    isRedBattery
                      ? classes.deviceContentLeftRedBattery
                      : classes.deviceContentLeft
                  }
                >
                  {Language.translate("Battery").toUpperCase()}
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={
                    isRedBattery
                      ? classes.deviceContentRightRedBattery
                      : classes.deviceContentRight
                  }
                >
                  {device.battery_level || device.battery_level === 0
                    ? device.battery_level + "%"
                    : "N/A"}
                  {isLowerBattery && (
                    <Battery20Icon className={classes.deviceBattery} />
                  )}
                  {isChargingBattery && (
                    <BatteryCharging80Icon className={classes.deviceBattery} />
                  )}
                </Grid>
              </Grid>

              {device?.report_rate !== null && (
                <Grid container className={classes.deviceContentLayer}>
                  <Grid item xs={5} className={classes.deviceContentLeft}>
                    {Language.translate("Report Interval").toUpperCase()}
                  </Grid>
                  <Grid item xs={7} className={classes.deviceContentRight}>
                    {device?.report_rate || "N/A"}
                  </Grid>
                </Grid>
              )}

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Report Age").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {reportAge || "N/A"}
                </Grid>
              </Grid>
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Last Reported").toUpperCase()}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device.report_timestamp &&
                  device.report_timestamp != undefined
                    ? Clock.formatTimestamp(device.report_timestamp)
                    : "N/A"}
                </Grid>
              </Grid>
            </div>
          );
      }
    }
  };

  return (
    <React.Fragment>
      {
        //!state.minimize &&
        //deviceTypeFeatures &&
        Object.keys(deviceTypeFeatures)?.length > 0 &&
          renderedListByDeviceType()
      }
    </React.Fragment>
  );
}
