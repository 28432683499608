import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// Components
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomTextField from "@Components/CustomTextField";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import Language from "sccLanguage";
import Permission from "sccPermission";
import { AppContext } from "../../../AppContext";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  moduleNameLabel: {
    padding: "5px 10px",
    background: `${theme.palette.colors.headerFooterText.main} !important`,
    fontSize: "1.05rem",
    color: theme.palette.colors.white.main,
    textTransform: "uppercase",
  },
}));

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;
const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function PermissionsForm(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [appState] = useContext(AppContext);
  const permittedRoles = mwState.getPermittedRoles();

  // to be used in add edit
  const formik = useFormikContext();
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const [moduleVisibility, setModuleVisibility] = useState({});

  const modVisibility = {};

  const defaultRoles = [
    "Admin",
    "Advanced User",
    "Contact User",
    "Customer Admin",
    "End User",
    "Provider Admin",
  ];

  const newRole = {
    title: "",
    permissions: [],
    disabledActions: {},
  };

  useEffect(() => {
    // update roles list for details
    if (mwState.wrapperDisplayMode === MW_LIST_MODE) {
      setMwState((p) => ({
        ...p,
        moduleData: Permission._allRoles,
        filteredModuleData: Permission._allRoles,
      }));
    }
    // update roles list for form
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      const roleData = mwState.moduleItemData;

      const disabledActions =
        roleData.permissions && roleData.permissions.length > 0
          ? buildDisabledActions(
              Object.values(roleData.groupedPermissions),
              roleData.permissions
            )
          : null;

      setMwState((p) => ({
        ...p,
        handleSpecialSaveValidation,
        moduleItemData: { ...newRole, disabledActions, ...roleData },
        moduleItemDataOriginal: { ...newRole, disabledActions, ...roleData },
      }));
      setModuleVisibility({ ...modVisibility });
    }
  }, [mwState.wrapperDisplayMode]);

  const arrPermissionsByModule = Permission.getAllPermissionsByModule();

  function buildDisabledActions(permissions, selectedPermissions) {
    const allPermissions = Permission.getAllPermissions();
    let disabledActions = moduleItemData.disabledActions || {};
    permissions.map((permission) =>
      permission.map((perm) => {
        var allPerm = _.find(allPermissions, {
          module: perm.module,
          action: "all",
        });

        if (allPerm) {
          var otherPermIds = _.map(
            _.filter(allPermissions, function (permission) {
              return (
                permission.module === perm.module && permission.action !== "all"
              );
            }),
            "id"
          );

          if (allPerm.id === perm.id) {
            _.each(otherPermIds, function (id) {
              if (_.indexOf(selectedPermissions, allPerm.id) > -1) {
                // disabling other actions if 'all' action is selected
                disabledActions[id] = true;
              }
            });
          }
        }

        var viewPerm = _.find(allPermissions, function (permission) {
          return (
            permission.module === perm.module && permission.action === "view"
          );
        });

        // nothing to be done if the module does not have 'view' action
        if (viewPerm) {
          // getting all permission IDs for actions other than 'all' and 'view'
          var nonViewPermIds = _.map(
            _.filter(allPermissions, function (permission) {
              return (
                permission.module === perm.module &&
                _.indexOf(["view", "all"], permission.action) === -1
              );
            }),
            "id"
          );

          // getting the list of non view actions that are selected
          var selectedNonViewIds = _.intersection(
            nonViewPermIds,
            selectedPermissions
          );

          // automatically selecting view and disabling checkbox if a non-view action is selected
          if (selectedNonViewIds.length) {
            disabledActions[viewPerm.id] = true;
          }
        }
      })
    );
    return disabledActions;
  }

  function permissionChanged(perm) {
    const newPermissions = _.xor(moduleItemData.permissions, [perm.id]);
    // gets the unique values from the 2 arrays
    manageViewClick(perm, newPermissions);
    // the sequence of the 3 function calls matter, so do not change this
    manageDisabledActions(perm, newPermissions);
    autoSelectView(perm, newPermissions);
    autoSelectAll(perm, newPermissions);
  }

  // when action is "view" and there is no "all" action
  function manageViewClick(perm, newPermissions) {
    if (perm.action === "view") {
      let formikUpdates = {};
      let disabledActions = moduleItemData.disabledActions || {};

      formikUpdates.permissions = newPermissions;
      formikUpdates.disabledActions = disabledActions;

      formik.setValues((prevValues) => ({
        ...prevValues,
        ...formikUpdates,
      }));
    }
  }

  function manageDisabledActions(perm, newPermissions) {
    // finding the permission object for the 'all' action
    // corresponding to module of the permission being modified
    let formikUpdates = {};
    const allPermissions = Permission.getAllPermissions();
    var allPerm = _.find(allPermissions, {
      module: perm.module,
      action: "all",
    });

    let disabledActions = moduleItemData.disabledActions || {};

    // nothing to be done if module does not have 'all' action
    if (!allPerm) return;
    if (!allPerm.id)
      throw new Error(
        "Could not obtain the id of 'all' action for module " + perm.module
      );

    // getting all permission IDs for actions other than 'all' for this module
    var otherPermIds = _.map(
      _.filter(allPermissions, function (permission) {
        return permission.module === perm.module && permission.action !== "all";
      }),
      "id"
    );

    if (_.indexOf(newPermissions, allPerm.id) > -1) {
      // if all is selected remove all other permissions for this module from the permissions object
      formikUpdates.permissions = _.difference(newPermissions, otherPermIds);
    } else {
      if (_.indexOf(newPermissions, perm.id) > -1) {
        formikUpdates.permissions = _.union(newPermissions, [perm.id]);
      } else {
        formikUpdates.permissions = _.difference(newPermissions, [perm.id]);
      }
    }
    if (allPerm.id === perm.id) {
      // if all is clicked, disable all other ids and add to disabled object, else remove from disabled
      _.each(otherPermIds, function (id) {
        if (_.indexOf(newPermissions, allPerm.id) > -1) {
          // disabling other actions if 'all' action is selected
          disabledActions[id] = true;
        } else {
          // enabling other actions if 'all' action is deselected
          delete disabledActions[id];
        }
      });
    }
    if (disabledActions) formikUpdates.disabledActions = disabledActions;
    console.log("rolesFromJSON", JSON.stringify(formikUpdates));
    formik.setValues((prevValues) => ({
      ...prevValues,
      ...formikUpdates,
    }));
  }

  function autoSelectAll(perm, newPermissions) {
    // nothing to be done if action is all
    if (perm.action === "all") return;

    // get the all object for the module where permission is clicked
    var allPerm = _.find(Permission.getAllPermissions(), function (permission) {
      return permission.module === perm.module && permission.action === "all";
    });

    // nothing to be done if the module does not have 'all' action
    if (!allPerm) return;

    var allPermId = allPerm.id;

    // getting all permission IDs for actions other than 'all'
    var nonAllPermIds = _.map(
      _.filter(Permission.getAllPermissions(), function (permission) {
        return (
          permission.module === perm.module &&
          _.indexOf(["all"], permission.action) === -1
        );
      }),
      "id"
    );

    // if every non-"all" action is selected
    if (
      _.intersection(nonAllPermIds, newPermissions).length ===
      nonAllPermIds.length
    ) {
      manageDisabledActions(
        { id: allPermId, module: perm.module, action: "all" },
        _.union(newPermissions, [allPermId])
      );
    }
  }

  function autoSelectView(perm, newPermissions) {
    // nothing to be done if action is 'view' or 'all'
    let formikUpdates = {};
    if (_.indexOf(["all", "view"], perm.action) > -1) {
      return;
    }

    var viewPerm = _.find(
      Permission.getAllPermissions(),
      function (permission) {
        return (
          permission.module === perm.module && permission.action === "view"
        );
      }
    );

    // nothing to be done if the module does not have 'view' action
    if (!viewPerm) return;

    var viewPermId = viewPerm.id;

    // getting all permission IDs for actions other than 'all' and 'view'
    var nonViewPermIds = _.map(
      _.filter(Permission.getAllPermissions(), function (permission) {
        return (
          permission.module === perm.module &&
          _.indexOf(["view", "all"], permission.action) === -1
        );
      }),
      "id"
    );

    // getting the list of non view actions that are selected
    var selectedNonViewIds = _.intersection(nonViewPermIds, newPermissions);

    let disabledActions = moduleItemData.disabledActions || {};
    // automatically selecting view and disabling checkbox if a non-view action is selected
    if (selectedNonViewIds.length) {
      formikUpdates.permissions = _.union(newPermissions, [viewPermId]);
      disabledActions[viewPermId] = true;
    } else {
      // enabling the view if no non-view action is selected.
      formikUpdates.permissions = _.difference(newPermissions, [perm.id]);
      delete disabledActions[viewPermId];
    }
    formikUpdates.disabledActions = disabledActions;
    if (!_.isEmpty(formikUpdates)) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        ...formikUpdates,
      }));
    }
  }

  function handleSpecialSaveValidation() {
    const roles = document
      .getElementById("permissions")
      .value.split(",")
      .map((val) => Number(val));

    const device = deviceIdsInRoles();
    const geofence = geofenceIdsInRoles();
    const alarm = alarmIdsInRoles();
    const alert = alertIdsInRoles();
    const group = groupIdsInRoles();
    const hermes = idsInRole("hermes_gateways");

    const checkDevice = _.intersection(roles, device);
    const checkGeofence = _.intersection(roles, geofence);
    const checkAlarm = _.intersection(roles, alarm);
    const checkAlert = _.intersection(roles, alert);
    const checkGroup = _.intersection(roles, group);
    const checkHermes = _.intersection(roles, hermes);

    if (checkDevice.length && !checkAlarm.length) {
      appState.displaySnackbarMessage({
        title: "Roles",
        message: Language.translate(
          "You need to check Alarms if you want Assets"
        ),
        variant: "error",
      });
      return false;
    }
    if (checkHermes.length && !checkDevice.length) {
      appState.displaySnackbarMessage({
        title: "Roles",
        message: Language.translate(
          "Please select the Asset Module if you would like to include the Hermes Module for this role."
        ),
        variant: "error",
      });
      return false;
    }
    if (!checkDevice.length && checkAlarm.length) {
      appState.displaySnackbarMessage({
        title: "Roles",
        message: Language.translate(
          "You need to check Assets if you want Alarms"
        ),
        variant: "error",
      });
      return false;
    }

    if (checkGeofence.length && (!checkDevice.length || !checkAlarm.length)) {
      appState.displaySnackbarMessage({
        title: "Roles",
        message: Language.translate(
          "You need to check Assets and Alarms if you want Geofence"
        ),
        variant: "error",
      });
      return false;
    }

    if (checkAlert.length && !checkDevice.length) {
      appState.displaySnackbarMessage({
        title: "Roles",
        message: Language.translate(
          "You need to check Assets if you want Alert Rules"
        ),
        variant: "error",
      });
      return false;
    }

    if (checkGroup.length > 0 && group[0] != checkGroup[0]) {
      appState.displaySnackbarMessage({
        title: "Roles",
        message: Language.translate(
          "Select All Actions for GROUPS permissions to enable GROUPS usage"
        ),
        variant: "error",
      });
      return false;
    }

    if (!permittedRoles.length) {
      // todo: use Utils.confirm when the function is fixed
      if (
        window.confirm(
          Language.translate(
            "You did not select any action for this role. Do you wish to continue?"
          )
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  // Get all Device ids in Roles and add to array
  function deviceIdsInRoles() {
    const perms = Permission.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "device") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  function idsInRole(module) {
    const perms = Permission.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === module) {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  // Get all Alarm ids in Roles and add to array
  function alarmIdsInRoles() {
    const perms = Permission.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "alarm") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  // Get all Geofence ids in Roles and add to array
  function geofenceIdsInRoles() {
    const perms = Permission.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "geofence") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  // Get all Alert ids in Roles and add to array
  function alertIdsInRoles() {
    const perms = Permission.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "ar") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  // Get all Group ids in Roles and add to array
  function groupIdsInRoles() {
    const perms = Permission.getAllPermissions();
    const ids = [];
    _.each(perms, (key, value) => {
      if (key.module === "group") {
        ids.push(_.toNumber(value));
      }
    });
    return ids;
  }

  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <div className="pullRight">
        {`* ${Language.translate("Required fields")}`}
      </div>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <input
            type="hidden"
            value={moduleItemData.permissions}
            name="permissions"
            id="permissions"
          />
          <CustomTextField
            label={`${Language.translate("Title")} *`}
            value={moduleItemData.title}
            name="title"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          {Object.keys(arrPermissionsByModule).map((mod) => {
            const arrActions = Permission.reorderByAction(
              arrPermissionsByModule[mod]
            );
            modVisibility[mod] = false;
            return (
              <React.Fragment>
                <div
                  style={!moduleVisibility[mod] ? { display: "none" } : null}
                >
                  <h5 className={classes.moduleNameLabel}>
                    {Language.translate(Permission.getModuleName(mod))}
                  </h5>
                  {arrActions.map((perm) => {
                    if (Permission.verify(mod, perm.action, true)) {
                      modVisibility[mod] = true;
                      return (
                        <CustomCheckbox
                          checked={
                            moduleItemData.permissions &&
                            moduleItemData.disabledActions
                              ? moduleItemData.permissions.indexOf(perm.id) >
                                  -1 ||
                                Boolean(moduleItemData.disabledActions[perm.id])
                              : false
                          }
                          disabled={
                            moduleItemData.disabledActions
                              ? moduleItemData.disabledActions[perm.id]
                              : false
                          }
                          onChange={() => permissionChanged(perm)}
                          name={perm.action}
                          label={Language.translate(
                            Permission.getActionName(perm.action)
                          )}
                        />
                      );
                    }
                  })}
                </div>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
}
