import React, { useEffect, useState, useContext } from "react";

// External
import _ from "lodash";
import clsx from "clsx";

// Material-UI
import Checkbox from "@material-ui/core/Checkbox";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import GroupIcon from "@material-ui/icons/Group";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

// Internal
import Language from "sccLanguage";
import Group from "sccGroup";
import Permission from "sccPermission";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomTooltip from "../../CustomTooltip";
import { CustomAssetSelectorContext } from "../CustomAssetSelectorContext";
import CustomAssetSelector from "../CustomAssetSelector";
import AssetDevice from "../AssetDevice";
import { customAssetSelector } from "@Styles/CustomAssetSelector";

const MW_ASSETS_MODULE_MODE = process.env.REACT_APP_MW_ASSETS_MODULE_MODE;

export default function AssetGroup(props) {
  const [open, setOpen] = useState(props.openMain);
  const [expanded, setExpanded] = useState({});
  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  const [value, setValue] = useState(false);

  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const editMode = assetSelectorState.editMode;
  const handleEdit = props.handleItemEdit;
  const handleDelete = mwState.handleItemDelete;
  const routeUrl = "/api/v1/group"; //specifically set here for use in the group delete

  const groups = props.groups.sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  const name = props.name;
  const selectionGroups = props.selectionGroups;
  const selectionDevices = props.selectionDevices;
  const setStateForGroupRadioSelection =
    assetSelectorState.setStateForGroupRadioSelection;
  const groupButtons = props.groupButtons;
  const showDeviceSearchField = props.showDeviceSearchField;

  const handleSelectedGroups = props.handleSelectedGroups;
  const showGroupRadioButtons = props.showGroupRadioButtons;

  //just used to give special treatment to main
  const setMainLoaded = props.setMainLoaded;

  const handleToggle = (id) => {
    setExpanded({ ...expanded, [id]: !expanded[id] });
  };

  const handleChange = (group, name) => {
    if (editMode) {
      assetSelectorState.showGroupRadioButtons
        ? setStateForGroupRadioSelection([group.id], name)
        : handleSelectedGroups(group, name);
    } else {
      handleToggle(group.id);
    }
  };

  const useStyles = makeStyles((theme) => ({
    ...customAssetSelector(theme),
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      {!_.isUndefined(groups) && groups.length > 0
        ? groups.map((group) => {
            return (
              <List
                key={group.id}
                className={clsx(
                  classes.assetGroup,
                  group.title == mwState.mainGroupName
                    ? classes.mainGroupAdjustment
                    : null
                )}
              >
                {group.title !== mwState.mainGroupName &&
                  (group.groups.length > 0 || group.devices.length > 0) && (
                    <span>
                      {expanded[group.id] ? (
                        <KeyboardArrowDownIcon
                          onClick={() => handleToggle(group.id)}
                          className="assetGroupIcon"
                        />
                      ) : group.groups.length > 0 || !props.hideDevices ? (
                        <KeyboardArrowRightIcon
                          onClick={() => handleToggle(group.id)}
                          className="assetGroupIcon"
                        />
                      ) : null}
                    </span>
                  )}
                <ListItem
                  className="assetGroupLabel"
                  role={undefined}
                  dense
                  button
                  onClick={() => handleChange(group, name)}
                >
                  {editMode && (
                    <React.Fragment>
                      {assetSelectorState.showGroupRadioButtons ? (
                        <Radio
                          checked={selectionGroups.indexOf(group.id) > -1}
                          value={group.id}
                          color="primary"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "A" }}
                        />
                      ) : (_.intersection(group.devices, selectionDevices)
                          .length > 0 ||
                          _.intersection(
                            _.map(group.groups, "id"),
                            selectionGroups
                          ).length > 0) &&
                        (_.intersection(group.devices, selectionDevices)
                          .length < group.devices.length ||
                          _.intersection(
                            _.map(group.groups, "id"),
                            selectionGroups
                          ).length < group.groups.length) ? (
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            indeterminate={true}
                            color="primary"
                            disableRipple
                            inputProps={{ "aria-labelledby": group.id }}
                          />
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selectionGroups.indexOf(group.id) > -1}
                            color="primary"
                            disableRipple
                            inputProps={{ "aria-labelledby": group.id }}
                          />
                        </ListItemIcon>
                      )}
                    </React.Fragment>
                  )}
                  <CustomTooltip
                    title={Language.translate("Group")}
                    arrow
                    placement="top"
                  >
                    <IconButton>
                      <GroupIcon />
                    </IconButton>
                  </CustomTooltip>
                  <ListItemText id={`AssetGroup-${group.id}`}>
                    {group.title === mwState.mainGroupName
                      ? Language.translate(mwState.mainGroupName)
                      : group.title}
                    &nbsp;({group.count || group.devices.length})
                  </ListItemText>
                  <ListItemSecondaryAction
                    className={classes.itemActionButtonsContainer}
                  >
                    {!editMode && groupButtons ? (
                      <React.Fragment>
                        {Permission.verify("group", "add") && (
                          <CustomTooltip
                            title={Language.translate("Add Group")}
                            arrow
                            placement="top"
                          >
                            <IconButton
                              edge="end"
                              aria-label="edit"
                              onClick={() =>
                                handleEdit({ parent_id: group.id }, 1)
                              }
                            >
                              <GroupAddIcon />
                            </IconButton>
                          </CustomTooltip>
                        )}
                        {Permission.verify("group", "edit") && (
                          <CustomTooltip
                            title={Language.translate("Edit Group")}
                            arrow
                            placement="top"
                          >
                            <IconButton
                              edge="end"
                              aria-label="edit"
                              onClick={() => handleEdit(Group.get(group.id), 1)}
                            >
                              <EditIcon />
                            </IconButton>
                          </CustomTooltip>
                        )}
                        {Permission.verify("group", "delete") &&
                          group.title !== mwState.mainGroupName && (
                            <CustomTooltip
                              title={Language.translate("Delete Group")}
                              arrow
                              placement="top"
                            >
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  handleDelete(
                                    `${routeUrl}/${group.id}`,
                                    mwState.title,
                                    "Group",
                                    `${group.title}`,
                                    MW_ASSETS_MODULE_MODE
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </CustomTooltip>
                          )}
                      </React.Fragment>
                    ) : null}
                  </ListItemSecondaryAction>
                </ListItem>
                {
                  <Collapse
                    key={`collapseGroup-${group.title}`}
                    in={
                      open && group.title == mwState.mainGroupName
                        ? true
                        : !props.hideDevices
                        ? expanded[group.id]
                        : group.groups.length > 0 //incase of hidedevices true, we check if there is any subgroup or not
                        ? expanded[group.title]
                        : false
                    }
                    timeout="auto"
                    unmountOnExit
                  >
                    <CustomAssetSelector
                      collection={group.groups}
                      name={name}
                      selectionGroups={selectionGroups}
                      selectionDevices={selectionDevices}
                      editMode={props.editMode}
                      devicesButtons={props.devicesButtons}
                      hideDevices={props.hideDevices}
                      handleItemEdit={props.handleItemEdit}
                      groupButtons={groupButtons}
                      displayDeviceImei={props.displayDeviceImei}
                    />
                    <AssetDevice
                      devices={group.devices}
                      selectionGroups={selectionGroups}
                      selectionDevices={selectionDevices}
                      showSelectedOnly={props.showSelectedOnly}
                      editMode={props.editMode}
                      hideDevices={props.hideDevices}
                      devicesButtons={props.devicesButtons}
                      name={name}
                      handleItemEdit={props.handleItemEdit}
                      showDeviceSearchField={showDeviceSearchField}
                      displayDeviceImei={props.displayDeviceImei}
                    />
                  </Collapse>
                }
              </List>
            );
          })
        : ""}
    </React.Fragment>
  );
}
