import { default as Module } from "sccModule";
var Utils = require("sccUtils").default;
var Language = require("sccLanguage").default;
var _ = require("lodash");
const log = require("loglevel");

/**
 * The base object for Message module
 *
 */
class MessageModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "message",
      getterSetter: ["MessageInfo", "CannedMessage"],
    };

    super(options);
  }

  // override method
  init() {
    const $this = this;
    this.defineSockets();

    return this.loadCannedMessage().then(function (cannedMessage) {
      log.debug("Canned Messages:", cannedMessage.data.result);
      $this.setCannedMessage(cannedMessage.data.result);
      return Promise.resolve();
    });
  }

  /**


	 * loads the message count info status from the DB
	 * 
	 * @return {Object} DB response object containing Message Count information
	 */
  loadMessageInfo() {
    var options = {
      url: this.routeUrl + "/message_info",
      method: "GET",
    };
    return Utils.httpRequestHandler(options);
  }

  loadCannedMessage() {
    var options = {
      url: this.routeUrl + "/canned_message",
      method: "GET",
    };
    return Utils.httpRequestHandler(options);
    // Feed canned message data to the chatbox react component
    // .then(function(data){
    // 	const { default: ChatBoxBottomBar } = require("../../chat/components/ChatBoxBottomBar");
    // 	log.info("@@@@@@@@@ "+JSON.stringify(data.data.result))
    // 	ChatBoxBottomBar.loadCannedMessages(data.data.result)
    // });
  }

  /**
   * gets message attachment by ID
   */
  getAttachment(message) {
    var options = {
      url: Utils.apiUrlPrefix + "/message/attachment_data/" + message.id,
      method: "GET",
    };

    return Utils.httpRequestHandler(options);
  }

  /**
   * resends a message
   */
  resend(data) {
    var options = {
      url: Utils.apiUrlPrefix + "/message/resend_message",
      method: "POST",
      data: data,
    };

    return Utils.httpRequestHandler(options);
  }

  refreshChatBox(data) {
    const Chat = require("sccChat").default;
    /*
			- On socket update (set read, unread, move to trash, restore etc), 
			we have reload the messages loaded in chat box 
			because the user might have archived or deleted more than one message

			- On socket add or message status update, we can just append the message obj.
		*/
    if (Chat.selectedDeviceId) {
      if (data && data.id) {
        //when message is insterted or message status is updated
        //the data object only contains info of one message
        Chat.update(data);
      } else {
        //when message is update like (set read, unread, move to trash, restore etc)
        //the data contains a list of messages that were updated.
        //Hence this logic decides what action to execute
        //based on the result of the structure of the data
        if (Chat.main) {
          Chat.main.loadMessageChat(Chat.selectedDeviceId);
        }
      }

      const {
        default: ChatOverlay,
      } = require("../../chat/components/ChatOverlay");
      ChatOverlay.updateFeatureSelected();
    }
    return;
  }

  onSocketAdd(url, data) {
    const Profile = require("sccProfile").default;
    var userId = Profile.get("id");

    const {
      default: MenuButtonList,
    } = require("../../menu/components/MenuButtonList");

    if (data.recipients.user_recipient_info[userId] != undefined) {
      // use this section to code for new message received snackbar
      // Utils.notify({message: "New Message Received", header: "NEW MESSAGE"});
      // alert("new message");
      MenuButtonList.handleNewMessageAlert();
    }
    this.refreshChatBox(data);
    this.notifyUserOfNewMesage(userId, data);

    MenuButtonList.handleRefreshMessages();

    if (
      _.indexOf(data.recipients.users, userId) > -1 &&
      data.recipients.user_recipient_info[userId]
    ) {
      this.updateGridMenuButton(
        !data.recipients.user_recipient_info[userId].message_read
      );
    }
  }
  onSocketUpdate(url, data) {
    // refresh chat box 
    this.refreshChatBox(data);
    const {
      default: MenuButtonList,
    } = require("../../menu/components/MenuButtonList");
    MenuButtonList.handleRefreshMessages();
    // MenuButtonList.handleRefreshMessageCounts();
    this.updateGridMenuButton(this.getMessageInfo().unread_count > 1);
  }

  updateGridMenuButton(isUnread) {
    //UPDATE GRID MENU BUTTON REACT COMPONENT
    const {
      default: MenuButtonList,
    } = require("../../menu/components/MenuButtonList");
    MenuButtonList.handleIsUnreadMSG(isUnread);
  }

  //We need to FIX THIS NOTIFYUSER as it is currently making use of Message menu and might not even be working
  //notifies toload the new messages if the message module
  //is open and it is a, inbox message
  notifyUserOfNewMesage(userId, data) {
    const MessageMenu = require("sccMessageMenu").default;

    //update inbox count and notify user of new inbox message if the user is recipient
    if (
      _.indexOf(data.recipients.users, userId) > -1 &&
      data.recipients.user_recipient_info[userId]
    ) {
      MessageMenu.hasNewMessages = true;
      //increment read and unread count
      this.getMessageInfo().inbox_count++;
      this.getMessageInfo().unread_count++;
    }

    //update sent count
    if (
      _.indexOf(data.senders.users, userId) > -1 &&
      data.senders.user_sender_info[userId]
    ) {
      this.getMessageInfo().sent_count++;
    }
  }

  getCannedMessageById(id) {
    if (!id) {
      return Language.translate("No Canned Message");
    }
    return Language.translate(this.getCannedMessage(id).canned_message);
  }
}

//module.exports= New MessageModule();
export default new MessageModule();
