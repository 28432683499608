import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CustomCheckbox from "../../CustomCheckbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

// Internal
import Device from "sccDevice";
import Group from "sccGroup";
import DeviceOverlay from "sccDeviceOverlay";
import Permission from "sccPermission";
import Language from "sccLanguage";
import TimeUtils from "sccTimeUtils";
import CustomPagination from "@Components/CustomPagination";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { UserSettingContext } from "../../../modules/user_setting/context/UserSettingContext";
import DeviceInfo from "../../../modules/device/components/DataDisplay/DeviceInfo";
import CustomTooltip from "../../CustomTooltip";
import CustomTextField from "../../CustomTextField";
import CustomDropdown from "../../CustomDropdown";
import { customAssetSelector } from "@Styles/CustomAssetSelector";
import { CustomAssetSelectorContext } from "../CustomAssetSelectorContext";

export default function AssetDevice({
  devices,
  name,
  devicesButtons,
  showDeviceSearchField = true,
  ...props
}) {
  const numberOfItems = devices && devices.length;
  const [expanded, setExpanded] = useState({});
  const [search, setSearch] = useState("");
  const [filterItem, setfilterItem] = useState("all");
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const { userSettingData, setUserSettingData } =
    useContext(UserSettingContext);

  const userSettingReportAge = userSettingData["report_age"];
  const nowReportTime = TimeUtils.getTimestamp();

  const reportAgeSeconds = userSettingReportAge * 60 * 60;

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: itemsPerPage,
    numberOfItems: numberOfItems,
    maxPages: 5,
    startIndex: 0,
    endIndex: numberOfItems > itemsPerPage ? itemsPerPage : numberOfItems,
  });

  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const editMode = assetSelectorState.editMode;
  const hideDeviceCheckbox = assetSelectorState.hideDeviceCheckbox;
  const handleEdit = props.handleItemEdit;
  const hideDevices = assetSelectorState.hideDevices;
  const showSelectedOnly = assetSelectorState.showSelectedOnly;
  const setStateForGroupSelection =
    assetSelectorState.setStateForGroupSelection;

  const setStateForSelection = assetSelectorState.setStateForSelection;
  const pollCommands = mwState.pollCommands;

  const selectionDevices = props.selectionDevices;
  const selectionGroups = props.selectionGroups;

  const arrItemsFilter = [
    {
      value: "all",
      label: Language.translate("All"),
    },
    {
      value: "checked",
      label: Language.translate("Checked"),
    },
    {
      value: "unchecked",
      label: Language.translate("Unchecked"),
    },
  ];

  const onFilterItemChange = (e) => {
    setfilterItem(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleChange = (deviceId, name) => {
    if (editMode && !hideDeviceCheckbox) {
      let arrGroups = selectionGroups;
      const arrDevices = _.xor(selectionDevices, deviceId);

      // Get the groups associated to this device
      const groups = Device.get(deviceId).groups;

      // Loop through each group to get total devices
      groups.forEach((groupId) => {
        arrGroups = _.xor(
          arrGroups,
          checkGroups(groupId, _.uniq(arrDevices.concat(deviceId)))
        );
      });

      setStateForSelection({ groups: arrGroups, devices: arrDevices }, name);

      function checkGroups(groupId, arrDevices) {
        //Get all devices in group
        const devices = Group.get(groupId).devices;
        if (_.intersection(devices, arrDevices).length === devices.length) {
          return [groupId];
        }
        return [];
      }
    } else {
      handleAssetToggle(deviceId);
    }
  };

  const handleAssetToggle = (deviceId) => {
    setExpanded({ ...expanded, [deviceId]: !expanded[deviceId] });
  };

  const handlePollGps = (e, device) => {
    e.stopPropagation();
    pollCommands(device, "poll_gps");
  };

  const handleZoom = (e, id) => {
    e.stopPropagation();
    DeviceOverlay.centerAndZoomTo(id);
  };

  useEffect(() => {
    //reset pagination if devices list have updated
    setPagination({
      currentPage: 1,
      itemsPerPage: itemsPerPage,
      numberOfItems: numberOfItems,
      maxPages: 5,
      startIndex: 0,
      endIndex: numberOfItems > itemsPerPage ? itemsPerPage : numberOfItems,
    });
  }, [devices]);

  // Search Filter for devices
  const filterDevices =
    devices &&
    devices.filter((deviceId) => {
      if (_.isUndefined(Device.get(deviceId))) return;
      const name = Device.get(deviceId).name;
      if (name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        if (filterItem === "all") {
          return deviceId;
        }
        if (filterItem === "checked") {
          return selectionDevices.indexOf(deviceId) !== -1 && deviceId;
        }
        if (filterItem === "unchecked") {
          return selectionDevices.indexOf(deviceId) === -1 && deviceId;
        }
      }
    });

  // on Page Item change
  const arritemsPerPage = [
    {
      value: 10,
      label: `10 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 20,
      label: `20 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 50,
      label: `50 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 100,
      label: `100 ${Language.translate("PER PAGE")}`,
    },
  ];

  const onItemPageChange = (e) => {
    setitemsPerPage(e.target.value);
    setPagination((prevs) => ({
      ...prevs,
      currentPage: 1, //reset the current page to 1
      itemsPerPage: e.target.value, //based on the selection
      numberOfItems: filterDevices.length, //based on the items in the list right now
      startIndex: 0,
      endIndex:
        filterDevices.length > e.target.value
          ? e.target.value
          : filterDevices.length,
    }));
  };

  const onPageChange = (currentPage, startIndex, endIndex) => {
    //change list display to show the current index of records
    setPagination((prevs) => ({
      ...prevs,
      currentPage: currentPage,
      startIndex,
      endIndex,
    }));
  };

  useEffect(() => {
    if (mwState.moduleName === "Device") {
      const updatedDevices = mwState.filteredModuleData[0]?.devices || [];
      setPagination((p) => ({
        ...p,
        currentPage: 1, //reset the current page to 1
        numberOfItems: updatedDevices.length, //based on the items in the list right now
        startIndex: 0,
        endIndex:
          filterDevices.length > p.itemsPerPage
            ? p.itemsPerPage
            : updatedDevices.length,
      }));
    }
  }, [mwState.filteredModuleData]);

  const useStyles = makeStyles((theme) => ({
    ...customAssetSelector(theme),
  }));
  const classes = useStyles();

  const showDeviceList = devices ? (
    filterDevices
      .slice(pagination.startIndex, pagination.endIndex)
      .map((deviceId) => {
        return (
          <React.Fragment>
            {!props.hideDevices &&
              (showSelectedOnly
                ? selectionDevices.indexOf(deviceId) > -1
                : true) && (
                <React.Fragment>
                  <ListItem
                    className="assetDeviceLabel"
                    key={deviceId}
                    role={undefined}
                    dense
                    button
                    onClick={(e) => handleChange([deviceId], name)}
                  >
                    <Grid container xs={12} spacing={1}>
                      {editMode && !hideDeviceCheckbox && (
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selectionDevices.indexOf(deviceId) > -1}
                            color="primary"
                            disableRipple
                            inputProps={{ "aria-labelledby": deviceId }}
                          />
                        </ListItemIcon>
                      )}
                      <Grid item xs={3}>
                        <CustomTooltip
                          title={Device.get(deviceId).name}
                          arrow
                          placement="top"
                        >
                          <ListItemText
                            style={{ width: "100%" }}
                            id={`AssetDevice-${deviceId}`}
                            className="listItemText"
                            primary={`${Device.get(deviceId).name}`}
                          />
                        </CustomTooltip>
                      </Grid>

                      <Grid item xs={7}>
                        {props.displayDeviceImei && (
                          <CustomTooltip
                            title={
                              [
                                "Sentry H6120 BM",
                                "Sentry H6110 MP",
                                "SAT-COM Leopard1",
                                "Trellisware TSM TW-950",
                              ].includes(Device.get(deviceId).type)
                                ? (Device.get(deviceId).type ==
                                  "Trellisware TSM TW-950"
                                    ? "IP-" + Device.get(deviceId).ip_address
                                    : "ALE-" +
                                      Device.get(deviceId).ale_address) +
                                  "/ESN-" +
                                  Device.get(deviceId).Serial_No
                                : Device.get(deviceId).imei
                            }
                            arrow
                            placement="top"
                          >
                            <ListItemText
                              style={{ width: "100%" }}
                              primary={
                                [
                                  "Sentry H6120 BM",
                                  "Sentry H6110 MP",
                                  "SAT-COM Leopard1",
                                  "Trellisware TSM TW-950",
                                ].includes(Device.get(deviceId).type)
                                  ? (Device.get(deviceId).type ==
                                    "Trellisware TSM TW-950"
                                      ? "IP-" + Device.get(deviceId).ip_address
                                      : "ALE-" +
                                        Device.get(deviceId).ale_address) +
                                    "/ESN-" +
                                    Device.get(deviceId).Serial_No
                                  : Device.get(deviceId).imei
                              }
                              className="listItemText"
                            />
                          </CustomTooltip>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        {!editMode && devicesButtons && (
                          <React.Fragment>
                            <ListItemSecondaryAction
                              className={classes.itemActionButtonsContainer}
                            >
                              {/* only display zoom button based on report_age in user setting */}
                              {(userSettingReportAge === -1 ||
                                nowReportTime - reportAgeSeconds <=
                                  Device.get(deviceId).report_timestamp) && (
                                <CustomTooltip
                                  title={Language.translate("Zoom")}
                                  arrow
                                  placement="top"
                                >
                                  <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    onClick={(e) => handleZoom(e, deviceId)}
                                  >
                                    <GpsFixedIcon />
                                  </IconButton>
                                </CustomTooltip>
                              )}
                              {Permission.verify("device", "poll") ? (
                                <CustomTooltip
                                  title={Language.translate("Poll GPS")}
                                  arrow
                                  placement="top"
                                >
                                  <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    onClick={(e) =>
                                      handlePollGps(e, Device.get(deviceId))
                                    }
                                  >
                                    <RssFeedIcon />
                                  </IconButton>
                                </CustomTooltip>
                              ) : null}
                              {Permission.verify("device", "edit") && (
                                <CustomTooltip
                                  title={Language.translate("Edit Device")}
                                  arrow
                                  placement="top"
                                >
                                  <IconButton
                                    edge="end"
                                    aria-label="edit"
                                    onClick={() =>
                                      handleEdit(Device.get(deviceId), 2)
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </CustomTooltip>
                              )}
                              <IconButton
                                onClick={() => handleAssetToggle(deviceId)}
                                edge="end"
                                aria-label="comments"
                              >
                                {expanded[deviceId] ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </IconButton>
                            </ListItemSecondaryAction>
                          </React.Fragment>
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                  {!editMode && (
                    <Collapse
                      key={`collapseAssetInfo${deviceId}`}
                      in={expanded[deviceId]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <DeviceInfo device={Device.get(deviceId)} />
                    </Collapse>
                  )}
                </React.Fragment>
              )}
          </React.Fragment>
        );
      })
  ) : (
    <div className="noDevices">{Language.translate("No Devices")}</div>
  );

  return (
    <ul className={classes.assetDevice}>
      <div className="assetDeviceFilterWrapper">
        {!props.hideDevices && devices.length > 10 && showDeviceSearchField && (
          <CustomTextField
            id="txtAssetSelectorSearch"
            name="search"
            placeholder={Language.translate("Search")}
            className="assetSelectorSearch"
            value={search}
            disableUnderline={true}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  id="clearSearch"
                  onClick={handleClearSearch}
                  style={{ cursor: "pointer" }}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />
        )}

        <div
          className={
            editMode && devices.length > 10 ? "pagingAndFilterContainer" : ""
          }
        >
          {!props.hideDevices && devices.length > 10 && (
            <div className="pagingContainer">
              <CustomDropdown
                id="pagingSelector"
                className="dropdown"
                value={itemsPerPage}
                onChange={onItemPageChange}
              >
                {arritemsPerPage.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomDropdown>
            </div>
          )}
          {editMode && !props.hideDevices && devices.length > 1 && (
            <CustomDropdown
              id="AssetSelectorDropdown"
              className="assetSelectorDropdown dropdown"
              value={filterItem}
              select
              InputProps={{ disableUnderline: true }}
              onChange={onFilterItemChange}
            >
              {arrItemsFilter.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomDropdown>
          )}
        </div>
      </div>
      {filterDevices.length === 0 ? (
        <div className="noDevices">{Language.translate("No Devices")}</div>
      ) : (
        <div className="deviceListContainer">
          {showDeviceList}
          {!props.hideDevices && filterDevices.length > 10 && (
            <div className="assetPagination">
              <CustomPagination
                pagination={pagination}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
      )}
    </ul>
  );
}
