import React, { useState, useContext, useEffect } from "react";

// External
import { useFormikContext } from "formik";
import _ from "lodash";

// Material-UI
import { Grid, Badge, makeStyles } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import NearMeIcon from "@material-ui/icons/NearMe";
import SyncIcon from "@material-ui/icons/Sync";
import TextField from "@material-ui/core/TextField";

// Internal
import Language from "sccLanguage";
import Utils from "sccUtils";
import Sync from "sccSync";
import SyncMenu from "sccSyncMenu";
import TimeUtils from "sccTimeUtils";
import Geofence from "sccGeofence";
import GeofenceOverlay from "sccGeofenceOverlay";
import Alert from "sccAlert";
import GuiUtils from "sccGuiUtils";
import Permission from "sccPermission";
import CustomPagination from "@Components/CustomPagination";
import CustomDialogYesNoCancel from "../../CustomDialogYesNoCancel";
import CustomDialog from "@Components/CustomDialog/index.js";
import CustomButton from "@Components/CustomButton";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "../ModuleWrapperContext";
import { SelectedGeofenceContext } from "../../../modules/geofence/components/context/SelectedGeofenceContext";
import CustomDialogYesNo from "@Components/CustomDialogYesNo/CustomDialogYesNo";
import CustomIcon from "@Components/CustomIcon";
import { AppContext } from "../../../AppContext";
import MasterDataFile from "../../../modules/device/body/MasterDataFile";
import Client from "sccClient";
import Profile from "sccProfile";

const MW_NOTHING = process.env.REACT_APP_MW_NOTHING;
const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;
const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;
const MW_SINGLEPAGE_MODE = process.env.REACT_APP_MW_SINGLEPAGE_MODE;
const MW_ALERTS_MODULE_MODE = process.env.REACT_APP_MW_ALERTS_MODULE_MODE;
const MW_ASSETS_MODULE_MODE = process.env.REACT_APP_MW_ASSETS_MODULE_MODE;
const MW_MESSAGE_MODULE_MODE = process.env.REACT_APP_MW_MESSAGE_MODULE_MODE;
const MW_MESSAGE_MODULE_LIST_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_LIST_MODE;
const MW_MESSAGE_MODULE_COMPOSE_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_COMPOSE_MODE;
const MW_MESSAGE_MODULE_READ_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_READ_MODE;
const MW_IRIDIUM_MODULE_MODE = process.env.REACT_APP_MW_IRIDIUM_MODULE_MODE;
const MW_SYNC_MODULE_MODE = process.env.REACT_APP_MW_SYNC_MODULE_MODE;

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "5px",
    backgroundColor: "#222b30",
    width: "100%",
    color: "#5b7182",
  },

  actionButton: {
    padding: 0,
  },

  syncActionButton: {
    padding: 0,
    flexWrap: "nowrap",
  },

  gapSpan: {
    width: 8,
    display: "inline-flex",
  },

  syncFooterItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.colors.green.main,
    borderColor: "#4cae4c",
    color: "#293239",
    margin: "0px 2px",
    height: 32,

    "& span": {
      paddingRight: 6,
      fontSize: 11,
    },
  },

  pendingFooterItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.colors.orange.main,
    borderColor: theme.palette.colors.orange.main,
    color: "#293239",
    margin: "0px 2px",
    height: 32,

    "& span": {
      paddingRight: 6,
      fontSize: 11,
    },
  },

  syncingFooterItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.colors.blue.main,
    borderColor: "#2e6da4",
    color: "#293239",
    margin: "0px 2px",
    position: "relative",
    height: 32,

    "& span": {
      paddingRight: 7,
      fontSize: 11,
    },
  },

  syncFooterText: {
    paddingRight: 7,
    fontSize: 11,
    display: "inline",
    maxWidth: 50,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  syncBadge: {
    "& span": {
      backgroundColor: "#293239",
      color: theme.palette.colors.green.main,
    },
  },

  pendingBadge: {
    "& span": {
      backgroundColor: "#293239",
      color: theme.palette.colors.orange.main,
    },
  },

  syncingBadge: {
    "& span": {
      backgroundColor: "#293239",
      color: theme.palette.colors.blue.main,
    },
  },

  sycnIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: 6,
    paddingRight: "0px !important",

    "& svg": {
      fontSize: 12,
    },
  },
}));

function ModuleWrapperFooter(props) {
  const [btnAddText, setBtnAddText] = useState(Language.translate("Add"));
  const [btnSaveText, setBtnSaveText] = useState(Language.translate("Save"));
  const [btnCancelText, setBtnCancelText] = useState(
    Language.translate("Cancel")
  );
  const [btnDeleteText, setBtnDeleteText] = useState(
    Language.translate("Delete")
  );

  const acknowledgeAllText = Language.translate(
    "Are you sure you want to acknowledge all"
  );

  const [alertsConfirmationOpen, setAlertsConfirmationOpen] = useState(false);

  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [appState, setAppState] = useContext(AppContext);
  const { setModuleEditMode } = useContext(SelectedGeofenceContext);
  const emptyFormData = mwState.emptyFormData;
  const updateModuleData = mwState.updateModuleData;
  const handleItemDelete = mwState.handleItemDelete;
  const changeToComposeMode = mwState.changeToComposeMode;
  const originalValues = mwState.moduleItemDataOriginal;
  const [password, setPassword] = useState();
  // these state and function are used to display confirmation box in address book to prompt if sync address_book
  const [openYesNoCancelDialogABsync, setOpenYesNoCancelDialogABsync] =
    useState(false);

  const [
    openYesNoDialogClientFeaturesRemoval,
    setOpenYesNoDialogClientFeaturesRemoval,
  ] = useState(false);
  const [confirmFeaturesRemovalMsg, setConfirmFeaturesRemovalMsg] =
    useState("");

  const [removeABdataFromClient, setRemoveABdataFromClient] = useState(false);
  const [removeSAdataFromClient, setRemoveSAdataFromClient] = useState(false);
  const [removeHermesDataFromClient, setRemoveHermesDataFromClient] =
    useState(false);

  const classes = useStyles();

  const [openEnterPasswordDialog, setOpenEnterPasswordDialog] = useState(false);

  const formik = useFormikContext();

  const moduleItemData = formik.values;

  // replace max_speed or min_speed value to pass hasChanged validation
  if (moduleItemData.max_speed === "") {
    moduleItemData.max_speed = null;
  }
  if (moduleItemData.min_speed === "") {
    moduleItemData.min_speed = null;
  }

  const inputDataHasChanged = mwState.inputDataHasChanged;

  const handleDialogOpenClose = () => {
    mwState.setDialogOkAction(() => okActionClose());
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  function okActionClose() {
    mwState.closeModuleWrapperPopper();
    mwState.setDialogOpen(false);
  }
  const handleDialogOpen = () => {
    mwState.setDialogOkAction(() => okAction());
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  const handleDialogOpenMessage = () => {
    mwState.setDialogOkAction(() => okActionMessage());
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };

  function changeToAddModeFromAssets(e, subMode) {
    e.preventDefault();
    setMwState((p) => ({
      ...p,
      wrapperDisplayMode: MW_ADDEDIT_MODE,
      wrapperDisplaySubMode: subMode,
    }));
  }
  function changeToAddMode(e) {
    e.preventDefault();
    setMwState((p) => ({
      ...p,
      wrapperDisplayMode: MW_ADDEDIT_MODE,
    }));
  }

  function okActionClose() {
    mwState.closeModuleWrapperPopper();
    mwState.setDialogOpen(false);
  }
  function okAction() {
    cancelAndBackToListingFunction();
    mwState.setDialogOpen(false);
  }
  function okActionMessage() {
    mwState.cancelAndBackToMessageListingMode();
    mwState.setDialogOpen(false);
  }
  function cancelAction() {
    mwState.setDialogOpen(false);
  }
  function cancelAndBackToListingFunction() {
    setMwState((p) => ({
      ...p,
      moduleFormHasChanges: false,
    }));
    if (mwState.moduleName === "Geofence") {
      // set map background to light
      setModuleEditMode("end");
      window.olMap.editMode = "end";
      GeofenceOverlay.endAllModifications();
      // make sure GeofenceOverlay returns to original setting
      GeofenceOverlay.setBlockSelection(false);
      GeofenceOverlay.deselectFeature();
      // reset Geofence
      const geofenceId = mwState.moduleItemData.id;
      if (geofenceId) {
        const geofence = Geofence.get(geofenceId);
        GeofenceOverlay.refresh(geofence);
      } else {
        // if in Add mode, end drawing
        GeofenceOverlay.endDrawing();
      }
      GeofenceOverlay.resetFeatureStyleToPreEdit();
      GeofenceOverlay.floatingFeatureOnMap = false;
      GeofenceOverlay.selectIconSource.clear();
      GeofenceOverlay.setDrawFeature(null);
    }
    if (mwState.moduleName !== "Device") {
      mwState.cancelAndBackToListingMode();
    } else {
      mwState.cancelAndBackToAssetListingMode();
    }
  }
  function customerSaveAndBack() {
    const newFeatureList = moduleItemData.features;
    const originalFeatureList = originalValues.features;
    var authRequiredFeatureBeingRemoved = false;
    var featuresToRemove = _.filter(originalFeatureList, (x) => {
      if (!newFeatureList.includes(x)) {
        if ([6, 11, 12].includes(x)) {
          authRequiredFeatureBeingRemoved = true;
        }
      }
      return !newFeatureList.includes(x);
    });

    if (featuresToRemove.length && authRequiredFeatureBeingRemoved) {
      _.each(featuresToRemove, (featureId) => {
        switch (featureId) {
          case 6:
            setRemoveSAdataFromClient(true);
            break;
          case 11:
            setRemoveABdataFromClient(true);
            break;
          case 12:
            setRemoveHermesDataFromClient(true);
        }
      });
    } else {
      saveAndBackToList();
    }
  }

  useEffect(() => {
    if (
      removeSAdataFromClient ||
      removeABdataFromClient ||
      removeHermesDataFromClient
    ) {
      showAuthRequiredFeaturesRemovalDialog();
    }
  }, [
    removeSAdataFromClient,
    removeABdataFromClient,
    removeHermesDataFromClient,
  ]);

  function showAuthRequiredFeaturesRemovalDialog() {
    const confirmBoxMsg1 =
      Language.translate(
        "The Following feature(s) will be removed from this customer:"
      ) + " ";

    var changedModulesString = "";
    if (removeHermesDataFromClient) {
      changedModulesString =
        changedModulesString + "- Hermes (Gateway Devices)";
    }
    if (removeABdataFromClient) {
      changedModulesString =
        changedModulesString + "- " + "Address Book (Address books)";
    }
    if (removeSAdataFromClient) {
      changedModulesString =
        changedModulesString + "- " + "Situational Awareness (Rules)";
    }

    changedModulesString =
      changedModulesString +
      " " +
      Language.translate(
        "Password confirmation will be required to complete this action."
      ) +
      " " +
      Language.translate("Press YES to continue.");

    setConfirmFeaturesRemovalMsg(confirmBoxMsg1 + changedModulesString);
    setOpenYesNoDialogClientFeaturesRemoval(true);
  }

  function removeFeaturesDataFromClientAndSave() {
    //Menu.prototype.save.call($this);
    if (removeABdataFromClient) {
      Client.deleteAllAddressBooks(moduleItemData.id);
    }
    if (removeHermesDataFromClient) {
      Client.deleteAllHermes(moduleItemData.id);
    }
    if (removeSAdataFromClient) {
      Client.deleteAllSaRules(moduleItemData.id);
    }
    setOpenYesNoDialogClientFeaturesRemoval(false);
    resetAuthRequiredFeaturesCheckStates();
    saveAndBackToList();
  }

  function resetAuthRequiredFeaturesCheckStates() {
    Client.emptyAbFeature();
    Client.emptyHermesFeature();
    Client.emptySaFeature();

    setRemoveSAdataFromClient(false);
    setRemoveABdataFromClient(false);
    setRemoveHermesDataFromClient(false);
  }

  function saveAndBackToList() {
    let continueFurther = true;
    const formikValues = formik.values;
    if (mwState.moduleName === "Geofence") {
      // set map background to light
      setModuleEditMode("end");
      window.olMap.editMode = "end";
      // make sure GeofenceOverlay returns to original setting
      GeofenceOverlay.endAllModifications();
      GeofenceOverlay.setBlockSelection(false);
      GeofenceOverlay.deselectFeature();
      GeofenceOverlay.removeDashStrokeFromFeature();
      GeofenceOverlay.setDrawFeature(null);
    }

    //  backend does not accept empty string, here we replace "" to null to pass validation
    if (formikValues.max_speed === "") {
      formikValues.max_speed = null;
    }

    if (formikValues.min_speed === "") {
      formikValues.min_speed = null;
    }

    if (typeof mwState.handleSpecialSaveValidation === "function") {
      continueFurther = mwState.handleSpecialSaveValidation();
    }
    if (!continueFurther) return false;

    formik.validateForm().then((validation) => {
      formik.setTouched(validation);
      if (Object.keys(validation).length === 0) {
        // Form is valid, do any success call
        const message = {
          title: mwState.title,
          text: Language.translate(
            "Successfully " + (formik.values.id ? "Updated" : "Created")
          ),
          subText: formik.values[mwState.searchField[0]]
            ? formik.values[mwState.searchField[0]]
            : null,
          //special fix for the Device module
          backToWrapperDisplayMode:
            mwState.moduleName === "Device"
              ? MW_ASSETS_MODULE_MODE
              : MW_LIST_MODE,
        };
        updateModuleData(formikValues, mwState.routeUrl, message).then(
          (data) => {
            //We do not want the moduledata to be updated like this after an update or save
            if (mwState.moduleName !== "Device") {
              // refresh list
              if (data && data?.result?.id) {
                const filteredData = mwState.filteredModuleData;
                const moduleData = mwState.moduleData;
                const updatedItem = data.result;

                // update Roles groupedPermissions
                if (mwState.moduleName === "Permission") {
                  updatedItem.groupedPermissions = updatedItem.permissions
                    ? Permission.getGroupedPermissions(updatedItem.permissions)
                    : null;
                }

                moduleData[updatedItem.id] = updatedItem;
                filteredData[updatedItem.id] = updatedItem;

                setMwState((p) => ({
                  ...p,
                  moduleData: moduleData,
                  filteredModuleData: filteredData,
                }));
              }
            }
          }
        );
      }
    });
  }

  function hasSubscribers(ab) {
    return (
      ab.subscribers.groups.length > 0 || ab.subscribers.devices.length > 0
    );
  }

  function hasMembers(ab) {
    return (
      ab.members.groups.length > 0 ||
      ab.members.devices.length > 0 ||
      ab.members.users.length > 0
    );
  }

  function addressBookSaveAndBack() {
    const newValues = formik.values;

    const checkSubscribers = _.isEqual(
      newValues.subscribers,
      originalValues.subscribers
    );
    const checkMembers = _.isEqual(newValues.members, originalValues.members);

    if (!Permission.isModuleAvailable("sync")) {
      saveAddressBook();
    } else if (!hasSubscribers(newValues) && checkSubscribers) {
      //Has no subs and subs have not changed
      saveAddressBook();
    } else if (checkMembers && checkSubscribers) {
      //Subscribers and members have not changed
      saveAddressBook();
    } else if (
      !hasSubscribers(newValues) &&
      !hasMembers(newValues) &&
      checkSubscribers
    ) {
      //no subs no members AND Subs changed
      saveAddressBook();
    } else if (
      !hasSubscribers(newValues) &&
      hasMembers(newValues) &&
      checkSubscribers
    ) {
      //no subs, has members AND Subs changed
      saveAddressBook();
    } else {
      // open sync confirmation Dialog
      setOpenYesNoCancelDialogABsync(true);
    }
  }

  // For Address_book save use (with or without sync)
  function saveAddressBook(withSync = false) {
    formik.validateForm().then((validation) => {
      formik.setTouched(validation);
      if (Object.keys(validation).length === 0) {
        // Form is valid, do any success call
        const message = {
          title: mwState.title,
          text: Language.translate(
            "Successfully " + (formik.values.id ? "Updated" : "Created")
          ),
          subText: formik.values[mwState.searchField[0]]
            ? formik.values[mwState.searchField[0]]
            : null,
          backToWrapperDisplayMode: MW_LIST_MODE,
        };

        const formikValues = formik.values;

        // check if sync subscribers upon save
        if (withSync) {
          formikValues["sync_now"] = true;
        } else {
          formikValues["sync_now"] = false;
        }
        updateModuleData(formikValues, mwState.routeUrl, message)
          .then((data) => {
            // refresh AB list
            if (data && data?.result?.id) {
              const filteredData = mwState.filteredModuleData;
              const moduleData = mwState.moduleData;
              const updatedItem = data.result;
              moduleData[updatedItem.id] = updatedItem;
              filteredData[updatedItem.id] = updatedItem;
              setMwState((p) => ({
                ...p,
                moduleData: moduleData,
                filteredModuleData: filteredData,
              }));
            }
          })
          .finally(() => {
            setOpenYesNoCancelDialogABsync(false);
          });
      }
    });
  }

  function saveAndClose() {
    let continueFurther = true;
    if (typeof mwState.handleSpecialSaveValidation === "function")
      continueFurther = mwState.handleSpecialSaveValidation();
    if (!continueFurther) return false;
    formik.validateForm().then((validation) => {
      formik.setTouched(validation);
      if (Object.keys(validation).length === 0) {
        // Form is valid, do any success call
        const message = {
          title: mwState.title,
          text: Language.translate("Successfully Updated"),
          subText: formik.values[mwState.searchField[0]]
            ? formik.values[mwState.searchField[0]]
            : null,
          closeModule: true,
        };
        updateModuleData(formik.values, mwState.routeUrl, message).then(
          (data) => {
            // refresh list
            if (data && data?.result?.id) {
              const filteredData = mwState.filteredModuleData;
              const moduleData = mwState.moduleData;
              const updatedItem = data.result;

              // update Roles groupedPermissions
              if (mwState.moduleName === "Permission") {
                updatedItem.groupedPermissions = updatedItem.permissions
                  ? Permission.getGroupedPermissions(updatedItem.permissions)
                  : null;
              }

              moduleData[updatedItem.id] = updatedItem;
              filteredData[updatedItem.id] = updatedItem;

              setMwState((p) => ({
                ...p,
                moduleData: moduleData,
                filteredModuleData: filteredData,
              }));
            }
          }
        );
      }
    });
  }
  function sendMessage() {
    const options = [
      1,
      mwState.pagination.itemsPerPage,
      mwState.moduleData.currentFolder,
      mwState.moduleData.sortDesc,
    ]; //used for reloading
    formik.validateForm().then((validation) => {
      formik.setTouched(validation);
      if (Object.keys(validation).length === 0) {
        //formik values are retrieved to be modified
        let messageValues = _.cloneDeep(formik.values);
        //replace 0 with null incase no canned mesages is selected
        if (formik.values.cannedmessage_id == 0) {
          messageValues.cannedmessage_id = "null";
        }
        // Form is valid, do any success call
        const message = {
          title: "MESSAGE",
          text: Language.translate("Successfully Created"),
          noRefresh: true,
          closeModule: false,
        };
        updateModuleData(messageValues, "/api/v1/message", message);
        mwState.loadMessages(options);
        mwState.backToMessagesList();
      }
    });
  }

  function acknowledgeAll(alertIds) {
    // acknowledge all alerts
    Alert.acknowledge(alertIds)
      .then((response) => {
        if (response.error) {
          appState.displaySnackbarMessage({
            title: mwState.title,
            message: Language.translate(response.error),
            subMessage: "",
            variant: "error",
          });
        } else if (response.result) {
          appState.displaySnackbarMessage({
            title: mwState.title,
            message: Language.translate("Successfully Acknowledged"),
            subMessage: "",
            variant: "success",
          });
        }
      })
      .catch(() => {
        appState.displaySnackbarMessage({
          title: mwState.title,
          message: Language.translate("Error"),
          subMessage: "",
          variant: "error",
        });
      });
  }

  function acknowledgeAlerts(alertIds) {
    acknowledgeAll(alertIds);
    setAlertsConfirmationOpen(false);
  }

  function getAcknowledgeAlertsIds(alerts) {
    let filteredAlerts = {};
    Object.values(alerts).forEach((alert) => {
      if (_.isEmpty(alert.acknowledgements)) {
        filteredAlerts[alert.id] = alert;
      }
    });
    if (filteredAlerts) {
      return Object.keys(filteredAlerts).map((id) => Number(id));
    } else {
      return [];
    }
  }

  function groupClean(bills) {
    return _.map(bills, (bill) => {
      return _.pick(bill, ["imei", "total_bytes", "total_inbox"]);
    });
  }

  function handleExport() {
    if (mwState.moduleItemData.length > 0) {
      Utils.generateCSVFileAndDownload(groupClean(mwState.moduleItemData), {
        file_title:
          "IridiumBilling" + TimeUtils.getCurrentDateTimeAsATag() + ".csv",
      });
    } else {
      appState.displaySnackbarMessage({
        title: "Iridium Billing",
        message: "No data is available.",
        subMessage: null,
        variant: "error",
      });
    }
    return;
  }

  function deleteItem() {
    if (mwState.moduleName === "address_book") {
      handleItemDelete(
        `${mwState.routeUrl}/${moduleItemData.id}/true`,
        mwState.title,
        mwState.moduleName,
        mwState.moduleItemDataOriginal[mwState.searchField[0]]
      );
    } else {
      handleItemDelete(
        `${mwState.routeUrl}/${moduleItemData.id}`,
        mwState.title,
        mwState.moduleName,
        mwState.moduleItemDataOriginal[mwState.searchField[0]]
      );
    }
  }
  function onPageChange(currentPage, startIndex, endIndex) {
    //change list display to show the current index of records
    setMwState((p) => ({
      ...p,
      pagination: {
        ...p.pagination,
        currentPage: currentPage,
        startIndex,
        endIndex,
      },
    }));
  }
  function onMessagingPageChange(currentPage, startIndex, endIndex) {
    const currentFolder = mwState.moduleData.currentFolder || "inbox";
    const sortDesc = _.isBoolean(mwState.moduleData.sortDesc)
      ? mwState.moduleData.sortDesc
      : true;
    if (!mwState.searchExecuted) {
      mwState.loadMessages([
        currentPage,
        mwState.pagination.itemsPerPage,
        currentFolder,
        sortDesc,
      ]);
    }
    setMwState((p) => ({ ...p, pagination: { ...p.pagination, currentPage } }));
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  function cancelEnterPasswordDialog() {
    setOpenEnterPasswordDialog(false);
    setOpenYesNoDialogClientFeaturesRemoval(false);
    resetAuthRequiredFeaturesCheckStates();
  }

  const handleLogin = (e) => {
    const username = Profile.get().username;
    const data = { username, password };
    const url = Utils.apiUrlPrefix + "/auth/login";

    return fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          appState.displaySnackbarMessage({
            message: Language.translate(
              "Authorization failure. Check your input and ensure you have internet connection."
            ),
            variant: "error",
          });
          setOpenEnterPasswordDialog(false);
          resetAuthRequiredFeaturesCheckStates();
        }
        if (data.token) {
          removeFeaturesDataFromClientAndSave();
          setOpenEnterPasswordDialog(false);
        }
      })
      .catch(function (err) {
        console.log("Error in Catch: ", err);
      });
  };

  if (mwState.showFooter) {
    switch (mwState.wrapperDisplayMode) {
      case MW_LIST_MODE: //generic listing modules
        return (
          <Grid
            justifyContent="space-between"
            className={classes.footer}
            container
          >
            <Grid item className={classes.actionButton} xs={3}>
              {mwState.verifyAddPermission && (
                <CustomButton
                  onClick={changeToAddMode}
                  size="medium"
                  color="command"
                  id="btnAdd"
                  variant="contained"
                  startIcon={<KeyboardArrowRightIcon />}
                >
                  {btnAddText}
                </CustomButton>
              )}
            </Grid>
            {mwState.showPagination && (
              <Grid item align="right" className="pagination" xs={9}>
                <CustomPagination
                  pagination={mwState.pagination}
                  onPageChange={onPageChange}
                />
              </Grid>
            )}
          </Grid>
        );
        break;
      case MW_ADDEDIT_MODE: //generic detail pages
        return (
          <Grid
            justifyContent="flex-start"
            className={classes.footer}
            container
          >
            <CustomDialogYesNoCancel
              open={openYesNoCancelDialogABsync}
              // text={Language.translate(
              //   "Would you like to sync this Address Book to the intended Subscribers upon saving?"
              // )}
              text="Would you like to sync this Address Book to the intended Subscribers upon saving?"
              onNoAction={() => saveAddressBook(false)}
              onYesAction={() => saveAddressBook(true)}
              onCancelAction={() => setOpenYesNoCancelDialogABsync(false)}
            />
            <CustomDialogYesNo
              open={openYesNoDialogClientFeaturesRemoval}
              text={confirmFeaturesRemovalMsg}
              onNoAction={() => {
                setOpenYesNoDialogClientFeaturesRemoval(false);
                resetAuthRequiredFeaturesCheckStates();
                setRemoveSAdataFromClient(false);
                setRemoveABdataFromClient(false);
                setRemoveHermesDataFromClient(false);
              }}
              onYesAction={() => setOpenEnterPasswordDialog(true)}
            />
            <CustomDialog
              type="logout"
              open={openEnterPasswordDialog}
              onCancelAction={cancelEnterPasswordDialog}
              onOkAction={handleLogin}
              text={
                Language.translate("Please enter your login password") + ":"
              }
              children={
                <TextField
                  autoFocus
                  margin="dense"
                  id="Factory_Reset_Password"
                  type="password"
                  onChange={handlePassword}
                  fullWidth
                />
              }
            />
            <Grid item className={classes.actionButton} xs={6}>
              <CustomButton
                onClick={() => {
                  if (!mwState.isSaveInProgress) {
                    switch (mwState.moduleName) {
                      case "address_book":
                        addressBookSaveAndBack();
                        break;
                      case "Client":
                        customerSaveAndBack();
                        break;
                      default:
                        saveAndBackToList();
                    }

                    setMwState((p) => ({
                      ...p,
                      isSaveInProgress: true,
                    }));

                    setTimeout(() => {
                      setMwState((p) => ({
                        ...p,
                        isSaveInProgress: false,
                      }));
                    }, 4000); //allows saving after 4 seconds
                  }
                }}
                size="medium"
                color="action"
                id="btnAdd"
                variant="contained"
                type="submit"
                disabled={
                  !mwState.moduleFormHasChanges ||
                  (mwState.moduleName === "AR" &&
                    moduleItemData.alerts?.length === 0) ||
                  mwState.isSaveInProgress
                }
                startIcon={<CheckIcon />}
              >
                {btnSaveText}
              </CustomButton>
            </Grid>
            <Grid item className="cancelButton" align="right" xs={6}>
              <CustomButton
                onClick={
                  !mwState.moduleFormHasChanges
                    ? cancelAndBackToListingFunction
                    : handleDialogOpen
                }
                size="medium"
                color="cancel"
                id="btnCancel"
                variant="contained"
                startIcon={<CloseIcon />}
              >
                {btnCancelText}
              </CustomButton>
              <span className={classes.gapSpan} />
              {mwState.verifyDeletePermission &&
              moduleItemData.id &&
              !["Device", "Map"].includes(mwState.moduleName) ? (
                <CustomButton
                  onClick={deleteItem}
                  size="medium"
                  color="delete"
                  id="btnDelete"
                  variant="contained"
                  startIcon={<DeleteIcon />}
                >
                  {btnDeleteText}
                </CustomButton>
              ) : null}
            </Grid>
          </Grid>
        );
        break;
      case MW_SINGLEPAGE_MODE: //currently being used by Profile
        return (
          <Grid
            justifyContent="flex-start"
            className={classes.footer}
            container
          >
            <Grid item className={classes.actionButton} xs={6}>
              <CustomButton
                onClick={() => {
                  if (!mwState.isSaveInProgress) {
                    saveAndClose();
                    setMwState((p) => ({
                      ...p,
                      isSaveInProgress: true,
                    }));

                    setTimeout(() => {
                      setMwState((p) => ({
                        ...p,
                        isSaveInProgress: false,
                      }));
                    }, 4000);
                  }
                }}
                size="medium"
                color="action"
                id="btnAdd"
                variant="contained"
                type="submit"
                disabled={
                  !mwState.moduleFormHasChanges || mwState.isSaveInProgress
                }
                startIcon={<CheckIcon />}
              >
                {btnSaveText}
              </CustomButton>
            </Grid>
            <Grid item className="cancelButton" align="right" xs={6}>
              <CustomButton
                onClick={
                  !mwState.moduleFormHasChanges
                    ? mwState.closeModuleWrapperPopper
                    : handleDialogOpenClose
                }
                size="medium"
                color="cancel"
                id="btnCancel"
                variant="contained"
                startIcon={<CloseIcon />}
              >
                {btnCancelText}
              </CustomButton>
            </Grid>
          </Grid>
        );
        break;
      case MW_ALERTS_MODULE_MODE: //currently being used by Alerts
        const confirmationText =
          getAcknowledgeAlertsIds(mwState.filteredModuleData).length > 0
            ? getAcknowledgeAlertsIds(mwState.filteredModuleData).length + " "
            : "";
        return (
          <>
            <CustomDialogYesNo
              open={alertsConfirmationOpen}
              onNoAction={() => setAlertsConfirmationOpen(false)}
              onYesAction={() =>
                acknowledgeAlerts(
                  getAcknowledgeAlertsIds(mwState.filteredModuleData)
                )
              }
              text={
                acknowledgeAllText +
                " " +
                confirmationText +
                Language.translate("alerts") +
                "?"
              }
            />

            <Grid
              justifyContent="flex-start"
              className={classes.footer}
              container
            >
              <Grid item className={classes.actionButton} xs={3}>
                {mwState.filteredModuleData &&
                Object.values(mwState.filteredModuleData)?.length > 0 ? (
                  <CustomButton
                    onClick={() => setAlertsConfirmationOpen(true)}
                    size="medium"
                    color="command"
                    id="btnAdd"
                    variant="contained"
                    type="submit"
                    startIcon={<CheckIcon />}
                    disabled={
                      getAcknowledgeAlertsIds(mwState.filteredModuleData)
                        .length < 1
                    }
                  >
                    {Language.translate("Acknowledge All").toUpperCase()}
                  </CustomButton>
                ) : (
                  <div />
                )}
              </Grid>
              {mwState.showPagination &&
                mwState.moduleData &&
                Object.values(mwState.moduleData)?.length > 0 && (
                  <Grid item align="right" className="pagination" xs={9}>
                    <CustomPagination
                      pagination={mwState.pagination}
                      onPageChange={onPageChange}
                    />
                  </Grid>
                )}
            </Grid>
          </>
        );
        break;
      case MW_ASSETS_MODULE_MODE: //used by Assets
        // return <CustomAssetSelectorContextProvider>
        return (
          <Grid
            justifyContent="space-between"
            className={classes.footer}
            container
          >
            <Grid item className={classes.actionButton} xs={3}>
              {mwState.verifyAddPermission && (
                <CustomButton
                  onClick={(e) => changeToAddModeFromAssets(e, 1)}
                  size="medium"
                  color="command"
                  id="btnAdd"
                  variant="contained"
                  startIcon={<KeyboardArrowRightIcon />}
                >
                  {btnAddText}
                </CustomButton>
              )}
            </Grid>
            <Grid item align="right" className="generateFile" xs={9}>
              <MasterDataFile
                label={Language.translate("Master Data File").toUpperCase()}
                mainGroupName={mwState.mainGroupName}
              />
            </Grid>
          </Grid>
        );
        // </CustomAssetSelectorContextProvider>;
        break;
      case MW_SYNC_MODULE_MODE: //used by Sync
        return (
          <Grid
            justifyContent="flex-start"
            className={classes.footer}
            container
          >
            <Grid item container className={classes.syncActionButton} xs={5}>
              {SyncMenu.getCountOfStatus(Sync.get()).synced_count > 0 && (
                <Grid item xs={4} className={classes.syncFooterItem}>
                  <CustomTooltip
                    title={Language.translate("Up-to-date").toUpperCase()}
                    placement="top"
                  >
                    <span className={classes.syncFooterText}>
                      {Language.translate("Up-to-date").toUpperCase()}
                    </span>
                  </CustomTooltip>
                  <Badge
                    badgeContent={
                      SyncMenu.getCountOfStatus(Sync.get()).synced_count
                    }
                    color="primary"
                    className={classes.syncBadge}
                  />
                </Grid>
              )}
              {SyncMenu.getCountOfStatus(Sync.get()).pending_count > 0 && (
                <Grid item xs={4} className={classes.pendingFooterItem}>
                  <CustomTooltip
                    title={Language.translate("Pending").toUpperCase()}
                    placement="top"
                  >
                    <span className={classes.syncFooterText}>
                      {Language.translate("Pending").toUpperCase()}
                    </span>
                  </CustomTooltip>
                  <Badge
                    badgeContent={
                      SyncMenu.getCountOfStatus(Sync.get()).pending_count
                    }
                    color="primary"
                    className={classes.pendingBadge}
                  />
                </Grid>
              )}
              {SyncMenu.getCountOfStatus(Sync.get()).syncing_count > 0 && (
                <Grid item xs={4} className={classes.syncingFooterItem}>
                  <span className={classes.sycnIcon}>
                    <SyncIcon />
                  </span>
                  <CustomTooltip
                    title={Language.translate("Syncing").toUpperCase()}
                    placement="top"
                  >
                    <span className={classes.syncFooterText}>
                      {Language.translate("Syncing").toUpperCase()}
                    </span>
                  </CustomTooltip>
                  <Badge
                    badgeContent={
                      SyncMenu.getCountOfStatus(Sync.get()).syncing_count
                    }
                    color="primary"
                    className={classes.syncingBadge}
                  />
                </Grid>
              )}
            </Grid>
            {mwState.showPagination && (
              <Grid item align="right" className="pagination" xs={7}>
                <CustomPagination
                  pagination={mwState.pagination}
                  onPageChange={onPageChange}
                />
              </Grid>
            )}
          </Grid>
        );
        break;
      case MW_MESSAGE_MODULE_MODE: //being used by Message
        return (
          <Grid
            justifyContent="space-between"
            className={classes.footer}
            container
          >
            {mwState.verifyAddPermission &&
              mwState.wrapperDisplaySubMode !==
                MW_MESSAGE_MODULE_COMPOSE_MODE && (
                <Grid item className={classes.actionButton} xs={3}>
                  <CustomButton
                    onClick={changeToComposeMode}
                    size="medium"
                    color="command"
                    id="btnCompose"
                    variant="contained"
                    startIcon={<NearMeIcon />}
                  >
                    {Language.translate("Compose")}
                  </CustomButton>
                </Grid>
              )}
            {mwState.wrapperDisplaySubMode === MW_MESSAGE_MODULE_LIST_MODE &&
              !mwState.reloadMessages &&
              !mwState.moduleData.reloading &&
              mwState.showPagination && (
                <Grid item align="right" className="pagination" xs={9}>
                  <CustomPagination
                    pagination={mwState.pagination}
                    onPageChange={onMessagingPageChange}
                  />
                </Grid>
              )}
            {mwState.wrapperDisplaySubMode ===
              MW_MESSAGE_MODULE_COMPOSE_MODE && (
              <Grid container>
                <Grid item className={classes.actionButton} xs={6}>
                  <CustomButton
                    onClick={sendMessage}
                    size="medium"
                    color="action"
                    id="btnAdd"
                    variant="contained"
                    type="submit"
                    disabled={
                      moduleItemData.recipients &&
                      _.isEmpty(moduleItemData.recipients.users) &&
                      _.isEmpty(moduleItemData.recipients.groups) &&
                      _.isEmpty(moduleItemData.recipients.devices)
                        ? true
                        : !mwState.moduleFormHasChanges
                    }
                    startIcon={<NearMeIcon />}
                  >
                    {Language.translate("Send")}
                  </CustomButton>
                </Grid>
                <Grid item className="cancelButton" align="right" xs={6}>
                  <CustomButton
                    onClick={
                      !mwState.moduleFormHasChanges
                        ? mwState.cancelAndBackToMessageListingMode
                        : handleDialogOpenMessage
                    }
                    size="medium"
                    color="cancel"
                    id="btnCancel"
                    variant="contained"
                    startIcon={<CloseIcon />}
                  >
                    {btnCancelText}
                  </CustomButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        );
        break;
      case MW_IRIDIUM_MODULE_MODE: //currently being used by Iridium Billing
        return (
          <Grid
            justifyContent="flex-start"
            className={classes.footer}
            container
          >
            <Grid item className={classes.actionButton} xs={3}>
              <CustomButton
                onClick={handleExport}
                size="medium"
                color="command"
                id="btnAdd"
                variant="contained"
                type="submit"
              >
                {Language.translate("EXPORT AS CSV")}
              </CustomButton>
            </Grid>
          </Grid>
        );
        break;
    }
  } else {
    return null;
  }
}

export default ModuleWrapperFooter;
