import React from "react";

// Material-UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Divider,
  IconButton,
  makeStyles,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

// Internal
import Language from "sccLanguage";
import CustomTooltip from "@Components/CustomTooltip";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    minHeight: "25%",
    maxHeight: "50%",
    top: 100,
  },

  dialogPaper: {
    width: 600,
    maxWidth: "100vw",
  },

  DialogActions: {
    display: "flex",
    justifyContent: "space-between",
  },

  noButton: {
      marginLeft: 8,
  }
}));

/**
 * Display Dialog with Cancel, Yes, No Buttons
 * @param {boolean}  open open dialog
 * @param {string} text text to show in dialog box
 * @param {function} onYesAction on Yes button click
 * @param {function} onNoAction on No button click
 * @param {function} onCancelAction on Cancel button click
 * @param {component} children content displayed in dialog
 *
 */
export default function CustomDialogYesNoCancel({
  onYesAction,
  onNoAction,
  onCancelAction,
  open,
  text,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      className={classes.dialogContainer}
      classes={{
        root: classes.dialogContainer,
        paper: classes.dialogPaper,
      }}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div style={{ top: 0, right: 0, position: "absolute" }}>
            <CustomTooltip
              title={Language.translate("Close Menu")}
              placement="bottom"
            >
              <IconButton onClick={onCancelAction} color="inherit">
                <CloseIcon />
              </IconButton>
            </CustomTooltip>
          </div>
          <div style={{ color: "black", fontSize: "1rem" }}>{text}</div>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.DialogActions}>
        <Button
          onClick={onCancelAction}
          variant="contained"
          color="default"
        >
          {Language.translate("Cancel")}
        </Button>
        <div>
          <Button
            onClick={onYesAction}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            {Language.translate("YES")}
          </Button>
          <Button
            onClick={onNoAction}
            variant="contained"
            color="default"
            startIcon={<CloseIcon />}
            className={classes.noButton}
          >
            {Language.translate("NO")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
