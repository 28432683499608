import React, { useState, useContext, useEffect } from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";
import EmailIcon from "@material-ui/icons/Email";
import NearMeIcon from "@material-ui/icons/NearMe";
import DeleteIcon from "@material-ui/icons/Delete";
import MemoryIcon from "@material-ui/icons/Memory";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";

//External
import { generatePath } from "react-router";
import _ from "lodash";
import clsx from "clsx";

//Internal
import Language from "sccLanguage";
import Profile from "sccProfile";
import Message from "sccMessage";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomTooltip from "@Components/CustomTooltip";
import MessageRead from "./MessageRead";
import Inbox from "./Inbox";
import Sent from "./Sent";
import Trash from "./Trash";
import Monitor from "./Monitor";
import Compose from "./Compose";
import { moduleListing } from "@Styles/ModuleListing";

const MW_MESSAGE_MODULE_LIST_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_LIST_MODE;
const MW_MESSAGE_MODULE_COMPOSE_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_COMPOSE_MODE;
const MW_MESSAGE_MODULE_READ_MODE =
  process.env.REACT_APP_MW_MESSAGE_MODULE_READ_MODE;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  messageBody: {
    "& .tabs": {
      borderRight: `1px solid ${theme.palette.colors.gray.light}`,
      "& .selectionsBox": {
        backgroundColor: theme.palette.colors.white.main,
        height: 35,
      },
      "& .selections": {
        textAlign: "center",
        height: 35,
        backgroundColor: theme.palette.colors.yellow.main,
        fontSize: "1rem",
        fontWeight: 700,
        "& .MuiIconButton-root": { padding: 10 },
      },
      "& .clearButton": {
        color: "#fff",
        backgroundColor: theme.palette.colors.red.main,
        borderRadius: "50%",
        padding: 1,
      },
      "& .alignLeft": {
        textAlign: "left",
      },
      "& .alignRight": {
        textAlign: "right",
      },
      "& .ellipsis": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& .iconBtn": {
        height: 20,
        "& .MuiIconButton-root": {
          padding: 0,
          marginTop: -5,
        },
      },
      "& .MuiTab-wrapper": {
        flexDirection: "row",
        justifyContent: "flex-start",
        "& .MuiSvgIcon-root": {
          margin: 5,
          fontSize: "1.4rem",
        },
      },
      "& .MuiTab-labelIcon": {
        minHeight: 30,
      },
      "& .MuiTab-textColorInherit.Mui-selected": {
        fontWeight: 700,
        backgroundColor: theme.palette.colors.gray.main,
      },
    },
    "& .tabPanel": {
      "& .MuiBox-root": {
        padding: 0,
      },
      "& .loading": {
        marginTop: 200,
        height: 500,
        color: theme.palette.colors.gray.dark,
        textAlign: "center",
      },
      "& .searchResults": {
        padding: 7,
        height: 21,
        color: theme.palette.colors.black.main,
        textAlign: "center",
      },
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.colors.gray.main,
      transition: "none",
    },
  },
}));

function ModuleWrapperMessageBody() {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [value, setValue] = React.useState(0);

  const options = [
    1,
    mwState.pagination.itemsPerPage,
    mwState.moduleData.currentFolder,
    mwState.moduleData.sortDesc,
  ]; //used for reloading
  if (mwState.searchExecuted) options[4] = mwState.searchText;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setMwState((p) => ({
      ...p,
      moduleData: {
        ...p.moduleData,
        currentFolder: mwState.moduleData.tabNamesIndexed[newValue], //specifically change the currentfolder as this is used to reload the new data
      },
      wrapperDisplaySubMode: MW_MESSAGE_MODULE_LIST_MODE, //helpful when the use is in read mode.
      searchExecuted: false,
      searchText: "",
    }));
    mwState.messageModuleLoading();
    mwState.loadMessages([
      1,
      mwState.pagination.itemsPerPage,
      mwState.moduleData.tabNamesIndexed[newValue],
      mwState.moduleData.sortDesc,
    ]);
  };
  function handleClick(data) {
    showMessage(data);
  }
  function handleSingleCheckbox(id) {
    const newChecked = _.xor(mwState.moduleData.checked, [id]);
    setMwState((p) => {
      return { ...p, moduleData: { ...p.moduleData, checked: newChecked } };
    });
  }

  const messageInfo = Message.getMessageInfo();

  useEffect(() => {
    mwState.messageModuleLoading();
    //we start on page 1
    //inbox is hardcoded here as at this time it doesnt exist in moduledata
    //default messages are sorted descending
    options[2] = "inbox";
    options[3] = true;
    if (mwState.searchExecuted) options[4] = mwState.searchText;
    mwState.refreshMessagesAndCounts(options);
  }, []);

  //helpful for a second window/screen/login operations
  useEffect(() => {
    if (mwState.reloadMessages) {
      mwState.refreshMessagesAndCounts(options);
      setMwState((p) => ({ ...p, reloadMessages: false }));
    }
  }, [mwState.reloadMessages]);

  function showMessage(data) {
    setMwState((p) => ({
      ...p,
      wrapperDisplaySubMode: MW_MESSAGE_MODULE_READ_MODE,
      moduleItemData: data,
      moduleItemDataOriginal: data,
    }));
  }

  function resetSorting() {
    //reloading the data and sending reverse sort to reload and set
    options[0] = mwState.pagination.currentPage;
    options[3] = !options[3];
    mwState.loadMessages(options);
  }

  function clearSelection() {
    setMwState((p) => ({
      ...p,
      moduleData: { ...p.moduleData, checked: [] },
    }));
  }

  const classes = useStyles();

  return mwState.wrapperDisplaySubMode !== MW_MESSAGE_MODULE_COMPOSE_MODE ? (
    <Grid container className={clsx(classes.body, classes.messageBody)}>
      <Grid item xs={2} className="tabs">
        <div className="selectionsBox">
          {mwState.wrapperDisplaySubMode == MW_MESSAGE_MODULE_LIST_MODE &&
          mwState.moduleData &&
          mwState.moduleData.checked &&
          mwState.moduleData.checked.length > 0 ? (
            <div className="selections">
              {mwState.moduleData.checked.length}{" "}
              {Language.translate("Selected")}
              <IconButton onClick={clearSelection}>
                <ClearIcon className="clearButton" />
              </IconButton>
            </div>
          ) : null}
        </div>
        <Tabs orientation="vertical" value={value} onChange={handleChange}>
          <Tab
            icon={<EmailIcon />}
            iconPosition="start"
            label={
              <Grid container xs={12}>
                <Grid item xs={6} className="alignLeft">
                  {Language.translate("INBOX")}
                </Grid>
                <Grid item xs={6} className="alignRight ellipsis">
                  {messageInfo
                    ? `${messageInfo.unread_count}/${messageInfo.inbox_count}`
                    : null}
                </Grid>
              </Grid>
            }
            {...a11yProps(0)}
          />
          <Tab
            icon={<NearMeIcon />}
            iconPosition="start"
            label={
              <Grid container xs={12}>
                <Grid item xs={6} className="alignLeft">
                  {Language.translate("SENT")}
                </Grid>
                <Grid item xs={6} className="alignRight ellipsis">
                  {messageInfo ? messageInfo.sent_count : null}
                </Grid>
              </Grid>
            }
            {...a11yProps(1)}
          />
          <Tab
            icon={<DeleteIcon />}
            iconPosition="start"
            label={
              <Grid container xs={12}>
                <Grid item xs={6} className="alignLeft">
                  {Language.translate("TRASH")}
                </Grid>
                <Grid item xs={6} className="alignRight">
                  {messageInfo ? messageInfo.trash_count : null}
                </Grid>
              </Grid>
            }
            {...a11yProps(2)}
          />
          <Tab
            icon={<MemoryIcon />}
            iconPosition="start"
            label={
              <Grid container xs={12}>
                <Grid item xs={8} className="alignLeft">
                  {Language.translate("MONITOR")}
                </Grid>
                <Grid item xs={4} className="alignRight iconBtn">
                  <CustomTooltip
                    title={Language.translate("Monitor")}
                    placement="top"
                  >
                    <IconButton color="inherit">
                      <ReplayIcon />
                    </IconButton>
                  </CustomTooltip>
                </Grid>
              </Grid>
            }
            {...a11yProps(3)}
          />
        </Tabs>
      </Grid>
      <Grid item xs={10} className="tabPanel">
        {mwState.wrapperDisplaySubMode === MW_MESSAGE_MODULE_READ_MODE ? (
          <MessageRead
            folderName={mwState.moduleData.tabNamesIndexed[value]}
            folderIndex={value}
          />
        ) : !mwState.moduleData || mwState.moduleData.reloading ? (
          <div className="loading">
            <CircularProgress color="inherit" size="5rem" />
          </div>
        ) : (
          <React.Fragment>
            {mwState.searchExecuted && mwState.searchText != "" && (
              <div className="searchResults">
                {Language.translate("Search results for")} '{mwState.searchText}
                ' &nbsp;-&nbsp;
                <b>
                  {Message.get()?.total_message_count}&nbsp;
                  {Language.translate("found")}
                </b>
              </div>
            )}
            <TabPanel value={value} index={0}>
              <Inbox
                handleClick={handleClick}
                resetSorting={resetSorting}
                handleSingleCheckbox={handleSingleCheckbox}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Sent
                handleClick={handleClick}
                resetSorting={resetSorting}
                handleSingleCheckbox={handleSingleCheckbox}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Trash
                handleClick={handleClick}
                resetSorting={resetSorting}
                handleSingleCheckbox={handleSingleCheckbox}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Monitor
                handleClick={handleClick}
                resetSorting={resetSorting}
                handleSingleCheckbox={handleSingleCheckbox}
              />
            </TabPanel>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid container className="MessageBody">
      <Compose />
    </Grid>
  );
}

export default ModuleWrapperMessageBody;
