import React, { useEffect, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Language from "sccLanguage";
import Permission from "sccPermission";
import PermissionsDetail from "./PermissionsDetail";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

const defaultRoles = [
  "Admin",
  "Advanced User",
  "Contact User",
  "Customer Admin",
  "End User",
  "Provider Admin",
];

export default function PermissionsList(props) {
  const data = props.data;
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleData: Permission._allRoles,
      filteredModuleData: Permission._allRoles,
    }));
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip title={data.title} placement="left">
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} fontSize="small" />
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          id={data.id}
          className={classes.itemPrimaryText}
          primary={data.title}
        />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {_.indexOf(defaultRoles, data.title) === -1
            ? Permission.verify("user", "addSibling") && (
                <>
                  {mwState.verifyEditPermission && (
                    <CustomTooltip
                      title={Language.translate("Edit")}
                      placement="left"
                    >
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleEdit(data)}
                      >
                        <EditIcon />
                      </IconButton>
                    </CustomTooltip>
                  )}
                  {mwState.verifyDeletePermission && (
                    <CustomTooltip
                      title={Language.translate("Delete")}
                      placement="bottom"
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() =>
                          handleItemDelete(
                            `${mwState.routeUrl}/${data.id}`,
                            mwState.title,
                            "role",
                            data.title
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CustomTooltip>
                  )}
                </>
              )
            : null}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <PermissionsDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
