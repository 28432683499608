import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.colors.gray.main,
    boxShadow: "none",
    marginLeft: -4,
  },
  checkBox: {
    background: theme.palette.colors.gray.main,
    marginLeft: 0,
    // height: 30,
    verticalAlign: "unset",
  },
  btn: {
    padding: 0,
    minWidth: "20px !important",
    width: "20px !important",
    height: "30px !important",
    verticalAlign: "unset",
    color: theme.palette.colors.black.light,
    background: theme.palette.colors.gray.light,
    borderLeft: `1px solid ${theme.palette.colors.gray.main}`,
  },
}));

function CustomMessagingDropdown(props) {
  const {
    name,
    label,
    value,
    color = "default",
    checked,
    indeterminate,
    labelPlacement = "end",
    actions,
    onCheckAllChange,
    handleAction,
    ...other
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleSelection = (event) => {
    handleAction(event.target.value);
    handleClose();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? `${name}_popoverActions` : undefined;

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            name={`${name}_chk`}
            value={value}
            color={color}
            className={classes.checkBox}
            onChange={onCheckAllChange}
            {...other}
          />
        }
        label={label}
        className={classes.checkBox}
        labelPlacement={labelPlacement}
      />
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className={classes.btn}
      >
        <ArrowDropDownIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ position: "absolute", top: 0, left: -75, borderRadius: 0 }}
        PaperProps={{
          style: { width: 150, borderRadius: 0, borderTop: "1px solid #bbb" },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {actions.map((option) => (
          <MenuItem
            disabled={option.disabled}
            key={option.value}
            onClick={handleSelection}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </div>
  );
}
export default CustomMessagingDropdown;
