import React, { Component } from "react";

// Material-UI
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core";

// Internal
import ClusterDisplayButtonGroup from "./ClusterDisplayButtonGroup";
import ClusterList from "./ClusterList";
import ClusterDisplayHeader from "./ClusterDisplayHeader";
import MinimizeContextProvider from "../context/MinimizeContext.js";
import POIOverlay from "sccPoiOverlay";

const styles = {
  poiPaper: {
    borderRadius: 0,
  },
};

class ClusterDisplay extends Component {
  constructor() {
    super();
    this.state = {
      devices: [],
    };

    if (!ClusterDisplay.publicInstance) {
      ClusterDisplay.publicInstance = this;
    }
  }

  static publicInstance;

  static updateFeatureSelected() {
    setTimeout(() => {
      ClusterDisplay.publicInstance.setFeatureSelected();
    });
  }

  setFeatureSelected() {
    this.setState({ devices: POIOverlay.getClusterFeatureIds() });
  }

  render() {
    const { devices } = this.state;
    const { classes } = this.props;
    return (
      <MinimizeContextProvider>
        <Paper
          elevation={3}
          id={"olMapPoiClusterPopup"}
          className={classes.poiPaper}
        >
          {devices ? (
            <React.Fragment>
              <ClusterDisplayHeader items={devices} />
              <ClusterDisplayButtonGroup devices={devices} />
              <ClusterList devices={devices} />
            </React.Fragment>
          ) : null}
        </Paper>
      </MinimizeContextProvider>
    );
  }
}

export default withStyles(styles)(ClusterDisplay);
