import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomTooltip from "@Components/CustomTooltip";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PublicIcon from "@material-ui/icons/Public";

import "./MapZoomButtons.scss";

//const OlMap = require("sccOlMapNew");
const Language = require("sccLanguage").default;

// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		display: "flex",
// 		"& > *": {
// 			margin: theme.spacing(1),
// 		},
// 	},
// }));

export default function MapZoomButtons(props) {

	const OlMap = (props.history==1) ? require("sccOlMapHistory").default : require("sccOlMapNew").default;
	const [levels, setLevels] = useState(OlMap.getZoom());
	// log.info("++++++++++++++++++++++++++ MapZoomButtons props 1: ", props);
	// log.info("++++++++++++++++++++++++++ MapZoomButtons props 2: ", props.olmap);

	const zoomOutFull = () => {
		OlMap.fullZoomOut();
		setLevels(OlMap.getZoom());
	};

	const zoomIn = () => {
		OlMap.zoomIn();
		setLevels(OlMap.getZoom());
	};

	const zoomOut = () => {
		OlMap.zoomOut();
		setLevels(OlMap.getZoom());
	};

	// const zoomLevels = () => {
	// 	setLevels(OlMap.getZoom());
	// };

	////////////////////////////////////////////////
	//Update zoom levels - Have to find a better way
	////////////////////////////////////////////////
	useEffect(() => {
		const timerID = setInterval(() => tick(), 1000);
		return function cleanUp() {
			clearInterval(timerID);
		};
	});

	function tick() {
		setLevels(OlMap.getZoom());
	}

	const mapZoomButtons = (OlMap.getOptions("zoomDisplay")) ?
		<ButtonGroup
			orientation="vertical"
			aria-label="vertical contained primary button group"
			variant="contained"
		>
			<CustomTooltip title={Language.translate("Zoom Out Full")} arrow placement="left">
				<Button startIcon={<PublicIcon />} onClick={zoomOutFull}></Button>
			</CustomTooltip>
			<CustomTooltip title={Language.translate("Zoom In")} arrow placement="left">
				<Button startIcon={<AddIcon />} onClick={zoomIn}></Button>
			</CustomTooltip>
			<CustomTooltip title={Language.translate("Map Zoom Levels")} arrow placement="left">
				<Button className="zoomLevels">{levels}</Button>
			</CustomTooltip>
			<CustomTooltip title={Language.translate("Zoom Out")} arrow placement="left">
				<Button startIcon={<RemoveIcon />} onClick={zoomOut}></Button>
			</CustomTooltip>
		</ButtonGroup> : "";

	return (

		<div id={OlMap.getOptions("zoomDisplay").elementId} className="hide-mobile">
			<div >
				{mapZoomButtons}
			</div>
		</div>
	);
	

}