import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import LayersIcon from "@material-ui/icons/Layers";
import CustomTooltip from "@Components/CustomTooltip";
import MapBaseLayers from "./MapBaseLayers";
import MapOverlays from "./MapOverlays";

import "./styles/MapLayers.scss";

const Language = require("sccLanguage").default;

export default function MapLayers(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "mapLayersPopover" : undefined;

  return (
    <div>
      <CustomTooltip
        title={Language.translate("Map Layers")}
        arrow
        placement="top"
      >
        <Button
          className="mapLayersBtn btn btn-default"
          aria-describedby={id}
          variant="contained"
          color="default"
          onClick={handleClick}
          startIcon={<LayersIcon />}
        ></Button>
      </CustomTooltip>
      <Popover
        id={id}
        className="mapLayersPopover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div className="mapLayerDropdown">
          <MapBaseLayers {...props} />
          <MapOverlays
            {...props}
            poiCategoryArr={props.poiCategoryArr}
            setPoiCategoryArr={props.setPoiCategoryArr}
          />
        </div>
      </Popover>
    </div>
  );
}
