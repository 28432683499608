import React from "react";
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
	
	// InputAdornment: {
	// 	backgroundColor: theme.palette.common.black,
	// 	fontSize: 12
	// }
}));



function CustomInputAdornment(props) {
	const classes= useStyles();
	const {position} = props;
	return (
		<InputAdornment 
			position= {position}
			{...props}
		>
			{props.children}
		</InputAdornment>
	
	);
}

export default CustomInputAdornment;