import React from "react";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

//Internal
import AssetGroup from "./AssetGroup";

export default function AssetSelectorDisplay(props) {
  const { collection } = props;
  const DEFAULT_MAIN_GROUP_TITLE =
    process.env.REACT_APP_DEFAULT_MAIN_GROUP_TITLE;

  const useStyles = makeStyles((theme) => ({
    assetGroupListingDisplay: {
      marginLeft: 35,
    },
  }));
  const classes = useStyles();

  return (
    <div
      className={
        collection.groups.length > 0 &&
        collection.groups[0].title !== DEFAULT_MAIN_GROUP_TITLE &&
        classes.assetGroupListingDisplay
      }
    >
      {collection && (
        <AssetGroup
          collection={collection}
          name={props.name}
          mainGroupName={DEFAULT_MAIN_GROUP_TITLE}
        />
      )}
    </div>
  );
}
