import React, { useState, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import { Grid, makeStyles } from "@material-ui/core";

// Components
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import Alert from "sccAlert";
import Clock from "sccClock";
import Device from "sccDevice";
import GuiUtils from "sccGuiUtils";
import { HistoryContext } from "../../../../history/components/HistoryContext";
import HistoryOverlay from "sccHistoryOverlay";
import Language from "sccLanguage";
import { MinimizeContext } from "../../context/MinimizeContext";
import OlMap from "sccOlMapNew";
import TimeUtils from "sccTimeUtils";
import { UserSettingContext } from "../../../../user_setting/context/UserSettingContext";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  deviceListContainer: {
    display: "flex",
    flexDirection: "column",
  },

  deviceContentLayer: {
    height: 23,
    borderTop: `1px solid ${theme.palette.colors.gray.main}`,
  },

  deviceContentLeft: {
    fontSize: 12,
    padding: "2px 6px",
    textAlign: "left",
    backgroundColor: theme.palette.colors.gray.main,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  deviceContentLeftRedBattery: {
    fontSize: 12,
    padding: "2px 6px",
    textAlign: "left",
    backgroundColor: theme.palette.colors.gray.main,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    backgroundColor: theme.palette.colors.red.main,
    color: theme.palette.colors.white.main,
    display: "flex",
    alignItems: "center",
  },

  deviceContentRight: {
    fontSize: 13,
    padding: "2px 6px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "right",
  },

  deviceContentRightRedBattery: {
    fontSize: 13,
    padding: "2px 6px",
    textAlign: "right",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    backgroundColor: theme.palette.colors.red.main,
    color: theme.palette.colors.white.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  darkBg: {
    backgroundColor: theme.palette.colors.gray.pure,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: `2px 2px 15px -1px ${theme.palette.colors.gray.main}`,
    border: `1px solid ${theme.palette.colors.gray.main}`,
  },

  speedFormat: {
    backgroundColor: theme.palette.colors.gray.veryDark,
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.colors.white.main,
    minWidth: 10,
    padding: "3px 7px",
    borderRadius: 10,
  },

  alignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  deviceBattery: {
    color: theme.palette.colors.white.main,
    fontSize: 16,
  },
  locationOverflow: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default function DeviceInfoHistoryTrail({ report, device }) {
  const [state] = useContext(HistoryContext);
  const minimizeState = useContext(MinimizeContext).state;
  const { userSettingData } = useContext(UserSettingContext);
  const deviceTypeFeatures = Device.getDeviceType(device.type_id);
  const classes = useStyles();
  const checkForRemoteDevice = state.checkForRemoteDevice;
  const Historic = state.Historic;
  const alertTypes = Alert.getAlertType();

  const deviceSpeed = () => {
    if (report.speed === null) return "N/A";
    switch (userSettingData["speed_format"]) {
      case "MPH":
        return Utils.transformSpeedFromKph(report.speed, "MPH");
      case "KNOTS":
        return Utils.transformSpeedFromKph(report.speed, "KNOTS");
      default:
        return report.speed;
    }
  };

  const location =
    (report &&
      report.latitude &&
      OlMap.formatCoordinate([report.longitude, report.latitude])) ||
    "N/A";

  //  define common list fields
  const commonRenderedList = () => {
    if (deviceTypeFeatures) {
      return (
        <>
          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("EVENT TIME")}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {Clock.formatTimestamp(report.event_timestamp) || "N/A"}
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("Altitude").toUpperCase()}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {report.altitude || "N/A"}
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("Course").toUpperCase()}
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {GuiUtils.ConvertHeadingToString(device.heading) || "N/A"}
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              <CustomTooltip
                title={OlMap.getUserLonlatFormat().toUpperCase()}
                arrow
                placement="top"
              >
                <div className={classes.locationOverflow}>
                  {OlMap.getUserLonlatFormat().toUpperCase()}
                </div>
              </CustomTooltip>
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              <CustomTooltip title={location} arrow placement="top">
                <div className={classes.locationOverflow}>{location}</div>
              </CustomTooltip>
            </Grid>
          </Grid>

          <Grid container className={classes.deviceContentLayer}>
            <Grid item xs={5} className={classes.deviceContentLeft}>
              {Language.translate("SPEED")}
              <span className={classes.speedFormat}>
                {userSettingData["speed_format"]}
              </span>
            </Grid>
            <Grid item xs={7} className={classes.deviceContentRight}>
              {deviceSpeed()}
            </Grid>
          </Grid>
          {checkForRemoteDevice(device.id) && (
            <Grid container className={classes.deviceContentLayer}>
              <Grid item xs={5} className={classes.deviceContentLeft}>
                {Language.translate("Gateway").toUpperCase()}
              </Grid>
              <Grid item xs={7} className={classes.deviceContentRight}>
                {Device.get(
                  Historic.getDeviceLastReport(HistoryOverlay.getSelectedId())
                    .parent_id
                ) == null
                  ? Language.translate("Gateway not found")
                  : Device.get(
                      Historic.getDeviceLastReport(
                        HistoryOverlay.getSelectedId()
                      ).parent_id
                    ).name}
              </Grid>
            </Grid>
          )}
        </>
      );
    }
  };

  const renderedListByDeviceType = () => {
    if (deviceTypeFeatures) {
      const deviceType = deviceTypeFeatures?.title?.toLowerCase() || "";

      switch (true) {
        case deviceType.includes("hawkeye"):
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("ALERT(S)")}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {!_.isEmpty(alertTypes) ? (
                    _.map(alertTypes, (data, index) => {
                      var deviceAlerts = Alert.getDeviceAlert(
                        device.id,
                        data.type
                      );

                      return data.type === "Message" ||
                        _.isNull(deviceAlerts) ||
                        deviceAlerts.length <= 0 ? (
                        ""
                      ) : (
                        <span
                          key={device.id + index}
                          className={classes.alertText}
                        >
                          {data.type}
                        </span>
                      );
                    })
                  ) : (
                    <div>N/A</div>
                  )}
                </Grid>
              </Grid>

              <Grid container className={classes.deviceContentLayer}>
                <Grid item xs={5} className={classes.deviceContentLeft}>
                  {Language.translate("Event(s)")}
                </Grid>
                <Grid item xs={7} className={classes.deviceContentRight}>
                  {device?.vehicle?.hard_breaking &&
                    Language.translate("hard braking; ")}
                  {device?.vehicle?.coolant_overheat &&
                    Language.translate("coolant overheat; ")}
                  {device?.vehicle?.device_low_battery &&
                    Language.translate("low batteray; ")}
                  {device?.vehicle?.oil_overheat &&
                    Language.translate("oil overheat; ")}
                  {!device?.vehicle?.oil_overheat &&
                    !device?.vehicle?.hard_breaking &&
                    !device?.vehicle?.coolant_overheat &&
                    !device?.vehicle?.device_low_battery &&
                    "N/A"}
                </Grid>
              </Grid>
            </div>
          );
        default:
          return (
            <div className={classes.deviceListContainer}>
              {commonRenderedList()}
            </div>
          );
      }
    }
  };

  return (
    <React.Fragment>
      {!minimizeState.minimize &&
        deviceTypeFeatures &&
        Object.keys(deviceTypeFeatures)?.length > 0 &&
        renderedListByDeviceType()}
    </React.Fragment>
  );
}
