import React, { Component } from "react";

// External
import log from "loglevel";

// Internal
import * as Images from "sccImage";

export default class Clientlogo extends Component {
  constructor() {
    super();
    this.state = {
      images: null,
      isLoading: false,
    };

    if (!Clientlogo.publicInstance) {
      Clientlogo.publicInstance = this;
    }
  }

  static publicInstance;

  getClientImages() {
    setTimeout(() => {
      Images.loadData()
        .then((result) => this.setState({ images: result }))
        .catch((err) =>
          log.info("Error in loading Images to ClientLogo component: ", err)
        );
    }, 1000);
  }

  static refreshLogoImages() {
    Clientlogo.publicInstance.getClientImages();
  }

  render() {
    const { images } = this.state;

    const obj = !images ? this.getClientImages() : "";

    const getClientImage = images
      ? images.filter((image) => image.tag === "platform")
      : "";

    const displayClientLogo =
      getClientImage?.length > 0
        ? getClientImage.map((image) => {
            // todo: this condition can be removed if backend not return titanbynortaclogo4 anymore
            if (image.name !== "titanbynortaclogo4.jpg") {
              return (
                <div key={image.id} className="navbar-brand custom_logo">
                  <img
                    src={Images.getImageSource(image.id)}
                    className="customLogo"
                    alt="Titan by NORTAC Platform"
                  />
                </div>
              );
            }
          })
        : "";

    return <div className="navbar-header client_logo">{displayClientLogo}</div>;
  }
}
