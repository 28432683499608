import React, { useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import NearMeIcon from "@material-ui/icons/NearMe";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

// Components
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import { HistoryContext } from "../../../../history/components/HistoryContext";
import Language from "sccLanguage";
import { MinimizeContext } from "../../context/MinimizeContext";

const useStyles = makeStyles((theme) => ({
  assetDisplayContainer: {
    padding: "2px",
  },

  assetCheckbox: {
    boxSizing: "content-box",
    borderRadius: 0,
  },

  enabledIcon: {
    boxSizing: "content-box",
    borderRadius: 0,
    color: theme.palette.colors.white.main,
    backgroundColor: theme.palette.colors.blue.main,
    width: 22,
  },

  disabledIcon: {
    boxSizing: "content-box",
    borderRadius: 0,
    backgroundColor: theme.palette.colors.white.main,
  },

  historyButtonGrid: {
    "& span": {
      padding: "1px",
    },
  },
}));

export default function DataDisplayButtonGroupHistory({ device }) {
  const [state] = useContext(HistoryContext);
  const minimizeState = useContext(MinimizeContext).state;
  const Historic = state.Historic;
  const updateKeysInState = state.updateKeysInState;
  const deviceVisibilityChanged = state.deviceVisibilityChanged;
  const showDeviceTrailChanged = state.showDeviceTrailChanged;

  const classes = useStyles();

  //functions copied from asset display
  const handleChangeVisibility = (event) => {
    Historic.playOptions.device[event.target.value].showTracks =
      event.target.checked;
    updateKeysInState(Historic);
    deviceVisibilityChanged(event.target.value);
  };
  const handleChangeFollow = (event) => {
    Historic.playOptions.device[event.target.value].isFollow =
      event.target.checked;
    updateKeysInState(Historic);
  };
  const handleChangeTrail = (event) => {
    const devices = Historic.queryParams.devices;
    Historic.playOptions.device[event.target.value].showTrail =
      event.target.checked;
    updateKeysInState(Historic);
    showDeviceTrailChanged(
      devices.length ==
        _.filter(Historic.playOptions?.device, { showTracks: false }).length
    );
  };

  const showButtonGroup = !minimizeState.minimize ? (
    <Grid container className={classes.assetDisplayContainer}>
      <Grid item className={classes.historyButtonGrid}>
        <CustomTooltip title={Language.translate("View")} arrow placement="top">
          <Checkbox
            checked={Historic.playOptions?.device[device.id]?.showTracks}
            onChange={handleChangeVisibility}
            icon={<VisibilityOffIcon className={classes.assetCheckbox} />}
            checkedIcon={<VisibilityIcon className={classes.enabledIcon} />}
            value={device.id}
            name={"view" + device.id}
          />
        </CustomTooltip>
      </Grid>
      <Grid item className={classes.historyButtonGrid}>
        <CustomTooltip
          title={Language.translate("Follow")}
          arrow
          placement="top"
        >
          <Checkbox
            checked={Historic.playOptions?.device[device.id]?.isFollow}
            disabled={!Historic.playOptions?.device[device.id]?.showTracks}
            onChange={handleChangeFollow}
            icon={<NearMeIcon className={classes.assetCheckbox} />}
            checkedIcon={<NearMeIcon className={classes.enabledIcon} />}
            value={device.id}
            name={"follow" + device.id}
          />
        </CustomTooltip>
      </Grid>
      <Grid item className={classes.historyButtonGrid}>
        <CustomTooltip
          title={Language.translate("Trail")}
          arrow
          placement="top"
        >
          <Checkbox
            checked={Historic.playOptions?.device[device.id]?.showTrail}
            disabled={!Historic.playOptions?.device[device.id]?.showTracks}
            onChange={handleChangeTrail}
            icon={<TrendingUpIcon className={classes.assetCheckbox} />}
            checkedIcon={<TrendingUpIcon className={classes.enabledIcon} />}
            value={device.id}
            name={"trail" + device.id}
          />
        </CustomTooltip>
      </Grid>
    </Grid>
  ) : (
    ""
  );

  return <div>{showButtonGroup}</div>;
}
