import React, { useState, useContext, useEffect } from "react";

//External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Components
import AssetGroupSelector from "@Components/CustomAssetWrapper/AssetGroupSelector_NR/AssetGroupSelector";
import CustomInputAdornment from "@Components/CustomInputAdornment";
import CustomItemCount from "@Components/CustomItemCount";
import CustomListItemCollapse from "@Components/CustomListItemCollapse";
import CustomSwitch from "@Components/CustomSwitch";
import CustomTextField from "@Components/CustomTextField";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import Device from "sccDevice";
import Group from "sccGroup";
import Language from "sccLanguage";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
}));

export default function NrForm() {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const formik = useFormikContext();
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;
  const handleFormInputCheck = mwState.handleFormInputCheck;
  const moduleFormValidations = mwState.moduleFormValidations;
  const mainGroupName = mwState.mainGroupName;

  const [selection, setSelection] = useState([]);
  const [disabledGroups, setDisabledGroups] = useState([]);
  const [indeterminateGroups, setIndeterminateGroups] = useState([]);

  const newNR = {
    title: "",
    enabled: true,
    total_devices: Object.keys(Device.get()).length,
    device_count: null,
    radius: null,
    report_age: null, //this should be 24 cuz the unit is hour
    members: {
      groups: [],
      devices: [],
    },
  };

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleItemData: { ...newNR, ...mwState.moduleItemData },
      moduleItemDataOriginal: { ...newNR, ...mwState.moduleItemData },
    }));
    if (mwState.moduleItemData.members) {
      setSelection(mwState.moduleItemData.members.groups);
      setDisabledGroups(
        buildDisabledGroups(mwState.moduleItemData.members.groups)
      );
    }
  }, []);

  useEffect(() => {
    formik.setFieldValue("members", { groups: selection, devices: [] });
    setIndeterminateGroups(buildIndeterminateSelection(selection));
  }, [selection]);

  function buildIndeterminateSelection(groups) {
    let lclIndeterminateGroups = [];

    groups.forEach((group) => {
      if (Group.get(group).title != mwState.mainGroupName) {
        let objGroup = Group.get(group);
        recursiveUp(objGroup.parent_id);
      }
    });
    return lclIndeterminateGroups;

    function recursiveUp(parentId) {
      lclIndeterminateGroups = _.union(lclIndeterminateGroups, [parentId]);
      if (Group.get(parentId).title != mwState.mainGroupName) {
        const objGroup = Group.get(parentId);
        recursiveUp(objGroup.parent_id);
      }
    }
  }

  function buildDisabledGroups(groups) {
    let listIds = [];

    groups.forEach((group) => {
      const objGroup = Group.get(group);
      recursiveDisable(listIds, objGroup);
      setDisabledGroups(_.union(_.clone(disabledGroups), listIds));
    });

    return listIds;

    function recursiveDisable(listIds, startGroup) {
      startGroup.groups.map((group) => {
        listIds.push(group.id);
        recursiveDisable(listIds, group);
      });
    }
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <Grid container>
          <Grid
            container
            className="switchWrap"
            item
            xs={6}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <CustomSwitch
              name="enabled"
              label={`${Language.translate("Status")}`}
              value={Boolean(moduleItemData.enabled)}
              onChange={handleFormInput}
              checked={Boolean(moduleItemData.enabled)}
              color="primary"
              labelPlacement="top"
              label2={
                moduleItemData.enabled
                  ? Language.translate("ON")
                  : Language.translate("OFF")
              }
            />
          </Grid>
          <Grid container className="itemCountWrap" item xs={6}>
            <CustomItemCount
              title={Language.translate("Assets")}
              count={Group.getDeviceCount({ groups: selection })}
            />
          </Grid>
        </Grid>

        <p className="pullRight">
          {`* ${Language.translate("Required fields")}`}
        </p>

        <Grid container item direction="column" spacing={1}>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Title")}*`}
              value={moduleItemData.title ? String(moduleItemData.title) : ""}
              name="title"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={Language.translate(
                formik.touched.title && formik.errors.title
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            <input
              value={moduleItemData.total_devices}
              name="total_devices"
              type="hidden"
            />
            <CustomTooltip
              placement="top"
              title={
                <React.Fragment>
                  <Typography>
                    <b>{Language.translate("Max No. of Assets")}</b>
                    <br />
                    {Language.translate(
                      "Cannot exceed total number of assets registered under client"
                    )}
                  </Typography>
                </React.Fragment>
              }
            >
              <CustomTextField
                label={
                  _.isUndefined(Device.get()) || _.isNull(Device.get())
                    ? `${Language.translate("Max No. of Assets (0)")}*`
                    : Language.translate("Max No. of Assets") +
                      `(${Object.keys(Device.get()).length})*`
                }
                value={moduleItemData.device_count}
                name="device_count"
                type="number"
                InputProps={{
                  endAdornment: (
                    <CustomInputAdornment className="unitsLabel" position="end">
                      {Language.translate("Assets").toUpperCase()}
                    </CustomInputAdornment>
                  ),
                }}
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.device_count &&
                  Boolean(formik.errors.device_count)
                }
                helperText={Language.translate(
                  formik.touched.device_count && formik.errors.device_count
                )}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs>
            <CustomTooltip
              placement="top"
              title={
                <React.Fragment>
                  <Typography>
                    <b>{Language.translate("Report Age")}</b>
                    <br />
                    <ul>
                      <li>
                        {Utils.roundHours(moduleItemData.report_age)}{" "}
                        <b>{Language.translate("Hours")}</b>
                      </li>
                      <li>
                        {Utils.convertHoursToMinutes(moduleItemData.report_age)}{" "}
                        <b>{Language.translate("Minutes")}</b>
                      </li>
                      <li>
                        {Utils.convertHoursToSeconds(moduleItemData.report_age)}
                        <b>{Language.translate(" Seconds")}</b>
                      </li>
                    </ul>
                  </Typography>
                </React.Fragment>
              }
            >
              <CustomTextField
                label={`${Language.translate("Report Age")}*`}
                value={moduleItemData.report_age}
                name="report_age"
                type="number"
                InputProps={{
                  endAdornment: (
                    <CustomInputAdornment className="unitsLabel" position="end">
                      {Language.translate("Hours").toUpperCase()}
                    </CustomInputAdornment>
                  ),
                }}
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.report_age && Boolean(formik.errors.report_age)
                }
                helperText={Language.translate(
                  formik.touched.report_age && formik.errors.report_age
                )}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs>
            <CustomTooltip
              placement="top"
              title={
                <React.Fragment>
                  <Typography>
                    <b>{Language.translate("Maximum Radius")}</b>
                    <br />
                    <ul>
                      <li>
                        {Utils.transformMetresToKm(
                          moduleItemData.radius,
                          "Kilometres"
                        )}{" "}
                        <b>{Language.translate("Kilometers")}</b>
                      </li>
                      <li>
                        {moduleItemData.radius}{" "}
                        <b>{Language.translate("Meters")}</b>
                      </li>
                      <li>
                        {Utils.transformMetresToKm(
                          moduleItemData.radius,
                          "Feet"
                        )}{" "}
                        <b>{Language.translate("Feet")}</b>
                      </li>
                    </ul>
                  </Typography>
                </React.Fragment>
              }
            >
              <CustomTextField
                label={`${Language.translate("Maximum Radius")}*`}
                value={moduleItemData.radius}
                name="radius"
                type="number"
                InputProps={{
                  endAdornment: (
                    <CustomInputAdornment className="unitsLabel" position="end">
                      {Language.translate("Meters").toUpperCase()}
                    </CustomInputAdornment>
                  ),
                }}
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={formik.touched.radius && Boolean(formik.errors.radius)}
                helperText={Language.translate(
                  formik.touched.radius && formik.errors.radius
                )}
              />
            </CustomTooltip>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid container item direction="column" xs={12}>
            <CustomListItemCollapse
              title={Language.translate("Assets").toUpperCase()}
              color="primary"
              collapse={true}
              count={Group.getDeviceCount({ groups: selection })}
            >
              <AssetGroupSelector
                collection={Group.getGroupTree().groups}
                disabledGroups={disabledGroups}
                setDisabledGroups={setDisabledGroups}
                selection={selection}
                setSelection={setSelection}
                indeterminateGroups={indeterminateGroups}
                setIndeterminateGroups={setIndeterminateGroups}
                name="members"
                mainGroupName={mainGroupName}
              />
            </CustomListItemCollapse>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
