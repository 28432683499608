import React, { useContext, useRef } from "react";
import { HistoryContext } from "../../../history/components/HistoryContext";
// Material-UI
import { makeStyles } from "@material-ui/core";

export default function GoogleMap(props) {

  const [state] = useContext(HistoryContext);
  const showHistoryControls = state.showHistoryControls;
  
  const useStyles = makeStyles((theme) => ({
    googleMap: {
      width:"100%",
      height:"100%",
      position: "absolute",
      top: 0,
      left: 0,
  
    },
    historyControlGoogleMap: {
      width:"100%",
      height: "100%",
      position: "fixed",
    },
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      {(
        <div
          id="googleMap" className={showHistoryControls ? classes.historyControlGoogleMap:classes.googleMap}
        >
        </div>
      )}
    </React.Fragment>
    
  );
}
