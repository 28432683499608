import React from "react";
import { withStyles } from "@material-ui/styles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme => ({
	root: props => {
		const color = (props.color)?props.color:"default";
		const styles = {
		};
		return (styles);
	}
})
);

const CustomRadioButton = withStyles(styles)(function (props) {
	const {name, label, value, color="default", checked, labelPlacement="end", onChange, ...other} = props;
	return 	<FormControlLabel
		control={<Radio 
			checked={checked} 
			name={name}
			label={label}
			value={value}
			color={color}
			onChange={onChange}
			{...other} />}
		label={label} 
		labelPlacement={labelPlacement} />;
});

export default CustomRadioButton;