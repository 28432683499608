import { default as OlMapOverlay } from "sccOlMapOverlay";
const _ = require("lodash");
const olProj = require("ol/proj");
const olStyleIcon = require("ol/style/Icon").default;
const olStyleStyle = require("ol/style/Style").default;
const olStyleStroke = require("ol/style/Stroke").default;
const olStyleFill = require("ol/style/Fill").default;
const olStyleText = require("ol/style/Text").default;
const olStyleRegularShape = require("ol/style/RegularShape").default;
const Poi = require("sccPoi").default;
const Images = require("sccImage").default;
const UserSetting = require("sccUserSetting").default;

// the diameter in pixels to use to size the icon
const iconDiameter = 40;

export const defaultOptions = {
  id: "poi_overlay",

  // overlay title
  title: "POI",

  zIndex: 150,

  // whether or not features of the layer are selectable
  isSelectable: true,

  /**
   * Contains clustering options and if provided layer would be clustered.
   */
  cluster: {
    popupContainerId: "olMapPoiClusterPopup",
    offset: [iconDiameter / 2 + 5, -(35 + iconDiameter / 2)],
  },

  /**
   * Provides info for the popup window.
   * Popup would be disabled if this property is not provided
   */
  popup: {
    containerId: "olMapPoiPopup",
    offset: [iconDiameter / 2 + 10, -(35 + iconDiameter / 2)],
  },
};

const defaultIconOptions = {
  anchor: [0.5, 0.5],
  anchorXUnits: "fraction",
  anchorYUnits: "fraction",
  opacity: 1,
};

class PoiOverlay extends OlMapOverlay.OlMapOverlay {
  constructor(options) {
    super(options);
    this.moduleName = "poi_overlay";
    this.filters = {};
  }

  init(options) {
    super.init(options);
    const $this = this;
    this.addOverlay();
    this.addFeature({
      data: getPois($this.filters),
    });
  }

  /**
   * gets the style for showing a feature
   * @param {Object} feature feature that is being styled
   * @return {Object} style to show the feature
   * @override
   */
  getStyle(feature) {
    super.getStyle();
    const features = feature.get("features");
    var size = (features && features.length) || 1;

    if (size == 1) {
      const f = features ? features[0] : feature;
      return getSingularStyle(f);
    }

    return getClusterStyle(features);
  }

  /**
   * gets the style for select icon and selected feature
   * @param {Object} feature clone of the selected feature
   * @return {Object} style to show the feature
   * @override
   */
  getSelectStyle(feature) {
    super.getSelectStyle(feature);
    // if feature has id, then it is the selected feature and must be
    // styled as before.
    // if feature does not have id, then it is the select icon
    if (feature.getId()) {
      return getSingularStyle(feature);
    }

    //TODO: must change this to return selection style for pois
    return selectStyle;
  }

  getIconById(id) {
    const poi = Poi.get(id);
    return getIcon(poi);
  }

  registerClickEvent(id, func) {
    this.OlMap.registerEvent("clickEvents", id, function (evt) {
      const coord = olProj.transform(evt.coordinate, "EPSG:3857", "EPSG:4326");
      func(coord);
    });
  }

  getFeatureData() {
    const filters = this.filters;

    // check if filtering is set for features on map
    const filterKeys = _.keys(filters);
    if (filterKeys == null || filterKeys.length == 0) {
      return _.values(Poi.get());
    }

    let filtered = [];
    _.each(filterKeys, (key) => {
      const filterValues = filters[key];
      const groupedPoi = _.groupBy(_.values(Poi.get()), key);
      _.each(filterValues, (keyVal) => {
        if (groupedPoi[keyVal] == null) return true;
        filtered = _.concat(filtered, groupedPoi[keyVal]);
      });
    });

    // making sure the end result is uniq by ID
    filtered = _.uniqBy(filtered, "id");

    return filtered;
  }

  setFilter(key, values) {
    this.filters[key] = values;
    this.reset(true);
  }

  unsetFilter(key) {
    delete this.filters[key];
    this.reset(true);
  }
}

const selectStyle = getSelectStyle();

function getSelectStyle() {
  console.log("***** in get select style");
  const fill = new olStyleFill({ color: [0, 0, 128, 0.2] });
  const stroke = new olStyleStroke({
    color: "black",
    lineDash: [7, 7],
    width: 2,
  });
  const iconStyle = new olStyleStyle({
    image: new olStyleRegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: getSelectIconRadius(),
      angle: Math.PI / 4,
    }),
  });
  return iconStyle;
}

/**
 * gets the radius of selection icon
 */
function getSelectIconRadius() {
  // get the sqrt(2) times the diameter/2 since it is a square
  // adds 2 pixels to avoid intersecting the icon
  return (iconDiameter * Math.SQRT2) / 2 + 2;
}

const singularStyleCache = {};

function getSingularStyle(feature, poi) {
  poi = poi || Poi.get(feature.getId());

  const text = getIconText(poi);
  const tag = getStyleCacheTag(poi, text);
  if (singularStyleCache[tag]) return singularStyleCache[tag];

  const icon = getIcon(poi);
  const iconOptions = _.assign(
    {
      rotateWithView: false,
      //scale: scale,
      src: icon,
    },
    defaultIconOptions
  );

  singularStyleCache[tag] = [
    new olStyleStyle({
      image: new olStyleIcon(iconOptions),
      text: new olStyleText({
        font: "16px Courier New,Calibri,sans-serif",
        fill: new olStyleFill({ color: "#fff" }),
        stroke: new olStyleStroke({
          color: "#000",
          width: 5,
        }),
        textAlign: "left",
        offsetX: iconDiameter / 2,
        offsetY: iconDiameter / 2,
        text: text,
      }),
    }),
  ];

  return singularStyleCache[tag];
}

function getStyleCacheTag(poi, text) {
  return poi.image_id + "_" + poi.nato_code + "_" + text;
}

const clusterStyle1 = new olStyleStyle({
  text: new olStyleText({
    font: "normal 48px FontAwesome",
    text: "\uf041",
    stroke: new olStyleStroke({
      color: "white",
      width: 3,
    }),
    fill: new olStyleFill({
      color: "black",
    }),
  }),
});

const clusterStyle2 = new olStyleStyle({
  text: new olStyleText({
    font: "normal 24px FontAwesome",
    text: "\uf111",
    // stroke: new olStyleStroke({
    // 	color: getTextOutlineColor(device.id), width: 3
    // }),
    offsetY: -5,
    fill: new olStyleFill({
      color: "black",
    }),
  }),
});

const clusterStylesCache = {};
const clusterStyles = [clusterStyle1, clusterStyle2];

/**
 * returns the style for showing clustered features
 * @param {Array} features list of features in the cluster
 * @return {Object} cluster style
 */
function getClusterStyle(features) {
  var size = features.length;

  if (clusterStylesCache[size]) return clusterStylesCache[size];

  const icon = getClusterIcon();
  const iconOptions = _.assign(
    {
      rotateWithView: false,
      //scale: scale,
      src: icon,
    },
    defaultIconOptions
  );

  const style3 = new olStyleStyle({
    image: new olStyleIcon(iconOptions),
    text: new olStyleText({
      font: "15px Calibri,sans-serif",
      text: size.toString(),
      offsetY: -5,
      fill: new olStyleFill({
        color: "#fff",
      }),
    }),
  });

  clusterStylesCache[size] = _.concat(clusterStyles, style3);

  return clusterStylesCache[size];
}

function getIconText(poi) {
  let poiName = "";
  let annotation = "";
  let separatorLine = "";

  if (UserSetting.get("poi_label")) {
    poiName = poi.title;
  }

  if (UserSetting.get("poi_annotation")) {
    annotation = poi.note;
    separatorLine = annotation != "" && poiName != "" ? "\n" : "";
  }

  return poiName + separatorLine + annotation;
}

function getIcon(poi) {
  let imageSrc = null;
  if (poi.image_id) {
    const icon = Images.get(poi.image_id);
    imageSrc = icon.data_resized;
    if (imageSrc == undefined && icon.data != null) {
      // Adding in the prefix for images that have been uploaded since the last page load.
      imageSrc = Images.getResizedImg(icon);
    }
  } else if (poi.nato_code) {
    imageSrc = Poi.getNatoSymbol(poi.nato_code, { size: 25 });
  }
  // else{
  // 	throw new Error("Could not find a proper image for the POI");
  // }
  return imageSrc;
}

function getClusterIcon() {
  const clusterIconSrc =
    Images.getImageCollection("map_poi").poi_cluster.default;

  return clusterIconSrc;
}

function getPois() {
  return _.values(Poi.get());
}

export default new PoiOverlay(defaultOptions);
