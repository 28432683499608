import React, { useState, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import PhoneAndroidRoundedIcon from "@material-ui/icons/PhoneAndroidRounded";
import Grid from "@material-ui/core/Grid";
import { makeStyles, alpha } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import SyncIcon from "@material-ui/icons/Sync";
import Typography from "@material-ui/core/Typography";

// Internal
import Language from "sccLanguage";
import TimeUtils from "sccTimeUtils";
import CustomTooltip from "@Components/CustomTooltip";
import { moduleListing } from "@Styles/ModuleListing";
import SyncDetail from "./SyncDetail";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  listItemSync: {
    ...moduleListing(theme).listItem,
    display: "flex",
  },

  syncedChip: {
    padding: "4px 8px",
    backgroundColor: theme.palette.colors.green.main,
    borderColor: theme.palette.colors.green.main,
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    color: theme.palette.colors.white.main,
  },

  refreshChip: {
    padding: "4px 8px",
    backgroundColor: theme.palette.colors.blue.main,
    borderColor: theme.palette.colors.blue.main,
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    color: theme.palette.colors.white.main,
  },

  syncIcon: {
    color: theme.palette.colors.white.main,
    width: 12,
    height: 12,
    marginRight: 4,
  },

  pendingIcon: {
    color: theme.palette.colors.white.main,
    width: 12,
    height: 12,
    marginRight: 4,
  },

  pendingChip: {
    padding: "4px 8px",
    backgroundColor: theme.palette.colors.orange.main,
    borderColor: theme.palette.colors.orange.main,
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    color: theme.palette.colors.white.main,
  },

  lastSynced: {
    color: alpha(theme.palette.colors.black.main, 0.5),
    backgroundColor: alpha(theme.palette.colors.black.main, 0.1),
    padding: "4px 0px 4px 4px",
    width: 80,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  lastSyncedTime: {
    color: alpha(theme.palette.colors.black.main, 0.5),
  },

  phoneIcon: {
    color: alpha(theme.palette.colors.black.main, 0.5),
    width: "2em",
    height: "2em",
  },
}));

function SyncListItem(props) {
  const data = props.data;
  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;

  const classes = useStyles();

  const [lastSyncAge, setLastSyncAge] = useState(
    TimeUtils.getAge(data?.last_synced_timestamp / 1000) || "N/A"
  );

  // update last sync age state
  const calcAge = (rt) => {
    setLastSyncAge(TimeUtils.getAge(rt));
  };

  useEffect(() => {
    // update last sync age every one second
    if (data.last_synced_timestamp) {
      const interval = setInterval(
        () => calcAge(data.last_synced_timestamp / 1000),
        1000
      );
      return () => {
        clearInterval(interval);
      };
    } else {
      setLastSyncAge("N/A");
    }
  }, []);

  if (!data || !data.id) return null;
  return (
    <React.Fragment>
      <ListItem
        className={classes.listItemSync}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip title={data.title} placement="left">
            <span>
              <PhoneAndroidRoundedIcon className={classes.phoneIcon} />
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          id={data.id}
          className={classes.itemPrimaryText}
          primary={
            <Grid
              container
              className={classes.syncItemContainer}
              alignItems="center"
            >
              <Grid item xs={6}>
                {data.title}
              </Grid>
              <Grid item container xs={4} alignItems="center">
                <Grid item xs={6}>
                  <div className={classes.lastSynced}>
                    {Language.translate("Last Synced") + ":"}
                  </div>
                </Grid>
                <Grid item xs={6} className={classes.lastSyncedTime}>
                  <CustomTooltip title={lastSyncAge} placement="top">
                    <span>{lastSyncAge}</span>
                  </CustomTooltip>
                </Grid>
              </Grid>
              <Grid item container xs={2} justifyContent="flex-end">
                {data.status === "synced" && (
                  <div className={classes.syncedChip}>
                    <DoneIcon className={classes.syncIcon} />
                    {Language.translate("Up-to-date")}
                  </div>
                )}{" "}
                {data.status === "syncing" && (
                  <div className={classes.refreshChip}>
                    <SyncIcon className={classes.syncIcon} />
                    {Language.translate("Syncing")}
                  </div>
                )}{" "}
                {data.status === "pending" && (
                  <div className={classes.pendingChip}>
                    <CircularProgress size={12} className={classes.syncIcon} />
                    {Language.translate("Pending")}
                  </div>
                )}
              </Grid>
            </Grid>
          }
        />
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <SyncDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}

export default SyncListItem;
