import React, { useContext, useMemo } from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";

// Internal
import { MinimizeContext } from "../../context/MinimizeContext";
import GeofenceOverlay from "sccGeofenceOverlay";
import Language from "sccLanguage";
import CustomTooltip from "@Components/CustomTooltip";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),

  DataDisplayHeader: {
    background: theme.palette.colors.gray.main,
    boxShadow: "none",
    color: alpha(theme.palette.colors.black.main, 0.85),

    "& .MuiToolbar-root": {
      minHeight: "initial",
      padding: "5px",

      "& .MuiButton-root": {
        minWidth: "initial",
      },
    },
  },

  DataDisplayHeaderToolbar: {
    minHeight: 30,
    padding: "1px 4px",
  },

  DataDisplayHeaderAvatar: {
    width: 30,
    height: 30,
    borderRadius: "inherit",
    backgroundColor: "transparent",
  },

  root: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    flexGrow: 1,
    fontSize: 18,
    paddingLeft: 5,
  },

  DataDisplayHeaderButton: {
    minWidth: 30,
    width: 30,
    height: 30,
  },

  DataDisplayHeaderIcon: {
    color: alpha(theme.palette.colors.black.main, 0.25),
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      color: theme.palette.colors.blue.dark,
    },
  },

  DataDisplayHeaderImage: {
    height: "100%",
  },

  inactiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.colors.gray.dark,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    borderRadius: "50%",
  },

  inclusiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.geofence.greenIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    borderRadius: "50%",
  },

  exclusiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.geofence.redIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
  },

  inactivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.colors.gray.dark,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    transform: "rotate(-45deg)",
  },

  inclusivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.geofence.greenIcon.dark,
    border: `1px solid ${theme.palette.geofence.greenIcon.dark}`,
    transform: "rotate(-45deg)",
  },

  exclusivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.geofence.redIcon.dark,
    border: `1px solid ${theme.palette.geofence.redIcon.dark}`,
    transform: "rotate(-45deg)",
  },

  inactiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.colors.gray.dark,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
  },

  inclusiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.geofence.greenIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
  },

  exclusiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.geofence.redIcon.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
  },

  inactivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.colors.gray.dark,
    margin: "5.77px 0px",

    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.geofence.greenIcon.main}`,
    },

    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      width: 0,
      borderLeft: `5.77px solid ${theme.palette.geofence.greenIcon.main}`,
    },
  },

  inclusivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.geofence.greenIcon.main,
    margin: "5.77px 0px",

    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.geofence.greenIcon.main}`,
    },

    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      width: 0,
      borderLeft: `5.77px solid ${theme.palette.geofence.greenIcon.main}`,
    },
  },

  exclusivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.geofence.redIcon.main,
    margin: "5.77px 0px",

    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.geofence.redIcon.main}`,
    },

    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      width: 0,
      borderLeft: `5.77px solid ${theme.palette.geofence.redIcon.main}`,
    },
  },
}));

export default function DataDisplayHeader({ device }) {
  var { state, toggleMinimize } = useContext(MinimizeContext);

  const classes = useStyles();

  const obj = device;

  const handleClose = () => {
    GeofenceOverlay.deselectFeature();
    toggleMinimize = true;
  };

  const renderedHeaderIcon = useMemo(() => {
    switch (obj.shape) {
      case "polygon":
        return (
          <div
            className={
              obj.inclusive
                ? classes.inclusivePolygon
                : classes.exclusivePolygon
            }
          />
        );

      case "path":
        return (
          <div
            className={
              obj.inclusive ? classes.inclusivePath : classes.exclusivePath
            }
          />
        );

      case "rectangle":
        return (
          <div
            className={
              obj.inclusive ? classes.inclusiveRec : classes.exclusiveRec
            }
          />
        );
      case "circle":
      default:
        return (
          <div
            className={
              obj.inclusive ? classes.inclusiveCircle : classes.exclusiveCircle
            }
          />
        );
    }
  }, [obj]);

  return (
    <div className={classes.root}>
      <div className="olMapPopupArrow" />
      <AppBar position="static" className={classes.DataDisplayHeader}>
        <Toolbar className={classes.DataDisplayHeaderToolbar}>
          <Avatar className={classes.DataDisplayHeaderAvatar}>
            <CustomTooltip
              title={
                !obj.active
                  ? Language.translate("INACTIVE")
                  : obj.inclusive
                  ? Language.translate("INCLUSIVE")
                  : Language.translate("EXCLUSIVE")
              }
              arrow
              placement="top"
            >
              {renderedHeaderIcon}
            </CustomTooltip>
          </Avatar>
          <Typography variant="h6" className={classes.title}>
            {obj.title}
          </Typography>
          <CustomTooltip
            title={Language.translate("Close")}
            arrow
            placement="top"
          >
            <Button
              size="medium"
              color="inherit"
              onClick={handleClose}
              className={classes.DataDisplayHeaderButton}
            >
              <CloseIcon
                fontSize="large"
                className={classes.DataDisplayHeaderIcon}
              />
            </Button>
          </CustomTooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}
