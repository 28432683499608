import React, { useEffect, useContext } from "react";

// External
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import Language from "sccLanguage";
import { ModuleWrapperContext } from "./ModuleWrapperContext";
import ModuleWrapperHeader from "./ModuleWrapperHeader";
import ModuleWrapperBody from "./ModuleWrapperBody";
import ModuleWrapperFooter from "./ModuleWrapperFooter";
import CustomDialog from "@Components/CustomDialog/index.js";
import { moduleWrapper } from "@Styles/ModuleWrapper";

function ModuleWrapper(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  let schema = require(`../../validation/${mwState.moduleName.replace(
    " ",
    ""
  )}Validation`).default;
  //Fix introduced due to different nature of Asset module.
  //This fix will help load the group form validation when a Group is being added or edited
  if (mwState.moduleName === "Device") {
    if (mwState.moduleItemData && mwState.moduleItemData.formType === "Group") {
      schema = require(`../../validation/GroupValidation`).default;
    }
  }
  const handleDialogOkAction = () => {
    mwState.handleDialogOkAction();
    mwState.setDialogOpen(false);
  };
  const handleDialogCancelAction = () => {
    mwState.handleDialogCancelAction();
    mwState.setDialogOpen(false);
  };

  // this function is to update filteredModuleData at the time of rerender
  const updatedFilteredModuleData = (
    filteredData,
    moduleData,
    addIds,
    deleteIds
  ) => {
    if (!filteredData && Object.keys(filteredData).length === 0) {
      return moduleData;
    }

    const filteredDataKeys = Object.keys(filteredData);

    let newFilteredData = {};
    if (addIds?.length > 0) {
      addIds.map((id) => (newFilteredData[id] = moduleData[id]));
    }

    if (deleteIds?.length > 0) {
      filteredDataKeys.filter(function (el) {
        return deleteIds.indexOf(el) < 0;
      });
    }

    filteredDataKeys.forEach((key) => {
      if (moduleData[key]) {
        newFilteredData[key] = moduleData[key];
      }
    });

    return newFilteredData;
  };

  function reRender(addIds, deleteIds) {
    //fix to show the updates - can try to remove timeout again later and see if it works always
    if (mwState.moduleName !== "Message" && mwState.moduleName !== "Device") {
      //only executing in case of other than messages and assets module as they handle data completely differently
      // Message module is taken care of in the loadMessages (for messaging) in context
      //Assets module is taken care of in else section below
      setTimeout(() => {
        const newdata = updatedFilteredModuleData(
          mwState.filteredModuleData,
          mwState.moduleData,
          addIds,
          deleteIds
        );
        const numberOfItems = newdata ? Object.keys(newdata).length : 0;

        setMwState((p) => ({
          ...p,
          //Assets module is a special case as it does not work like the rest of the MW modules.
          filteredModuleData: newdata,
          pagination: {
            ...p.pagination,
            numberOfItems: numberOfItems,
          },
        }));
      }, 100);
    } else if (mwState.moduleName == "Device") {
      setMwState((p) => ({
        ...p,
        //Assets module is a special case as it does not work like the rest of the MW modules.
        //The reRender is thus handled in the TopBar where the filter and search values exist
        reRenderAssetsModule: true,
      }));
    }
  }

  function callerFunc(e) {
    const action = e.target.dataset.action;
    const originator = e.target.dataset.originator;
    const addIds =
      e.target.dataset.addids != "" ? e.target.dataset.addids.split(", ") : [];
    const deleteIds =
      e.target.dataset.deleteids != ""
        ? e.target.dataset.deleteids.split(", ")
        : [];
    if (originator != "user_setting") {
      action === "post" ? reRender(addIds, deleteIds) : reRender();
    }
  }

  const width = mwState.title === "Message" ? 1000 : 750;
  const useStyles = makeStyles((theme) => ({
    ...moduleWrapper(theme),
  }));
  const classes = useStyles({ width });

  return (
    <React.Fragment>
      <Formik
        initialValues={mwState.moduleItemData}
        validationSchema={schema}
        enableReinitialize={true}
      >
        <div className={classes.container}>
          <Form>
            <ModuleWrapperHeader />
            <ModuleWrapperBody />
            <ModuleWrapperFooter />
            <CustomDialog
              open={mwState.dialogOpen}
              onCancelAction={handleDialogCancelAction}
              onOkAction={handleDialogOkAction}
              text={
                mwState.dialogMessage ||
                Language.translate(
                  "Your current changes will be lost. Do you want to continue?"
                )
              }
            />
            <button
              style={{ display: "none" }}
              onClick={callerFunc}
              id="hiddenBtn"
            >
              Hidden Button
            </button>
          </Form>
        </div>
      </Formik>
    </React.Fragment>
  );
}

export default ModuleWrapper;
