import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Language = require("sccLanguage").default;

const pwdRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,40}$/;

const schema = Yup.object().shape({
  old_password: baseSchema(Language).requiredStringPassword.matches(
    pwdRegex,
    Language.translate("Password must contain at least one number/Alphabet")
  ),
  password: baseSchema(Language).requiredStringPassword.matches(
    pwdRegex,
    Language.translate("Password must contain at least one number/Alphabets")
  ),
  confirm: baseSchema(Language).requiredStringPassword.oneOf(
    [Yup.ref("password"), null],
    Language.translate("Passwords are not identical")
  ),
});

export default schema;
