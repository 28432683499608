import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Profile = require("sccProfile").default;
const Client = require("sccClient").default;
const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  enabled: Yup.boolean(),
  title: baseSchema(Language).requiredStringMinMax,
  radius: baseSchema(Language).requiredNumberPositive.max(
    4294967295,
    Language.translate("Max radius out of range")
  ),
  report_age: baseSchema(Language).requiredNumberPositive.max(
    4294967295,
    Language.translate("Report age is too high")
  ),
  device_count: baseSchema(Language).requiredNumberPositiveInt.max(
    Yup.ref("total_devices"),
    Language.translate(
      "Device limit cannot exceed the maximum number of assets registered under the client"
    )
  ),
});

export default schema;
