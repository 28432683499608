import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { moduleListing } from "@Styles/ModuleListing";
import MapsForm from "./MapsForm";
import MapsList from "./MapsList";

const Language = require("sccLanguage").default;

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

function ModuleWrapperMapsBody(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);
  const classes = useStyles();

  const moduleData = Object.values(mwState.moduleData);
  const filteredModuleData = mwState.convertAndSort(
    mwState.filteredModuleData,
    mwState.searchField
  );
  const moduleItemData = mwState.moduleItemData;

  const handleClick = (id) => {
    openDetailsFor !== id ? setOpenDetailsFor(id) : setOpenDetailsFor(null);
  };

  return mwState.wrapperDisplayMode === MW_LIST_MODE ? (
    <ul className={classes.listContainer}>
      {!_.isEmpty(filteredModuleData) ? (
        filteredModuleData
          .slice(mwState.pagination.startIndex, mwState.pagination.endIndex)
          .map((data) => {
            return (
              <MapsList
                key={data.id}
                data={data}
                handleClick={handleClick}
                openDetailsFor={openDetailsFor}
              />
            );
          })
      ) : (
        <div align="center">{Language.translate("No Results!")}</div>
      )}
    </ul>
  ) : (
    <MapsForm />
  );
}

export default ModuleWrapperMapsBody;
