import React, { useEffect, useState, useContext } from "react";

// External
import _ from "lodash";

// Internal
import Clock from "sccClock";
import Historic from "sccHistory";
import { HistoryContext } from "../../HistoryContext";
import "./HistorySlider.scss";

export default function HistorySlider() {
  const [state, setState] = useContext(HistoryContext);
  const setStart = state.Historic.getStartTimestamp();
  const setEnd = state.Historic.getEndTimestamp();
  const { currentTimestamp } = state.Historic;
  const {
    updateKeysInState,
    sliderChanged,
    sliderTicks,
  } = state;

  const [leftStyle, setLeftStyle] = useState(0);

  useEffect(() => {
    setLeftStyle(getPercent(currentTimestamp));
  }, [currentTimestamp]);

  function valuetext(value) {
    if (!value) return;
    return Clock.formatTimestamp(value);
  }

  function getPercent(time) {
    return ((time - setStart) * 100) / (setEnd - setStart);
  }

  const handleCustomSlider = (e) => {
    setLeftStyle(getPercent(e.target.value));
    Historic.currentTimestamp = e.target.value;
    updateKeysInState(Historic);
    sliderChanged();
  };

  const handleChange = (e, newValue) => {
    Historic.currentTimestamp = newValue;
    updateKeysInState(Historic);
    sliderChanged();
  };

  const trackStyles = {
    height: "6px",
    display: "block",
    position: "absolute",
    borderRadius: "1px",
    background: "#95a5ff",
    top: "0px",
    width: `${leftStyle}%`,
  };

  return (
    <div className="HistorySlider">
      <div className="customSlider">
        <span id="sliderRail" className="slider-rail">
          <div id="tickmarks" className="tickMarks">
            {sliderTicks.map((tick, index) => (
              <span
                className={tick.label}
                key={index}
                style={{
                  position: "absolute",
                  left: `${getPercent(tick.value)}%`,
                }}
                value={tick.value}
                label={tick.label}
              ></span>
            ))}
          </div>

          <span
            id="sliderTrack"
            className="slider-track"
            style={trackStyles}
          ></span>

          {currentTimestamp != setStart && currentTimestamp != setEnd ? (
            <React.Fragment>
              <div
                id="tooltip"
                className="top on"
                style={{
                  left:
                    leftStyle >= 84
                      ? `calc(${leftStyle}% - 144px)`
                      : `${leftStyle}%`,
                }}
              >
                <div className="tooltip-arrow"></div>
                <div className="tooltip-label">
                  {valuetext(currentTimestamp)}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <span
              id="sliderTrackTooltip"
              className="slider-track-tooltip"
              style={{ left: `${leftStyle}%` }}
            ></span>
          )}
        </span>

        <input
          id="sliderRange"
          type="range"
          className="tickRange slider"
          min={setStart}
          max={setEnd}
          value={currentTimestamp}
          step={1}
          list="tickmarks"
          onChange={handleCustomSlider}
        />
      </div>
    </div>
  );
}
