import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MapTools from "../MapTools";
import MapSettings from "../MapSettings";
import MapLayers from "../MapLayers";
import "./MapUtilities.scss";

const OlMap = require("sccOlMapNew").default;
const Language = require("sccLanguage").default;
const UserSetting = require("sccUserSetting").default;

export default class MapUtilities extends Component {
  render() {
	const {poiCategoryArr, setPoiCategoryArr} = this.props;
    const mapUtilitiesButtons = OlMap.getOptions("layerSwitcherDisplay") ? (
      <ButtonGroup
        aria-label="outlined vertical contained primary button group"
        variant="contained"
      >
        <MapTools history={this.props.history || 0} />
        <MapSettings settings={UserSetting.get()} />
        <MapLayers
          history={this.props.history || 0}
          poiCategoryArr={poiCategoryArr}
          setPoiCategoryArr={setPoiCategoryArr}
        />
      </ButtonGroup>
    ) : (
      ""
    );

    return <div className="mapUtilitiesButtons">{mapUtilitiesButtons}</div>;
  }
}
