import React, { useState, useContext, useEffect } from "react";
// External
import _ from "lodash";

// Material-UI
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, alpha } from "@material-ui/core";
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import GeneralSettings from "./GeneralSettings";
import MapSettings from "./MapSettings";
import POICategorySettings from "./POICategorySettings";
import Language from "sccLanguage";
import Utils from 'sccUtils'

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),

  settingsBody: {
    maxWidth: "750px",
    minHeight: "250px",
    maxHeight: "600px",

    "& .tabs": {
      borderRight: `1px solid ${theme.palette.colors.gray.main}`,
      borderColor: theme.palette.colors.blue.main,
    },

    "& .tabPanel": {
      width: "98%",
      padding: "10px",

      "& .MuiBox-root": {
        padding: "0",
      },
    },

    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.colors.blue.main,
    },

    "& .listing": {
      padding: "3px",
      bgcolor: theme.palette.colors.white.main,
      margin: "5px",
      color: theme.palette.colors.black.main,
      overflowY: "hidden",

      "& .header": {
        bgcolor: `${theme.palette.colors.gray.dark} !important`,
        borderBottom: `1px solid ${theme.palette.colors.gray.dark}`,
        color: `${theme.palette.colors.black.main} !important`,
        fontWeigth: "700",
      },

      "& .listItem": {
        bgcolor: `${theme.palette.colors.gray.light} !important`,
        borderBottom: `1px solid ${theme.palette.colors.gray.main}`,

        "& .MuiSvgIcon-fontSizeSmall": {
          fontSize: "10px",
        },
        "& .MuiListItemIcon-root": {
          minWidth: "35px !important",
        },
        "& .MiuTypography-body2": {
          fontSize: "14px",
        },
      },

      "& .imageList": {
        maxHeight: "200px",
        overflowY: "scroll",
      },
      "& .addButton": {
        float: "right",
      },
    },

    "& .MuiListItem-container": {
      borderTop: `2px solid ${theme.palette.colors.white.main}`,
      borderBottom: `1px solid ${theme.palette.colors.gray.dark}`,
    },

    "& .dropdown": {
      paddingTop: "10px",
    },

    "& .MuiTab-root": {
      minWidth: "100px",
    },

    "& .CategoryAddForm": {
      "& .fileUploadInput": {
        display: "none",
      },
      "& .listing": {
        "& .MuiListItem-root": {
          paddingTop: "0",
          paddingBottom: "0",
        },
        "& .MuiGridContainer": {
          alignItems: "center",
        },
      },
      "& .fileNameBox": {
        background: alpha(theme.palette.colors.headerFooterText.main, 0.85),
        width: "585px",
        marginLeft: "6px",
        height: "34px !important",
        "& .fileName": {
          paddingLeft: "8px",
          marginTop: "9px !important",
          fontSize: "14px",
        },
      },
      "& .error": {
        bgcolor: alpha(theme.palette.colors.secondary.main, 0.85) + " !important",
        borderColor: theme.palette.colors.red.main,
      },
      "& .errorBtn": {
        color: theme.palette.colors.white.main,
      },
      "& .logoPreview": {
        maxWidth: "50px",
        maxHeight: "50px",
        "& .picFile": {
          maxWidth: "32px",
          maxHeight: "32px",
        },
      },

      "& .poiFooter": {
        margin: "10px",
        width: "622px",
      },
    },

    "& .errorInfo": {
      background: `${theme.palette.colors.red.main} !important`,
      color: `${theme.palette.colors.white.main} !important`,
      margin: "0 10px 0 10px",
      lineHeight: "32px",
      "& a": {
        color: theme.palette.colors.white.main,
      },
    },
    "& .linkButton": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    "& .imgChecked": {
      border: `2px solid ${theme.palette.colors.red.main}`,
      margin: "5px",
    },
    "& .imgUnChecked": {
      border: `2px solid ${theme.palette.colors.white.main}`,
      margin: "5px",
    },
    "& .imgDisabled": {
      border: `2px solid ${theme.palette.colors.gray.dark}`,
      margin: "5px",
      cursor: "no-drop",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function ModuleWrapperSettingsBody() {
  const [value, setValue] = useState(0);
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [pageState, setPageState] = useState({
    pageDisplayMode: 1,
    pageTitle: Language.translate("Category List"),
    categoryItemData: {},
    categoryItemDataOriginal: {},
  });
  const [disableButton, setDisableButton] = useState(true);
  const [uploadFileDisplay, setUploadFileDisplay] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [userIsAdmin] = useState(
    Utils.getCookie("userrole") === "1" ? true : false
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleItemData: { ...p.moduleData },
      moduleItemDataOriginal: { ...p.moduleData },
    }));
  }, []);

  const classes = useStyles();

  return (
    <Grid container className={classes.settingsBody}>
      <Grid item xs={2} className="tabs">
        <Tabs orientation="vertical" value={value} onChange={handleChange}>
          <Tab label={Language.translate("General")} {...a11yProps(0)} />
          {!userIsAdmin && (
            <Tab label={Language.translate("Map")} {...a11yProps(1)} />
          )}
          <Tab label={Language.translate("POI")} {...a11yProps(2)} />
        </Tabs>
      </Grid>
      <Grid item xs={10}>
        <TabPanel className="tabPanel" value={value} index={0}>
          <GeneralSettings />
        </TabPanel>
        {!userIsAdmin && (
          <TabPanel className="tabPanel" value={value} index={1}>
            <MapSettings />
          </TabPanel>
        )}
        <TabPanel
          className="tabPanel"
          value={value}
          index={userIsAdmin ? 1 : 2}
        >
          <POICategorySettings
            pageState={pageState}
            setPageState={setPageState}
            disableButton={disableButton}
            setDisableButton={setDisableButton}
            uploadFileDisplay={uploadFileDisplay}
            setUploadFileDisplay={setUploadFileDisplay}
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
            filesToDelete={filesToDelete}
            setFilesToDelete={setFilesToDelete}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default ModuleWrapperSettingsBody;
