import React, { useState, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { moduleListing } from "@Styles/ModuleListing";
import SyncListItem from "./SyncListItem";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  syncBody: {
    maxWidth: 750,
  },

  syncListing: {
    padding: "3px 0px",
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
    marginTop: 0,
    marginBottom: 0,
  },
}));

function ModuleWrapperSyncBody(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);
  const classes = useStyles();

  //to be used in the listing page
  const moduleData = mwState.moduleData && Object.values(mwState.moduleData);

  const handleClick = (id) => {
    openDetailsFor !== id ? setOpenDetailsFor(id) : setOpenDetailsFor(null);
  };

  const filteredModuleData = () => {
    let returnedData = mwState.searchExecuted
      ? Object.values(mwState.filteredModuleData)
      : moduleData;

    if (mwState.selectedOrderBy && mwState.selectedOrderBy !== "") {
      if (mwState.selectedOrderBy === "Last Synced") {
        returnedData.sort(
          (a, b) => b.last_synced_timestamp - a.last_synced_timestamp
        );
      } else {
        returnedData.sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }
    }

    return returnedData;
  };

  return (
    <div className={classes.syncBody}>
      <List className={classes.listContainer}>
        {!_.isEmpty(filteredModuleData()) &&
          filteredModuleData()
            .slice(mwState.pagination.startIndex, mwState.pagination.endIndex)
            .map((data) => {
              if (data?.id) {
                return (
                  <SyncListItem
                    key={data.id}
                    data={data}
                    handleClick={handleClick}
                    openDetailsFor={openDetailsFor}
                  />
                );
              }
            })}
      </List>
    </div>
  );
}

export default ModuleWrapperSyncBody;
