// External
import _ from "lodash";
import log from "loglevel";

// Internal
import { default as Module } from "sccModule";
import { default as Permission } from "sccPermission";
import { default as Utils } from "sccUtils";
import Language from "sccLanguage";

/**
 * The base class for Client
 *
 * @class ClientModule
 **/
class ClientModule extends Module.Module {
  constructor() {
    super({
      moduleName: "client",
    });
    this.saFeature = null;
    this.abFeature = null;
  }

  init() {
    if (!Permission.verify("client", "view")) {
      log.debug("User is not permitted for client module");
      return Promise.resolve();
    }

    return super.init();
  }

  onSocketDelete(url, data) {
    var AdminDevice = require("sccAdminDevice");
    var AdminUser = require("sccAdminUser");
    _.each(data.devices, function (device) {
      AdminDevice.set(null, device);
    });

    _.each(data.users, function (user) {
      AdminUser.set(null, user);
    });

    Module.Module.prototype.onSocketDelete.call(this, url, data);
  }

  getMapLayers() {
    return Utils.httpRequestHandler({
      method: "GET",
      url: Utils.apiUrlPrefix + "/map_layer/all",
    }).then(function (response) {
      return Promise.resolve(response.data.result);
    });
  }

  getFeatures() {
    return Utils.httpRequestHandler({
      method: "GET",
      url: Utils.apiUrlPrefix + "/feature",
    }).then(function (response) {
      return Promise.resolve(response.data.result);
    });
  }

  // Overload update to clear SA limits if SA as a feature is removed.
  update(data) {
    var SituationalAwarenessFeatureID = 6;

    if (!data.features.includes(SituationalAwarenessFeatureID)) {
      data.sa_scct_limit = 0;
      data.sa_wave_limit = 0;
    }

    return super.update(data);
  }

  deleteAllSaRules(clientId) {
    const url = Utils.apiUrlPrefix + "/client/clear_sa/" + clientId;
    var options = {
      url: url,
      method: "DELETE",
    };
    return Utils.httpRequestHandler(options);
  }

  deleteAllAddressBooks(clientId) {
    const url = Utils.apiUrlPrefix + "/client/clear_addressbook/" + clientId;
    var options = {
      url: url,
      method: "DELETE",
    };
    return Utils.httpRequestHandler(options);
  }

  deleteAllHermes(clientId) {
    var $this = this;
    var options = {
      url:
        Utils.apiUrlPrefix +
        "/hermes_gateways/getHermesDevicesToDelete/" +
        clientId,
      method: "GET",
    };
    return Utils.httpRequestHandler(options).then(function (response) {
      if (response.data.result != 0) {
        return $this.recursiveDeleteAllHermes(response.data.result, 0);
      } else {
        Utils.notify(
          "Hermes Gateways",
          Language.translate("No Gateway devices found for this customer."),
          "",
          "success"
        );
      }
    });
  }

  recursiveDeleteAllHermes(devices, index) {
    var $this = this;
    if (index < devices.length) {
      var gatewayID = devices[index].id;
      var deleteOptions = {
        url: Utils.apiUrlPrefix + "/admin_device/" + gatewayID,
        method: "DELETE",
      };
      return Utils.httpRequestHandler(deleteOptions).then(function () {
        index++;
        return $this.recursiveDeleteAllHermes(devices, index);
      });
    } else {
      Utils.notify(
        "Hermes Gateways",
        Language.translate(
          "All Gateway devices have been removed from the customer."
        ),
        "",
        "success"
      );
    }
  }

  emptySaFeature() {
    this.saFeature = null;
  }
  emptyAbFeature() {
    this.abFeature = null;
  }
  emptyHermesFeature() {
    this.hermesFeature = null;
  }
}
export default new ClientModule();
