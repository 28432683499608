import * as Yup from "yup";
import baseSchema from "./BaseValidation";
import Language from "sccLanguage";

const schema = Yup.object().shape({
  cannedmessage_id: baseSchema(Language).dropdownSelectNum,
  message: baseSchema(Language).notRequiredString.when("cannedmessage_id", {
    is: (num) => num === null || num === 0,
    then: baseSchema(Language).requiredString,
  }),
  _attachmentTooBig: Yup.boolean(),
  _invalidFileName: Yup.boolean(),
  attachments: Yup.array().test({
    name: "attachments_test",
    exclusive: true,
    message: Language.translate("Required"),
    test: function (value) {
      return !(
        this.options.parent._attachmentTooBig ||
        this.options.parent._invalidFileName
      );
    },
  }),
});

export default schema;
