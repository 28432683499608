import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.black
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
		fontSize: 12
	}
}));



function CustomTooltip(props) {
	const classes= useStyles();
	const {title, placement} = props;
	return (
		<Tooltip
			classes={classes}
			arrow
			title={title}
			placement={placement}
			{...props}
		>
			{props.children}
		</Tooltip>
	);
}

export default CustomTooltip;