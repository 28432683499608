import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

// Components
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Language from "sccLanguage";
import AdminDevice from "sccAdminDevice";
import AdminDeviceDetail from "./AdminDeviceDetail";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
}));

export default function AdminDeviceList(props) {
  const data = props.data;
  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  const handleClick = props.handleClick;
  const openDetailsFor = props.openDetailsFor;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  const moduleData = Object.values(mwState.moduleData);
  const filteredModuleData = mwState.convertAndSort(
    mwState.filteredModuleData,
    mwState.searchField
  );
  const moduleItemData = mwState.moduleItemData;

  const adminUserName = AdminDevice.getDeviceType(data.type_id).title;

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleItemData: { ...p.moduleItemData },
    }));
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItem
        className={classes.listItem}
        key={data.id}
        onClick={() => handleClick(data.id)}
      >
        <ListItemIcon className={classes.itemIcon}>
          <CustomTooltip title={data.company} placement="left">
            <span>
              <CustomIcon id={`icon-${mwState.icon}`} fontSize="small" />
            </span>
          </CustomTooltip>
        </ListItemIcon>
        <ListItemText
          id={data.id}
          className={classes.itemPrimaryText}
          primary={
            <React.Fragment>
              {data.name + " "}

              {adminUserName ? (
                <Chip
                  size="small"
                  avatar={<Avatar>{adminUserName.charAt(0)}</Avatar>}
                  color="primary"
                  label={adminUserName}
                />
              ) : null}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction className={classes.itemActionButtonsContainer}>
          {data.role_id !== 2 ? (
            <React.Fragment>
              {mwState.verifyEditPermission && (
                <CustomTooltip
                  title={Language.translate("Edit")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEdit(data)}
                  >
                    <EditIcon />
                  </IconButton>
                </CustomTooltip>
              )}
              {mwState.verifyDeletePermission && (
                <CustomTooltip
                  title={Language.translate("Delete")}
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      handleItemDelete(
                        `${mwState.routeUrl}/${data.id}`,
                        mwState.title,
                        "admin_device",
                        data.name
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
              )}
            </React.Fragment>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openDetailsFor === data.id} timeout="auto" unmountOnExit>
        <AdminDeviceDetail data={data} />
      </Collapse>
    </React.Fragment>
  );
}
