import React, { useState, useEffect } from "react";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Internal
import CustomTooltip from "@Components/CustomTooltip";
import Clock from "sccClock";
import UserSetting from "sccUserSetting";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  zonesRoot: {
    "& .hide-tablet": {
      display: "block !important",
    },
    "& .clock-zones-box": {
      float: "left",
      width: "320px",
      boxSizing: "border-box",
      "& ul": {
        marginTop: "0",
      },

      "& .timeZoneBox": {
        "& li": {
          "& a": {
            color: "#5b7182 !important",
            width: "100px",
            padding: "15px",
            background: "#fff",
            cursor: "default",
            padding: "0",
            textAlign: "center",
            fontSize: "14px",
            float: "left",
            height: "50px",
            marginRight: "5px",

            "& span": {
              display: "block",
              marginTop: "0",
              marginBottom: "7px",
              borderRadius: "0",
              position: "relative !important",
              fontSize: "12px !important",
              minWidth: "80px",
              right: "0 !important",
              background: "#293239 !important",
              padding: "5px",
              whiteSpace: "nowrap",
              textAlign: "center",
              color: "#fff",
              lineHeight: "1",
            },
          },
        },
      },
    },
  },
}));

export default function Zones() {
  const [clock, setClock] = useState(Clock.zone_1);

  const obj = [
    { id: 1, zone: Clock.zone_1, key: "", tooltip: Clock.time_zone_1 },
    {
      id: 2,
      zone: Clock.zone_2,
      key: "time_zone_2m",
      tooltip: UserSetting.get("time_zone_2"),
    },
    {
      id: 3,
      zone: Clock.zone_3,
      key: "time_zone_3",
      tooltip: UserSetting.get("time_zone_3"),
    },
  ];

  const classes = useStyles();

  const zone = obj.map((item) =>
    UserSetting.get(item.key) != 0 ? (
      <li key={item.id + 1}>
        <CustomTooltip title={item.tooltip} arrow placement="bottom">
          <a>
            <span className="label label-primary">
              {Language.translate("ZONE")} {item.id}
            </span>
            {item.zone}
          </a>
        </CustomTooltip>
      </li>
    ) : (
      ""
    )
  );

  const displayZones = Clock.initialized ? (
    <div className="hide-tablet clock-zones-box">
      <ul className="timeZoneBox">{zone}</ul>
    </div>
  ) : (
    ""
  );

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanUp() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setClock(Clock.zone_1);
  }

  return <div className={classes.zonesRoot}>{displayZones}</div>;
}
