import React, { useEffect } from "react";
import CustomTextField from "@Components/CustomTextField";

function Search(props) {
  let {
    name,
    label,
    value,
    filterSelection,
    data,
    filteredData,
    searchField,
    onChange,
    updateFilteredData,
    ...other
  } = props;

  const _ = require("lodash");
  const dataToFilter = _.cloneDeep(data);
  const Device = require("sccDevice").default;

  function matchingDevices(group, filters) {
    var title = filters.title && filters.title.toLowerCase();
    var typeId = filters.type_id;

    return _.reduce(
      group.devices,
      function (result, deviceId) {
        var device = Device.get(deviceId);
        if (!device) return result || false;

        var filterTitleCheck =
          title !== ""
            ? device.name.toLowerCase().indexOf(title) > -1 ||
              device.imei.toLowerCase().indexOf(title) > -1
            : true;
        var filterTypeCheck = _.isEmpty(typeId)
          ? true
          : _.indexOf(typeId, device.type_id) > -1;

        if (filterTitleCheck && filterTypeCheck)
          result = _.concat(result, [deviceId]);

        return result || (filterTitleCheck && filterTypeCheck);
      },
      []
    );
  }

  function filteredTree(group, filters, type) {
    if (!filters) return false;

    if (!(filters.title || (filters.type_id && filters.type_id.length)))
      return false;

    type = type || "device";

    recursiveFilter(group, filters, 1, type);
  }

  function recursiveFilter(group, filters, level, type) {
    group.groups.map((subGroup) => {
      if (_.isArray(subGroup.groups) && subGroup.groups.length > 0) {
        level += 1;
        recursiveFilter(subGroup, filters, level, type);
        level -= 1;
      }
      if (matchingDevices(subGroup, filters).length > 0) {
        subGroup.devices = matchingDevices(subGroup, filters);
      } else {
        //if there is no matching device we dont need to look anY further, so we remove the group from parent groups array
        group.groups = group.groups.filter((obj) => !_.isEqual(obj, subGroup));
      }
    });
  }

  useEffect(() => {
    const filters = { title: value, type_id: filterSelection.type_id };
    if (dataToFilter?.groups?.length > 0) {
      console.log("fill", dataToFilter, JSON.stringify(filters));
      filteredTree(dataToFilter.groups[0], filters, "device");
      dataToFilter.groups[0].devices = matchingDevices(
        dataToFilter.groups[0],
        filters
      );
    }
    updateFilteredData(dataToFilter);
  }, [value]);

  return (
    <CustomTextField
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      {...other}
    />
  );
}

export default Search;
