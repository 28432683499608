import React from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";

import "moment/locale/fr";
import "moment/locale/es";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
  },
});

function CustomCalendar(props) {
  const classes = useStyles();
  const { name, label, value, onChange, locale, ...other } = props;
  moment.locale(locale); // it is required to select default locale manually
  return (
    <MuiPickersUtilsProvider
      locale={locale}
      libInstance={moment}
      utils={MomentUtils}
    >
      <KeyboardDateTimePicker
        autoOk={true}
        ampm={false}
        variant="standard"
        inputVariant="outlined"
        error={false}
        helperText={null}
        value={value}
        onChange={onChange}
        label={label}
        format="DD/MM/yyyy HH:mm"
        classes={{
          root: classes.root,
        }}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
}

export default CustomCalendar;
