import React, {useEffect, useState, useContext} from 'react'
import ResetPassword from '../../login/components/ResetPassword'

var _= require("lodash");

function ResetPasswordMain() {
	return (
		<div>
				<ResetPassword />
		</div>
	)
}

export default ResetPasswordMain;