import React from "react";

// currentPage (optional) - the current active page, defaults to the first page
// itemsPerPage (optional) - the number of items per page, defaults to 10
// numberOfItems (required) - the total number of items to be paged
// maxPages (optional) - the maximum number of page navigation links to display, defaults to 10
function Paginate(
	currentPage: number = 1,
	itemsPerPage: number = 20,
	numberOfItems: number,
	maxPages: number = 5
) {
	// calculate total pages
	let totalPages = Math.ceil(numberOfItems / itemsPerPage);

	// ensure current page isn't out of range
	if (currentPage < 1) {
		currentPage = 1;
	} else if (currentPage > totalPages) {
		currentPage = totalPages;
	}

	let startPage: number, endPage: number;
	if (totalPages <= maxPages) {
		// total pages less than max so show all pages
		startPage = 1;
		endPage = totalPages;
	} else {
		// total pages more than max so calculate start and end pages
		let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
		let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
		if (currentPage <= maxPagesBeforeCurrentPage) {
			// current page near the start
			startPage = 1;
			endPage = maxPages;
		} else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
			// current page near the end
			startPage = totalPages - maxPages + 1;
			endPage = totalPages;
		} else {
			// current page somewhere in the middle
			startPage = currentPage - maxPagesBeforeCurrentPage;
			endPage = currentPage + maxPagesAfterCurrentPage;
		}
	}

	// calculate start and end item indexes
	let startIndex = (currentPage - 1) * itemsPerPage;
	let endIndex = Math.min(startIndex + itemsPerPage - 1, numberOfItems - 1);

	// create an array of pages to repeat in the pager control
	let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

	// return object with all pager properties required by the view
	return {
		numberOfItems: numberOfItems,
		currentPage: currentPage,
		itemsPerPage: itemsPerPage,
		totalPages: totalPages,
		startPage: startPage,
		endPage: endPage,
		startIndex: startIndex,
		endIndex: endIndex,
		pages: pages
	};
}

export default Paginate;