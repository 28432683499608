// import { default as Menu } from "sccMenu";
const Message= require("sccMessage").default;
const Utils= require("sccUtils");
const Menu= require("sccMenu").default;
const _= require("lodash");
const log = require("loglevel");
const Chat= require("sccChat");
const Language = require("sccLanguage").default;
const User= require("sccUser");
/**
 * Message menu class
 * 
 * @class MessageMenu
 * @param {object} options - Message menu information object
 */
class MessageMenu extends Menu{
	constructor(){
		const options= {
			tab: "MSG",
			shortTitle: "MSG",
			title: "Message",
			moduleName: "message",
			mainClass: "Message",
			searchable: true,
			showPagination: false,
			//imageClass: "fa-envelope",
			imageClass: "envelope",
			module: Message
		};
		super(options);

		this.loader= false;
		this.pageCount= 50;
		this.currentFolder= "inbox";
		this.currentPage= 1;
		this.selection= [];
		this.disabledDevices= [];
		this.maxFileSize= 100000; // in Bytes
		this.sortByDesc= true;
		this.hasNewMessages= false;
		this.searchTextError = false;
		this.hideUnauthorized = false;
		this.expandMessageMenu = false;
	}

	init($scope){		
		return super.init($scope)
		.then(()=>{
			
			// Temporary fix 
			//return this.refresh();
			return this.loadMessageInfo();
			
		});		
	}

	/**
	 * overriding the add method of the Menu class
	 * 
	 */
	add(obj){
		obj= _.assign(obj, { 
			recipients: { users: [], devices: [], groups: [] }, 
			attachments: [], 
			cannedmessage_id: "null",
			_file: null,
			_attachmentTooBig: false,
			_invalidFileName: false
		});

		super.add(obj);	
	}

	refresh(data){
		var $this= this;
		$this.loader= true;
		$this.hasNewMessages = false;
		
		//refreshes datadisplay chatbox if its open
		Message.refreshChatBox(data);
		
		if(!$this.currentPage){
			$this.currentPage = 1;
		}

		return Message.loadData([
			$this.currentPage, 
			$this.pageCount,
			$this.currentFolder, 
			$this.sortByDesc,
			$this.currentSearchText
			
		])
		.then(function(data){
			$this.selection= [];
			log.debug("Inbox:", data.result);
			return Message.loadMessageInfo();
		}) 
		.then(function(messageInfo){
			log.debug("messageInfo", messageInfo.data.result);
			Message.setMessageInfo(messageInfo.data.result);
			$this.loader= false;
		
			/*
				- On socket update (set read, unread, move to trash, restore etc), 
				we have reload the messages loaded in chat box 
				because the user might have archived or deleted more than one message
	
				- On socket add or message status update, we can just append the message obj.
			*/
			if(Chat.selectedDeviceId){
				if(data && data.id){
					//when message is insterted or message status is updated
					//the data object only contains info of one message
					Chat.update(data);

				}else{
					//when message is update like (set read, unread, move to trash, restore etc)
					//the data contains a list of messages that were updated. 
					//Hence this logic decides what action to execute
					//based on the result of the structure of the data
					Chat.loadMessageChat();
				}
			}
			return;
		});
	}

	updateChat(data){
		/*
			- On socket update (set read, unread, move to trash, restore etc), 
			we have reload the messages loaded in chat box 
			because the user might have archived or deleted more than one message

			- On socket add or message status update, we can just append the message obj.
		*/
		if(Chat.selectedDeviceId){
			if(data.id){
				//when message is insterted or message status is updated
				//the data object only contains info of one message
				Chat.update(data);

			}else{
				//when message is update like (set read, unread, move to trash, restore etc)
				//the data contains a list of messages that were updated. 
				//Hence this logic decides what action to execute
				//based on the result of the structure of the data
				Chat.loadMessageChat();
			}
		}
	}

	loadMessageInfo(){
		return Message.loadMessageInfo()
		.then(function(messageInfo){
			var $this= this;
			log.debug("messageInfo", messageInfo.data.result);
			Message.setMessageInfo(messageInfo.data.result);
			// $this.loader= false;
			return;
		});
	}
	
	sendNotifyMessage(getMsg, getHeader){
		Utils.notify({message: Language.translate(getMsg), header: Language.translate(getHeader)});
	}
	
	// CHECK THE STATUS OF THE CHECKBOX
	selectionStatus(){	
		var messages = _.map(Message.get().messages, "id");
		var intersection = _.intersection(this.selection, messages);
	
		if (intersection.length <= 0) return;
		
		if(intersection.length !=  messages.length){
			return "partial";
		}else{
			return "all";
		}
	}
	
	//MESSAGE MENU - CHECK ALL CHECKBOXES
	checkAll() {
		var messages = _.map(Message.get().messages, "id");
		var intersection = _.intersection(this.selection, messages);
		var difference= _.difference(messages, this.selection);
	
		if (intersection.length <= 0){
			this.selection =  _.xor(this.selection, messages);
			return;
		}else if(intersection.length !=  messages.length){
			this.selection =  _.xor(this.selection, difference);
		}else{
	
			this.selection =  _.xor(this.selection, messages);
		}
	}
	
	//Returns TOTAL COUNT of messages depending on Message Type
	totalCount(){
		var fieldName= this.currentFolder+"_count";		
		if(this.currentSearchText || this.currentFolder == "monitor" ){
			return Message.get().total_message_count;
		}else{
			return Message.getMessageInfo()[fieldName];
		}
	}
	
	
	lastPage(){
		return	Math.ceil(this.totalCount() / this.pageCount);
	}
	
	
	/**
	 * reduces the page number by 1 if all items in the last page is removed
	 */
	fixCurrentPage(){
		if(this.selectionStatus() == "all"){
			if(this.currentPage == this.lastPage()){
				this.currentPage --;
			}
		}
	}
	
	/**
	 * Downloads attachement data
	 */
	downloadAttachment(attach, base64){
		var name= attach.attachment_name;
		var type= attach.attachment_type;
		var data= "data:"+ type+ ";base64,"+ base64;
		require("downloadjs")(data, name, type);	
	}
	
	clearSearchInput(){
		this.searchText= "";
		this.currentSearchText= "";
	}

	/**
	 * Return length of users with username
	 */
	usersLength(){
		const ids = [];
		_.each(User.get(), function(key) { 
			if(key.username){
				ids.push(key.id);
			}
		});
		return ids.length;
	}
}

require("../styles/message_styles.scss");
//module.exports= New MessageMenu();
export default new MessageMenu();