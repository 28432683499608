import React, { useState, useContext } from "react";

// Material-UI
import {
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Internal
import Device from "sccDevice";
import Language from "sccLanguage";
import UserSetting from "sccUserSetting";
import CustomIcon from "@Components/CustomIcon";
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
  noDevice: {
    textAlign: "center",
  },
}));

export default function GeofenceDetail({ data }) {
  const [value, setValue] = useState(0);

  function handleTabs(e, val) {
    setValue(val);
  }

  function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div>
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }

  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} className={classes.detailsContainer}>
      <Tabs
        value={value}
        onChange={handleTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          label={Language.translate("Info")}
          icon={<CustomIcon fontSize="small" id="icon-info-circle" />}
        />
        <Tab
          label={Language.translate("Triggers")}
          icon={
            <Badge
              badgeContent={data.triggers?.devices?.length || 0}
              color="primary"
            >
              <CustomIcon fontSize="small" id="icon-group" />
            </Badge>
          }
        />
        <Tab
          label={Language.translate("Tactical")}
          icon={
            <Badge
              badgeContent={data.sync?.devices?.length || 0}
              color="primary"
            >
              <CustomIcon fontSize="small" id="icon-refresh" />
            </Badge>
          }
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container xs={12} md={12}>
          <Grid item xs={6} md={6}>
            <List>
              <ListItem>
                <ListItemText
                  md={12}
                  primary={Language.translate("Information")}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={Language.translate("Annotation").toUpperCase() + ":"}
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={data.note}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={Language.translate("Status").toUpperCase() + ":"}
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    data.active
                      ? Language.translate("Active")
                      : Language.translate("Inactive")
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={Language.translate("Shape").toUpperCase() + ":"}
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={data.shape}
                />
              </ListItem>
              {(data.shape === "Polygon" || data.shape === "rectangle") && (
                <>
                  {UserSetting.get("speed_format") && (
                    <ListItem>
                      <ListItemText
                        md={6}
                        className="itemTextFirst"
                        primary={Language.translate("SPEED UNIT") + ":"}
                      />
                      <ListItemText
                        md={6}
                        className="itemTextSecond"
                        primary={UserSetting.get("speed_format")}
                      />
                    </ListItem>
                  )}
                  {data.min_speed && (
                    <ListItem>
                      <ListItemText
                        md={6}
                        className="itemTextFirst"
                        primary={
                          Language.translate("Minimum Speed").toUpperCase() +
                          ":"
                        }
                      />
                      <ListItemText
                        md={6}
                        className="itemTextSecond"
                        primary={data.min_speed}
                      />
                    </ListItem>
                  )}
                  {data.max_speed && (
                    <ListItem>
                      <ListItemText
                        md={6}
                        className="itemTextFirst"
                        primary={
                          Language.translate("Maximum Speed").toUpperCase() +
                          ":"
                        }
                      />
                      <ListItemText
                        md={6}
                        className="itemTextSecond"
                        primary={data.max_speed}
                      />
                    </ListItem>
                  )}
                </>
              )}
              {data.shape === "circle" && (
                <ListItem>
                  <ListItemText
                    md={6}
                    className="itemTextFirst"
                    primary={Language.translate("Radius").toUpperCase() + ":"}
                  />
                  <ListItemText
                    md={3}
                    className="itemTextSecond splitIn2"
                    primary={data.width || "N/A"}
                  />
                  <ListItemText
                    md={3}
                    className="itemTextFirst splitIn2"
                    primary={Language.translate("Meters").toUpperCase()}
                  />
                </ListItem>
              )}
              {data.shape === "path" && (
                <ListItem>
                  <ListItemText
                    md={6}
                    className="itemTextFirst"
                    primary={Language.translate("Radius").toUpperCase() + ":"}
                  />
                  <ListItemText
                    md={3}
                    className="itemTextSecond splitIn2"
                    primary={data.width || "N/A"}
                  />
                  <ListItemText
                    md={3}
                    className="itemTextFirst splitIn2"
                    primary={Language.translate("Meters").toUpperCase()}
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemText
                  md={6}
                  className="itemTextFirst"
                  primary={Language.translate("Type").toUpperCase() + ":"}
                />
                <ListItemText
                  md={6}
                  className="itemTextSecond"
                  primary={
                    data.inclusive
                      ? Language.translate("Inclusive")
                      : Language.translate("Exclusive")
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} md={6}>
            <List>
              <ListItem>
                <ListItemText
                  md={12}
                  primary={Language.translate("Coordinates")}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  xs={12}
                  md={12}
                  primary={
                    data.coordinates?.length > 0 &&
                    data.coordinates.map((coord, index) => {
                      return (
                        <Grid
                          xs={12}
                          md={12}
                          key={index}
                          item
                          className="itemTextAlone"
                        >
                          {coord.longitude}, {coord.latitude}
                        </Grid>
                      );
                    })
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List>
          {data.triggers.devices?.length > 0 ? (
            data.triggers.devices.map((deviceId) => {
              const deviceInfo = Device.get(deviceId);
              return (
                <ListItem key={deviceId}>
                  <ListItemText
                    md={12}
                    primary={deviceInfo.name}
                    className="itemTextAlone"
                  />
                </ListItem>
              );
            })
          ) : (
            <p className={classes.noDevice}>
              {Language.translate("There are no devices in Triggers")}
            </p>
          )}
        </List>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <List>
          {data.sync.devices?.length > 0 ? (
            data.sync.devices.map((deviceId) => {
              const deviceInfo = Device.get(deviceId);
              return (
                <ListItem key={deviceId}>
                  <ListItemText
                    md={12}
                    primary={deviceInfo.name}
                    className="itemTextAlone"
                  />
                </ListItem>
              );
            })
          ) : (
            <ListItem className={classes.noResults}>
            <ListItemText className="itemTextAlone">
              {Language.translate("There are no devices in Tactical")}
            </ListItemText>
          </ListItem>
          )}
        </List>
      </TabPanel>
    </Grid>
  );
}
