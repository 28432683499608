import React, { useEffect, useContext, useState } from "react";

//External
import clsx from "clsx";
import _ from "lodash";
import { toLower } from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

// Components
import CustomDropdown from "@Components/CustomDropdown";
import CustomSearch from "@Components/CustomSearch";
import CustomFilter from "@Components/CustomFilter";
import CustomPagination from "@Components/CustomPagination";
import CustomButton from "@Components/CustomButton";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import { AppContext } from "../../../AppContext";
import Alert from "sccAlert";
import Clock from "sccClock";
import Device from "sccDevice";
import GuiUtils from "sccGuiUtils";
import Language from "sccLanguage";
import olMap from "sccOlMapNew";
import { ReportContext } from "./ReportContext";
import TimeUtils from "sccTimeUtils";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 400,
    paddingBottom: 4,
    overflowX: "hidden",
    overflowY: "auto",
    border: `1px solid ${theme.palette.colors.gray.pure}`,
  },
  tableHead: {
    height: 30,
    background: theme.palette.colors.gray.main,
    borderBottom: `1px solid ${theme.palette.colors.gray.dark}`,
  },
  tableRow: {
    height: 25,
    borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
  },
  tableCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding: 5,
  },
  date: {
    fontColor: theme.palette.colors.black.main,
    textDecoration: "none",
    verticalAlign: "top",
  },
  dataTitle: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 25,
    display: "flex",
    marginBottom: 10,
  },
  btnExport: {
    float: "right",
    marginTop: -40,
  },
  recordsPerPage: {
    padding: 5,
    "& .dropdown": {
      backgroundColor: theme.palette.colors.gray.main,
      width: 125,
    },
  },
  events: {
    paddingLeft: 15,
    "& span.eventTypes": {
      width: 10,
      height: 10,
      borderRadius: "50%",
      marginLeft: -13,
      marginTop: 3,
      float: "left",
    },
    "& span.Start": {
      background: "green",
    },
    "& span.End": {
      background: "red",
    },
  },
  alerts: {
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: ".5rem",
      marginLeft: 3,
      marginTop: 5,
    },
    "& .MuiListItemIcon-root": {
      maxWidth: "10px !important",
    },
  },
  emergency: {
    color: theme.palette.buttons.delete.main,
  },
  geofence: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: theme.palette.colors.orange.main,
    },
  },
  report: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: theme.palette.colors.gray.light,
    },
  },
  speed: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: theme.palette.colors.purple.main,
    },
  },
  "non-report": {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: theme.palette.colors.green.main,
    },
  },
  cargo: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: theme.palette.alerts.cargo.main,
    },
  },
  vehicle: {
    "& .MuiSvgIcon-fontSizeSmall": {
      color: theme.palette.alerts.vehicle.main,
    },
  },
  noResults: {
    textAlign: "center",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function DataTable() {
  const [reportState, setReportState] = useContext(ReportContext);
  const [searchFilter, setSearchFilter] = useState("");
  const [filterSelection, setFilterSelection] = useState({});
  const leftPanelDrawerOpen = reportState.leftPanelDrawerOpen;
  const itemsPerPage = reportState.itemsPerPage;
  const [rightPanelStyle, setRightPanelStyle] = useState({
    transition: "margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)",
  });
  const [appState] = useContext(AppContext);

  const reportData = reportState.reportData;
  const filteredReportData = reportState.filteredReportData;
  const sortReportData = reportState.sortReportData;
  const orderByASC = reportState.orderByASC;
  const sortField = reportState.sortField;

  //Object that helps translate historic events to alert titles
  const filterList = {
    report: { id: "report", type: "report", title: "Report" },
    emergency: { id: "emergency", type: "emergency", title: "Emergency" },
    geofence: { id: "geofence", type: "geofence", title: "Geofence" },
    speed: { id: "speed", type: "speed", title: "Speed" },
    cargo: { id: "cargo", type: "cargo", title: "Cargo" },
    non_report: { id: "non_report", type: "non_report", title: "Non-Report" },
    vehicle: { id: "vehicle", type: "vehicle", title: "Vehicle Events" },
  };

  const filterField = [
    {
      title: "Alert Type",
      list: filterList,
      id: "event",
      displayField: "title",
      searchField: "title",
    },
  ];

  const alerts = [
    "Emergency",
    "Geofence",
    "Speed",
    "Non-Report",
    "Cargo",
    "Vehicle",
  ];

  useEffect(() => {
    if (leftPanelDrawerOpen)
      setRightPanelStyle({
        transition: "margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)",
        marginLeft: 300,
      });
    else
      setRightPanelStyle({
        transition: "margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)",
      });
  }, [leftPanelDrawerOpen]);

  useEffect(() => {
    setReportState((p) => ({
      ...p,
      filteredReportData: sortReportData(
        filteredReportData,
        orderByASC,
        sortField
      ),
    }));
  }, [orderByASC]);

  useEffect(() => {
    setSearchFilter("");
  }, [reportState.reportTypeDisplaying]);

  let sortReverse = false;
  const Historic = reportState.Historic;
  const Reports = reportState.Reports;

  function updateFilteredData(data) {
    const numberOfItems = data && Object.keys(data).length;
    setReportState((p) => ({
      ...p,
      filteredReportData: sortReportData(
        Object.values(data),
        orderByASC,
        sortField
      ),
      searchExecuted: true,
      pagination: {
        ...p.pagination,
        currentPage: 1,
        numberOfItems: numberOfItems,
        startIndex: 0,
        endIndex:
          numberOfItems > p.pagination.itemsPerPage
            ? p.pagination.itemsPerPage
            : numberOfItems,
      },
    }));
  }
  function filterSelectionChange(e, filterName, originalData) {
    let allFilters = { ...filterSelection };
    let value = null;
    setSearchFilter("");
    if (e === null) {
      allFilters = {};
      setFilterSelection({});
    } else {
      value = !isNaN(e.target.value)
        ? e.target.value == ""
          ? null
          : Number(e.target.value)
        : e.target.value.toString();

      if (filterName !== null) {
        if (_.indexOf(allFilters[filterName], value) > -1) {
          allFilters[filterName] = _.difference(allFilters[filterName], [
            value,
          ]);
        } else {
          allFilters[filterName] = _.union(allFilters[filterName], [value]);
        }
      }
      setFilterSelection(allFilters);
    }

    const data = filteredList(Object.values(originalData), allFilters);
    updateFilteredData({ ...data });
  }

  function filteredList(list, filters) {
    _.each(_.keys(filters), function (filterKey) {
      if (!filters[filterKey]) {
        return list;
      }
      if (_.isArray(filters[filterKey])) {
        if (filters[filterKey].length == 0) {
          return list;
        }
        list = _.reduce(
          list,
          function (result, item) {
            if (_.indexOf(filters[filterKey], item[filterKey]) > -1) {
              result = _.concat(result, item);
            }
            return result;
          },
          []
        );
      }
    });
    return list;
  }

  function handleClearSearch() {
    setSearchFilter("");
    setReportState((p) => ({
      ...p,
      filteredReportData: sortReportData(
        p.filteredReportData,
        orderByASC,
        sortField
      ),
      searchExecuted: false,
    }));
  }

  const arrItemsPerPage = [
    {
      value: 10,
      label: `10 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 20,
      label: `20 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 50,
      label: `50 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 100,
      label: `100 ${Language.translate("PER PAGE")}`,
    },
  ];

  function onPageChange(currentPage, startIndex, endIndex) {
    //change list display to show the current index of records
    setReportState((p) => ({
      ...p,
      pagination: {
        ...p.pagination,
        currentPage: currentPage,
        startIndex,
        endIndex,
      },
    }));
  }
  function onItemsPerPageChange(e) {
    //make appropriate change in the context
    setReportState((p) => ({
      ...p,
      itemsPerPage: e.target.value, //based on the selection
      pagination: {
        ...p.pagination,
        currentPage: 1, //reset the current page to 1
        itemsPerPage: e.target.value, //based on the selection
        numberOfItems: Object.keys(p.filteredReportData).length, //based on the items in the list right now
        startIndex: 0,
        endIndex:
          Object.keys(p.filteredReportData).length > e.target.value
            ? e.target.value
            : Object.keys(p.filteredReportData).length,
      },
    }));
  }

  function getEventDetails(eventObj) {
    if (!eventObj.vehicle_alert_type_id) return "Emergency";

    return Alert.getVehicleAlertType(eventObj.vehicle_alert_type_id).type;
  }

  //Generates CSV file for Report
  function generateCSVFileForReport(reportData) {
    let dataForCsv = [];

    const options = {
      file_title: "Report",
    };

    if (!Reports.showLatestReportsTable) {
      options.file_title += "_Position_Alerts";
      formatDataForPositionAlerts(dataForCsv, reportData);
    } else if (Reports.showLatestReportsTable) {
      options.file_title += "_Latest_Reports";
      formatDataForLatestReports(dataForCsv, reportData);
    }

    appState.displaySnackbarMessage({
      message: Language.translate(
        "Please be patient. You may experience a delay while downloading large data sets"
      ),
      title: Language.translate("CSV Export"),
      variant: "info",
    });

    options.file_title += "_" + TimeUtils.getCurrentDateTimeAsATag() + ".csv";

    return Utils.generateCSVFileAndDownload(dataForCsv, options);
  }

  //Custom functions to build raw data set for position/alerts data
  function formatDataForPositionAlerts(dataForCsv, positionAlertsData) {
    _.each(positionAlertsData, function (event) {
      if (event.event_timestamp >= Historic.getStartTimestamp()) {
        let eventStatus =
          event.alert_started != undefined
            ? Reports.getStatusOfEvent(event)
            : "";
        let eventInfo = filterList[event.event].title + " " + eventStatus;

        let formattedData = {
          Name: Device.get(event.device_id).name,
          Type: Device.get(event.device_id).type,
          Date: Clock.formatTimestamp(event.event_timestamp),
          Location: Reports.getEventCoordinate(event),
          Event: eventInfo,
          Alerts: getAlertsInfo("Position/Alerts", { event: event }),
          Speed:
            Historic.getReportById(event.report_id) &&
            Historic.getReportById(event.report_id).speed != null
              ? Historic.getReportById(event.report_id).speed
              : "N/A",
          Altitude:
            Historic.getReportById(event.report_id) &&
            Historic.getReportById(event.report_id).altitude != null
              ? Historic.getReportById(event.report_id).altitude
              : "N/A",
          Course:
            Historic.getReportById(event.report_id) &&
            Historic.getReportById(event.report_id).heading != null
              ? GuiUtils.ConvertHeadingToString(
                  Historic.getReportById(event.report_id).heading
                )
              : "N/A",
        };

        dataForCsv.push(formattedData);
      }
    });
  }

  //Custom functions to build raw data set for latest reports data
  function formatDataForLatestReports(dataForCsv, latestReportsData) {
    _.each(latestReportsData, function (device) {
      if (device.report_timestamp >= Historic.getStartTimestamp()) {
        let formattedData = {
          Name: device.name,
          Type: device.type,
          Date: Clock.formatTimestamp(device.report_timestamp),
          Location: olMap.formatCoordinate([device.longitude, device.latitude]),
          Event: filterList["report"].title,
          Alerts: getAlertsInfo("Latest Reports", { device_id: device.id }),
          Speed: device.speed != null ? device.speed : "N/A",
          Altitude: device.altitude != null ? device.altitude : "N/A",
          Course:
            device.heading != null
              ? GuiUtils.ConvertHeadingToString(device.heading)
              : "N/A",
        };

        dataForCsv.push(formattedData);
      }
    });
  }
  function getAlertsInfo(reportType, options) {
    let alertsInfo = [];
    let alertTypes = [
      "Emergency",
      "Geofence",
      "Speed",
      "Non-Report",
      "Cargo",
      "Vehicle",
    ];

    if (reportType == "Position/Alerts") {
      alertTypes = [
        "emergency",
        "geofence",
        "speed",
        "non_report",
        "cargo",
        "vehicle",
      ];
    }
    _.forEach(alertTypes, function (alert) {
      if (reportType == "Position/Alerts") {
        if (Historic.hasAlertCheckForReport(options.event, alert)) {
          alertsInfo.push(filterList[alert].title);
        }
      } else if (reportType == "Latest Reports") {
        if (Alert.getDeviceAlert(options.device_id, alert)) {
          alertsInfo.push(alert);
        }
      }
    });
    return alertsInfo.join("/");
  }

  const classes = useStyles();

  function getDeviceAlert(deviceId, alertType) {
    const alertValues = _.values(Alert.get());
    const alertTypeId = Alert.getAlertTypeId(alertType);
    let deviceAlerts = _.filter(alertValues, {
      device_id: deviceId,
      alert_type_id: alertTypeId,
    });
    deviceAlerts = _.filter(deviceAlerts, (alert) => {
      return alert.info.end_timestamp == null;
    });
    return deviceAlerts.length ? deviceAlerts : null;
  }

  return (
    <Grid container style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Grid item container xs={12} style={rightPanelStyle}>
        <Grid item xs={12}>
          <div className={classes.dataTitle}>
            {reportState.reportTypeDisplaying === 1 &&
            reportState.showLatestReportsTable
              ? Language.translate("Latest Reports")
              : Language.translate("Position/Alerts")}
          </div>
          <div className={classes.btnExport}>
            {reportState.pagination.numberOfItems > 0 && (
              <CustomButton
                onClick={() =>
                  generateCSVFileForReport(reportState.filteredReportData)
                }
                size="small"
                color="command"
                variant="contained"
              >
                {Language.translate("Export As CSV")}
              </CustomButton>
            )}
          </div>
        </Grid>
        {!reportState.leftPanelDrawerOpen ? <Grid item xs={1}></Grid> : null}
        <Grid container item xs={6}>
          {reportState.reportTypeDisplaying === 2 && reportData.length > 0 && (
            <Grid item xs={5}>
              <CustomFilter
                name="filter"
                label={Language.translate("Filter")}
                filterSelection={filterSelection}
                onChange={filterSelectionChange}
                originalData={reportData}
                filteredData={{ ...filteredReportData }}
                updateFilteredData={updateFilteredData}
                filterField={filterField}
              />
            </Grid>
          )}
          <Grid item xs={7}>
            <CustomSearch
              id="txtSearch"
              name="search"
              placeholder={Language.translate("Search")}
              className="textfield"
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
              filters={filterSelection}
              filteredList={filteredList}
              data={reportData}
              indexedObject={true}
              updateFilteredData={updateFilteredData}
              searchField={
                reportState.reportType == 1 ? ["name"] : ["device_name"]
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    id="clearSearch"
                    onClick={handleClearSearch}
                    style={{ cursor: "pointer" }}
                  >
                    <ClearIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {!reportState.leftPanelDrawerOpen ? (
          <Grid item xs={2}></Grid>
        ) : (
          <Grid xs={3}></Grid>
        )}
        <Grid item xs={3} className="classes.recordsPerPage">
          {reportState.pagination.numberOfItems > 10 && (
            <CustomDropdown
              id="pagingSelector"
              className="dropdown"
              value={itemsPerPage}
              onChange={onItemsPerPageChange}
            >
              {arrItemsPerPage.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomDropdown>
          )}
        </Grid>
      </Grid>
      <Grid xs={12} item container style={rightPanelStyle}>
        <Grid item container className={classes.tableHead}>
          <Grid item xs={3} className={classes.tableCell}>
            {Language.translate("Name")}
          </Grid>
          <Grid item xs={1} className={classes.tableCell}>
            {Language.translate("Type")}
          </Grid>
          <Grid item xs={2} className={classes.tableCell}>
            <Link
              onClick={() =>
                setReportState((p) => ({ ...p, orderByASC: !orderByASC }))
              }
              className={classes.date}
            >
              {Language.translate("Date")}
            </Link>
            <span style={{ marginTop: -2 }}>
              {orderByASC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </span>
          </Grid>
          <Grid item xs={1} className={classes.tableCell}>
            {Language.translate("Location")}
          </Grid>
          <Grid item xs={1} className={classes.tableCell}>
            {Language.translate("Event")}
          </Grid>
          <Grid item xs={1} className={classes.tableCell}>
            {Language.translate("Alerts")}
          </Grid>
          <Grid item xs={1} className={classes.tableCell}>
            {Language.translate("Speed")}
          </Grid>
          <Grid item xs={1} className={classes.tableCell}>
            {Language.translate("Altitude")}
          </Grid>
          <Grid item xs={1} className={classes.tableCell}>
            {Language.translate("Course")}
          </Grid>
        </Grid>
        <Grid xs={12} className={classes.container}>
          {reportState.reportTypeDisplaying === 1 &&
            reportState.tableLoaded &&
            reportState.showLatestReportsTable &&
            filteredReportData
              .slice(
                reportState.pagination.startIndex,
                reportState.pagination.endIndex
              )
              .map((row) => (
                <Grid item container className={classes.tableRow}>
                  <Grid item xs={3} className={classes.tableCell}>
                    {row.name}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {row.type}
                  </Grid>
                  <Grid item xs={2} className={classes.tableCell}>
                    {Clock.formatTimestamp(row.report_timestamp)}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {olMap.formatCoordinate([row.longitude, row.latitude])}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {filterList["report"].title}
                  </Grid>
                  <Grid item xs={1} className={classes.alerts}>
                    {alerts.map((alert) => {
                      if (Alert.getDeviceAlert(row.id, alert)) {
                        return (
                          <CustomTooltip
                            title={Language.translate(alert)}
                            placement="top"
                            className={classes[`${toLower(alert)}`]}
                          >
                            <span>
                              <CustomIcon
                                type={toLower(alert)}
                                id={`icon-${toLower(alert)}`}
                                fontSize="small"
                              />
                            </span>
                          </CustomTooltip>
                        );
                      }
                    })}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {row.speed != null ? row.speed.toFixed(0) : "N/A"}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {row.altitude != null ? row.altitude : "N/A"}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {row.heading != null
                      ? GuiUtils.ConvertHeadingToString(row.heading)
                      : "N/A"}
                  </Grid>
                </Grid>
              ))}
          {reportState.reportTypeDisplaying === 2 &&
            reportState.tableLoaded &&
            !reportState.showLatestReportsTable &&
            filteredReportData
              .slice(
                reportState.pagination.startIndex,
                reportState.pagination.endIndex
              )
              .map((row) => (
                <Grid item container className={classes.tableRow}>
                  <Grid item xs={3} className={classes.tableCell}>
                    {row.device_name}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {row.device_type}
                  </Grid>
                  <Grid item xs={2} className={classes.tableCell}>
                    {Clock.formatTimestamp(row.event_timestamp)}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {Reports.getEventCoordinate(row)}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className={`${classes.tableCell} ${classes.events}`}
                  >
                    {row.alert_started != undefined ? (
                      <CustomTooltip
                        title={Reports.getStatusOfEvent(row)}
                        placement="top"
                        className="eventTypes"
                      >
                        <span className={Reports.getStatusOfEvent(row)}></span>
                      </CustomTooltip>
                    ) : null}
                    {row.event === "vehicle" || row.event === "emergency"
                      ? Language.translate(getEventDetails(row))
                      : Language.translate(filterList[row.event].title)}
                  </Grid>
                  <Grid item xs={1} className={classes.alerts}>
                    {alerts.map((alert) => {
                      if (getDeviceAlert(row.id, alert)) {
                        return (
                          <CustomTooltip
                            title={Language.translate(alert)}
                            placement="top"
                            className={classes[`${toLower(alert)}`]}
                          >
                            <span>
                              <CustomIcon
                                type={toLower(alert)}
                                id={`icon-${toLower(alert)}`}
                                fontSize="small"
                              />
                            </span>
                          </CustomTooltip>
                        );
                      }
                    })}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {Historic.getReportById(row.report_id) &&
                    Historic.getReportById(row.report_id).speed != null
                      ? Historic.getReportById(row.report_id).speed.toFixed(0)
                      : "N/A"}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {Historic.getReportById(row.report_id) &&
                    Historic.getReportById(row.report_id).altitude != null
                      ? Historic.getReportById(row.report_id).altitude
                      : "N/A"}
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    {Historic.getReportById(row.report_id) &&
                    Historic.getReportById(row.report_id).heading != null
                      ? GuiUtils.ConvertHeadingToString(
                          Historic.getReportById(row.report_id).heading
                        )
                      : "N/A"}
                  </Grid>
                </Grid>
              ))}
          {filteredReportData.length === 0 && (
            <Grid item container className={classes.tableRow}>
              <Grid
                item
                alignItems="center"
                justifyContent="center"
                xs={12}
                className={clsx(classes.tableCell, classes.noResults)}
              >
                {Language.translate("No results")}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item className="pagination" xs={12} style={{ paddingTop: 5 }}>
        <CustomPagination
          pagination={reportState.pagination}
          onPageChange={onPageChange}
        />
      </Grid>
    </Grid>
  );
}

export default DataTable;
