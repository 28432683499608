import React, { useState, useContext } from "react";

// External
import _ from "lodash";
import clsx from "clsx";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Internal
import AssetGroup from "./AssetGroup";
import { CustomAssetSelectorContext } from "./CustomAssetSelectorContext";
import { customAssetSelector } from "@Styles/CustomAssetSelector";

export default function CustomAssetSelector(props) {
  const groups = props.collection;
  // is in History window
  const isHistory = props.isHistory;
  const showDeviceSearchField = props.showDeviceSearchField;

  const [assetSelectorState, setAssetSelectorState] = useContext(
    CustomAssetSelectorContext
  );
  const name = props.name;
  const hideDeviceCheckbox = assetSelectorState.hideDeviceCheckbox;
  const selectionGroups = props.selectionGroups;
  const selectionDevices = props.selectionDevices;
  const setStateForDeviceSelection =
    assetSelectorState.setStateForDeviceSelection;
  const setStateForGroupSelection =
    assetSelectorState.setStateForGroupSelection;
  const setStateForDeviceFromDevice =
    assetSelectorState.setStateForDeviceFromDevice;
  const setStateForSelection = assetSelectorState.setStateForSelection;

  const addOtherGroupsToSelection =
    assetSelectorState.addOtherGroupsToSelection;
  const removeOtherGroupsFromSelection =
    assetSelectorState.removeOtherGroupsFromSelection;

  const selection = assetSelectorState.selection[name];

  function handleSelectedGroups(group, name) {
    // Check if the group clicked is already checked and in state groups array
    const alreadyChecked = selectionGroups.indexOf(group.id) > -1;

    var selection2 = _.isUndefined(_.clone(assetSelectorState.selection[name]))
      ? { groups: [], devices: [] }
      : _.clone(assetSelectorState.selection[name]);

    let selection = recursiveGroups(group, selection2, alreadyChecked);
    
    setStateForDeviceSelection(selection.devices, name);
    setStateForGroupSelection(selection.groups, name);

    function recursiveGroups(group, selection, removeAll) {
      if (removeAll) {
        // Remove ids from arrays
        selection.groups = selection.groups.filter((n) => n != group.id);
        selection.devices = selection.devices.filter(
          (n) => !new Set(group.devices).has(n)
        );
        removeOtherGroupsFromSelection(
          selection,
          _.difference(group.devices, selection.devices)
        );
      } else {
        // Add ids to arrays
        selection.groups = _.uniq(selection.groups.concat([group.id]));
        selection.devices = _.uniq(selection.devices.concat(group.devices));

        //selectinf other groups based on device selected
        addOtherGroupsToSelection(selection);
      }

      // Loop through each group and run recursive again to add id's for group and devices array
      group.groups.forEach((group) => {
        selection = recursiveGroups(group, selection, alreadyChecked);
      });
      return selection;
    }
  }

  const useStyles = makeStyles((theme) => ({
    ...customAssetSelector(theme),
  }));
  const classes = useStyles();

  return (
    <div
      className={
        isHistory
          ? clsx(
              classes.customAssetSelector,
              classes.historyCustomAssetSelector
            )
          : classes.customAssetSelector
      }
    >
      {groups && (
        <AssetGroup
          groups={groups}
          selectionGroups={selectionGroups}
          selectionDevices={selectionDevices}
          handleSelectedGroups={handleSelectedGroups}
          name={name}
          showGroupRadioButtons={props.showGroupRadioButtons}
          editMode={props.editMode}
          hideDevices={props.hideDevices}
          devicesButtons={props.devicesButtons}
          groupButtons={props.groupButtons}
          openMain={props.openMain}
          handleItemEdit={props.handleItemEdit}
          showSelectedOnly={props.showSelectedOnly}
          showDeviceSearchField={showDeviceSearchField}
          displayDeviceImei={props.displayDeviceImei}
        />
      )}
    </div>
  );
}
