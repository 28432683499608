import { default as OlMapOverlay } from "sccOlMapOverlay";
const _ = require("lodash");
const olStyleStyle = require("ol/style/Style").default;
const olStyleFill = require("ol/style/Fill").default;
const olStyleStroke = require("ol/style/Stroke").default;
//const OlMapOverlay= require("sccOlMapOverlay");
const Historic = require("sccHistory").default;
const olStyleCircle = require("ol/style/Circle").default;
const olFeature = require("ol/Feature").default;
const olGeomLineString = require("ol/geom/LineString").default;
const olGeomMultiPoint = require("ol/geom/MultiPoint").default;

// the diameter in pixels to use to size the icon
const iconDiameter = 16;

class HistoryTrailOverlay extends OlMapOverlay.OlMapOverlay {
  constructor(options) {
    super(options);
    this.moduleName = "history_trail_overlay";
  }

  init() {
    this.addOverlay();
  }

  addFeature(lastReport, nextReport) {
    const $this = this;
    const deviceId = lastReport.device_id;

    // do not show trail if device trail is off
    if (!Historic.isDeviceTrailOn(deviceId)) return;

    const coordinates = [
      $this.getCoord(lastReport),
      $this.getCoord(nextReport),
    ];
    const line = new olGeomLineString(coordinates);

    const id = this.getId(lastReport);
    const feature = new olFeature({
      geometry: line,
      id: id,
    });

    feature.setId(id);

    feature.set("overlayId", $this.id);

    this.source.addFeatures([feature]);
  }

  addFeatures(reportArray) {
    _.each(reportArray, (deviceReports) => {
      for (let i = 0; i < deviceReports.length - 1; ++i) {
        this.addFeature(deviceReports[i], deviceReports[i + 1]);
      }
    });
  }

  /**
   *
   */
  refresh(lastReport, nextReport, remove) {
    if (!remove) {
      this.addFeature(lastReport, nextReport);
    } else {
      const id = this.getId(nextReport);
      const feature = this.getFeature(id);
      if (!feature) return;
      this.removeFeature(feature);
    }
  }

  /**
   * gets the style for showing a feature
   * @param {Object} feature feature that is being styled
   * @return {Object} style to show the feature
   * @override
   */
  getStyle(feature) {
    super.getStyle();

    return getStyle(feature);
  }

  /**
   * gets the style for select icon and selected feature
   * @param {Object} feature clone of the selected feature
   * @return {Object} style to show the feature
   * @override
   */
  getSelectStyle(feature) {
    super.getSelectStyle(feature);
    // if feature has id, then it is the selected feature and must be
    // styled as before.
    // if feature does not have id, then it is the select icon

    if (feature.getId()) {
      return getStyle(feature);
    }

    return;
  }

  /**
   * gets the coordinates for the center of a feature
   *
   * this method is overriden because trail popup would need to show
   * on the first point of the linestring segment.
   *
   * @param {Object} feature openlayers feature object
   * @override
   */
  getFeatureCenter(feature) {
    const geom = feature.getGeometry();
    const coord = geom.getCoordinates()[0];
    return coord;
  }
}

function getStyle(feature) {
  const fill = new olStyleFill({ color: getAlertColor(feature) });

  const styleLine = new olStyleStyle({
    stroke: new olStyleStroke({
      color: getAlertColor(feature),
      width: 2,
    }),
    fill: new olStyleFill({
      color: fill,
    }),
  });

  const stylePoint = new olStyleStyle({
    image: new olStyleCircle({
      radius: iconDiameter / 2,
      fill: fill,
    }),
    geometry: function (feature) {
      // we are only showing the start point of the line segment
      // which is the current event.
      const coordinates = feature.getGeometry().getCoordinates();
      return new olGeomMultiPoint([coordinates[0]]);
    },
  });

  return [styleLine, stylePoint];
}

/**
 * gets the alert color to be used for text outline
 * @param {Number} feature feature object
 * @return {String} hex representation of the alert color
 */
function getTextOutlineColor(feature) {
  const Alert = require("sccAlert").default;
  const report = Historic.getReportById(feature.getId());
  const deviceId = report.device_id;
  return Alert.getDeviceAlertColor(deviceId);
}

/**
 * gets the alert color for a feature
 * @param {Number} feature feature object
 * @return {String} colort tag string
 */
function getAlertColor(feature) {
  const Alert = require("sccAlert");
  const Device = require("sccDevice").default;
  let colorTag = null;
  const report = Historic.getReportById(feature.getId());

  if (!report) return "black";

  if (Historic.hasAlert(report, "emergency")) {
    colorTag = "Emergency";
  } else if (Historic.hasAlert(report, "geofence")) {
    colorTag = "Geofence";
  } else if (Historic.hasAlert(report, "speed")) {
    colorTag = "Speed";
  } else if (Historic.hasAlert(report, "cargo")) {
    colorTag = "Cargo";
  } else if (Historic.hasAlert(report, "vehicle")) {
    colorTag = "Vehicle";
  }

  if (!colorTag) return "black";
  const alertColor = Alert.default.getAlertColor(colorTag);

  return alertColor;
}

export default new HistoryTrailOverlay({
  OlMap: require("sccOlMapHistory").default,

  id: "history_trail_overlay",

  // data property to be used as unique key of feature
  // by defaul it looks for a "id" as key
  uniquekey: "report_id",

  // overlay title
  title: "History Trails",

  noSwitcherOption: true,

  zIndex: 120,

  // whether or not features of the layer are selectable
  isSelectable: true,

  /**
   * Provides info for the popup window.
   * Popup would be disabled if this property is not provided
   */
  popup: {
    containerId: "olMapHistoryTrailPopup",
    offset: [iconDiameter / 2 + 5, -55],
  },
});
//export default new HistoryTrailOverlay();
