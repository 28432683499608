import * as Yup from "yup";
import baseSchema from "./BaseValidation";

const Profile = require("sccProfile").default;
const Client = require("sccClient").default;
const Language = require("sccLanguage").default;

const schema = Yup.object().shape({
  
	enabled: Yup.boolean(),
	title: baseSchema(Language).requiredStringMinMax,
	alerts: Yup.array().test({
    name: "alerts_test",
    exclusive: true,
    message: Language.translate("Required"),
    test: (value) => value && value.length > 0,
  }),
	// alerts:
	// subscribers:
	// members: 

  // device_count: baseSchema.requiredNumberPositive,
  // radius: baseSchema.requiredNumberPositive,
	// report_age: baseSchema.requiredNumberPositive,

  // device_count: Yup.number()
  //   .min(0)
  //   .max(Client.get(Profile.get().client_id).devices.length, Language.translate("Device limit cannot exceed the device limit")),
});

export default schema;
