import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext, getIn } from "formik";

// Material-UI
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";

// Components
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomRadioButton from "@Components/CustomRadioButton";
import CustomSwitch from "@Components/CustomSwitch";
import CustomTextField from "@Components/CustomTextField";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";
import theme from "@Styles/theme";

// Internal
import AdminDevice from "sccAdminDevice";
import Client from "sccClient";
import Device from "sccDevice";
import Feed from "sccFeed";
import Language from "sccLanguage";
import { AppContext } from "../../../AppContext";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  encryptionButtonsToggle: {
    marginTop: 10,
    padding: "10px 5px",
    width: 175,
  },
  encryptionButtons: {
    padding: "10px 5px",
    width: "calc(100% - 10px)",
    "& .encryptionButtonsGenerate": {
      float: "left",
    },

    "& .encryptionButtonsCancel": {
      float: "right",
    },
  },
  adminDeviceFeatures: {
    borderBottom: `1px solid ${theme.palette.colors.gray.dark}`,
    "& h4": {
      fontSize: "1.2rem",
      width: "100%",
    },
    "& .MuiFormControlLabel-root": {
      width: "calc(25% - 7px)",
    },
    "& .MuiFormGroup-root": {
      display: "initial",
      "& .MuiFormControlLabel-root": {
        width: "calc(25% - 7px)",
      },
    },
  },
  noForm: {
    paddingTop: 30,
    height: 45,
    width: "-webkit-fill-available",
    fontSize: "1.25rem",
    color: theme.palette.colors.blue.dark,
    backgroundColor: theme.palette.colors.blue.light,
    textAlign: "center",
  },
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function AdminDeviceForm(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  const formik = useFormikContext();

  const [deviceTypeFeatures, setDeviceTypeFeatures] = useState({});
  const [checkedFeeds, setCheckedFeeds] = useState([]);
  const [toggleCryptFields, setToggleCryptFields] = useState(false);
  const [appState] = useContext(AppContext);

  //to be used in add edit

  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const newDevice = {
    client_id: null,
    imei: "",
    tx_id: "",
    sms: null,
    type_id: null,
    type: null,
    name: "",
    mode: 0,
    encryption_key: "",
    decryption_key: "",
    device_incoming_mode: null,
    device_outgoing_mode: null,
    registration_status: 1,
    error_message: null,
    active: false,
    color: theme.palette.colors.black.main,
    latest_sa_timestamp: 0,
    reset_device_status: "ready",
    ale_address: null,
    Serial_No: "",
    ip_address: null,
    feed_codes: [1],
    device_configurations: {
      apn_host: null,
      apn_user: null,
      apn_password: null,
      sos_number: null,
      interval: 1800,
      iridex_pin: null,
      zigBee_id: null,
    },
    comm_id: null,
  };

  useEffect(() => {
    const deviceData = mwState.moduleItemData;
    if (deviceData.type_id) {
      deviceData.type = AdminDevice.getDeviceType(deviceData.type_id).title;
    }
    setMwState((p) => ({
      ...p,
      moduleItemData: {
        ...newDevice,
        ...deviceData,
      },
      moduleItemDataOriginal: {
        ...newDevice,
        ...deviceData,
      },
    }));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(moduleItemData)) {
      if (!_.isNull(moduleItemData.type_id)) {
        setCheckedFeeds(moduleItemData.feed_codes);
        setDeviceTypeFeatures(
          AdminDevice.getDeviceType(moduleItemData.type_id)
        );

        if (moduleItemData.type_id) {
          const device_incoming_modes = Object.values(
            AdminDevice.getDeviceType(moduleItemData.type_id)
              .device_incoming_modes
          ).filter((deviceType) => deviceType.default === true);
          formik.setFieldValue(
            "device_incoming_mode",
            device_incoming_modes.length > 0
              ? device_incoming_modes[0].id
              : null
          );
          let device_outgoing_mode_selection = null;
          if (
            AdminDevice.getDeviceType(moduleItemData.type_id).components &&
            AdminDevice.getDeviceType(moduleItemData.type_id).components
              .communication_mode_pairing &&
            device_incoming_modes.length > 0
          ) {
            device_outgoing_mode_selection = device_incoming_modes[0].id;
          }
          if (device_outgoing_mode_selection == null) {
            const device_outgoing_modes = Object.values(
              AdminDevice.getDeviceType(moduleItemData.type_id)
                .device_outgoing_modes
            );
            device_outgoing_mode_selection =
              device_outgoing_modes.length > 0
                ? device_outgoing_modes[0].id
                : null;
          }
          formik.setFieldValue(
            "device_outgoing_mode",
            device_outgoing_mode_selection
          );
          formik.setFieldValue(
            "type",
            AdminDevice.getDeviceType(moduleItemData.type_id).title
          );
        }
      }
    }
  }, [moduleItemData.type_id]);

  function specialTAGCase(e) {
    formik.setFieldValue("imei", e.target.value.toUpperCase());
    upperCase(e);
  }
  function upperCase(e) {
    e.target.value = e.target.value.toUpperCase();
    handleFormInput(e);
  }

  const handleDateBlur = () => {};

  const handleRadioButtons = (e) => {
    formik.setFieldValue(e.target.name, Number(e.target.value));
    const gatewayDeviceModeID = 8;
    if (Number(e.target.value) == gatewayDeviceModeID)
      if (!isHermesModuleAccessible()) {
        //gateway device
        formik.setFieldValue(e.target.name, 0);

        appState.displaySnackbarMessage({
          title: "Denied",
          message: Language.translate(
            "Customer access to Hermes Gateways feature is required."
          ),
          subMessage: null,
          variant: "error",
        });
      }
  };

  const isHermesModuleAccessible = () => {
    //console.log("device mode ", AdminDevice.getDeviceMode(moduleItemData));
    const features = Client.get(moduleItemData.client_id).features;

    if (!features.includes(12)) {
      //client does not have access to Hermes Gateways module
      return false;
    }
    return true;
  };

  const handleFeed = (id) => {
    const currentIndex = _.isUndefined(checkedFeeds)
      ? -1
      : checkedFeeds?.indexOf(id);
    var newChecked;
    if (!_.isUndefined(checkedFeeds)) {
      newChecked = [...checkedFeeds];
    } else {
      newChecked = [];
    }
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedFeeds(newChecked);
    formik.setFieldValue("feed_codes", newChecked);
  };

  const generateEncryption = (cancel) => {
    if (cancel) {
      formik.setFieldValue("encryption_key", "");
      formik.setFieldValue("decryption_key", "");
    } else {
      return Device.generateAes256().then(function (key) {
        formik.setFieldValue("encryption_key", key);
        formik.setFieldValue("decryption_key", key);
      });
    }
  };

  const checkDefaultFeed = (feed) => {
    return false;
  };

  const updateIMEIstring = () => {
    if (
      ["Trellisware TSM TW-950"].includes(
        AdminDevice.getDeviceType(moduleItemData.type_id).title
      )
    ) {
      moduleItemData.imei = moduleItemData.Serial_No + "II000";
    } else {
      moduleItemData.imei =
        moduleItemData.Serial_No + "II" + moduleItemData.ale_address;
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <Grid container xs={12} spacing={1}>
        <Grid item xs={12} className="switchWrap">
          <CustomSwitch
            label2={
              moduleItemData.active
                ? Language.translate("active").toUpperCase()
                : Language.translate("inactive").toUpperCase()
            }
            name="active"
            value={Boolean(moduleItemData.active)}
            onChange={handleFormInput}
            checked={Boolean(moduleItemData.active)}
            color="primary"
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={12} className="pullRight">
          {`* ${Language.translate("Required fields")}`}
        </Grid>
        <Grid item xs={12}>
          <CustomDropdown
            label={Language.translate("Customer")}
            name="client_id"
            value={Number(moduleItemData.client_id)}
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={formik.touched.client_id && Boolean(formik.errors.client_id)}
            helperText={Language.translate(
              formik.touched.client_id && formik.errors.client_id
            )}
          >
            {Object.values(Client.get()).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.company}
              </MenuItem>
            ))}
          </CustomDropdown>
        </Grid>
        <Grid item xs={12}>
          <input
            type="hidden"
            value={moduleItemData.type}
            name="type"
            id="type"
          />
          <CustomDropdown
            label={Language.translate("Device Type")}
            name="type_id"
            value={Number(moduleItemData.type_id)}
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={formik.touched.type_id && Boolean(formik.errors.type_id)}
            helperText={Language.translate(
              formik.touched.type_id && formik.errors.type_id
            )}
          >
            {Object.values(AdminDevice.getDeviceType()).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </CustomDropdown>
        </Grid>
        {moduleItemData.type !== "Windows" &&
        moduleItemData.type !== "IDP-800" ? (
          moduleItemData.client_id &&
          moduleItemData.type_id && (
            <React.Fragment>
              <Grid item xs={12}>
                <CustomTextField
                  label={`${Language.translate("Name")}*`}
                  value={moduleItemData.name}
                  name="name"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={Language.translate(
                    formik.touched.name && formik.errors.name
                  )}
                />
              </Grid>
              {[
                "Sentry H6120 BM",
                "Sentry H6110 MP",
                "SAT-COM Leopard1",
                "Trellisware TSM TW-950",
                "NORTAC Orion",
              ].includes(
                AdminDevice.getDeviceType(moduleItemData.type_id).title
              ) ? (
                <Grid item xs={12}>
                  <CustomTextField
                    label={
                      AdminDevice.getDeviceType(moduleItemData.type_id).title ==
                      "NORTAC Orion"
                        ? `${Language.translate("IMEI")}*`
                        : `${Language.translate("Serial Number")}*`
                    }
                    value={moduleItemData.Serial_No}
                    name="Serial_No"
                    onChange={(e) => {
                      upperCase(e);
                      handleFormInput(e);
                    }}
                    onBlur={(e) => {
                      handleFormBlur(e);
                      if (
                        !["NORTAC Orion"].includes(
                          AdminDevice.getDeviceType(moduleItemData.type_id)
                            .title
                        )
                      )
                        updateIMEIstring();
                    }}
                    error={
                      formik.touched.Serial_No &&
                      Boolean(formik.errors.Serial_No)
                    }
                    helperText={Language.translate(
                      formik.touched.Serial_No && formik.errors.Serial_No
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <CustomTextField
                    label={`${Language.translate("IMEI")}*`}
                    value={moduleItemData.imei}
                    name="imei"
                    onChange={handleFormInput}
                    onBlur={handleFormBlur}
                    error={formik.touched.imei && Boolean(formik.errors.imei)}
                    helperText={Language.translate(
                      formik.touched.imei && formik.errors.imei
                    )}
                  />
                </Grid>
              )}
              {["NORTAC Orion"].includes(
                AdminDevice.getDeviceType(moduleItemData.type_id).title
              ) ? (
                <Grid item xs={12}>
                  <CustomTextField
                    label={`${Language.translate("Device ID")}*`}
                    value={moduleItemData.imei}
                    name="imei"
                    onChange={(e) => upperCase(e)}
                    onBlur={handleFormBlur}
                    error={formik.touched.imei && Boolean(formik.errors.imei)}
                    helperText={Language.translate(
                      formik.touched.imei && formik.errors.imei
                    )}
                  />
                </Grid>
              ) : null}
              {[
                "Sentry H6120 BM",
                "Sentry H6110 MP",
                "SAT-COM Leopard1",
              ].includes(
                AdminDevice.getDeviceType(moduleItemData.type_id).title
              ) ? (
                <Grid item xs={12}>
                  <CustomTextField
                    label={`${Language.translate("ALE Address")}*`}
                    value={moduleItemData.ale_address}
                    name="ale_address"
                    onChange={(e) => upperCase(e)}
                    onBlur={(e) => {
                      handleFormBlur(e);
                      if (
                        !["NORTAC Orion"].includes(
                          AdminDevice.getDeviceType(moduleItemData.type_id)
                            .title
                        )
                      )
                        updateIMEIstring();
                    }}
                    error={
                      formik.touched.ale_address &&
                      Boolean(formik.errors.ale_address)
                    }
                    helperText={Language.translate(
                      formik.touched.ale_address && formik.errors.ale_address
                    )}
                  />
                </Grid>
              ) : null}
              {AdminDevice.getDeviceMode(moduleItemData) ==
                "Gateway Device" && (
                <Grid item xs={12}>
                  <CustomTextField
                    label={`${Language.translate("IP Address")}*`}
                    value={moduleItemData.ip_address}
                    name="ip_address"
                    onChange={handleFormInput}
                    onBlur={handleFormBlur}
                    error={
                      formik.touched.ip_address &&
                      Boolean(formik.errors.ip_address)
                    }
                    helperText={Language.translate(
                      formik.touched.ip_address && formik.errors.ip_address
                    )}
                  />
                </Grid>
              )}
              {deviceTypeFeatures.components &&
              deviceTypeFeatures.components.iridex_pin ? (
                <Grid item xs={12}>
                  <CustomTextField
                    label={`${Language.translate("Registration Pin")}*`}
                    value={moduleItemData.device_configurations.iridex_pin}
                    name="device_configurations.iridex_pin"
                    disabled={deviceTypeFeatures.components.iridex_pin}
                  />
                </Grid>
              ) : null}

              {deviceTypeFeatures.components &&
                deviceTypeFeatures.components.zigbee_id && (
                  <Grid item xs={12}>
                    <CustomTextField
                      label={`${Language.translate("Zigbee")}*`}
                      value={moduleItemData.device_configurations.zigBee_id}
                      name="device_configurations.zigBee_id"
                      onChange={(e) => specialTAGCase(e)}
                      onBlur={handleFormBlur}
                      error={Boolean(
                        getIn(
                          formik.touched,
                          "device_configurations.zigBee_id"
                        ) &&
                          getIn(
                            formik.errors,
                            "device_configurations.zigBee_id"
                          )
                      )}
                      helperText={
                        getIn(
                          formik.touched,
                          "device_configurations.zigBee_id"
                        ) &&
                        getIn(formik.errors, "device_configurations.zigBee_id")
                      }
                    />
                  </Grid>
                )}
              {deviceTypeFeatures.components &&
                deviceTypeFeatures.components.phone_number && (
                  <Grid item xs={12}>
                    <CustomTextField
                      label={
                        deviceTypeFeatures.title === "LT-3100"
                          ? Language.translate("Telephone")
                          : Language.translate("SMS")
                      }
                      value={moduleItemData.sms}
                      name="sms"
                      onChange={handleFormInput}
                      onBlur={handleFormBlur}
                      error={formik.touched.sms && Boolean(formik.errors.sms)}
                      helperText={Language.translate(
                        formik.touched.sms && formik.errors.sms
                      )}
                    />
                  </Grid>
                )}
              {deviceTypeFeatures.components &&
                deviceTypeFeatures.components.cipher && (
                  <Button
                    className={classes.encryptionButtonsToggle}
                    variant="contained"
                    onClick={() => setToggleCryptFields(!toggleCryptFields)}
                  >
                    {Language.translate("Toggle Encryption")}
                  </Button>
                )}

              {deviceTypeFeatures.components &&
                deviceTypeFeatures.components.cipher &&
                toggleCryptFields && (
                  <>
                    <Grid item xs={12}>
                      <CustomTextField
                        label={`${Language.translate("Encryption")}*`}
                        value={moduleItemData.encryption_key}
                        name="encryption_key"
                        onChange={handleFormInput}
                        onBlur={handleFormBlur}
                        error={
                          formik.touched.encryption_key &&
                          Boolean(formik.errors.encryption_key)
                        }
                        helperText={
                          formik.touched.encryption_key &&
                          formik.errors.encryption_key
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        label={`${Language.translate("Decryption")}*`}
                        value={moduleItemData.decryption_key}
                        name="decryption_key"
                        onChange={handleFormInput}
                        onBlur={handleFormBlur}
                        error={
                          formik.touched.decryption_key &&
                          Boolean(formik.errors.decryption_key)
                        }
                        helperText={
                          formik.touched.decryption_key &&
                          formik.errors.decryption_key
                        }
                      />
                    </Grid>
                    <div className={classes.encryptionButtons}>
                      <Button
                        className={classes.encryptionButtonsGenerate}
                        color="primary"
                        variant="contained"
                        onClick={() => generateEncryption(false)}
                      >
                        {Language.translate("Generate Encryption")}
                      </Button>
                      <Button
                        className={classes.encryptionButtonsCancel}
                        color="secondary"
                        variant="contained"
                        onClick={() => generateEncryption(true)}
                        disabled={
                          deviceTypeFeatures.title === "Android" ||
                          deviceTypeFeatures.title === "iPhone"
                        }
                      >
                        {Language.translate("Cancel Encryption")}
                      </Button>
                    </div>
                  </>
                )}

              {moduleItemData.device_incoming_mode && (
                <Grid item xs={12} className={classes.adminDeviceFeatures}>
                  <h4>{`${Language.translate("Mobile Terminated")}*`}</h4>
                  <RadioGroup name="device_incoming_mode">
                    {deviceTypeFeatures.device_incoming_modes &&
                      Object.values(
                        deviceTypeFeatures.device_incoming_modes
                      ).map((item) => (
                        <CustomRadioButton
                          label={item.title}
                          value={Number(item.id)}
                          key={`mobileTerminated-${item.id}`}
                          color="primary"
                          name="device_incoming_mode"
                          checked={
                            moduleItemData.device_incoming_mode == item.id
                          }
                          onChange={(e) => handleRadioButtons(e)}
                          error={
                            formik.touched.device_incoming_mode &&
                            Boolean(formik.errors.device_incoming_mode)
                          }
                          helperText={
                            formik.touched.device_incoming_mode &&
                            formik.errors.device_incoming_mode
                          }
                        />
                      ))}
                  </RadioGroup>
                </Grid>
              )}

              {moduleItemData.device_outgoing_mode && (
                <Grid item xs={12} className={classes.adminDeviceFeatures}>
                  <h4>{`${Language.translate("Mobile Originated")}`}</h4>
                  <RadioGroup name="device_outgoing_mode">
                    {deviceTypeFeatures.device_outgoing_modes &&
                      Object.values(
                        deviceTypeFeatures.device_outgoing_modes
                      ).map((item) => (
                        <CustomRadioButton
                          label={item.title}
                          value={Number(item.id)}
                          key={`mobileOriginated-${item.id}`}
                          color="primary"
                          name="device_outgoing_mode"
                          checked={
                            moduleItemData.device_outgoing_mode == item.id
                          }
                          onChange={(e) => handleRadioButtons(e)}
                          error={
                            formik.touched.device_outgoing_mode &&
                            Boolean(formik.errors.device_outgoing_mode)
                          }
                          helperText={
                            formik.touched.device_outgoing_mode &&
                            formik.errors.device_outgoing_mode
                          }
                        />
                      ))}
                  </RadioGroup>
                </Grid>
              )}

              <Grid item xs={12} className={classes.adminDeviceFeatures}>
                <h4>{`${Language.translate("Mode")}*`}</h4>
                <RadioGroup>
                  {deviceTypeFeatures.modes &&
                    Object.values(deviceTypeFeatures.modes).map((item) => (
                      <CustomRadioButton
                        label={item.title}
                        value={item.id}
                        name="mode"
                        key={`deviceModes-${item.id}`}
                        checked={moduleItemData.mode === item.id}
                        onChange={(e) => handleRadioButtons(e)}
                        color="primary"
                      />
                    ))}
                </RadioGroup>
              </Grid>

              <Grid item xs={12} className={classes.adminDeviceFeatures}>
                <h4>{`${Language.translate("Feed")}*`}</h4>
                {Object.values(Feed.get()).map((item) => (
                  <CustomCheckbox
                    label={item.title}
                    key={`feedCode-${item.id}`}
                    name="feedCode"
                    onChange={() => handleFeed(item.id)}
                    color="primary"
                    checked={
                      !checkDefaultFeed(item) &&
                      !_.isUndefined(checkedFeeds) &&
                      checkedFeeds.indexOf(item.id) !== -1
                    }
                    disabled={item.feed_code === 128}
                  />
                ))}
              </Grid>
              {deviceTypeFeatures &&
                deviceTypeFeatures.components &&
                deviceTypeFeatures.components.apn_configurations && (
                  <React.Fragment>
                    <Grid
                      container
                      direction="row"
                      className={classes.adminDeviceFeatures}
                      spacing={1}
                    >
                      <h4>{`${Language.translate(
                        "Configuration Settings"
                      )}*`}</h4>
                      <Grid item xs={6}>
                        <CustomTextField
                          label={`${Language.translate("APN Host")}*`}
                          name="device_configurations.apn_host"
                          value={moduleItemData.device_configurations.apn_host}
                          onChange={handleFormInput}
                          onBlur={handleFormBlur}
                          error={Boolean(
                            getIn(
                              formik.touched,
                              "device_configurations.apn_host"
                            ) &&
                              getIn(
                                formik.errors,
                                "device_configurations.apn_host"
                              )
                          )}
                          helperText={
                            getIn(
                              formik.touched,
                              "device_configurations.apn_host"
                            ) &&
                            getIn(
                              formik.errors,
                              "device_configurations.apn_host"
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          label={`${Language.translate("Apn User")}*`}
                          name="device_configurations.apn_user"
                          value={moduleItemData.device_configurations.apn_user}
                          onChange={handleFormInput}
                          onBlur={handleFormBlur}
                          error={Boolean(
                            getIn(
                              formik.touched,
                              "device_configurations.apn_user"
                            ) &&
                              getIn(
                                formik.errors,
                                "device_configurations.apn_user"
                              )
                          )}
                          helperText={
                            getIn(
                              formik.touched,
                              "device_configurations.apn_user"
                            ) &&
                            getIn(
                              formik.errors,
                              "device_configurations.apn_user"
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          label={`${Language.translate("APN Password")}*`}
                          name="device_configurations.apn_password"
                          value={
                            moduleItemData.device_configurations.apn_password
                          }
                          onChange={handleFormInput}
                          onBlur={handleFormBlur}
                          error={Boolean(
                            getIn(
                              formik.touched,
                              "device_configurations.apn_password"
                            ) &&
                              getIn(
                                formik.errors,
                                "device_configurations.apn_password"
                              )
                          )}
                          helperText={
                            getIn(
                              formik.touched,
                              "device_configurations.apn_password"
                            ) &&
                            getIn(
                              formik.errors,
                              "device_configurations.apn_password"
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          label={`${Language.translate("SOS Phone Number")}`}
                          name="device_configurations.sos_number"
                          value={
                            moduleItemData.device_configurations.sos_number
                          }
                          onChange={handleFormInput}
                          onBlur={handleFormBlur}
                          error={Boolean(
                            getIn(
                              formik.touched,
                              "device_configurations.sos_number"
                            ) &&
                              getIn(
                                formik.errors,
                                "device_configurations.sos_number"
                              )
                          )}
                          helperText={
                            getIn(
                              formik.touched,
                              "device_configurations.sos_number"
                            ) &&
                            getIn(
                              formik.errors,
                              "device_configurations.sos_number"
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          label={`${Language.translate(
                            "Interval"
                          )}* (${Language.translate("Seconds")})`}
                          name="device_configurations.interval"
                          value={moduleItemData.device_configurations.interval}
                          onChange={handleFormInput}
                          onBlur={handleFormBlur}
                          error={Boolean(
                            getIn(
                              formik.touched,
                              "device_configurations.interval"
                            ) &&
                              getIn(
                                formik.errors,
                                "device_configurations.interval"
                              )
                          )}
                          helperText={
                            getIn(
                              formik.touched,
                              "device_configurations.interval"
                            ) &&
                            getIn(
                              formik.errors,
                              "device_configurations.interval"
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
            </React.Fragment>
          )
        ) : (
          <div className={classes.noForm}>
            {Language.translate(
              "The form for this device is under construction!"
            )}
          </div>
        )}
      </Grid>
    </div>
  );
}
