import React, { useState } from "react";

// Material-UI
import Grid from "@material-ui/core/Grid";

// Internal
import Utils from "sccUtils";
import log from "loglevel";
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";
import "./login.scss";

export default function ForgotPassword(props) {
  //parent functions and state
  const translateText = props.translateText;
  const setErrorMessage = props.setErrorMessage;
  const setLoginState = props.setLoginState;
  const errorOnPage = props.loginState.errorOnPage;

  const [username, setUsername] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const resetFields = () => {
    setLoginState((p) => {
      return Object.assign({}, p, {
        step: 1,
        errorOnPage: 0,
        errorMessage: "",
        userId: "",
        tfaExpiry: "",
      });
    });
  };

  const submitResetPassword = (Utils, username) => {
    return fetch(Utils.apiUrlPrefix + "/auth/reset_password", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        log.info("Send forgot password email", data);
        let errorLoggingIn = false;
        if (data.message) {
          errorLoggingIn = false;
          //email code here
          //change display
          setEmailSent(true);
        } else if (data.error_info.name) {
          setErrorMessage(
            translateText("Error resetting password") +
              ": " +
              translateText("Error: User with username") +
              ": " +
              username +
              " " +
              translateText("does not exist") +
              "."
          );
          errorLoggingIn = true;
        }

        if (errorLoggingIn) {
          return Promise.resolve();
        }

        setLoginState((p) => {
          return Object.assign({}, p, {
            userId: "",
            tfaExpiry: "",
            errorOnPage: 0,
            errorMessage: "",
          });
        });
        return Promise.resolve();
      })
      .catch((e) => log.info(e));
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    submitResetPassword(Utils, username);
  };

  return (
    <form
      className="loginForm"
      id="ForgotPasswordLoginPageForm"
      name="logForm"
      onSubmit={handleSignIn}
      noValidate
      autoComplete="off"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <h4>{translateText("Forgot Password?")}</h4>
          {translateText("Enter your username below to reset password.")}
        </Grid>
        {(() => {
          if (!emailSent) {
            return (
              <React.Fragment>
                <Grid item>
                  <CustomTextField
                    id="txtUsername"
                    name="txtUsername"
                    fullWidth={true}
                    placeholder={translateText("Username")}
                    required
                    error={errorOnPage}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <CustomButton
                    fullWidth={true}
                    size="large"
                    id="btn2FactorLogin"
                    disabled={!username ? true : false}
                    onClick={handleSignIn}
                    type="submit"
                    color="command"
                  >
                    {translateText("Submit")}
                  </CustomButton>
                </Grid>
              </React.Fragment>
            );
          } else {
            return (
              <Grid item>
                <p className="alert alert-success">
                  {translateText("Success!")}&nbsp;
                  {translateText(
                    "An email was sent to you with a link to reset your password."
                  )}
                </p>
              </Grid>
            );
          }
        })()}
        <Grid item>
          <a
            href="#"
            id="btnForgotPasswordLogin"
            className="back-btn"
            onClick={() => resetFields()}
          >
            {translateText("Back to login")}
          </a>
        </Grid>
      </Grid>
    </form>
  );
}
