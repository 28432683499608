import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import HistoryIcon from "@material-ui/icons/History";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import GroupIcon from "@material-ui/icons/Group";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

// Internal
import Language from "sccLanguage";
import Profile from "sccProfile";
import HistoryOverlay from "sccHistoryOverlay";
import { HistoryContext } from "../HistoryContext";
import CustomAssetWrapper from "../../../../reusable-components/CustomAssetWrapper";
import { CustomAssetSelectorContext } from "../../../../reusable-components/CustomAssetWrapper/CustomAssetSelectorContext";

const log = require("loglevel");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
  },
  textField: {
    borderRadius: 0,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "12px",
      "& .MuiOutlinedInput-input:focus": {
        border: `1px solid ${theme.palette.colors.red.main}`,
      },
    },
  },
  button: {
    borderRadius: 0,
    fontSize: "1rem",
    width: "100%",
  },
  LeftGridItem: {
    width: "100%",
  },
  fullHeight: {
    height: "100%",
  },

  assetGridItem: {
    width: "100%",
    maxHeight: "calc(100% - 180px)",
    overflow: "hidden",
  },

  assetBoxText: {
    height: 45,
    padding: 16,
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 2,
    cursor: "pointer",
  },

  assetText: {
    padding: "0px 8px",
    fontSize: 16,
  },

  assetBoxCollapse: {
    maxHeight: "calc(100% - 45px)",
    overflowY: "auto",
  },
}));

const formatDateForCalendar = (date) => {
  const d = new Date(date);
  return (
    [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join(
      "-"
    ) +
    "T" +
    [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
  );
};

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const d = new Date().getTime();
const dateOffsetOneDay = 24 * 60 * 60 * 1000; //hr*min*sec*ms to offset one day
const dstart = formatDateForCalendar(d - dateOffsetOneDay);
const dend = formatDateForCalendar(d);

function LeftPanel() {
  const [startDate, setStartDate] = useState(dstart);
  const [endDate, setEndDate] = useState(dend);
  const [loadHistoryText, setLoadHistoryText] = useState(
    Language.translate("Load History")
  );
  const [loadHistory, setLoadHistory] = useState(false);

  const [state] = useContext(HistoryContext);
  const [assetSelectorState, setAssetSelectorState] = useContext(
    CustomAssetSelectorContext
  );
  const Historic = state.Historic;
  const updateKeysInState = state.updateKeysInState;
  const notificationOptions = state.notificationOptions;
  const setSliderTicks = state.setSliderTicks;
  // const resetTrailOverlay = state.resetTrailOverlay;
  const resetAllComponents = state.resetAllComponents;
  const selectionDevices = assetSelectorState.selection["members"]?.devices;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const displayMessage = (title, message, type) =>
    state.displayMessage(enqueueSnackbar, title, message, type);

  useEffect(() => {
    Historic.queryParams.startTimestamp = dstart;
    Historic.queryParams.endTimestamp = dend;

    const urlStr = window.location.href;
    let deviceId = null;
    try {
      const url = new URL(urlStr);
      deviceId = parseInt(url.searchParams.get("deviceId"));
    } catch (err) {
      // IE 11 does not support URL() function so we just split the url and search for device ID
      log.warn(err);
      const urlSplit = urlStr.split("=");
      deviceId = urlSplit.length > 0 ? urlSplit[1] : null;
    }

    setAssetSelectorState((assetSelectorState) => ({
      ...assetSelectorState,
      selection: {
        ...assetSelectorState.selection,
        ["members"]: { groups: [], devices: deviceId ? [deviceId] : [] },
      },
    }));
    Historic.queryParams.devices = deviceId ? [deviceId] : [];
  }, []);

  useEffect(() => {
    updateKeysInState({ showNotification: !_.isEmpty(notificationOptions) });
  }, [notificationOptions]);

  useEffect(() => {
    handleDateBlur();
  }, [selectionDevices]);

  useEffect(() => {
    setLoadHistory(
      !_.isUndefined(selectionDevices) && selectionDevices.length > 0
    );
  }, [assetSelectorState]);

  const handleDateBlur = () => {
    Historic.queryParams.startTimestamp = moment(startDate).unix() * 1000; //fixing the value retrieved from the calendar to match the format stored in historic;
    Historic.queryParams.endTimestamp = moment(endDate).unix() * 1000; //fixing the value retrieved from the calendar to match the format stored in historic
    validateHistoricDataSetSize();
  };
  const handleAcceptDate = (from, date) => {
    let tmpStartDate = startDate;
    let tmpEndDate = endDate;
    setTimeout(() => {
      if (from === "startDate") {
        tmpStartDate = formatDateForCalendar(date);
        setStartDate(formatDateForCalendar(date));
      } else {
        tmpEndDate = formatDateForCalendar(date);
        setEndDate(formatDateForCalendar(date));
      }
      Historic.queryParams.startTimestamp = moment(tmpStartDate).unix() * 1000;
      Historic.queryParams.endTimestamp = moment(tmpEndDate).unix() * 1000;
      validateHistoricDataSetSize();
    }, 1000);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    if (endDate !== null) {
      handleDateBlur();
    }
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    if (startDate !== null) {
      handleDateBlur();
    }
  };

  /*
		Validate historic data set size 
		and control enabling of search button 
		and output error or warning message via buildNotificationError   
	*/
  const validateHistoricDataSetSize = function () {
    Historic.queryParams.devices = selectionDevices;
    const devices = Historic.queryParams.devices;
    //Convert Moment object to timestamp for backend
    const startTimestamp = Historic.getStartTimestamp();
    const endTimestamp = Historic.getEndTimestamp();

    const accountStartTimestamp = Profile.get()
      ? Profile.get().client.start_timestamp
      : startTimestamp; //account creation date or current day -1
    if (startTimestamp && endTimestamp && devices && devices.length > 0) {
      //date inputs validation and return on failure
      if (
        !validateTimestamps(startTimestamp, endTimestamp, accountStartTimestamp)
      ) {
        updateKeysInState({ notificationOptions: {} });
        return;
      }

      buildNotificationOptions(Language.translate("Calculating Data"));

      //since the dates are valid we are validating the dataset
      //execute request to fetch history info
      //before enabling the load history button we are making sure the results for this query would be valid
      Historic.validateDataSet(devices, startTimestamp, endTimestamp).then(
        function (validation) {
          if (validation.type == "warning") {
            buildNotificationOptions(
              Language.translate(
                "The data requested is large and may slow down your browser"
              ),
              validation.type
            );
            setLoadHistory(true);
          } else if (validation.type == "error") {
            buildNotificationOptions(
              Language.translate(
                "The data requested is too large. Please narrow your search criteria and try again"
              ),
              validation.type
            );
            setLoadHistory(false);
          } else {
            updateKeysInState({ notificationOptions: {} });
            setLoadHistory(true);
          }
        }
      );
    } else {
      validateTimestamps(startTimestamp, endTimestamp, accountStartTimestamp);
    }
  };

  //validates client's date inputs. If incorrect, returns false
  const validateTimestamps = (
    startTimestamp,
    endTimestamp,
    accountTimestamp
  ) => {
    setLoadHistory(false); //at this time the load button is disabled and remains so
    if (isNaN(startTimestamp)) {
      displayMessage(
        Language.translate("History"),
        Language.translate("Invalid start date. Please select new start date"),
        "error"
      );
      return false;
    }
    if (isNaN(endTimestamp)) {
      displayMessage(
        Language.translate("History"),
        Language.translate("Invalid end date. Please select new end date"),
        "error"
      );
      return false;
    }
    if (startTimestamp < accountTimestamp) {
      displayMessage(
        Language.translate("History"),
        Language.translate("Invalid start date. Please select new start date"),
        "error"
      );
      return false;
    }

    if (endTimestamp < accountTimestamp) {
      displayMessage(
        Language.translate("History"),
        Language.translate("Invalid end date. Please select new end date"),
        "error"
      );
      return false;
    }

    if (moment(new Date().toString()) < moment.unix(endTimestamp)) {
      displayMessage(
        Language.translate("History"),
        Language.translate("Invalid end date. Please select new end date"),
        "error"
      );
      return false;
    }

    if (startTimestamp > endTimestamp) {
      displayMessage(
        Language.translate("History"),
        Language.translate("Invalid start date. Please select new start date"),
        "error"
      );
      return false;
    }
    return true;
  };

  function buildNotificationOptions(msg, icon = "", type = "info", time = 0) {
    updateKeysInState({
      notificationOptions: {
        msg: msg,
        icon: icon,
        type: type,
        time: time,
      },
    });
  }

  /*
   * SUBMIT FORM after submitting
   */
  const searchHistory = () => {
    //const devices = Historic.queryParams.devices;
    setLoadHistoryText(Language.translate("Loading") + "...");

    const devices = selectionDevices;

    // Historic.queryParams.startTimestamp = moment(startDate).format("X");
    // Historic.queryParams.endTimestamp = moment(endDate).format("X");
    Historic.queryParams.startTimestamp = startDate;
    Historic.queryParams.endTimestamp = endDate;

    //Convert Moment object to timestamp for backend
    const startTimestamp = Historic.getStartTimestamp();
    const endTimestamp = Historic.getEndTimestamp();

    getHistoryData(devices, startTimestamp, endTimestamp);
  };

  function getHistoryData(devices, startTimestamp, endTimestamp) {
    if (!devices || !startTimestamp || !endTimestamp) {
      throw new Error("did not receive proper arguments to calculate history");
    }

    // reset history and histroyOverlay data
    Historic.clearStructures();
    state.resetAllComponents();

    HistoryOverlay.hideClusterPopup();
    HistoryOverlay.deselectFeature();
    HistoryOverlay.reset(true);

    Historic.loadData(devices, startTimestamp, endTimestamp)
      .then(() => {
        let eventSlider_options_ticksArray = [];
        eventSlider_options_ticksArray = getEventTicksArray();
        const eventsLength = eventSlider_options_ticksArray.length;
        //setSliderTicks(eventSlider_options_ticksArray);

        if (eventsLength == 0) {
          buildNotificationOptions(
            Language.translate(
              "There are no events for the selected time range"
            )
          );
          updateKeysInState({
            showRightPanel: false,
            showLeftPanel: false,
            eventsDatasetValid: false,
          });
        } else {
          updateKeysInState({
            showHistoryControls: true,
            showRightPanel: false,
            showLeftPanel: false,
            playEvent: false,
            eventsDatasetValid: true,
            currentTimestamp: Historic.getStartTimestamp(),
            //sliderTicks: eventSlider_options_ticksArray,
          });
          setSliderTicks();
        }
      })
      .then(() => {
        setLoadHistoryText(Language.translate("Load History"));
      })
      .catch(function (error) {
        console.log("ERROR : ", error);
      });
    resetControls();
  }

  function resetControls() {
    resetAllComponents();
  }

  function getEventTicksArray() {
    const ts = {};
    const events = _.map(
      _.filter(Historic.get(), (item) => {
        const windowSize = window.innerWidth || 4000;
        const offset = Math.round(
          ((item.event_timestamp - Historic.getStartTimestamp()) * windowSize) /
            (Historic.getEndTimestamp() - Historic.getStartTimestamp())
        );
        let checkSameTimestamp = true;
        if (item.event == "report") {
          if (ts[offset]) {
            checkSameTimestamp = false;
          } else {
            ts[offset] = true;
          }
        }
        return (
          checkSameTimestamp &&
          item.show == true &&
          item.event_timestamp >= Historic.getStartTimestamp()
        );
      }),
      "event_timestamp"
    );
    return events;
  }

  const classes = useStyles();
  const [assetBoxChecked, setAssetBoxChecked] = useState(false);

  const handleAssetBoxChange = () => {
    setAssetBoxChecked((prev) => !prev);
  };
  return (
    <form className={classes.container} noValidate>
      <Grid
        container
        spacing={2}
        direction="column"
        className={classes.fullHeight}
      >
        <Grid item className={classes.LeftGridItem}>
          <TextField
            id="datetime-local"
            variant="outlined"
            label={Language.translate("Start Date")}
            type="datetime-local"
            value={startDate}
            onChange={(e) => handleStartDate(e)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item className={classes.LeftGridItem}>
          <TextField
            id="datetime-local"
            variant="outlined"
            label={Language.translate("End Date")}
            type="datetime-local"
            value={endDate}
            onChange={(e) => handleEndDate(e)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item className={classes.assetGridItem}>
          <Paper
            onClick={handleAssetBoxChange}
            className={classes.assetBoxText}
          >
            <GroupIcon />
            <span className={classes.assetText}>
              {Language.translate("Assets")}
            </span>
            {assetBoxChecked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Paper>
          <Collapse
            in={assetBoxChecked}
            classes={{
              entered: classes.assetBoxCollapse,
            }}
          >
            <CustomAssetWrapper
              editMode={true}
              name="members"
              isHistory={true}
              height="100%"
              openMain={true}
            />
          </Collapse>
        </Grid>
        <Grid item className={classes.LeftGridItem}>
          <Button
            variant="contained"
            disabled={!loadHistory}
            color="primary"
            startIcon={<HistoryIcon />}
            onClick={searchHistory}
            className={classes.button}
          >
            {loadHistoryText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default LeftPanel;
