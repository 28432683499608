
var Utils= require("sccUtils").default;
var Language= require("sccLanguage").default;


	function initGoogleMapLicense(){ //fetch google map API key from sccnode.env
		var EnvVar= require("sccEnvVar").default;
		var key=EnvVar.get("GOOGLE_MAPS_KEY");
	
		if(!(key===undefined)||(key===null)){ //if GOOGLE_MAPS_KEY variable exists in sccnode.env, but key is empty
			if(!key.length) //prevents google map from being loaded under google maps API development mode as is the case with empty key.
				key=undefined;
		}
		
		if(window.name=="History" || window.name.includes("Extended")){
			attachInitMapsScriptToWindow();
		}
		
	
		var googleMapAPIcallStartTime;
		function attachGoogleMapsAPItoDocWhenReady(){
			setTimeout(()=>{
				if(!window.initMaps)
				attachGoogleMapsAPItoDocWhenReady();
				else{
					attachGoogleMapsAPItoDocument(key);
					googleMapAPIcallStartTime=Date.now();
					console.log("checking for google maps API response");
					checkForGoogleMapsAPIresponse();
				}	
			},50);
		}

		attachGoogleMapsAPItoDocWhenReady();
	
		function checkForGoogleMapsAPIresponse(){

			setTimeout(() => {
		
				var timeSinceGoogleMapsAPIrequest=Date.now()-googleMapAPIcallStartTime; //in milliseconds

				if(!window.googleMapsAPIloaded && timeSinceGoogleMapsAPIrequest>5000){ //5 second waiting duration for Google Maps API response. 																				
					window.googleMapsAPIresponseWaitExpired=true;																				
					require("sccOlMapNew").default.doAfterGMapsAPIloadAttempted();																							
					return true;																									
				}
				else if (!window.googleMapsAPIloaded){
		
					checkForGoogleMapsAPIresponse();
		
				}
				else { //response acquired
					console.log("google maps API responded");
					window.googleMapsAPIloaded=true;	
					require("sccOlMapNew").default.doAfterGMapsAPIloadAttempted();			
					return true;
				}
			}, 100); //check every 100ms for the duration of 5 seconds max.
		}
		
	}
	
	const attachGoogleMapsAPItoDocument=(key)=>{
			var script = document.createElement("script");
			script.src = "https://maps.googleapis.com/maps/api/js?v=beta&key="+key+"&callback=initMaps"; //callback: window.initMaps()
			document.head.appendChild(script);
	}
	
	function attachInitMapsScriptToWindow(){
		window.initMaps=function(){
			window.googleMapsAPIloaded=true;
		};
	
		window.gm_authFailure=function(){ //Google Maps API automatically searches for a method by the name "gm_authFailure" and 
			//invokes it upon key invalidation.
			window.invalidGoogleMapKey=true;
			require("sccOlMapNew").default.BaseLayer.giveOSMlayerAccessAndMakeVisible();
		
		};
	}

module.exports={
	initGoogleMapLicense: initGoogleMapLicense
};