import React, { useState, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import { Grid, makeStyles} from "@material-ui/core";

// Internal
import Clock from "sccClock";
import Language from "sccLanguage";
import TimeUtils from "sccTimeUtils";


const useStyles = makeStyles((theme) => ({
  deviceContentLayer: {
    height: 23,
    borderTop: `1px solid ${theme.palette.colors.gray.main}`,
  },

  deviceContentLeft: {
    fontSize: 12,
    padding: "2px 6px",
    textAlign: "left",
    backgroundColor: theme.palette.colors.gray.main,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  deviceContentRight: {
    fontSize: 13,
    padding: "2px 6px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "right",
  },
}));

const WaveLastSASent = ({ device }) => {
  const classes = useStyles();
  const [lastSASent, setLastSASent] = useState("N/A");

  // update last SA sent age state
  const calcAge = (rt) => {
    setLastSASent(TimeUtils.getAge(rt));
  };

  useEffect(() => {
    //  update last SA sent age every one second
    if (device.latest_sa_timestamp) {
      const interval = setInterval(
        () => calcAge(device.latest_sa_timestamp),
        1000
      );

      calcAge(device.latest_sa_timestamp);

      return () => {
        clearInterval(interval);
      };
    } else {
      setLastSASent("N/A");
    }
  }, [device]);

  return (
    <Grid container className={classes.deviceContentLayer}>
      <Grid item xs={5} className={classes.deviceContentLeft}>
        {Language.translate("Last SA Sent").toUpperCase()}
      </Grid>
      <Grid item xs={7} className={classes.deviceContentRight}>
        {lastSASent || "N/A"}
      </Grid>
    </Grid>
  );
};

export default WaveLastSASent;
