import * as Yup from "yup";
import baseSchema from "./BaseValidation";
import Language from "sccLanguage";

const schema = Yup.object().shape({
  start_date: Yup.number()
    .typeError(Language.translate("Select a valid start date"))
    .positive()
    .lessThan(
      Yup.ref("end_date"),
      Language.translate("Start date must be before end date")
    )
    .required(),
  end_date: Yup.number()
    .typeError(Language.translate("Select a valid end date"))
    .positive()
    .moreThan(
      Yup.ref("start_date"),
      Language.translate("End date must be after start date")
    ),
});

export default schema;
