import React, { useState } from "react";

import { ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";

const Utils = require("sccUtils");
const log = require("loglevel");

let helperFunctions = {};

export default function TwoFactorLogin(props) {
  //parent functions and state
  const translateText = props.translateText;
  const setErrorMessage = props.setErrorMessage;
  const setLoginState = props.setLoginState;
  const loginState = props.loginState;
  const errorOnPage = loginState.errorOnPage;

  const [code, setCode] = useState("");

  helperFunctions = {
    translateText,
    setErrorMessage,
    setLoginState,
    errorOnPage,
    loginState,
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    submit2FaCode(Utils, helperFunctions, code);
    return false;
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <form
          className="loginForm"
          id="2FactorAuthLoginPageForm"
          name="logForm"
          noValidate
          autoComplete="off"
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <h4>{translateText("Two Factor Authentication")}</h4>
              <p>
                {translateText(
                  "Enter Two Factor Authentication Code to Sign In."
                )}
              </p>
            </Grid>
            <Grid item>
              <CustomTextField
                id="txt2FactorAuth"
                name="text"
                fullWidth={true}
                placeholder={translateText("Code")}
                required
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Grid>
            <Grid item>
              <CustomButton
                fullWidth
                size="large"
                color="command"
                id="btn2FactorLogin"
                disabled={!code ? true : false}
                onClick={handleSignIn}
                type="submit"
              >
                {translateText("Sign In")}
              </CustomButton>
            </Grid>
            <Grid item>
              <a
                href="#"
                id="btn2FactorBackLogin"
                className="back-btn"
                onClick={() => resetFields(helperFunctions)}
              >
                {translateText("Back to login")}
              </a>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

const resetFields = (helperFunctions) => {
  helperFunctions.setLoginState((p) => {
    return Object.assign({}, p, {
      step: 1,
      errorOnPage: 0,
      errorMessage: "",
      userId: "",
      tfaExpiry: "",
    });
  });
};

/**
 * 2nd level Authentication where it is active for the user
 */
function submit2FaCode(Utils, helperFunctions, code) {
  const userId = helperFunctions.loginState.userId;
  const tfaCode = code;
  return fetch(Utils.default.apiUrlPrefix + "/auth/2fa", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ tfa_code: tfaCode, user_id: userId }),
  })
    .then((res) => res.json())
    .then((data) => {
      let errorLoggingIn = false;
      if (data.message) {
        errorLoggingIn = false;
      } else if (data.error_info.name) {
        helperFunctions.setErrorMessage(
          helperFunctions.translateText(data.error)
        );
        errorLoggingIn = true;
      }

      if (errorLoggingIn) {
        helperFunctions.setLoginState((p) => {
          return Object.assign({}, p, {
            step: 1,
            userId: "",
            tfaExpiry: "",
          });
        });
        return Promise.resolve();
      }
      log.info("2FA Successful:", data);

      const dateAfter1Week = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
      Utils.default.setCookie("accesstoken", data.token, {
        path: "/",
        expires: dateAfter1Week,
      });
      Utils.default.setCookie("userrole", data.role_id, {
        path: "/",
        expires: dateAfter1Week,
      });
      console.log("Login Level 2 achieved. User logged in and cookie saved");
      window.location.replace("/t24.html");

      return Promise.resolve();
    })
    .catch((e) => {
      log.debug("Two Factor Error:", e);
    });
}
