import React from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from "@material-ui/icons/Minimize";
import MaximizeIcon from "@material-ui/icons/Maximize";

// Internal
import ChatOverlay from "..";
import Device from "sccDevice";
import Images from "sccImage";
import CustomTooltip from "@Components/CustomTooltip";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  DataDisplayHeader: {
    background: theme.palette.colors.gray.main,
    boxShadow: "none",
    color: theme.palette.colors.blue.dark,
  },

  DataDisplayHeaderToolbar: {
    minHeight: 30,
    padding: "1px 4px",
  },

  DataDisplayHeaderAvatar: {
    width: 30,
    height: 30,
    borderRadius: "inherit",
    backgroundColor: "transparent",
    justifyContent: "flex-start",
  },

  title: {
    flexGrow: 1,
    fontSize: 18,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
  },

  DataDisplayHeaderImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },

  DataDisplayHeaderIcon: {
    color: alpha(theme.palette.colors.black.main, 0.25),
  },

  DataDisplayHeaderButton: {
    minWidth: 30,
    width: 30,
    height: 30,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
      "& $DataDisplayHeaderIcon": {
        color: theme.palette.colors.blue.dark,
      },
    },
  },
}));

export default function ChatHeader(props) {
  const minimize = props.minimize;

  const classes = useStyles();

  const obj = props.device.id ? props.device : Object.values(props.device)[0];

  const handleClose = () => {
    ChatOverlay.hideChatWindow();
  };

  const showChat = () => {
    ChatOverlay.maximizeChatWindow();
  };
  const hideChat = () => {
    ChatOverlay.minimizeChatWindow();
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.DataDisplayHeader}>
        <Toolbar className={classes.DataDisplayHeaderToolbar}>
          <Avatar className={classes.DataDisplayHeaderAvatar}>
            <img
              className={classes.DataDisplayHeaderImage}
              alt={Images.get(Device.getDeviceType(obj.type_id).image_id).name}
              src={Images.getImageSource(
                Device.getDeviceType(obj.type_id).image_id
              )}
            />
          </Avatar>
          <Typography variant="h6" className={classes.title}>
            {obj.name}
          </Typography>
          <CustomTooltip
            title={
              minimize
                ? Language.translate("Maximize")
                : Language.translate("Minimize")
            }
            arrow
            placement="top"
          >
            {minimize ? (
              <IconButton
                size="medium"
                color="inherit"
                onClick={showChat}
                className={classes.DataDisplayHeaderButton}
              >
                <MaximizeIcon className={classes.DataDisplayHeaderIcon} />
              </IconButton>
            ) : (
              <IconButton
                size="medium"
                color="inherit"
                onClick={hideChat}
                className={classes.DataDisplayHeaderButton}
              >
                <MinimizeIcon className={classes.DataDisplayHeaderIcon} />
              </IconButton>
            )}
          </CustomTooltip>
          <CustomTooltip
            title={Language.translate("Close")}
            arrow
            placement="top"
          >
            <IconButton
              size="medium"
              color="inherit"
              onClick={handleClose}
              className={classes.DataDisplayHeaderButton}
            >
              <CloseIcon className={classes.DataDisplayHeaderIcon} />
            </IconButton>
          </CustomTooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}
