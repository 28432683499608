import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import theme from "./styles/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import CustomSnackbar from "@Components/CustomSnackbar";
import "./styles/global.scss";
import { AppContextProvider } from "./AppContext";
import { CustomAssetSelectorContextProvider } from "./reusable-components/CustomAssetWrapper/CustomAssetSelectorContext";
import { ModuleWrapperContextProvider } from "./reusable-components/ModuleWrapper/ModuleWrapperContext";
import UserSettingContextProvider from "./modules/user_setting/context/UserSettingContext";
import SelectedGeofenceContextProvider from "./modules/geofence/components/context/SelectedGeofenceContext";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CustomSnackbar>
      <AppContextProvider>
        <ModuleWrapperContextProvider>
          <UserSettingContextProvider>
            <SelectedGeofenceContextProvider>
              <CustomAssetSelectorContextProvider>
                <App />
              </CustomAssetSelectorContextProvider>
            </SelectedGeofenceContextProvider>
          </UserSettingContextProvider>
        </ModuleWrapperContextProvider>
      </AppContextProvider>
    </CustomSnackbar>
  </ThemeProvider>,
  document.getElementById("root")
);
