/* global log */
var UserSetting = require("sccUserSetting").default;
//var UserSettingMenu= require("sccUserSettingMenu");
//var Language = require("sccLanguage").default;
var _ = require("lodash");
/**
 * Clock class
 * 
 * @class Clock
 */
class ClockModule {
	constructor() {
		this.moduleName = "clock";
		this._allZones = [];
	}

	init() {
		this.runClockTick();
		this.time_zone_1 = moment.tz.guess();

		var $this = this;
		setInterval(function () {
			$this.runClockTick();
		}, 1000);

		$this.initAllZones();
		$this.initialized = true;
		return Promise.resolve();
	}

	runClockTick() {
		var format1 = "H:mm:ss";
		var format2 = "H:mm";
		if (UserSetting.get().clock_format == 12) {
			format1 = "h:mm:ss a";
			format2 = "h:mm a";
		}

		this.zone_1 = moment().format(format1);

		var timeZone2 = UserSetting.get("time_zone_2");
		this.zone_2 = (timeZone2 != 0) ? moment().tz(timeZone2).format(format2) : null;

		var timeZone3 = UserSetting.get("time_zone_3");
		this.zone_3 = (timeZone3 != 0) ? moment().tz(timeZone3).format(format2) : null;
	}

	/**
	 * converts timestamp to formtted data-time based on user setting
	 * 
	 * @param {Number} timestamp input timestamp in seconds
	 * 
	 * @return {String} formatted data-time string
	 */
	formatTimestamp(timestamp, format) {
		format = format || "MMM D, Y HH:mm:ss";
		if (UserSetting.get() && UserSetting.get().clock_format == 12) {
			format = "MMM D, Y hh:mm:ss a";
		}
		// Get current users plarforms language setting and change according the date format
		var lang;
		switch (UserSetting.get().language_id) {
		case 1:
			lang = "en";
			break;
		case 2:
			lang = "es";
			break;
		case 3:
			lang = "fr";
			break;
		default:
			lang = "en";
			return;
		}
		return moment.unix(timestamp).locale(lang).format(format);
	}

	getOffsetString(zone, now) {
		now = now || Date.now();
		var hoursOffset = Math.floor(zone.parse(now) / 60);
		var signOffset = (hoursOffset > 0) ? "-" : "+";
		var minutesOffset = Math.abs(zone.parse(now) % 60);
		hoursOffset = Math.abs(hoursOffset);
		minutesOffset = (minutesOffset > 9) ? "" + minutesOffset : "0" + minutesOffset;
		var offsetString = signOffset + hoursOffset + ":" + minutesOffset;
		return offsetString;
	}

	initAllZones() {
		var $this = this;
		this._allZones = _.map(moment.tz.names(), function (tzName) {
			return $this.getZone(tzName);
		});

		this._allZones = _.orderBy(this._allZones, ["offsetNow", "name"], ["asc", "asc"]);
	}

	getZoneTitle(zone) {
		return "(" + this.getOffsetString(zone, Date.now()) + " " + zone.abbrNow + ") " + zone.name;
	}

	getZone(tzName) {
		var now = Date.now();
		var zone = moment.tz.zone(tzName);
		zone.abbrNow = zone.abbr(now);
		//zone.offsetNow = zone.parse(now);
		//zone.offsetNowString = this.getOffsetString(zone, now);
		return zone;
	}

	getAllZones() {
		return this._allZones;
	}

}

// an array of zone objects 


var moment = require("moment");
require("moment-timezone")(moment);

//module.exports = new ClockModule();
export default new ClockModule();
