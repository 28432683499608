import React, { useState, useContext, useEffect } from "react";

// External
import { useFormikContext } from "formik";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

// Components
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleListing } from "@Styles/ModuleListing";

// Internal
import Group from "../../group/scripts/Group";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
   ...moduleListing(theme),
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function AssetsList(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);
  const moduleData = Object.values(mwState.moduleData);
  const moduleItemData = mwState.moduleItemData;
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  //this function is local to assets module as we make it work differently
  function handleItemEdit(data, subMode) {
    setMwState((p) => ({
      ...p,
      wrapperDisplayMode: MW_ADDEDIT_MODE,
      wrapperDisplaySubMode: subMode,
      moduleItemData: data,
      moduleItemDataOriginal: data,
    }));
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      {/* filteredModuleData needs to be of format [{}] and contain only the main group */}
      <CustomAssetWrapper
        collection={mwState.filteredModuleData}
        editMode={false}
        devicesButtons={true}
        openMain={true}
        name="Listing"
        groupButtons={true}
        showSelectedOnly={false}
        handleItemEdit={handleItemEdit}
        showSearchField={false}
      />
    </React.Fragment>
  );
}
