// External
import _ from "lodash";

// Internal
import log from "loglevel";
import Utils from "sccUtils";
import Language from "sccLanguage";
import audioFile from "../audio/new-msg.mp3";
const audio = new Audio(audioFile);
/**
 * Chat class
 *
 * @class Chat
 */
class Chat {
  constructor() {
    this.moduleName = "data_display";
    this.isSendingChat = false;
    this.isChatLoading = false;
    this.isChatEmpty = false;
    this.minimizeWindow = false;
    this.initialized = false;
    this.chatmessage = {
      recipients: { users: [], devices: [], groups: [] },
      attachments: [],
      cannedmessage_id: "null",
      _file: null,
      _attachmentTooBig: false,
      _invalidFileName: false,
    };
  }
  init() {
    this.initialized = true;
  }

  /**
   * opens the chat box and sets the device id for the device to chat with
   * @param {Number} deviceId device id
   */
  showChatBox(deviceId) {
    this.selectedDeviceId = deviceId;
    this.loadMessageChat(deviceId);
    this.minimizeWindow = false;
    const { default: ChatOverlay } = require("../components/ChatOverlay");
    ChatOverlay.updateFeatureSelected();
    ChatOverlay.maximizeChatWindow();
  }

  /**
   * forces scroll bar to stay at the bottom when new chat arrives
   */
  resetScrollBar() {
    setTimeout(
      function () {
        var scroller = document.getElementById("autoscroll");
        if (scroller) {
          scroller.scrollTop = scroller.scrollHeight;
        }
      },
      150,
      false
    );
  }

  /**
   * loads the messages for device in Chat from the DB
   * @param {Number} deviceId device id
   */
  loadMessageChat(deviceId) {
    if (!deviceId) {
      return;
    }
    this.isChatLoading = true;
    var options = {
      url: Utils.apiUrlPrefix + "/message/device/" + deviceId,
      method: "GET",
    };
    var $this = this;
    return Utils.httpRequestHandler(options).then(function (response) {
      $this.isChatLoading = false;

      $this.chatMessages = response.data.result;

      var { default: ChatOverlay } = require("../components/ChatOverlay");
      ChatOverlay.updateFeatureSelected();

      if (_.isEmpty($this.chatMessages)) {
        $this.isChatEmpty = true;

        return;
      } else {
        $this.isChatEmpty = false;
        $this.resetScrollBar();
      }
    });
  }

  /**
   * Sends a chat message to the device
   * @param {Number} deviceId device id
   */
  sendChatMessage(deviceId) {
    const $this = this;
    $this.isSendingChat = true;

    //Check for invalid data
    if ($this.validateChatMessage()) {
      $this.chatmessage = {};
      $this.isSendingChat = false;
      return;
    }

    // Empty Objects
    // $this.chatmessage.message= "";

    $this.chatmessage = {
      message: $this.chatmessage.message,
      recipients: { users: [], devices: [deviceId], groups: [] },
      attachments: $this.chatmessage.attachments,
      cannedmessage_id: $this.chatmessage.cannedmessage_id,
      _file: $this.chatmessage._file,
      _attachmentTooBig: false,
      _invalidFileName: false,
    };

    var options = {
      url: Utils.apiUrlPrefix + "/message",
      method: "POST",
      body: $this.chatmessage,
    };

    return Utils.httpRequestHandler(options)
      .then(() => {
        $this.chatmessage = {};
        $this.isSendingChat = false;
        $this.chatmessage.cannedmessage_id = "null";
        //$this.update(response.data.result);
        return true;
      })
      .catch((error) => {
        log.debug("ERROR : ", error);
        const msg = Language.translate(error);
        Utils.notify({
          message: msg,
          title: Language.translate("Error"),
          type: "error",
        });
        throw error;
      });
  }

  /**
   * validates chat message.
   * A user can send chat with just canned message or just a message text or both
   * @return {Boolean} true if there is invalid data, and false otherwise
   */
  validateChatMessage() {
    if (
      (!this.chatmessage.message && !this.chatmessage.cannedmessage_id) ||
      (!this.chatmessage.message &&
        this.chatmessage.cannedmessage_id == "null" &&
        !this.chatmessage.attachments)
    ) {
      this.isSendingChat = false;
      return true;
    } else {
      return false;
    }
  }

  /**
   * sends chat messages on key press event
   * @param {Object} event event object
   * @param {Number} deviceId deviceId
   */
  sendChatMessageKeyPress(event, deviceId) {
    const check =
      (!this.chatmessage.message && !this.chatmessage.cannedmessage_id) ||
      (!this.chatmessage.message &&
        this.chatmessage.cannedmessage_id == "null");

    //Check for invalid data
    if (check) return;
    // if enter button is clicked, then run function
    if (event.which == 13 || event.keyCode == 13) {
      //Check if enter was clicked and function already is running
      if (!this.isSendingChat) {
        this.isSendingChat = true;
        this.sendChatMessage(deviceId);
      }
    }
  }

  /**
   * updates chat messages with new data
   * @param {Number} data new chat message data
   */
  update(data) {
    //This condition is hit when device sends a message to a user the data.deviceId is the device id of the sender.
    //This is the only way to distinguish between messages sent by user and messages coming from device
    if (data.device_id) {
      //This condition is hit when user sends a message to a device via chatbox
      if (data.device_id != this.selectedDeviceId) {
        return;
      }
    } else {
      //This condition is hit when user sends a message to a device via chatbox
      if (data.recipients.devices[0] != this.selectedDeviceId) {
        return;
      }
    }
    this.chatMessages[data.id] = data;
    this.isChatEmpty = false;
    this.playSound();
    this.resetScrollBar();
  }

  /**
   * plays sound when there is a new message
   */
  playSound() {
    audio.loop = false;
    audio.currentTime = 0;
    audio.play();
  }
}

export default new Chat();
