import React, { useState, useContext, useEffect } from "react";

// External
import { useFormikContext } from "formik";
import _ from "lodash";

// Material-UI
import {
  Grid,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Collapse,
  MenuItem,
  Popper,
  Fade,
  Paper,
  ClickAwayListener,
  alpha,
} from "@material-ui/core";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import IconButton from "@material-ui/core/IconButton";
import FormLabel from "@material-ui/core/FormLabel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

// Internal
import Language from "sccLanguage";
import PoiCategory from "sccPoiCategory";
import PoiMenu from "sccPoiMenu";
import Poi from "sccPoi";
import olMap from "sccOlMapNew";
import PoiOverlay from "sccPoiOverlay";
import Images from "sccImage";
import Device from "sccDevice";
import UserSetting from "sccUserSetting";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomTextField from "@Components/CustomTextField";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import POIDetail from "./POIDetail";
import POIDeviceList from "./POIDeviceList";
import { moduleForm } from "@Styles/ModuleForm";
import { moduleListing } from "@Styles/ModuleListing";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  ...moduleForm(theme),
  refreshIcon: {
    position: "absolute",
    top: 13,
    paddingLeft: 6,
  },
  clickLocationNote: {
    textAlign: "center",
    backgroundColor: alpha(theme.palette.colors.cyan.main, 0.5),
    margin: 8,
  },
  zoomToCoord: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    "& svg": {
      width: 12,
      height: 12,
      paddingLeft: 4,
    },
  },
  markerImage: {
    maxHeight: 25,
    maxWidth: 45,
    display: "block",
    margin: "0px auto",
  },

  natoMarkerImg: {
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    minHeight: 30,
    marginTop: -1,
    display: "flex",
    alignItems: "center",
  },

  markerContainer: {
    height: 30,
    display: "flex",
    alignItems: "center",
  },

  defaultPoiMarker: {
    maxHeight: 260,
  },

  optionItem: {
    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
    },
  },

  markerPopper: {
    zIndex: 1001, // keep this popper on top of main layer
  },

  PoiMarker: {
    width: 160,
    backgroundColor: theme.palette.colors.blue.main,
    height: 30,
    textAlign: "center",
    lineHeight: "30px",
    cursor: "pointer",
    borderRadius: 1,
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  popperPaper: {
    width: 700,
    height: 200,
    padding: 5,
    overflow: "auto",
    display: "flex",
  },

  popperItem: {
    width: 40,
    height: 40,
    padding: 8,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 4px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.colors.gray.dark,
    },
  },

  syncDevices: {
    paddingBottom: "0px !important",
  },
}));

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;
const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

function ModuleWrapperPOIBody(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const formik = useFormikContext();
  const [formattedLong, setFormattedLong] = useState("");
  const [formattedLat, setFormattedLat] = useState("");
  const [mgrs, setMGRS] = useState("");
  const [typedMGRS, setTypedMGRS] = useState(false);
  // these states are used to display images options popper
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);

  const onMarkerButtonClick = (event) => {
    setPopperAnchor(event.currentTarget);
    setPopperOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setPopperOpen(false);
  };

  const handleImageChange = (imageId) => {
    formik.setFieldValue("image_id", imageId);
  };

  //to be used in the listing page
  const filteredModuleData = mwState.convertAndSort(
    mwState.filteredModuleData,
    mwState.searchField
  );
  const handleEdit = mwState.handleItemEdit;
  const handleItemDelete = mwState.handleItemDelete;

  //to be used in add edit
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const newPOI = {
    category_id: null,
    device_id: undefined,
    image_id: null,
    nato_code: "S-----------",
    affiliationCode: "",
    areaCode: "",
    title: "",
    note: "",
    mgrs: "",
    latitude: mwState.coordsForAddNewPOIctxMenuOption
      ? mwState.coordsForAddNewPOIctxMenuOption[1]
      : "",
    longitude: mwState.coordsForAddNewPOIctxMenuOption
      ? mwState.coordsForAddNewPOIctxMenuOption[0]
      : "",
    sync: { groups: [], devices: [] },
  };

  //used to check the sync devices
  const [checkedDevices, setCheckedDevices] = useState([]);
  useEffect(() => {
    if (!_.isUndefined(moduleItemData.sync)) {
      setCheckedDevices(moduleItemData.sync.devices);
    }

    if (
      mwState.moduleItemData.nato_code &&
      !mwState.moduleItemData.affiliationCode
    ) {
      //ensures that Affiliation and Area populate, even when a NATO POI edit is clicked while another edit form was already open
      populateAffiliationAndArea();
    }
  }, [moduleItemData]);

  useEffect(() => {
    if (assetSelectorState.selection["sync"]) {
      formik.setFieldValue("sync", assetSelectorState.selection["sync"]);
    }
  }, [assetSelectorState.selection]);

  const handleToggle = (id) => {
    const currentIndex = checkedDevices.indexOf(id);
    const newChecked = [...checkedDevices];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedDevices(newChecked);
    formik.setFieldValue("sync.devices", newChecked);
  };

  const populateAffiliationAndArea = () => {
    const newModuleItemData = {
      ...mwState.moduleItemData,
      affiliationCode:
        mwState.moduleItemData.nato_code?.length > 0 &&
        mwState.moduleItemData.nato_code?.split("")[1] !== "-"
          ? mwState.moduleItemData.nato_code?.split("")[1]
          : "",
      areaCode:
        mwState.moduleItemData.nato_code?.length > 0 &&
        mwState.moduleItemData.nato_code?.split("")[2] !== "-"
          ? mwState.moduleItemData.nato_code?.split("")[2]
          : "",
    };

    setMwState((p) => ({
      ...p,
      moduleItemData: { ...newPOI, ...newModuleItemData },
      moduleItemDataOriginal: { ...newPOI, ...newModuleItemData },
    }));
  };

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      if (!_.isUndefined(moduleItemData.sync)) {
        setCheckedDevices(moduleItemData.sync.devices);
      }
      populateAffiliationAndArea();
    }
  }, [mwState.wrapperDisplayMode]);

  useEffect(() => {
    if (UserSetting.get("lonlat_format") == "MGRS") {
      if (!typedMGRS) {
        if (moduleItemData.longitude && moduleItemData.latitude) {
          setMGRS(
            getFormattedCoord([
              moduleItemData.longitude,
              moduleItemData.latitude,
            ])
          );
        }
      } else {
        getFormattedCoord(mgrs);
        formik.setFieldValue("latitude", formattedLat);
        formik.setFieldValue("longitude", formattedLong);
      }
    } else {
      setFormattedLat(moduleItemData.latitude);
      setFormattedLong(moduleItemData.longitude);
    }
  }, [moduleItemData.longitude, moduleItemData.latitude, moduleItemData.mgrs]);

  const handleAccept = (poi) => {
    PoiMenu.poiSyncAccept(poi);
  };

  const handleDecline = (poi) => {
    PoiMenu.poiSyncReject(poi);
  };

  const handleClick = (id) => {
    openDetailsFor !== id ? setOpenDetailsFor(id) : setOpenDetailsFor(null);
  };

  function zoomToCoord(longitude, latitude) {
    const zoom = olMap.mapQuickZoomInLevel;
    const orgCoord = [longitude, latitude];
    const coord = olMap.transformToMapCoordinate(orgCoord);
    olMap.setCenterAndZoom(coord, zoom);
  }

  PoiOverlay.registerClickEvent("poi_click", (coord) => {
    if (
      mwState.wrapperDisplayMode == MW_ADDEDIT_MODE &&
      mwState.title == "POI"
    ) {
      formik.setFieldValue("latitude", coord[1]);
      formik.setFieldValue("longitude", coord[0]);
      if (UserSetting.get("lonlat_format") == "MGRS") getFormattedCoord(coord);
    }
  });

  const newNatoCode = (e) => {
    let n = null;
    if (e.target.name == "affiliationCode") n = 1;
    if (e.target.name == "areaCode") n = 2;
    formik.setFieldValue(
      "nato_code",
      moduleItemData.nato_code.replaceAt(n, e.target.value)
    );
    handleFormInput(e);
  };

  // Changes select element for icon category
  const categoryChanged = (e) => {
    if (!e.target.value && moduleItemData.nato_code == null) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        category_id: e.target.value || null,
        nato_code: "S-----------",
        image_id: null,
      }));
    } else {
      formik.setValues((prevValues) => ({
        ...prevValues,
        category_id: e.target.value,
        nato_code: null,
        affiliationCode: "",
        areaCode: "",
        image_id: null,
      }));
    }
  };

  const getPoiCategoryList = () => {
    const newPoiCategory = [{ title: Language.translate("NATO"), value: 0 }];
    _.each(PoiCategory.get(), (poiCat) => {
      newPoiCategory.push({ title: poiCat.title, value: poiCat.id });
    });
    return newPoiCategory;
  };

  const poiaffiliate = [
    { label: Language.translate("Friend"), value: "F" },
    { label: Language.translate("Hostile"), value: "H" },
    { label: Language.translate("Neutral"), value: "N" },
    { label: Language.translate("Unknown"), value: "U" },
  ];

  const poiarea = [
    { label: Language.translate("Air"), value: "A" },
    { label: Language.translate("Ground"), value: "G" },
    { label: Language.translate("Sea Surface"), value: "S" },
    { label: Language.translate("Sea Sub Surface"), value: "U" },
  ];

  const getPlatformImgOptions = () => {
    //platform images are the images other than the Nato ones, Nato category_id is null
    if (moduleItemData.category_id !== null) {
      return PoiCategory.get(moduleItemData.category_id).images;
    }
  };

  const getFormattedCoord = (coord) => {
    if (coord) {
      var newCoords;
      if (typedMGRS) {
        newCoords = window.olMap.convertLonlatUser2Map([coord], 0, "MGRS");
        var temp = newCoords[0];
        newCoords[0] = newCoords[1];
        newCoords[1] = temp;
        newCoords = String(newCoords);
      } else {
        newCoords = olMap.formatCoordinate([coord[0], coord[1]]);
      }
      setFormattedLat(newCoords.split(",")[0]);
      setFormattedLong(newCoords.split(",")[1]);

      return newCoords;
    } else {
      setFormattedLat("");
      setFormattedLong("");
      return null;
    }
  };

  const allSyncDevices = Device.getSyncDevices();

  const classes = useStyles();

  return mwState.wrapperDisplayMode === MW_LIST_MODE ? (
    <ul className={classes.listContainer}>
      {!_.isEmpty(filteredModuleData) ? (
        filteredModuleData
          .slice(mwState.pagination.startIndex, mwState.pagination.endIndex)
          .map((data) => {
            return (
              <React.Fragment key={data.id}>
                <ListItem
                  className={classes.listItem}
                  key={data.id}
                  onClick={() => handleClick(data.id)}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <CustomTooltip title={data.title} placement="left">
                      <span>
                        {data.image_id == null ? (
                          <img
                            className="poi-marker-img"
                            name="image"
                            alt={data.title}
                            src={Poi.getNatoSymbol(data.nato_code)}
                            required
                          />
                        ) : (
                          <img
                            className="poi-marker-img-platform"
                            name="image"
                            alt={data.title}
                            src={Images.getImageSource(data.image_id)}
                          />
                        )}
                      </span>
                    </CustomTooltip>
                  </ListItemIcon>
                  <ListItemText
                    id={data.id}
                    className={classes.itemPrimaryText}
                    primary={
                      <React.Fragment>
                        {data.title}
                        {data.nato_code && data.sync?.devices?.length > 0 && (
                          <ListItemIcon>
                            <span className={classes.refreshIcon}>
                              <CustomIcon
                                id="icon-refresh"
                                style={{ fontSize: 9 }}
                              />
                            </span>
                          </ListItemIcon>
                        )}
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction
                    className={classes.itemActionButtonsContainer}
                  >
                    {!data.approved && (
                      <React.Fragment>
                        <CustomTooltip
                          title={Language.translate("Accept")}
                          placement="bottom"
                        >
                          <IconButton
                            edge="end"
                            aria-label="accept"
                            onClick={handleAccept}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                        </CustomTooltip>
                        <CustomTooltip
                          title={Language.translate("Decline")}
                          placement="bottom"
                        >
                          <IconButton
                            edge="end"
                            aria-label="decline"
                            onClick={handleDecline}
                          >
                            <CancelIcon />
                          </IconButton>
                        </CustomTooltip>
                      </React.Fragment>
                    )}
                    {data.approved ? (
                      <CustomTooltip
                        title={Language.translate("Locate")}
                        placement="top"
                      >
                        <IconButton
                          className="zoomToCoord"
                          edge="end"
                          aria-label="locate"
                          onClick={() =>
                            zoomToCoord(data.longitude, data.latitude)
                          }
                        >
                          <MyLocationIcon />
                        </IconButton>
                      </CustomTooltip>
                    ) : null}
                    {mwState.verifyEditPermission && data.approved ? (
                      <CustomTooltip
                        title={Language.translate("Edit")}
                        placement="top"
                      >
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => handleEdit(data)}
                        >
                          <EditIcon />
                        </IconButton>
                      </CustomTooltip>
                    ) : null}
                    {mwState.verifyDeletePermission && data.approved ? (
                      <CustomTooltip
                        title={Language.translate("Delete")}
                        placement="top"
                      >
                        <IconButton
                          edge="end"
                          aria-label="comments"
                          onClick={() =>
                            handleItemDelete(
                              `${mwState.routeUrl}/${data.id}`,
                              "Poi",
                              "POI",
                              data.title
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CustomTooltip>
                    ) : null}
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse
                  in={openDetailsFor === data.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <POIDetail data={data} />
                </Collapse>
              </React.Fragment>
            );
          })
      ) : (
        <div className="noResults">{Language.translate("No Results!")}</div>
      )}
    </ul>
  ) : (
    <React.Fragment>
      <div className={classes.formContainer}>
        <div className="pullRight">
          {"* " + Language.translate("Required fields")}
        </div>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={3}>
            {moduleItemData.category_id == null ||
            moduleItemData.category_id == 0 ? (
              <div>
                <FormLabel className="MuiInputLabel-root">
                  <span className={classes.marker}>
                    {Language.translate("Marker") + "*"}
                  </span>
                  {moduleItemData.id && (
                    <CustomTooltip
                      title={Language.translate("Locate")}
                      placement="top"
                    >
                      <span
                        className={classes.zoomToCoord}
                        aria-label="locate"
                        onClick={() =>
                          zoomToCoord(
                            moduleItemData.longitude,
                            moduleItemData.latitude
                          )
                        }
                      >
                        <MyLocationIcon />
                      </span>
                    </CustomTooltip>
                  )}
                </FormLabel>
                <div className={classes.natoMarkerImg}>
                  <img
                    name="image"
                    className={classes.markerImage}
                    alt={moduleItemData.title}
                    src={Poi.getNatoSymbol(
                      moduleItemData.nato_code !== "S-----------"
                        ? moduleItemData.nato_code
                        : ""
                    )}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div>
                  {Language.translate("Marker") + "*"}{" "}
                  {moduleItemData.id && (
                    <CustomTooltip
                      title={Language.translate("Locate")}
                      placement="top"
                    >
                      <span
                        className={classes.zoomToCoord}
                        aria-label="locate"
                        onClick={() =>
                          zoomToCoord(
                            moduleItemData.longitude,
                            moduleItemData.latitude
                          )
                        }
                      >
                        <MyLocationIcon />
                      </span>
                    </CustomTooltip>
                  )}
                </div>
                <div className={classes.markerContainer}>
                  <img
                    name="image"
                    className={classes.markerImage}
                    src={Images.getImageSource(moduleItemData.image_id)}
                  />
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={3}>
            <CustomDropdown
              label={Language.translate("Category") + "*"}
              value={
                moduleItemData.category_id == null
                  ? 0
                  : moduleItemData.category_id
              }
              name="category_id"
              onChange={categoryChanged}
              onBlur={handleFormBlur}
              error={
                formik.touched.category_id && Boolean(formik.errors.category_id)
              }
              helperText={
                formik.touched.category_id && formik.errors.category_id
              }
            >
              {getPoiCategoryList().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {/* Only translates "NATO" and "POI". No custom POI category titles are translated */}
                  {[0, 1].includes(option.value)
                    ? Language.translate(option.title)
                    : option.title}
                </MenuItem>
              ))}
            </CustomDropdown>
          </Grid>
          {moduleItemData.category_id == null ||
          moduleItemData.category_id == 0 ? (
            <React.Fragment>
              <Grid item xs={3}>
                <CustomDropdown
                  label={Language.translate("Affiliation") + "*"}
                  name="affiliationCode"
                  value={
                    moduleItemData.affiliationCode
                      ? String(moduleItemData.affiliationCode)
                      : ""
                  }
                  onChange={newNatoCode}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.affiliationCode &&
                    Boolean(formik.errors.affiliationCode)
                  }
                  helperText={
                    formik.touched.affiliationCode &&
                    formik.errors.affiliationCode
                  }
                >
                  {poiaffiliate.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              </Grid>
              <Grid item xs={3}>
                <CustomDropdown
                  label={Language.translate("Area") + "*"}
                  name="areaCode"
                  value={
                    moduleItemData.areaCode
                      ? String(moduleItemData.areaCode)
                      : ""
                  }
                  onChange={newNatoCode}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.areaCode && Boolean(formik.errors.areaCode)
                  }
                  helperText={formik.touched.areaCode && formik.errors.areaCode}
                >
                  {poiarea.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item container xs={6} justifyContent="center">
              <Grid item xs={6}>
                <div>
                  <div>{Language.translate("Select")}</div>
                  <div>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <div
                        className={classes.PoiMarker}
                        onClick={(e) => onMarkerButtonClick(e)}
                      >
                        {Language.translate("POI Marker")}
                      </div>
                    </ClickAwayListener>
                    <Popper
                      open={popperOpen}
                      anchorEl={popperAnchor}
                      placement="bottom"
                      transition
                      className={classes.markerPopper}
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper className={classes.popperPaper} elevation={7}>
                            {getPlatformImgOptions().map((option) => (
                              <div
                                key={option}
                                className={classes.popperItem}
                                onClick={() => handleImageChange(option)}
                              >
                                <img
                                  name="image"
                                  className={classes.markerImage}
                                  src={Images.getImageSource(option)}
                                />
                              </div>
                            ))}
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <Grid item xs={6}>
            <CustomTextField
              label={Language.translate("Title") + "*"}
              value={moduleItemData.title}
              name="title"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={Language.translate("Annotation") + "*"}
              value={moduleItemData.note}
              name="note"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.note && Boolean(formik.errors.note)}
              helperText={formik.touched.note && formik.errors.note}
            />
          </Grid>
          <Grid item xs={12} className={classes.clickLocationNote}>
            {Language.translate(
              "Please click on the map to populate location coordinates"
            )}
          </Grid>
          {UserSetting.get("lonlat_format") != "MGRS" ? (
            <React.Fragment>
              <Grid item xs={6}>
                <CustomTextField
                  label={Language.translate("Latitude") + "*"}
                  value={formattedLat}
                  name="latitude"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.latitude && Boolean(formik.errors.latitude)
                  }
                  helperText={formik.touched.latitude && formik.errors.latitude}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  label={Language.translate("Longitude") + "*"}
                  value={formattedLong}
                  name="longitude"
                  onChange={handleFormInput}
                  onBlur={handleFormBlur}
                  error={
                    formik.touched.longitude && Boolean(formik.errors.longitude)
                  }
                  helperText={
                    formik.touched.longitude && formik.errors.longitude
                  }
                />
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12}>
              <CustomTextField
                label={Language.translate("MGRS") + "*"}
                value={mgrs}
                name="mgrs"
                onChange={(e) => {
                  handleFormInput(e);
                  setMGRS(e.target.value);
                }}
                onFocus={() => {
                  setTypedMGRS(true);
                }}
                onBlur={(e) => {
                  handleFormBlur(e);
                  setTypedMGRS(false);
                }}
                error={formik.touched.mgrs && Boolean(formik.errors.mgrs)}
                helperText={formik.touched.mgrs && formik.errors.mgrs}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {!moduleItemData.category_id || moduleItemData.category_id == 0 ? (
              <POIDeviceList
                checkedDevices={checkedDevices}
                allDevices={allSyncDevices}
                onDeviceClick={handleToggle}
              />
            ) : null}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default ModuleWrapperPOIBody;
