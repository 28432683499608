import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import BuildIcon from "@material-ui/icons/Build";
import CustomTooltip from "@Components/CustomTooltip";
import Button from "@material-ui/core/Button";
import "./MapTools.scss";
import OlMapOverlay from "../../scripts/OlMapOverlay";

const Language = require("sccLanguage").default;

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative"
	}
}));

export default function ClickAway(props) {
	const classes = useStyles();
	const OlMap = (props.history==1) ? require("sccOlMapHistory").default : require("sccOlMapNew").default;
	const [open, setOpen] = useState(false);
	const [lineControl, setLineControl] = useState(false);
	const [areaControl, setAreaControl] = useState(false);
	const [lineValue, setLineValue] = useState(false);
	const [areaValue, setAreaValue] = useState(false);

	const handleClick = () => {
		setOpen((prev) => !prev);
	};

	const handleClickAway = () => {
		// Check if the user has started to use the measuring tools, keep popover open
		if (lineControl || areaControl) {
			setOpen(true);
			setLineValue(OlMap.Measure.lineValue);
			setAreaValue(OlMap.Measure.areaValue);
		} else {
			setOpen(false);	
		}
	};
  
	const toggleLineControl = (e) => {
		setLineControl(!lineControl);
		setAreaControl(false);
		OlMap.Measure.toggleLineControl();
	};

	const toggleAreaControl = (e) => {
		setLineControl(false);
		setAreaControl(!areaControl);
		OlMap.Measure.toggleAreaControl();
	};

	const id = open ? "mapToolsPopover" : undefined;

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div className="mapToolsPopover">
       
				<CustomTooltip title={Language.translate("Map Tools")} arrow placement="top">
					<Button 
						className="mapToolsBtn btn btn-default" 
						aria-describedby={id} 
						variant="contained" 
						color="default" 
						onClick={handleClick}
						startIcon={<BuildIcon />}
					>
					</Button>
				</CustomTooltip>
				{open ? (
					<div className="mapToolsPopoverBox">
            
						<h5>{Language.translate("Map Tools")}</h5>
						<ul className="mapToolsMenu">
                  
							{ OlMap.Measure.lineActive ? 
								<li>{OlMap.Measure.lineValue || Language.translate("Draw line to be measured")}</li>
								: null}
                
							{ OlMap.Measure.areaActive ? 
								<li>{OlMap.Measure.areaValue || Language.translate("Draw line to be measured")}</li>
								: null}


							<li>{Language.translate("Length")}
								{ lineControl ? 
									<Button onClick={toggleLineControl} size="small" variant="outlined" color="secondary">{Language.translate("Close")}</Button>
									: 
									<Button onClick={toggleLineControl} size="small" variant="outlined">{Language.translate("Measure")}</Button>
								}
							</li>
							<li>{Language.translate("Area")}
								{ areaControl ?
									<Button onClick={toggleAreaControl} size="small" variant="outlined" color="secondary">{Language.translate("Close")}</Button>
									: 
									<Button onClick={toggleAreaControl} size="small" variant="outlined">{Language.translate("Measure")}</Button>
								}

							</li>
            
						</ul>
					</div>          
				) : null}
			</div>
		</ClickAwayListener>
	);
}