import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import Language from "sccLanguage";
import Alert from "sccAlert";
import User from "sccUser";
import Group from "sccGroup";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomSwitch from "@Components/CustomSwitch";
import CustomTextField from "@Components/CustomTextField";
import CustomListItemCollapse from "@Components/CustomListItemCollapse";
import CustomIcon from "@Components/CustomIcon";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import CustomInputAdornment from "@Components/CustomInputAdornment";
import CustomItemCount from "@Components/CustomItemCount";
import CustomTooltip from "@Components/CustomTooltip";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import { moduleForm } from "@Styles/ModuleForm";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  alertCheckItem: {
    "& label": {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  tableContainer: {
    overflowX: "hidden",
  },
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function ARForm(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const formik = useFormikContext();
  const moduleItemData = formik.values;

  const [assetSelectorState] = useContext(CustomAssetSelectorContext);

  const [value, setValue] = useState(0);
  const [checkedState, setCheckedState] = useState(
    !_.isUndefined(moduleItemData) ? moduleItemData.enabled : false
  );
  const [checkedAlerts, setCheckedAlerts] = useState([]);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [subscribersDeviceCount, setSubscribersDeviceCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [membersCount, setMembersCount] = useState(0);

  const [users, setUsers] = useState({});
  const [checkedEmails, setCheckedEmails] = useState([]);
  const [checkedSMS, setCheckedSMS] = useState([]);

  const [initalSubscibersState, setInitalSubscibersState] = useState(false);

  //to be used in add edit
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;
  const handleFormInputCheck = mwState.handleFormInputCheck;
  const moduleFormValidations = mwState.moduleFormValidations;

  const minimizeSelection = assetSelectorState.minimizeSelection;
  const totalDevicesFromGroups = assetSelectorState.totalDevicesFromGroups;

  const newAR = {
    title: "",
    enabled: true,
    alerts: [],
    members: {
      devices: [],
      groups: [],
    },
    subscribers: {
      devices: [],
      groups: [],
      users: {},
    },
  };

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...newAR,
          ...p.moduleItemData,
          subscribers: {
            users,
            ...newAR.subscribers,
            ...p.moduleItemData.subscribers,
          },
        },
        moduleItemDataOriginal: {
          ...newAR,
          ...p.moduleItemData,
          subscribers: {
            users,
            ...newAR.subscribers,
            ...p.moduleItemData.subscribers,
          },
        },
      }));
      //used as a fix to set the initial state of the AS, as otherwise the AS needs to rerender before the values show up as selected
      if (mwState.moduleItemData.id) {
        assetSelectorState.setStateForSelection(
          assetSelectorState.maximizeSelection(
            mwState.moduleItemData.subscribers
          ),
          "subscribers"
        );
        assetSelectorState.setStateForSelection(
          assetSelectorState.maximizeSelection(mwState.moduleItemData.members),
          "members"
        );
      } else {
        assetSelectorState.setStateForSelection(
          { groups: [], devices: [], users: {} },
          "subscribers"
        );
        assetSelectorState.setStateForSelection(
          { groups: [], devices: [] },
          "members"
        );
      }
    }
  }, [mwState.wrapperDisplayMode]);

  //this useeffect is called on change in selection of AS and sets the members & subscribers for formik
  useEffect(() => {
    // The value of members and subscribers need to be locally written
    // to keep them seperate from the AssetSelector and Formik values
    if (assetSelectorState.selection["members"]) {
      setMembersCount(assetSelectorState.selection["members"].devices.length);
      formik.setFieldValue(
        "members",
        minimizeSelection(assetSelectorState.selection["members"])
      );
    }
    if (assetSelectorState.selection["subscribers"]) {
      let newSubscribers = minimizeSelection(
        assetSelectorState.selection["subscribers"]
      );
      // Add users to object
      newSubscribers.users = users;
      formik.setFieldValue("subscribers", newSubscribers);
    }
  }, [assetSelectorState.selection]);

  useEffect(() => {
    if (!_.isEmpty(moduleItemData.alerts)) {
      setCheckedAlerts(moduleItemData.alerts);
    }
  }, [moduleItemData.alerts]);

  useEffect(() => {
    formik.setFieldValue("subscribers.users", users);
  }, [users]);
  // SET TOTAL USERS COUNT
  useEffect(() => {
    const total = _.union(checkedEmails, checkedSMS);
    setUsersCount(total.length);
  }, [checkedEmails, checkedSMS]);

  useEffect(() => {
    // Set the initial values from the database
    if (moduleItemData.subscribers) {
      if (!initalSubscibersState) {
        setUsersInitialCount();
      }
      if (!_.isEmpty(moduleItemData.subscribers)) {
        setSubscribersTotalDevice();
      }
    }
  }, [moduleItemData.subscribers]);

  function setSubscribersTotalDevice() {
    // Get devices from groupid's then set the subscriber count
    const groupDevicesCount =
      (moduleItemData.subscribers.groups &&
        totalDevicesFromGroups(
          moduleItemData.subscribers.groups,
          moduleItemData.subscribers.devices
        ).length) ||
      0;
    setSubscribersDeviceCount(groupDevicesCount);
    setSubscribersCount(subscribersDeviceCount + usersCount);
  }

  function setUsersInitialCount() {
    const arrEmail = [];
    const arrSMS = [];
    _.each(moduleItemData.subscribers.users, (item) => {
      if (item.send_email) {
        arrEmail.push(item.id);
      }
      if (item.send_sms) {
        arrSMS.push(item.id);
      }
    });
    setCheckedEmails(_.uniq(arrEmail));
    setCheckedSMS(_.uniq(arrSMS));
    setUsersCount(_.union(_.uniq(arrEmail), _.uniq(arrSMS)).length);
    //Set users to state
    setUsers(moduleItemData.subscribers.users);
    // Change flag so it does not run again
    setInitalSubscibersState(true);
  }

  // SET USERS
  const handleUsers = (user, e) => {
    const { name, checked } = e.target;
    if (name == "send_email") {
      setCheckedEmails(_.xor([...checkedEmails], [user.id]));
    }
    if (name == "send_sms") {
      setCheckedSMS(_.xor([...checkedSMS], [user.id]));
    }
    setUsers((prevState) => ({
      ...prevState,
      ...users,
      [user.id]: {
        ...users[user.id],
        [name]: checked,
        id: user.id,
      },
    }));
  };

  function handleTabs(e, val) {
    setValue(val);
  }

  const handleAlertsToggle = (id) => {
    const currentIndex = checkedAlerts.indexOf(id);
    const newChecked = [...checkedAlerts];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAlerts(newChecked);
    formik.setFieldValue("alerts", newChecked);
  };

  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <Grid container xs={12}>
        <Grid item xs={6} className="switchWrap">
          <CustomSwitch
            name="enabled"
            label={`${Language.translate("Status")}`}
            value={Boolean(moduleItemData.enabled)}
            onChange={handleFormInput}
            checked={Boolean(moduleItemData.enabled)}
            color="primary"
            labelPlacement="top"
          />
        </Grid>
        <Grid className="itemCountWrap" item xs={6}>
          <CustomItemCount
            className="CustomItemCount"
            title={Language.translate("Subscribers")}
            count={subscribersDeviceCount + usersCount}
          />
          <CustomItemCount
            className="CustomItemCount"
            title={Language.translate("Members")}
            count={membersCount}
          />
        </Grid>
        <Grid item xs={12} className="pullRight">
          {"* " + Language.translate("Required fields")}
        </Grid>
        <Grid item xs="12">
          <CustomTextField
            label={`${Language.translate("Title")}*`}
            value={String(moduleItemData.title)}
            name="title"
            onChange={handleFormInput}
            onBlur={handleFormBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            required
          />
        </Grid>
        <Grid item xs="12">
          <CustomListItemCollapse
            title={Language.translate("Alerts").toUpperCase()}
            color="primary"
            collapse={true}
            error={
              formik.errors.alerts
                ? Language.translate(formik.errors.alerts)
                : null
            }
            count={checkedAlerts.length}
            className="alertCollapseText"
          >
            {Object.values(Alert.getAlertType()).map((item) => (
              <CustomCheckbox
                key={item.id}
                label={Language.translate(item.type)}
                name="alerts"
                onChange={() => handleAlertsToggle(item.id)}
                color="primary"
                checked={checkedAlerts.indexOf(item.id) !== -1}
                className="alertOption"
              />
            ))}
          </CustomListItemCollapse>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <CustomListItemCollapse
              title={Language.translate("Subscribers").toUpperCase()}
              color="primary"
              collapse={true}
              count={subscribersDeviceCount + usersCount}
            >
              <Tabs
                value={value}
                onChange={handleTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  label={Language.translate("Users")}
                  style={{ fontSize: 9 }}
                  icon={
                    <Badge badgeContent={usersCount}>
                      <CustomIcon style={{ fontSize: 9 }} id="icon-laptop" />
                    </Badge>
                  }
                />
                <Tab
                  label={Language.translate("Assets")}
                  style={{ fontSize: 9 }}
                  icon={
                    <Badge badgeContent={subscribersDeviceCount}>
                      <CustomIcon style={{ fontSize: 9 }} id="icon-group" />
                    </Badge>
                  }
                />
              </Tabs>
              {value == 0 && (
                <List>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {Language.translate("Name").toUpperCase()}{" "}
                          </TableCell>
                          <TableCell>
                            {Language.translate("Username").toUpperCase()}
                          </TableCell>
                          <TableCell>
                            {Language.translate("Email").toUpperCase()}
                          </TableCell>
                          <TableCell>{Language.translate("SMS")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="ArFormTableBody">
                        {Object.values(User.get()).map((item) => (
                          <TableRow key={item.id}>
                            <TableCell component="th" scope="row">
                              <span className="textOverflow">
                                {item.first_name + " " + item.last_name}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              {item.username}
                            </TableCell>
                            <TableCell align="right">
                              {
                                <CustomCheckbox
                                  name="send_email"
                                  checked={checkedEmails.indexOf(item.id) > -1}
                                  onChange={(e) => handleUsers(item, e)}
                                  color="primary"
                                />
                              }
                            </TableCell>
                            <TableCell align="right">
                              {item.phone_number ? (
                                <CustomCheckbox
                                  name="send_sms"
                                  checked={checkedSMS.indexOf(item.id) > -1}
                                  onChange={(e) => handleUsers(item, e)}
                                  color="primary"
                                />
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </List>
              )}
              {value == 1 && (
                <CustomAssetWrapper
                  openMain={true}
                  editMode={true}
                  selection={moduleItemData.subscribers}
                  displayDeviceImei={false}
                  showDeviceSearchField={false}
                  name="subscribers"
                />
              )}
            </CustomListItemCollapse>
          </Grid>
          <Grid container item xs={6} direction="column">
            <CustomListItemCollapse
              title={Language.translate("Members").toUpperCase()}
              color="primary"
              collapse={true}
              count={membersCount}
            >
              <CustomAssetWrapper
                openMain={true}
                editMode={true}
                selection={moduleItemData.members}
                displayDeviceImei={false}
                showDeviceSearchField={false}
                name="members"
              />
            </CustomListItemCollapse>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
