import React, { useContext } from "react";

// External
import _ from "lodash";

// Internal
import { HistoryContext } from "../../HistoryContext";
import CustomTooltip from "@Components/CustomTooltip";
import Language from "sccLanguage";
import UserSetting from "sccUserSetting";

// Material-UI
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, alpha } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import NearMeIcon from "@material-ui/icons/NearMe";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  assetDisplayContainer: {
    backgroundColor: theme.palette.colors.white.main,
    width: "100%",
    height: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    "& .assetButtonContainer": {
      float: "right",
      alignItems: "center",
      "& .assetCheckbox": {
        boxSizing: "content-box",
        borderRadius: 0,
      },
      "& .enabledIcon": {
        color: theme.palette.common.white,
        border: "1px solid " + theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
      "& .disabledIcon": {
        backgroundColor: theme.palette.common.white,
      },
    },
    "& .noEvents": {
      opacity: 0.6,
    },
    "& .assetText": {
      float: "left",
      padding: "5px",
    },
    "& .chipText": {
      fontSize: "1.2rem",
      color: theme.palette.colors.white.main,
      paddingTop: 0,
      fontWeight: "bold",
      backgroundColor: alpha(theme.palette.colors.black.main, 0.5),
    },
    "& .MuiChip-sizeSmall": {
      marginTop: 1,
      height: "20px",
    },
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
  deviceNameSpan: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: 4,
    width: 155,
    marginRight: 4,
  },
}));

const AssetDisplay = (props) => {
  const [state, setState] = useContext(HistoryContext);
  const Historic = state.Historic;
  const updateKeysInState = state.updateKeysInState;
  const deviceVisibilityChanged = state.deviceVisibilityChanged;
  const showDeviceTrailChanged = state.showDeviceTrailChanged;

  const handleChangeVisibility = (event) => {
    Historic.playOptions.device[event.target.value].showTracks =
      event.target.checked;
    updateKeysInState(Historic);
    deviceVisibilityChanged(event.target.value);
  };
  const handleChangeFollow = (event) => {
    Historic.playOptions.device[event.target.value].isFollow =
      event.target.checked;
    updateKeysInState(Historic);
  };
  const handleChangeTrail = (event) => {
    const devices = Historic.queryParams.devices;
    Historic.playOptions.device[event.target.value].showTrail =
      event.target.checked;
    updateKeysInState(Historic);
    showDeviceTrailChanged(
      devices.length ==
        _.filter(Historic.playOptions.device, { showTracks: false }).length
    );
  };

  //To determine the length of devic name to display, each language requires different length due to the "No events" chip length
  //UserSetting.get("language_id") will return 1,2,3. English's language id is 1, Spanish 2, French 3
  const displayDeviceName = (str) => {
    let st = str;

    if (UserSetting.get("language_id") === 1) {
      if (st.length >= 30) {
        st = st.substring(0, 27) + "...";
      }
    }
    if (UserSetting.get("language_id") === 2) {
      if (st.length >= 25) {
        st = st.substring(0, 22) + "...";
      }
    }
    if (UserSetting.get("language_id") === 3) {
      if (st.length >= 21) {
        st = st.substring(0, 18) + "...";
      }
    }
    return st;
  };

  const classes = useStyles();

  return (
    <Grid container className={classes.assetDisplayContainer}>
      <Grid
        item
        container
        xs={12}
        className={
          !Historic.playOptions.device[props.id].hasEvents ? "noEvents" : null
        }
      >
        <Grid
          item
          container
          xs={9}
          className="assetText"
          justifyContent="flex-start"
          alignItems="center"
        >
          <span className={classes.deviceNameSpan}>
            {displayDeviceName(props.name)}
          </span>
          {!Historic.playOptions.device[props.id].hasEvents ? (
            <Chip
              className="chipText"
              size="small"
              label={Language.translate("No events")}
            />
          ) : null}
        </Grid>
        <Grid
          item
          container
          row="true"
          xs={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="assetButtonContainer"
        >
          <Grid item xs={4}>
            <CustomTooltip
              title={Language.translate("View")}
              arrow
              placement="top"
            >
              <Checkbox
                checked={Historic.playOptions.device[props.id].showTracks}
                onChange={handleChangeVisibility}
                icon={<VisibilityOffIcon className="assetCheckbox" />}
                checkedIcon={
                  <VisibilityIcon className="assetCheckbox enabledIcon" />
                }
                value={props.id}
                name={"viewAll_" + props.id}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={4}>
            <CustomTooltip
              title={Language.translate("Follow")}
              arrow
              placement="top"
            >
              <Checkbox
                checked={Historic.playOptions.device[props.id].isFollow}
                disabled={!Historic.playOptions.device[props.id].showTracks}
                onChange={handleChangeFollow}
                icon={<NearMeIcon className="assetCheckbox" />}
                checkedIcon={
                  <NearMeIcon className="assetCheckbox enabledIcon" />
                }
                value={props.id}
                name={"followAll_" + props.id}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={4}>
            <CustomTooltip
              title={Language.translate("Trail")}
              arrow
              placement="top"
            >
              <Checkbox
                checked={Historic.playOptions.device[props.id].showTrail}
                disabled={!Historic.playOptions.device[props.id].showTracks}
                onChange={handleChangeTrail}
                icon={<TrendingUpIcon className="assetCheckbox" />}
                checkedIcon={
                  <TrendingUpIcon className="assetCheckbox enabledIcon" />
                }
                value={props.id}
                name={"trailAll_" + props.id}
              />
            </CustomTooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssetDisplay;
