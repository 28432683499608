import React, { useState, useContext, useEffect } from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CheckIcon from "@material-ui/icons/Check";
import Badge from "@material-ui/core/Badge";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

//External
import _ from "lodash";
import moment from "moment";

//Internal
import Language from "sccLanguage";
import Profile from "sccProfile";
import Message from "sccMessage";
import Permission from "sccPermission";
import Device from "sccDevice";
import User from "sccUser";
import Clock from "sccClock";
import CustomCheckbox from "@Components/CustomCheckbox";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomTooltip from "@Components/CustomTooltip";
import { moduleMessage } from "@Styles/ModuleMessage";

function Sent(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const filteredModuleData = mwState.filteredModuleData;
  const handleClick = props.handleClick;
  const handleSingleCheckbox = props.handleSingleCheckbox;
  const resetSorting = props.resetSorting;

  function getPendingMsg(pending) {
    var pendingNo = [];
    _.forEach(pending, function (value, key) {
      if (value.message_status == "pending") {
        pendingNo.push(parseInt(key));
      }
    });
    return pendingNo.length;
  }
  function getFailedMsg(pending) {
    var failedNo = [];
    _.forEach(pending, function (value, key) {
      if (value.message_status == "fail") {
        failedNo.push(parseInt(key));
      }
    });
    return failedNo.length;
  }
  function showStatus(data) {
    if (
      getPendingMsg(data.recipients.device_message_status) === 0 &&
      getFailedMsg(data.recipients.device_message_status) === 0
    ) {
      return <CheckIcon />;
    } else if (getFailedMsg(data.recipients.device_message_status) > 0) {
      return (
        <Badge
          color="secondary"
          badgeContent={getFailedMsg(data.recipients.device_message_status)}
        ></Badge>
      );
    } else if (getPendingMsg(data.recipients.device_message_status) > 0) {
      return (
        <Badge
          color="secondary"
          badgeContent={getPendingMsg(data.recipients.device_message_status)}
        />
      );
    }
  }

  function getMessageTo(data) {
    if (data.recipients.devices.length) {
      data.to = data.recipients.devices
        .map((recpt, index) => {
          return index > 1
            ? ", " + Device.get(recpt).name
            : Device.get(recpt).name;
        })
        .join();
      return data.recipients.devices.map((recpt, index) => {
        return index > 1
          ? ", " + Device.get(recpt).name
          : Device.get(recpt).name;
      });
    } else {
      if (data.recipients.users.length) {
        data.to = data.recipients.users
          .map((recpt, index) => {
            return index > 1
              ? ", " +
                  `${User.get(recpt).first_name} ${User.get(recpt).last_name}`
              : `${User.get(recpt).first_name} ${User.get(recpt).last_name}`;
          })
          .join();
        return data.recipients.users.map((recpt, index) => {
          return index > 1
            ? ", " +
                `${User.get(recpt).first_name} ${User.get(recpt).last_name}`
            : `${User.get(recpt).first_name} ${User.get(recpt).last_name}`;
        });
      } else {
        data.to = "";
        return null;
      }
    }
  }

  const useStyles = makeStyles((theme) => ({
    ...moduleMessage(theme),
  }));
  const classes = useStyles();

  return (
    <Grid container xs={12} className={classes.listContainer}>
      <Grid
        container
        xs={12}
        spacing={0}
        className="header"
        alignContent="center"
      >
        <Grid item xs={1} className="chkBox"></Grid>
        <Grid item container xs={11}>
          <Grid item xs={3}>
            {Language.translate("TO")}
          </Grid>
          <Grid item xs={3}>
            {Language.translate("MESSAGE")}
          </Grid>
          <Grid item xs={3}>
            {Language.translate("Canned").toUpperCase()}
          </Grid>
          <Grid item xs={2}>
            <Link href="#" onClick={resetSorting} underline="none">
              <span className="dateLink">{Language.translate("DATE")}</span>
              {mwState.moduleData.sortDesc ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )}
            </Link>
          </Grid>
          <Grid item xs={1}>
            {Language.translate("Status").toUpperCase()}
          </Grid>
        </Grid>
      </Grid>
      {!_.isEmpty(filteredModuleData) && mwState.moduleData.checked ? (
        filteredModuleData.map((data) => {
          return !(
            Device.get(data.senders.devices).name != undefined &&
            mwState.moduleData.hideUnauthorized
          ) ? (
            <Grid
              container
              xs={12}
              spacing={0}
              className={`listItem 
              ${
                mwState.moduleData.checked.indexOf(data.id) > -1
                  ? "checked"
                  : null
              }`}
              alignItems="center"
            >
              <Grid item xs={1} className="chkBox">
                <CustomCheckbox
                  onChange={() => handleSingleCheckbox(data.id)}
                  checked={
                    mwState.moduleData.checked
                      ? mwState.moduleData.checked.indexOf(data.id) > -1
                      : false
                  }
                  name={`chk_${data.id}`}
                  value={data.id}
                />
              </Grid>
              <Grid item container xs={11} onClick={() => handleClick(data)}>
                <Grid className="ellipsis" item xs={3}>
                  {getMessageTo(data)}
                </Grid>
                <Grid className="ellipsis" item xs={3}>
                  {data.message}
                </Grid>
                <Grid className="ellipsis" item xs={3}>
                  {Message.getCannedMessageById(data.cannedmessage_id)}{" "}
                </Grid>
                <Grid className="ellipsis" item xs={2}>
                  {Clock.formatTimestamp(data.message_timestamp)}
                </Grid>
                <Grid item className="ellipsis status" xs={1}>
                  {_.keys(data.attachments).length > 0 ? (
                    <AttachmentIcon />
                  ) : null}
                  {showStatus(data)}
                </Grid>
              </Grid>
            </Grid>
          ) : null;
        })
      ) : (
        <Grid item xs={12} className="noMessages">
          {Language.translate("No Messages!")}
        </Grid>
      )}
    </Grid>
  );
}

export default Sent;
