import React from "react";
import TextField from "@material-ui/core/TextField";

function CustomTextField(props) {
  const {
    name,
    label,
    value,
    error = null,
    helperText,
    onChange,
    disabled = false,
    ...other
  } = props;
  return (
    <TextField
      variant="standard"
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      onKeyPress={other && other.onBlur}
      disabled={disabled}
      fullWidth
      autoComplete="off"
      {...(error && { error, helperText })}
      {...other}
    />
  );
}

export default CustomTextField;
