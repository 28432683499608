import React, { useState, useEffect, useContext } from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from "@material-ui/core/MenuItem";

// Components
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Internal
import Utils from "sccUtils";
import Filter from "./Filter";
import Search from "./Search";

function TopBar(props) {
  let {
    searchFilter,
    setSearchFilter,
    filterSelection,
    setFilterSelection,
    filterLabel,
    searchPlaceholder,
    filteredList,
    filteredData,
    ...other
  } = props;

  const [mwState, setMwState] = useContext(ModuleWrapperContext);

  const _ = require("lodash");

  const data =
    _.isArray(mwState.moduleData) && _.cloneDeep(mwState.moduleData)[0];

  const Device = require("sccDevice").default;

  const [filters, setFilters] = useState({
    title: searchFilter,
    ...filterSelection,
  });

  const [groupTree, setGroupTree] = useState(mwState.moduleData);

  function updateFilteredData(data) {
    setMwState((p) => ({
      ...p,
      filteredModuleData: data,
      searchExecuted: true,
    }));
  }

  function matchingDevices(group, filters) {
    var title = filters.title && filters.title.toLowerCase();
    var typeId = filters.type_id;

    return _.reduce(
      group.devices,
      function (result, deviceId) {
        var device = Device.get(deviceId);
        if (!device) return result || false;

        var filterTitleCheck =
          title !== ""
            ? device.name.toLowerCase().indexOf(title) > -1 ||
              device.imei.toLowerCase().indexOf(title) > -1
            : true;
        var filterTypeCheck = _.isEmpty(typeId)
          ? true
          : _.indexOf(typeId, device.type_id) > -1;

        if (filterTitleCheck && filterTypeCheck)
          result = _.concat(result, [deviceId]);

        return result || (filterTitleCheck && filterTypeCheck);
      },
      []
    );
  }

  function filteredTree(group, filters, type) {
    if (!filters) return false;

    if (!(filters.title || (filters.type_id && filters.type_id.length)))
      return false;

    type = type || "device";

    recursiveFilter(group, filters, 1, type);
  }

  function recursiveFilter(group, filters, level, type) {
    group.groups.map((subGroup) => {
      if (_.isArray(subGroup.groups) && subGroup.groups.length > 0) {
        level += 1;
        recursiveFilter(subGroup, filters, level, type);
        level -= 1;
      }
      if (matchingDevices(subGroup, filters).length > 0) {
        subGroup.devices = matchingDevices(subGroup, filters);
      } else {
        //if there is no matching device we dont need to look anY further, so we remove the group from parent groups array
        group.groups = group.groups.filter((obj) => !_.isEqual(obj, subGroup));
      }
    });
  }

  // on filter change
  function filterSelectionChange(e, filterName, originalData) {
    let allFilters = { ...filterSelection, title: searchFilter };
    let value = null;
    setSearchFilter("");
    if (e === null) {
      allFilters = { type_id: [], title: "" };
      setFilterSelection({ type_id: [], title: "" });
    } else {
      value = !isNaN(e.target.value)
        ? e.target.value == ""
          ? null
          : Number(e.target.value)
        : e.target.value.toString();
      if (filterName !== null) {
        if (_.indexOf(allFilters[filterName], value) > -1) {
          allFilters[filterName] = _.difference(allFilters[filterName], [
            value,
          ]);
        } else {
          allFilters[filterName] = _.union(allFilters[filterName], [value]);
        }
      }
      setFilterSelection({ type_id: allFilters.type_id });
      setFilters(allFilters);
    }
    const dataToFilter = filterData(
      _.isArray(mwState.moduleData) && _.cloneDeep(mwState.moduleData)[0],
      allFilters
    );
    updateFilteredData([dataToFilter]);
  }

  function clearFilterSelection(e, filterName, originalData) {
    filterSelectionChange(null, null, originalData);
  }

  //used to search
  useEffect(() => {
    const localFilters = {
      type_id: filterSelection.type_id,
      title: searchFilter,
    };
    setFilters(localFilters); // = {title: searchFilter, type_id: filterSelection.type_id}
    const dataToFilter = filterData(
      _.isArray(mwState.moduleData) && _.cloneDeep(mwState.moduleData)[0],
      localFilters
    );
    updateFilteredData([dataToFilter]);
  }, [searchFilter]);

  useEffect(() => {
    if (mwState.reRenderAssetsModule) {
      const localFilters = {
        type_id: filterSelection.type_id,
        title: searchFilter,
      };
      setFilters(localFilters); // = {title: searchFilter, type_id: filterSelection.type_id}
      const dataToFilter = filterData(
        _.isArray(mwState.moduleData) && _.cloneDeep(mwState.moduleData)[0],
        localFilters
      );
      updateFilteredData([dataToFilter]);
      setMwState((p) => ({
        ...p,
        reRenderAssetsModule: false,
      }));
    }
    console.log(mwState);
  }, [mwState.reRenderAssetsModule]);

  //the data is expected as the main group object and the filters contains both title and type_id data
  function filterData(data, filters) {
    if (data?.groups) {
      filteredTree(data, filters, "device");
      data.devices = matchingDevices(data, filters);
    }
    return data;
  }

  return (
    <Grid justifyContent="space-between" className="header" container xs={12}>
      <Grid item xs={5} className="filter">
        {mwState.showFilter && (
          <Filter
            name="filter"
            label={filterLabel}
            filterSelection={filterSelection}
            onChange={filterSelectionChange}
            data={data}
            filteredData={filteredData}
            updateFilteredData={updateFilteredData}
            clearFilterSelection={clearFilterSelection}
            searchFilter={searchFilter}
          />
        )}
      </Grid>
      <Grid item xs={7} align="right" className="searchFilter">
        {mwState.showSearch && (
          <Search
            id="txtSearch"
            name="search"
            placeholder={searchPlaceholder}
            className="textfield"
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            filterSelection={filterSelection}
            filteredList={filteredList}
            data={data}
            updateFilteredData={updateFilteredData}
            searchField={["name"]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  id="clearSearch"
                  onClick={() => setSearchFilter("")}
                  style={{ cursor: "pointer" }}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default TopBar;
