import React, { useState } from "react";

// External
import _ from "lodash";
import log from "loglevel";

// Internal
import Historic from "sccHistory";
import Device from "sccDevice";
import Group from "sccGroup";
import Language from "sccLanguage";
import HistoryOverlay from "sccHistoryOverlay";
import HistoryTrailOverlay from "sccHistoryTrailOverlay";

const HistoryContext = React.createContext([{}, () => {}]);

const HistoryContextProvider = (props) => {
  const [state, setState] = useState({
    Historic,
    Device,
    Group,
    //common reusable functions
    updateKeysInState,
    resetTrailOverlay,
    resetAllComponents,
    showDeviceTrailChanged,
    deviceVisibilityChanged,
    sliderChanged,
    displayMessage,
    setSliderTicks,
    capitalizeFirstLetter,
    setDeviceSelectData,
    setDeviceTreeData,
    checkForRemoteDevice,
    //shared variables
    hideNoEvents: false,
    showLeftPanel: true,
    showRightPanel: false,
    showHistoryControls: false,
    currentTimestamp: null,
    playEvent: false,
    eventFinished: false,
    noPrevEvent: true,
    showNotification: false,
    eventsDatasetValid: false, //used as the events are loaded in Historic even though they are out of our date range
    playInterval: null, // holds the interval number for History when in play mode.
    //objects
    notificationOptions: {},
    sliderTicks: [],
    filteredEvents: {
      emergency: true,
      speed: true,
      geofence: true,
      cargo: true,
      non_report: true,
      report: true,
      vehicle: true,
    },
    deviceSelectData: [],
    deviceTreeData: [],
  });

  function updateKeysInState(keys) {
    setState((p) => {
      return Object.assign({}, p, keys);
    });
  }

  function setDeviceSelectData(data) {
    updateKeysInState({ deviceSelectData: data });
  }

  function setDeviceTreeData(data) {
    updateKeysInState({ deviceTreeData: data });
  }

  function showDeviceTrailChanged(value) {
    if (value) return;
    resetTrailOverlay();
  }

  function setSliderTicks(events) {
    const marks = [];

    const filteredEvents = events || Historic.playOptions.event;
    _.each(filteredEvents, (value, key) => {
      _.each(Historic._allEvents, (report, event) => {
        if (
          value &&
          key === report.event &&
          report.event_timestamp >= Historic.getStartTimestamp()
        ) {
          marks.push({ value: report.event_timestamp, label: report.event });
        }
      });
    });

    updateKeysInState({ sliderTicks: _.orderBy(marks, ["value"]) });
  }

  function displayMessage(
    enqueueSnackbar,
    title,
    message,
    type = "error",
    options = {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    }
  ) {
    enqueueSnackbar(
      <p>
        {title != null ? (
          <React.Fragment>
            <strong>{title}</strong> <br />
          </React.Fragment>
        ) : null}
        {message}
      </p>,
      options
    );
  }

  function resetTrailOverlay() {
    const pastReports = Historic.getPastDeviceReports();
    //  make sure display report is after firstReportTimestamp
    const timestamp = Historic.currentTimestamp;
    const firstReportTimestamp = Historic.getFirstReportTimestamp();
    HistoryTrailOverlay.removeFeature();

    if (firstReportTimestamp <= timestamp) {
      HistoryTrailOverlay.addFeatures(pastReports);
    }
  }

  function resetAllComponents() {
    //resetTrailOverlay();
    HistoryOverlay.deselectFeature();
    updateKeysInState({
      showHistoryControls: false,
      currentTimestamp: Historic.getStartTimestamp(),
      playEvent: false,
      eventFinished: false,
      noPrevEvent: true,
      playInterval: null,
    });
  }

  function deviceVisibilityChanged(deviceIds) {
    deviceIds = _.concat([], deviceIds);
    _.each(deviceIds, (deviceId) => {
      if (!Historic.playOptions.device[deviceId].showTracks) {
        Historic.playOptions.device[deviceId].isFollow = false;
        Historic.playOptions.device[deviceId].showTrail = false;
        setState((p) => {
          return Object.assign({}, p, { Historic });
        });
      }
    });
    Historic.resetEventsStructures();
    sliderChanged();
  }

  function checkForRemoteDevice(id) {
    const device = Device.get(id);
    if (
      (device.type == "Sentry H6120 BM" ||
        device.type == "Sentry H6110 MP" ||
        device.type == "SAT-COM Leopard1" ||
        device.type == "Trellisware TSM TW-950") &&
      Device.getDeviceMode(device) == "Standalone"
    ) {
      return true;
    }
    return false;
  }

  function sliderChanged() {
    const timestamp = Historic.currentTimestamp;

    Historic.fastPlayTo(timestamp);
    const data = _.values(Historic.getCurrentDeviceReports());

    HistoryOverlay.removeFeature();

    //  make sure the data time is before timeStap and after start time
    const filteredData = data.filter(
      (d) =>
        d.event_timestamp <= timestamp &&
        d.event_timestamp >= Historic.getStartTimestamp()
    );

    if (filteredData?.length > 0) {
      HistoryOverlay.addFeature({ data: data });
    }

    const noPrevEvent =
      Historic.currentTimestamp > Historic.getStartTimestamp() ? false : true;

    const eventFinished =
      Historic.currentTimestamp === Historic.getEndTimestamp() ? true : false;

    updateKeysInState({
      noPrevEvent: noPrevEvent,
      eventFinished: eventFinished,
    });

    resetTrailOverlay();
  }

  //function to get alert type colors
  //yet to be used
  function getAlertTypeColor(timestamp) {
    const obj = Historic.getEventByTimestamp(timestamp);
    //check if the obj contains an event field that references an event type(e.g. emergency, geofence and so on)
    if (
      _.find(obj, {
        event: "emergency",
        alert_started: true,
        show: true,
      })
    )
      return "red";
    if (
      _.find(obj, {
        event: "geofence",
        alert_started: true,
        show: true,
      })
    )
      return "#F9840F";
    if (_.find(obj, { event: "speed", alert_started: true, show: true }))
      return "#c43cc4";
    if (_.find(obj, { event: "cargo", alert_started: true, show: true }))
      return "#148C90";
    if (
      _.find(obj, {
        event: "non_report",
        alert_started: true,
        show: true,
      })
    )
      return "#67D06C";
    if (_.find(obj, { event: "report", show: true })) return "#000";

    if (_.find(obj, { event: "vehicle", show: true })) return "#0091EA"; //temporary tick folor for vehicle events, to be replaced when exact HEX is provided to frontend team

    return "transparent";
  }

  function playNextReportEvent() {
    const skipHiddenEventTypes = true;
    const nextReport = Historic.getNextEventReport(skipHiddenEventTypes);
    const currentEvent = Historic.getCurrentEvent();
    if (!nextReport) {
      log.warn("No next event available");
      return;
    }
    log.warn("Next event available");
    // moving the current timestamp to the time of current event
    Historic.currentTimestamp = currentEvent.event_timestamp;
    setState({ currentTimestamp: currentEvent.event_timestamp });
  }

  const search = (e) => {
    setState((p) =>
      Object.assign({}, p, {
        searchFilter: e.target.value,
      })
    );
  };

  /**
   * Returns the string with capitalized first letter
   * @param String string
   */
  function capitalizeFirstLetter(string) {
    if (string == "non_report") {
      return "Non Report";
    }
    if (string == "vehicle") {
      return "Vehicle Events";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <HistoryContext.Provider value={[state, setState]}>
      {props.children}
    </HistoryContext.Provider>
  );
};
export { HistoryContext, HistoryContextProvider };
