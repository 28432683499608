export const customAssetSelector = (theme) => ({
  customAssetSelectorWrapper: {
    width: "100%",
    padding: 0,
    "& .customAssetSelectorTitle": {
      textAlign: "center",
      fontSize: "1.2rem",
      fontWeight: "bold",
      "& > .MuiBadge-root": {
        "& .MuiBadge-badge": {
          fontSize: "0.9rem",
        },
      },
    },
    "& .historyCustomAssetSelector": {
      width: "100% !important",
    },
  },
  customAssetSelector: {
    paddingLeft: 20,
  },
  mainGroupAdjustment: {
    marginLeft: -20,
  },
  assetGroup: {
    padding: 0,
    "& .assetGroupIcon": {
      cursor: "pointer",
      position: "absolute",
      left: -20,
      top: 15,
      fontSize: "1.2rem",
    },
    "& .MuiListItem-container": {
      border: "none",
      "& .MuiListItem-gutters": {
        paddingLeft: 6,
        paddingRight: 6,
      },
      "& .assetGroupLabel": {
        height: 40,
        backgroundColor: theme.palette.colors.gray.main,
        borderTop: `2px solid ${theme.palette.colors.gray.light}`,
        borderBottom: `1px solid ${theme.palette.colors.gray.dark}`,
        "& .MuiIconButton-edgeStart": {
          marginLeft: 0,
        },
        "& .MuiListItemIcon-root": {
          minWidth: 10,
        },
        "& .MuiListItemText-root": {
          "& .MuiListItemText-primary": {
            fontSize: ".9rem",
            overflowX: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        },
        "& .MuiTypography-body2": {
          fontWeight: "bold",
        },
      },
    },
  },
  assetDevice: {
    "& .assetDeviceLabel": {
      background: theme.palette.colors.white.main,
      border: `1px solid ${theme.palette.colors.gray.main}`,
      marginTop: -1,
      paddingTop: 4,
      paddingBottom: 4,
    },
    "& .listItemText": {
      width: 110,
      "& span": {
        width: "100%",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
    "& .pagingAndFilterContainer": {
      display: "flex",
    },
    "& .pagingContainer": {
      width: "45%",
      margin: "4px 0px",
      maxWidth: 150,
      "& .root": {
        paddingBottom: 0,
      },
    },
    "& .assetPagination": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 2,
      "& .pagination": {
        display: "flex",
        flexWrap: "nowrap",
        "& li a": {
          padding: "6px !important",
        },
      },
    },
    "& .noDevices": {
      textAlign: "center",
      fontWeight: 600,
      background: theme.palette.colors.white.main,
      border: `1px solid ${theme.palette.colors.gray.main}`,
      marginTop: 1,
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& .MuiCollapse-wrapperInner": {
      padding: 0,
      border: "none",
    },
    "& .assetDeviceFilterWrapper": {
      float: "left",
      width: "99%",
      margin: 5,
      "& .assetSelectorDropdown": {
        maxWidth: "50%",
        margin: "4px 0",
        "& .MuiInputBase-root": {
          margin: 0,
          top: 0,
          paddingLeft: 0,
          position: "initial",
          border: `1px solid ${theme.palette.colors.gray.main}`,
          background: theme.palette.colors.white.main,
          "& .MuiSelect-root": {
            paddingLeft: 8,
          },
          "& .MuiSelect-icon": {
            top: 3,
            right: 4,
          },
        },
      },
      "& .assetSelectorSearch": {
        maxWidth: "50%",
        margin: "4px 0",
        float: "right",
      },
      "& .MuiInputBase-root": {
        position: "initial",
        border: `1px solid ${theme.palette.colors.gray.main}`,
        background: theme.palette.colors.white.main,
        paddingLeft: 4,
      },
      "& .MuiInputBase-root": {
        position: "initial",
        border: `1px solid ${theme.palette.colors.gray.main}`,
        background: theme.palette.colors.white.main,
        paddingLeft: 4,
      },
    },
    "& .MuiTypography-body2": {
      fontSize: "0.9rem",
    },
  },
  itemActionButtonsContainer: {
    "& .MuiSvgIcon-root": {
      fontSize: "1.4rem",
    },
  },
});
