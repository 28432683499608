import React, { useState, useContext, useEffect } from "react";

//External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// Components
import CustomTextField from "@Components/CustomTextField";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import Device from "sccDevice";
import Group from "sccGroup";
import Language from "sccLanguage";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function FeedsForm() {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const formik = useFormikContext();
  //to be used in add edit
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;
  const handleFormInputCheck = mwState.handleFormInputCheck;
  const moduleFormValidations = mwState.moduleFormValidations;

  const newFeed = {
    title: "",
    feed_code: null,
    generate_token: false,
  };

  const handleDateBlur = () => {};

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      const feedData = mwState.moduleItemData;
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...newFeed,
          ...feedData,
        },
        moduleItemDataOriginal: { ...newFeed, ...feedData },
      }));
    }
  }, [mwState.wrapperDisplayMode]);

  const handleGenerateFeedCode = (e) => {
    formik.setFieldValue("generate_token", !moduleItemData.generate_token);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <p className="pullRight">
          {`* ${Language.translate("Required fields")}`}
        </p>
        <Grid container item direction="column" spacing={1}>
          <Grid item xs="12">
            <CustomTextField
              label={`${Language.translate("Name")}*`}
              value={moduleItemData.title}
              name="title"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>
          <Grid item xs="12">
            <CustomTextField
              label={`${Language.translate("Feed Code")}*`}
              value={moduleItemData.feed_code}
              name="feed_code"
              disabled={moduleItemData.id && moduleItemData.feed_code}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={
                formik.touched.feed_code && Boolean(formik.errors.feed_code)
              }
              helperText={formik.touched.feed_code && formik.errors.feed_code}
            />
          </Grid>
          {!moduleItemData.token && !moduleItemData.id && (
            <Grid item xs={12}>
              <CustomCheckbox
                label={Language.translate("Generate Token")}
                name="generate_token"
                onChange={(e) => handleGenerateFeedCode(e)}
                color="primary"
                value={moduleItemData.generate_token}
              />
            </Grid>
          )}

          {moduleItemData.token && (
            <Grid item xs="12">
              <CustomTextField
                label={`${Language.translate("Feed Token")}*`}
                value={String(moduleItemData.token)}
                name="token"
                disabled={moduleItemData.id && moduleItemData.token}
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={formik.touched.token && Boolean(formik.errors.token)}
                helperText={formik.touched.token && formik.errors.token}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
}
