import React, { useState, useContext } from "react";

// Material-UI
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

// Internal
import { HistoryContext } from "../../HistoryContext";
import log from "loglevel";
import HistoryOverlay from "sccHistoryOverlay";
import HistoryTrailOverlay from "sccHistoryTrailOverlay";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },

  ReplayBarButtonGroup: {
    margin: "0",
    padding: "0",
  },

  iconSize: {
    fontSize: 18,
  },

  button: {
    width: 50,
    height: 30,
  },
}));

export default function ReplayBarButtonGroup() {
  const [state, setState] = useContext(HistoryContext);
  const Historic = state.Historic;
  const [playInterval, setPlayInterval] = useState();
  const { updateKeysInState, sliderChanged, playEvent } = state;
  const classes = useStyles();

  let eventFinished = state.eventFinished;
  let noPrevEvent = state.noPrevEvent;

  const handleRewind = () => {
    playPreviousReportEvent();
  };
  const handlePlay = () => {
    let newPlayInterval;
    // set auto skip interval to 2000 which avoid map changes too quick to show device icon
    if(Historic.playOptions.autoSkip) {
      newPlayInterval = setInterval(
        () => playAutoSkip(newPlayInterval),
        2000 / Historic.playSpeed
      );
    } else {
      newPlayInterval = setInterval(
        () => playRealTime(newPlayInterval),
        1000 / Historic.playSpeed
      );

    }
    setPlayInterval(newPlayInterval);
    updateKeysInState({ playEvent: true, playInterval: newPlayInterval });
  };


  const handlePause = () => {
    pausePlay();
  };
  const handleForward = () => {
    playNextReportEvent();
  };

  function pausePlay() {
    clearInterval(playInterval);
    updateKeysInState({ playEvent: false, playInterval: null });
  }

  function showEventTrail(nextReport) {
    const currentReport = Historic.getPastReport();
    if (currentReport) {
      HistoryTrailOverlay.refresh(currentReport, nextReport);
    }
  }

  function playAutoSkip(playInterval) {
    log.debug("playing auto-skip", Historic.currentTimestamp);
    if (Historic.currentTimestamp >= Historic.getEndTimestamp()) {
      clearInterval(playInterval);
      updateKeysInState({
        playEvent: false,
        eventFinished: true,
        playInterval: null,
      });

      return;
    }
    playNextReportEvent(playInterval);
  }

   // next button click 
  function playNextReportEvent(playInterval) {
    const skipHiddenEventTypes = true;
    const nextReport = Historic.getNextReport(skipHiddenEventTypes);
    if (!nextReport) {
      clearInterval(playInterval);
      updateKeysInState({
        playEvent: false,
        eventFinished: true,
        noPrevEvent: false,
        playInterval: null,
        currentTimestamp: Historic.getEndTimestamp(),
      });
      //Forcing the replay to end the video
      Historic.currentTimestamp = Historic.getEndTimestamp();
      updateKeysInState(Historic);
      sliderChanged();
    } else if (nextReport?.event_timestamp) {
      updateKeysInState({
        noPrevEvent: false,
      });
      Historic.currentTimestamp = nextReport.event_timestamp;
      sliderChanged();
    }
  }
 

  function playRealTime(playInterval) {
    //let nextReport = Historic.getCurrentReport() || Historic.getNextEventReport();
    let nextReport = Historic.getNextEventReport();
    const noProgress = true;
    let nextEvent = Historic.getNextEvent(noProgress);
    
    if (Historic.currentTimestamp > Historic.getEndTimestamp()) {
      clearInterval(playInterval);
      updateKeysInState({
        playEvent: false,
        eventFinished: true,
        noPrevEvent: false,
        playInterval: null,
        currentTimestamp: Historic.getEndTimestamp(),
      });
      //Forcing the replay to end the video
      Historic.currentTimestamp = Historic.getEndTimestamp();
      updateKeysInState(Historic);
      return;
    }
    while (
      nextEvent &&
      nextEvent.event_timestamp <= Historic.currentTimestamp
    ) {
      nextReport = Historic.getNextEventReport();
      HistoryOverlay.refresh(nextReport);
      showEventTrail(nextReport);
      nextEvent = Historic.getNextEvent(noProgress);
    }
    //Historic.currentTimestamp++;
    //updateKeysInState(Historic);
    updateKeysInState({
      currentTimestamp: Historic.currentTimestamp++,
    });
    sliderChanged();
  }

  // previous button click 
  function playPreviousReportEvent() {
    const skipHiddenEventTypes = true;
    const prevReport = Historic.getPreviousReport(skipHiddenEventTypes);
    updateKeysInState({ playEvent: false });
    if (!prevReport) {
      updateKeysInState({ noPrevEvent: true });
      //Forcing the replay to start the video
      Historic.currentTimestamp = Historic.getStartTimestamp();
      sliderChanged();
    } else if (prevReport?.event_timestamp) {
      Historic.currentTimestamp = prevReport.event_timestamp;
      sliderChanged();
    }
  }

  return (
    <div className={classes.ReplayBarButtonGroup}>
      <ButtonGroup
        variant="contained"
        color="default"
        aria-label="contained primary button group"
      >
        <Button
          onClick={handleRewind}
          disabled={noPrevEvent}
          className={classes.button}
        >
          <SkipPreviousIcon className={classes.iconSize} />
        </Button>
        {!playEvent ? (
          <Button
            onClick={handlePlay}
            disabled={eventFinished}
            className={classes.button}
          >
            <PlayArrowIcon />
          </Button>
        ) : (
          <Button onClick={handlePause}>
            <PauseIcon className={classes.iconSize} />
          </Button>
        )}

        {/* <Button onClick={handlePause}><PauseIcon fontSize="large" /></Button> */}
        <Button
          onClick={handleForward}
          disabled={eventFinished}
          className={classes.button}
        >
          <SkipNextIcon className={classes.iconSize} />
        </Button>
      </ButtonGroup>
    </div>
  );
}
