import React, { useState, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import EventIcon from "@material-ui/icons/Event";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomTooltip from "@Components/CustomTooltip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// Internal
import Language from "sccLanguage";
import { HistoryContext } from "../../HistoryContext";
import EventReport from "../EventReport/EventReport";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },

  eventReportsPopper: {
    minWidth: 250,
    maxWidth: 300,
    zIndex: 100,
  },

  h4: {
    fontWeight: "bold",
    paddingLeft: "10px",
  },

  eventReport: {
    width: "100%",
  },

  noEvents: {
    padding: "3px",
    textAlign: "center",
    marginBottom: "6px",
    fontSize: 15,
  },

  eventReportsMenu: {
    marginBottom: 20,
    marginTop: 0,
  },
}));

export default function EventReports(props) {
  const classes = useStyles();
  const [state] = useContext(HistoryContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { Historic } = state;
  const filteredEvents = state.filteredEvents;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  function getEvents() {

    const events = Historic.getCurrentDeviceReports();

    // the following code is to make sure that events of the same device show close to each other
    const groupedEvents = _.sortBy(events, "device_id");
    return groupedEvents;
  }

  const [open, setOpen] = useState(Boolean(anchorEl));
  const id = open ? "eventReportsPopper" : undefined;

  const events = getEvents();

  return (
    <div>
      <CustomTooltip title={Language.translate("Events")} arrow placement="top">
        <Button
          className="btn btn-default"
          aria-describedby={id}
          variant="contained"
          color="default"
          onClick={handleClick}
          startIcon={<EventIcon />}
        ></Button>
      </CustomTooltip>
      <Popper
        className={classes.eventReportsPopper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="top-end"
        modifiers={{ offset: { enabled: true, offset: "0, 30" } }}
      >
        <Paper elevation={8} className="scrollable">
          <Grid container alignItems="center" direction="column">
            <Grid item container>
              <Grid item xs={10}>
                <h4 className={classes.h4}>{Language.translate("Events")}</h4>
              </Grid>
              <Grid item container xs={2} justifyContent="flex-end">
                <IconButton disableRipple={true} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ul className={classes.eventReportsMenu}>
                {events ? (
                  events.map((event, index) =>
                    filteredEvents[event.event] ? (
                      <li key={index} className={classes.eventReport}>
                        <EventReport currentEvent={event} />
                      </li>
                    ) : (
                      ""
                    )
                  )
                ) : (
                  <p className={classes.noEvents}>
                    {Language.translate("No Event to display")}
                  </p>
                )}
              </ul>
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </div>
  );
}
