import React from "react";
import clsx from "clsx";
import { ButtonProps, Button, makeStyles, capitalize } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
//This button can accept the following colors
//command (Add, Edit, Login, Search)
//action (Save)
//cancel
//back
//download
//delete
//default

const EXTRA_COLORS = ["command", "action", "cancel", "back", "delete", "download"];

const useStyles = props => makeStyles(theme => {
	const variant = props.variant || "contained";
	const color = props.color || "command";
	const styles = {
		disabled: {},
		[`text${capitalize(color)}`]: {
			color: theme.palette.buttons[`${color}`].main,
			"&:hover": {
				backgroundColor: alpha(theme.palette.buttons[`${color}`].main, theme.palette.action.hoverOpacity),
				"@media (hover: none)": {
					backgroundColor: "transparent"
				}
			}
		},
		[`outlined${capitalize(color)}`]: {
			color: theme.palette.buttons[`${color}`].main,
			border: `1px solid ${alpha(theme.palette.buttons[`${color}`].main, 0.5)}`,
			"&:hover": {
				border: `1px solid ${theme.palette.buttons[`${color}`].main}`,
				backgroundColor: alpha(theme.palette.buttons[`${color}`].main, theme.palette.action.hoverOpacity),
				"@media (hover: none)": {
					backgroundColor: "transparent"
				}
			},
			"&$disabled": {
				border: `1px solid ${theme.palette.action.disabled}`
			}
		},
		[`contained${capitalize(color)}`]: {
			color: theme.palette.buttons[`${color}`].contrastText,
			backgroundColor: theme.palette.buttons[`${color}`].main,
			"&:hover": {
				backgroundColor: theme.palette.buttons[`${color}`].dark,
				"@media (hover: none)": {
					backgroundColor: theme.palette.buttons[`${color}`].main
				}
			},
			"&.Mui-disabled": {
				pointerEvents: "auto",
				cursor: "not-allowed",
				backgroundColor: theme.palette.buttons[`${props.color}`].light,
				"&:hover": {
					backgroundColor: theme.palette.buttons[`${props.color}`].light
				}
			}
		}
	};
	return (styles);
});

const CustomButton = function (
	props: Omit<ButtonProps, "color"> & { color: ButtonProps["color"] | "command" | "action" | "cancel" | "back" | "download" | "delete" }
) {
	const { disabled, className, color = "default", variant = "contained", children, onChange, ...otherProps } = props;
	const classes = useStyles(props)();
	return <Button
		className={
			EXTRA_COLORS.includes(color)
				? clsx(
					{
						[classes.disabled]: disabled,
						[classes[`${variant}${capitalize(color)}`]]: color !== "default" && color !== "inherit"
					},
					className
				)
				: className
		}
		disabled={disabled}
		color={EXTRA_COLORS.includes(color) ? undefined : color}
		onChange={onChange}
		{...otherProps}>{children}</Button>;
};

export default CustomButton;
