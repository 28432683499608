import React, { useState, useContext, useEffect } from "react";

//Material UI
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AttachmentIcon from "@material-ui/icons/Attachment";
import CheckIcon from "@material-ui/icons/Check";
import Badge from "@material-ui/core/Badge";
import Link from "@material-ui/core/Link";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import ReplyIcon from "@material-ui/icons/Reply";
import NearMeIcon from "@material-ui/icons/NearMe";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

//External
import _ from "lodash";
import moment from "moment";

//Internal
import Language from "sccLanguage";
import Profile from "sccProfile";
import Message from "sccMessage";
import Permission from "sccPermission";
import Device from "sccDevice";
import User from "sccUser";
import Clock from "sccClock";
import CustomButton from "@Components/CustomButton";
import CustomCheckbox from "@Components/CustomCheckbox";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import CustomTooltip from "@Components/CustomTooltip";
import { moduleMessage } from "@Styles//ModuleMessage";

function MessageRead(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const data = mwState.moduleItemData;
  const folderIndex = props.folderIndex;
  const folderName = props.folderName;
  const options = [
    1,
    mwState.pagination.itemsPerPage,
    mwState.moduleData.currentFolder,
    mwState.moduleData.sortDesc,
  ]; //used for reloading
  if (mwState.searchExecuted) options[4] = mwState.searchText;
  const resendPendingMessages = mwState.resendPendingMessages;
  const markMessageAsRead = mwState.markMessageAsRead;

  function getMessageSentResult(result) {
    if (mwState.moduleData.currentFolder === "monitor") {
      return "-primary";
    }
    if (result == "pending") {
      return "-warning";
    } else if (result == "sent") {
      return "-success";
    } else if (result == "fail") {
      return "-danger";
    } else {
      return;
    }
  }
  function moveMessagesToTrash() {
    mwState.moveMessagesToTrash([data.id], options);
  }
  function restoreDeletedMessages() {
    mwState.restoreDeletedMessages([data.id], options);
  }
  function replyToMessage(e) {
    mwState.changeToComposeMode(e, data.id);
  }
  function actionsRender(index) {
    switch (index) {
      case 0:
        return (
          <Grid container>
            <Grid item xs={6}>
              <CustomButton
                variant="outlined"
                onClick={replyToMessage}
                color="default"
                startIcon={<ReplyIcon />}
              >
                {Language.translate("Reply").toUpperCase()}
              </CustomButton>
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                variant="outlined"
                onClick={moveMessagesToTrash}
                color="default"
                startIcon={<DeleteIcon />}
              >
                {Language.translate("TRASH")}
              </CustomButton>
            </Grid>
          </Grid>
        );
        break;
      case 1:
        return (
          <Grid container>
            {/* need to add this condition later. copied from ang system */}
            {/* {_.keys(Message.get().messages[data.id].recipients.device_message_status).length && } */}
            <Grid item xs={6}>
              <CustomButton
                variant="outlined"
                color="default"
                onClick={() => resendPendingMessages(data.id)}
                startIcon={<NearMeIcon />}
              >
                {Language.translate("Resend").toUpperCase()}
                <Badge
                  color="default"
                  className="count"
                  badgeContent={
                    _.keys(
                      Message.get().messages[data.id].recipients
                        .device_message_status
                    ).length
                  }
                  max={9999}
                />
              </CustomButton>
            </Grid>
            <Grid item xs={6} className={classes.trash}>
              <CustomButton
                variant="outlined"
                onClick={moveMessagesToTrash}
                color="default"
                startIcon={<DeleteIcon />}
              >
                {Language.translate("TRASH")}
              </CustomButton>
            </Grid>
          </Grid>
        );
        break;
      case 2:
        return (
          <Grid container>
            <Grid item xs={6}>
              <CustomButton
                variant="outlined"
                onClick={restoreDeletedMessages}
                color="default"
                startIcon={<DeleteIcon />}
              >
                {Language.translate("RESTORE")}
              </CustomButton>
            </Grid>
          </Grid>
        );
        break;
      case 3:
        break;
    }
  }

  useEffect(() => {
    //mark message as read only if in inbox. This seems like a bug to me, but currently it return an error if the code is executed in trash
    if (options[2] == "inbox") {
      const userId = Profile.get("id");
      const messageObj = Message.get().messages[data.id];
      if (!messageObj.recipients.user_recipient_info[userId].message_read)
        markMessageAsRead(data.id, options);
    }
  }, []);

  useEffect(() => {
    if (mwState.reloadMessages) {
      mwState.refreshMessageCounts();
      setMwState((p) => ({ ...p, reloadMessages: false }));
    }
  }, [mwState.reloadMessages]);

  const useStyles = makeStyles((theme) => ({
    ...moduleMessage(theme),
    trash: {
      textAlign: "end",
    },
  }));
  const classes = useStyles();

  return (
    <Grid container xs={12} className={classes.messageContainer}>
      <Grid item xs={9} className="date">
        {Clock.formatTimestamp(data.message_timestamp)}
      </Grid>
      <Grid item xs={3} className="action">
        {actionsRender(folderIndex)}
      </Grid>
      <Grid item xs={12}>
        <h2>
          {Message.get().messages[data.id].senders?.devices
            ? Message.get().messages[data.id].senders.devices.map(
                (deviceId) => Device.get(deviceId).name
              )
            : null}
          {Message.get().messages[data.id].senders?.users
            ? Message.get().messages[data.id].senders.users.map(
                (userId) =>
                  User.get(userId).first_name + " " + User.get(userId).last_name
              )
            : null}
        </h2>
        {_.keys(data.attachments).length > 0
          ? Message.get().messages[data.id].attachments.map((attachment) => {
              return (
                <div className="attachments">
                  <CustomTooltip
                    title={attachment.attachment_name}
                    arrow
                    placement="top"
                  >
                    <a href="#">
                      <AttachmentIcon />
                      <span>
                        {attachment.attachment_name} (
                        {attachment.attachment_size}
                        kb)
                      </span>
                    </a>
                  </CustomTooltip>
                </div>
              );
            })
          : null}
      </Grid>
      <Divider />
      <Grid item xs={12}>
        {Message.get().messages[data.id].recipients.devices
          ? Message.get().messages[data.id].recipients.devices.map(
              (deviceId) => {
                return (
                  <Chip
                    className={`chip${getMessageSentResult(
                      Message.get().messages[data.id].recipients
                        .device_message_status[deviceId].message_status
                    )}`}
                    avatar={
                      <Avatar>
                        <LaptopMacIcon />
                      </Avatar>
                    }
                    label={Device.get(deviceId).name}
                    variant="outlined"
                  />
                );
              }
            )
          : null}
        {Message.get().messages[data.id].recipients.users &&
        Permission.verify("user", "view")
          ? Message.get().messages[data.id].recipients.users.map((userId) => {
              return (
                <Chip
                  className="chip-success"
                  avatar={
                    <Avatar>
                      <LaptopMacIcon />
                    </Avatar>
                  }
                  label={
                    User.get(userId).first_name +
                    " " +
                    User.get(userId).last_name
                  }
                  variant="outlined"
                />
              );
            })
          : null}
      </Grid>
      <Grid item xs={12}>
        <h3>{Language.translate("Canned Message")}</h3>
        {Message.getCannedMessageById(data.cannedmessage_id)}
      </Grid>
      <Grid item xs={12}>
        <h3>{Language.translate("Message")}</h3>
        {data.message}{" "}
      </Grid>
    </Grid>
  );
}

export default MessageRead;
