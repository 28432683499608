import { moduleListing } from "./ModuleListing";

export const moduleIridiumBilling = (theme) => ({
  searchDateContainer: {
    margin: 10,
    padding: 0,
    backgroundColor: theme.palette.colors.white.main,
    color: theme.palette.colors.black.main,
  },
  textFieldDate: {
    paddingRight: 20,
  },
  submitButton: {
    marginTop: 15,
  },
  loading: {
    textAlign: "center",
  },
  noResults: {
    textAlign: "center",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
