import React, { useEffect } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";

// Internal
import Message from "sccMessage";
import Chat from "sccChat";
import Utils from "sccUtils";
import Language from "sccLanguage";

const maxFileSize = 100000; // in Bytes 100kb

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  chatFooter: {
    "& .TextField": {
      padding: "2px",
      margin: "2px",
      width: "98%",
    },

    "& .fileUploadInput": {
      visibility: "hidden",
    },
  },

  DataDisplayHeader: {
    background: theme.palette.colors.gray.main,
    boxShadow: "none",
    color: theme.palette.colors.blue.dark,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: 18,
  },

  sendButton: {
    backgroundColor: theme.palette.colors.blue.main,

    "&:hover": {
      backgroundColor: theme.palette.buttons.command.main,
    },
  },
  sendIcon: {
    paddingRight: "4px",
  },

  uploadFileGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  uploadFileGridFirst: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: 8,
  },

  deleteFileIcon: {
    borderRadius: 0,
  },

  buttonEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },

  cannedMessageOption: {
    cursor: "pointer",
    height: 16,
    padding: "4px",

    "&:hover": {
      backgroundColor: theme.palette.colors.gray.main,
    },
  },
}));

export default function ChatFooter({ device }) {
  var [cannedMessage, setCannedMessage] = React.useState("-1");
  var [textMessage, setTextMessage] = React.useState("");
  var [uploadFile, setUploadFile] = React.useState("");
  var [attachment, setAttachment] = React.useState({});

  const isNortac = device.type.toLowerCase().includes("nortac orion");

  const handleCannedChange = (event) => {
    setCannedMessage(event.target.value);
  };
  const handleTextChange = (event) => {
    setTextMessage(event.target.value);
  };

  const classes = useStyles();

  const obj = device.id ? device : Object.values(device)[0];

  const cannedMessages = Message.getCannedMessage();

  const triggerGetAttachment = () => {
    document.getElementById("chatAddAttachment").click();
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0] && event.target.files[0];

    if (file.size > maxFileSize) {
      Utils.notify(
        Language.translate("Attachment size is too big"),
        Language.translate("max size is 100kb"),
        "",
        "error"
      );
      document.getElementById("chatAddAttachment").value = null;
    } else if (!["image/jpg", "image/jpeg", "text/plain"].includes(file.type)) {
      Utils.notify(
        Language.translate("Attachment type is not supported"),
        Language.translate("only jpg, jpeg, and txt are supported"),
        "",
        "error"
      );
      document.getElementById("chatAddAttachment").value = null;
    } else {
      const base64 = await convertBase64(file);
      const data = base64.substring(base64.indexOf(",") + 1);
      // adding data to the file object
      file.data = data;
      file.id = 1;
      var attachmentName = document
        .getElementById("chatAddAttachment")
        .value.match(/(?<=(C:\\fakepath\\)).+$/gm);
      setUploadFile(file);
      setAttachment({
        name: attachmentName,
        type: file.size,
        size: file.size,
        attachments: [
          {
            attachment_name: file.name,
            attachment_type: file.type,
            attachment_size: file.size,
            attachment_data: file.data,
          },
        ],
      });
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const removeAttachment = () => {
    // reset all locations with file to empty object or null
    setUploadFile({});
    setAttachment({});

    if (document.getElementById("chatAddAttachment")) {
      document.getElementById("chatAddAttachment").value = null;
    }
  };

  const handleSend = () => {
    Chat.chatmessage.message = textMessage;
    Chat.chatmessage.cannedmessage_id =
      cannedMessage > -1 ? cannedMessage : "null";
    _.isUndefined(attachment.attachments)
      ? (Chat.chatmessage.attachments = [])
      : (Chat.chatmessage.attachments = attachment.attachments);

    Chat.sendChatMessage(obj.id);
    setTextMessage("");
    setCannedMessage("-1");
    removeAttachment();
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("chatSendButton").click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div className={classes.chatFooter}>
      <AppBar position="static" className={classes.DataDisplayHeader}>
        {attachment.name?.length > 0 ? (
          <Grid container alignItems="center">
            <Grid item xs={8} className={classes.uploadFileGridFirst}>
              {attachment.name}
            </Grid>
            <Grid item xs={2} className={classes.uploadFileGrid}>
              {((Math.ceil(uploadFile.data?.length / 4) * 3) / 1024).toFixed(2)}{" "}
              KB
            </Grid>{" "}
            {/* Base64 encode is 3 bytes of data into 4 characters so length times 3 divided by 4 returns data size in bytes, then 1024 bytes to a kilobyte */}
            <Grid item xs={2} className={classes.uploadFileGrid}>
              <IconButton
                size="medium"
                color="inherit"
                className={classes.deleteFileIcon}
                onClick={() => removeAttachment()}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <TextField
          fullWidth
          // className="TextField"
          id="outlined-basic"
          onChange={handleTextChange}
          value={textMessage}
          className="TextField chatBoxTextBox"
          variant="outlined"
        />
        <TextField
          select
          fullWidth
          // className="TextField"
          className="TextField chatboxCannedMessages"
          value={cannedMessage}
          onChange={handleCannedChange}
          variant="outlined"
        >
          <option key="-1" value="-1" className={classes.cannedMessageOption}>
            {Language.translate("No Canned Message Selected.")}
          </option>
          {_.map(cannedMessages, (message) => {
            return (
              <option
                key={message.id}
                value={message.id}
                className={classes.cannedMessageOption}
              >
                {message.canned_message}
              </option>
            );
          })}
        </TextField>
        <Toolbar className={isNortac ? "" : classes.buttonEnd}>
          {isNortac && (
            <>
              <Button
                size="medium"
                color="inherit"
                onClick={() => triggerGetAttachment()}
              >
                <AttachFileIcon fontSize="large" />
              </Button>
              <input
                id="chatAddAttachment"
                type="file"
                onChange={(e) => handleFileRead(e)}
                className="fileUploadInput"
                accept=".jpg,.jpeg,.txt"
              />
            </>
          )}
          <Button
            size="medium"
            color="inherit"
            onClick={handleSend}
            id="chatSendButton"
            className={classes.sendButton}
            disabled={
              isNortac
                ? textMessage.length === 0 &&
                  _.isUndefined(attachment.attachments) &&
                  cannedMessage == -1
                : textMessage.length === 0 && cannedMessage == -1
            }
          >
            <SendIcon fontSize="medium" className={classes.sendIcon} />
            {Language.translate("SEND")}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
