import React from "react";

// External
import _ from "lodash";

// Material-UI
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import Badge from "@material-ui/core/Badge";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

// Internal
import AddressBook from "sccAddressBook";
import Poi from "sccPoi";
import Language from "sccLanguage";
import Images from "sccImage";
import Geofence from "sccGeofence";
import Clock from "sccClock";
import Sync from "sccSync";
import SyncMenu from "sccSyncMenu";
import TimeUtils from "sccTimeUtils";
import CustomTooltip from "@Components/CustomTooltip";
import CustomIcon from "@Components/CustomIcon";
import SyncDeviceInfo from "./SyncDeviceInfo";
import { moduleListing } from "@Styles/ModuleListing";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  detailsTime: {
    padding: "16px 8px 4px 8px",
  },

  timeLabel: {
    fontWeight: 700,
    paddingLeft: 8,
  },

  detailContainer: {
    padding: "4px",
  },

  detailHeader: {
    backgroundColor: theme.palette.colors.gray.dark,
    "& th": {
      padding: "4px 8px",
      fontWeight: 700,
    },
  },

  tableRow: {
    "& td": {
      padding: "4px 8px",
      fontSize: 12,
      fontWeight: 500,
    },
    "& th": {
      padding: "4px 8px",
      fontSize: 12,
      fontWeight: 500,
    },
  },

  geofenceIconContainer: {
    padding: "4px 8px 4px 13px !important",
  },

  // display geofence icon
  inactiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.colors.gray.main,
    border: `1px solid ${theme.palette.colors.gray.main}`,
    borderRadius: "50%",
  },

  inclusiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.colors.green.main,
    border: `1px solid ${theme.palette.colors.gray.main}`,
    borderRadius: "50%",
  },

  exclusiveCircle: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.colors.red.main,
    border: `1px solid ${theme.palette.colors.gray.main}`,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
  },

  inactivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.colors.gray.main,
    border: `1px solid ${theme.palette.colors.gray.main}`,
    transform: "rotate(-45deg)",
  },

  inclusivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.colors.green.main,
    border: `1px solid ${theme.palette.colors.green.main}`,
    transform: "rotate(-45deg)",
  },

  exclusivePath: {
    width: 20,
    height: 0,
    backgroundColor: theme.palette.colors.red.main,
    border: `1px solid ${theme.palette.colors.red.main}`,
    transform: "rotate(-45deg)",
  },

  inactiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.colors.gray.main,
    border: `1px solid ${theme.palette.colors.gray.main}`,
  },

  inclusiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.colors.green.main,
    border: `1px solid ${theme.palette.colors.gray.main}`,
  },

  exclusiveRec: {
    width: 20,
    height: 10,
    backgroundColor: theme.palette.colors.red.main,
    border: `1px solid ${theme.palette.colors.gray.main}`,
  },

  inactivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.colors.gray.main,
    margin: "5.77px 0px",

    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.colors.green.main}`,
    },

    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      width: 0,
      borderLeft: `5.77px solid ${theme.palette.colors.green.main}`,
    },
  },

  inclusivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.colors.green.main,
    margin: "5.77px 0px",

    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.colors.green.main}`,
    },

    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      width: 0,
      borderLeft: `5.77px solid ${theme.palette.colors.green.main}`,
    },
  },

  exclusivePolygon: {
    position: "relative",
    height: 20,
    width: 11.55,
    backgroundColor: theme.palette.colors.red.main,
    margin: "5.77px 0px",

    "&::before": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      right: "100%",
      borderRight: `5.77px solid ${theme.palette.colors.red.main}`,
    },

    "&::after": {
      content: "''",
      position: "absolute",
      width: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      left: "100%",
      width: 0,
      borderLeft: `5.77px solid ${theme.palette.colors.red.main}`,
    },
  },

  poiMarkerImg: {
    maxHeight: 25,
    height: 22,
    maxWidth: 45,
    display: "block",
  },
}));

export default function SyncDetail({ data }) {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  function handleTabs(e, val) {
    setValue(val);
  }

  function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div>
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }

  function zoomToCoord(longitude, latitude) {
    const olMap = require("sccOlMapNew").default;
    const zoom = olMap.mapQuickZoomInLevel;
    const orgCoord = [longitude, latitude];
    const coord = olMap.transformToMapCoordinate(orgCoord);
    olMap.setCenterAndZoom(coord, zoom);
  }

  // display geofence icon
  const renderedGeofenceHeaderIcon = (obj) => {
    switch (obj.shape) {
      case "polygon":
        return (
          <div
            className={
              obj.inclusive
                ? classes.inclusivePolygon
                : classes.exclusivePolygon
            }
          />
        );

      case "path":
        return (
          <div
            className={
              obj.inclusive ? classes.inclusivePath : classes.exclusivePath
            }
          />
        );

      case "rectangle":
        return (
          <div
            className={
              obj.inclusive ? classes.inclusiveRec : classes.exclusiveRec
            }
          />
        );
      case "circle":
      default:
        return (
          <div
            className={
              obj.inclusive ? classes.inclusiveCircle : classes.exclusiveCircle
            }
          />
        );
    }
  };

  return (
    <Grid item xs={12} md={12} className="SyncDetails">
      <Tabs
        value={value}
        onChange={handleTabs}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          label={Language.translate("Details")}
          style={{ fontSize: 9 }}
          icon={
            <Badge
              color="primary"
              badgeContent={
                _.keys(Sync.get(data.id).current_syncs.geofences).length +
                _.keys(Sync.get(data.id).current_syncs.pois).length
              }
            >
              <CustomIcon style={{ fontSize: 9 }} id="icon-list" />
            </Badge>
          }
        />

        <Tab
          label={Language.translate("Information")}
          style={{ fontSize: 9 }}
          icon={<CustomIcon style={{ fontSize: 9 }} id="icon-info-circle" />}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container className={classes.detailsTime}>
          <Grid item xs={6}>
            <span className={classes.timeLabel}>
              {Language.translate("Last notified").toUpperCase() + ":"}{" "}
            </span>
            {data.last_ring_sent
              ? Clock.formatTimestamp(data.last_ring_sent / 1000)
              : "N/A"}
          </Grid>
          <Grid item xs={6}>
            <span className={classes.timeLabel}>
              {Language.translate("Last Synced").toUpperCase() + ":"}{" "}
            </span>
            {data.last_synced_timestamp
              ? Clock.formatTimestamp(data.last_synced_timestamp / 1000)
              : "N/A"}
          </Grid>
        </Grid>

        <div className={classes.detailContainer}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow className={classes.detailHeader}>
                  <TableCell>{Language.translate("Icon")} </TableCell>
                  <TableCell>{Language.translate("Name")}</TableCell>
                  <TableCell>{Language.translate("Type")}</TableCell>
                  <TableCell>{Language.translate("Action")}</TableCell>
                  <TableCell>{Language.translate("Last Modified")}</TableCell>
                  <TableCell>{Language.translate("Modified By")}</TableCell>
                  <TableCell>{Language.translate("Locate")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.current_syncs?.geofences &&
                  Object.values(data.current_syncs?.geofences).length > 0 &&
                  Object.values(data.current_syncs?.geofences).map((geo) => {
                    const geofenceInfo = Geofence.get(Number(geo.id));
                    return (
                      <TableRow key={geo.id} className={classes.tableRow}>
                        <TableCell
                          className={
                            geofenceInfo.shape === "polygon"
                              ? classes.geofenceIconContainer
                              : ""
                          }
                        >
                          <CustomTooltip title={geofenceInfo.title}>
                            {renderedGeofenceHeaderIcon(geofenceInfo)}
                          </CustomTooltip>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {geofenceInfo.title}
                        </TableCell>
                        <TableCell>{Language.translate("Geofence")}</TableCell>
                        <TableCell>
                          {geo.action ? Language.translate(geo.action) : "N/A"}
                        </TableCell>
                        <TableCell>
                          {geo.last_modified_timestamp
                            ? TimeUtils.getAge(
                                geo.last_modified_timestamp / 1000
                              )
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {geo.modifier_comm_id
                            ? SyncMenu.getModifierUserName(geo.modifier_comm_id)
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          <CustomTooltip
                            title={Language.translate("Locate")}
                            placement="top"
                          >
                            <IconButton
                              className="zoomToCoord"
                              edge="end"
                              aria-label="locate"
                              onClick={() =>
                                zoomToCoord(
                                  geofenceInfo.longitude,
                                  geofenceInfo.latitude
                                )
                              }
                            >
                              <MyLocationIcon />
                            </IconButton>
                          </CustomTooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {data?.current_syncs?.pois &&
                  Object.values(data.current_syncs?.pois).length > 0 &&
                  Object.values(data.current_syncs?.pois).map((poi) => {
                    const PoiInfo = Poi.get(Number(poi.id));
                    // data.current_syncs.pois
                    return (
                      <TableRow key={poi.id} className={classes.tableRow}>
                        <TableCell component="th" scope="row">
                          {data.image_id == null ? (
                            <img
                              className={classes.poiMarkerImg}
                              name="image"
                              alt={PoiInfo.title}
                              src={Poi.getNatoSymbol(PoiInfo.nato_code)}
                              required
                            />
                          ) : (
                            <img
                              className={classes.poiMarkerImg}
                              name="image"
                              alt={PoiInfo.title}
                              src={Images.getImageSource(PoiInfo.image_id)}
                            />
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {PoiInfo.title}
                        </TableCell>
                        <TableCell>{Language.translate("Poi")}</TableCell>
                        <TableCell>
                          {poi.action ? Language.translate(poi.action) : "N/A"}
                        </TableCell>
                        <TableCell>
                          {poi.last_modified_timestamp
                            ? TimeUtils.getAge(
                                poi.last_modified_timestamp / 1000
                              )
                            : "N/A"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {poi.modifier_comm_id
                            ? SyncMenu.getModifierUserName(poi.modifier_comm_id)
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          <CustomTooltip
                            title={Language.translate("Locate")}
                            placement="top"
                          >
                            <IconButton
                              className="zoomToCoord"
                              edge="end"
                              aria-label="locate"
                              onClick={() =>
                                zoomToCoord(PoiInfo.longitude, PoiInfo.latitude)
                              }
                            >
                              <MyLocationIcon />
                            </IconButton>
                          </CustomTooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {data?.current_syncs?.addressbooks &&
                  Object.values(data.current_syncs.addressbooks).length > 0 &&
                  Object.values(data.current_syncs.addressbooks).map(
                    (addressbook) => {
                      const adInfo = AddressBook.get(Number(addressbook.id));
                      return (
                        <TableRow key={addressbook.id}>
                          <TableCell component="th" scope="row">
                            <ListItemIcon>
                              <CustomTooltip
                                title={data.title}
                                placement="left"
                              >
                                <span>
                                  <CustomIcon
                                    id={`icon-address-book`}
                                    style={{ fontSize: 9 }}
                                  />
                                </span>
                              </CustomTooltip>
                            </ListItemIcon>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {adInfo.title}
                          </TableCell>
                          <TableCell>
                            {Language.translate("Addressbook")}
                          </TableCell>
                          <TableCell>
                            {addressbook.action
                              ? Language.translate(addressbook.action)
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            {addressbook.last_modified_timestamp
                              ? TimeUtils.getAge(
                                  addressbook.last_modified_timestamp / 1000
                                )
                              : "N/A"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {addressbook.modifier_comm_id
                              ? SyncMenu.getModifierUserName(
                                  addressbook.modifier_comm_id
                                )
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SyncDeviceInfo deviceId={data.id} />
      </TabPanel>
    </Grid>
  );
}
