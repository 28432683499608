import React, { useState, useContext } from "react";

//External
import _ from "lodash";

// Material-UI
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

// Components
import AssetSelectorDisplay from "@Components/CustomAssetWrapper/AssetSelectorListingDisplay/AssetSelectorDisplay";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import CustomIcon from "@Components/CustomIcon";

// Styles
import { moduleListingDetails } from "@Styles/ModuleListingDetails";

// Internal
import Language from "sccLanguage";
import Utils from "sccUtils";
import Group from "sccGroup";

const useStyles = makeStyles((theme) => ({
  ...moduleListingDetails(theme),
}));

export default function NrDetail(props) {
  const data = props.data;
  const [value, setValue] = useState(0);
  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const buildCollectionToDisplay = assetSelectorState.buildCollectionToDisplay;
  const [numOfDevices, setNumOfDevices] = useState(undefined);

  function handleTabs(e, val) {
    setValue(val);
  }

  function calculateDeviceTotal() {
    var deviceCount = 0;
    for (const index in data.members.groups) {
      if (Group.get(data.members.groups[index]) != undefined) {
        deviceCount += Group.get(data.members.groups[index]).devices.length;
      }
    }
    setNumOfDevices(deviceCount);
    return deviceCount;
  }

  const classes = useStyles();

  return (
    <Grid container className={classes.detailsContainer}>
      <Grid item xs={12} md={12}>
        <Tabs
          value={value}
          onChange={handleTabs}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={Language.translate("Info")}
            icon={<CustomIcon fontSize="small" id="icon-info-circle" />}
          />
          <Tab
            label={Language.translate("Assets")}
            icon={
              <Badge
                badgeContent={
                  numOfDevices == undefined
                    ? calculateDeviceTotal()
                    : numOfDevices
                }
                color="primary"
              >
                <CustomIcon fontSize="small" id="icon-group" />
              </Badge>
            }
          />
        </Tabs>
        {value === 0 && (
          <List>
            <ListItem>
              <ListItemText
                md={6}
                className="itemTextFirst spiltIn3"
                primary={`${Language.translate("Title").toUpperCase()}: `}
              />
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={data.title}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                md={6}
                className="itemTextFirst"
                primary={`${Language.translate(
                  "Max No. of Assets"
                ).toUpperCase()}: `}
              />
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={data.device_count}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                md={6}
                className="itemTextFirst"
                primary={`${Language.translate(
                  "Report Age (H)"
                ).toUpperCase()}: `}
              />
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={data.report_age}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                md={6}
                className="itemTextFirst"
                primary={`${Language.translate(
                  "Max Radius (KM)"
                ).toUpperCase()}: `}
              />
              <ListItemText
                md={6}
                className="itemTextSecond"
                primary={Utils.transformMetresToKm(
                  Number(data.radius),
                  "Kilometres"
                )}
              />
            </ListItem>
          </List>
        )}
        {value === 1 && (
          <List>
            {numOfDevices !== 0 ? (
              <>
                <AssetSelectorDisplay
                  collection={{
                    groups: [
                      buildCollectionToDisplay({
                        groups: data.members.groups,
                        devices: [],
                      }),
                    ],
                  }}
                  name="members"
                />
              </>
            ) : (
              <ListItem className="noResults">
                <ListItemText className="itemTextAlone">
                  {Language.translate("There are no devices in Assets")}
                </ListItemText>
              </ListItem>
            )}
          </List>
        )}
      </Grid>
    </Grid>
  );
}
