import * as Yup from "yup";
import baseSchema from "./BaseValidation";
import Language from "sccLanguage";

const schema = Yup.object().shape({
	category_id: Yup.number().nullable(true),
	image_id: Yup.number().nullable(true)
		.when("category_id", {
			is: catId => catId>0,
			then: baseSchema(Language).dropdownSelectNum
		}),
	affiliationCode: Yup.string().nullable(true)
		.when("category_id", {
			is: null,
			then: baseSchema(Language)
				.dropdownSelectStr
				.nullable(false)
		}),
	areaCode: Yup.string().nullable(true)
		.when("category_id", {
			is: null,
			then: baseSchema(Language)
				.dropdownSelectStr
				.nullable(false)
		}),
	title: baseSchema(Language).requiredStringMinMax,
	note: baseSchema(Language).requiredStringMinMax,
	mgrs: baseSchema(Language).MGRS,
	latitude: Yup.number()
		.when("lonlat_format", {
			is: format => format!='MGRS',
			then: baseSchema(Language).requiredNumber,
		}),
	longitude: Yup.number()
		.when("lonlat_format", {
			is: format => format!='MGRS',
			then: baseSchema(Language).requiredNumber,
		}),
});

export default schema;