import React, { useState, useContext, useEffect } from "react";

// External
import { useFormikContext } from "formik";
import { _, filter } from "lodash";

// Material-UI
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CloseIcon from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/ArrowDownward";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/styles";

// Components
import CustomButton from "@Components/CustomButton";
import CustomTooltip from "@Components/CustomTooltip";
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";

// Internal
import { AppContext } from "../../../AppContext";
import Group from "sccGroup";
import GuiUtils from "sccGuiUtils";
import Language from "sccLanguage";
import Permission from "sccPermission";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    borderTop: `1px solid ${theme.palette.colors.gray.dark}`,
    width: 750,
    maxWidth: 750,
    zIndex: 1000,
    height: "36%",
    overflow: "auto",
  },
  button: {
    height: 30,
    marginRight: 10,
  },
  paper: {
    minHeight: "-webkit-fill-available",
    borderRadius: 0,
    padding: 10,
    background: theme.palette.colors.white.main,
  },
  backSplash: {
    minHeight: "-webkit-fill-available",
    minWidth: 750,
    opacity: 0.8,
    background: theme.palette.colors.black.main,
    zIndex: 1000,
    marginTop: 40,
    marginBottom: 40,
    top: 0,
    left: 0,
    position: "absolute",
  },
}));

function MasterDataFile(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { label, ...other } = props;

  const [open, setOpen] = useState(Boolean(anchorEl));

  const id = open ? "MasterDataFilePopper" : undefined;

  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const [appState] = useContext(AppContext);
  const mainGroupName = props.mainGroupName;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  function generateMasterDataFile(group) {
    var MasterData = require("../scripts/MasterData.js");
    var groupId = null;
    var fileTitle = "";
    var message = "";

    if (group) {
      groupId = group.id;
      fileTitle = "MasterData_Group(" + group.title + ").xml";
      const groupTitle =
        group.title === mainGroupName
          ? Language.translate(mainGroupName)
          : group.title;

      message =
        Language.translate("Generating the master data file for Group ") +
        groupTitle +
        ". " +
        Language.translate("Please wait") +
        "...";
    } else {
      fileTitle = "MasterData_ALL.xml";
      message = Language.translate(
        "Generating the master data file. Please wait..."
      );
    }

    appState.displaySnackbarMessage({ message: message, variant: "info" });
    var data = MasterData.generateMain(groupId);
    GuiUtils.saveTextAsFile(data, fileTitle, "application/xml");
  }

  useEffect(() => {
    if (open) {
      assetSelectorState.setStateForEditMode(true);
      assetSelectorState.setHideDevices(true);
      assetSelectorState.setShowSelectedOnly(false);
      assetSelectorState.setShowGroupRadioButtons(true);
      assetSelectorState.setDevicesButtons(false);
      assetSelectorState.setGroupButtons(false);
    } else {
      assetSelectorState.setStateForEditMode(false);
      assetSelectorState.setHideDevices(false);
      assetSelectorState.setShowSelectedOnly(false);
      assetSelectorState.setShowGroupRadioButtons(false);
      assetSelectorState.setDevicesButtons(true);
      assetSelectorState.setGroupButtons(true);
    }
  }, [open]);

  const classes = useStyles();

  return (
    Permission.verify("device", "view") && (
      <React.Fragment>
        <CustomTooltip title={label} arrow placement="top">
          <CustomButton
            className={classes.button}
            onClick={handleClick}
            size="medium"
            color="command"
            variant="contained"
            startIcon={<InsertDriveFileIcon />}
            endIcon={<ArrowDropUpIcon />}
          >
            {label}
          </CustomButton>
        </CustomTooltip>
        <div
          style={{ visibility: open ? "visible" : "hidden" }}
          className={classes.backSplash}
        ></div>
        <Popper
          id={id}
          disablePortal={false}
          open={open}
          className={classes.root}
          anchorEl={anchorEl}
          onClose={handleClose}
          placement="top-end"
          modifiers={{ offset: { enabled: true, offset: "15, 5" } }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper elevation={8} className={classes.paper}>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={8}>
                  {Language.translate("Select a Group").toUpperCase()}
                </Grid>
                <Grid item xs={3}>
                  <CustomButton
                    size="medium"
                    color="command"
                    id="btnGenerate"
                    variant="contained"
                    onClick={() =>
                      generateMasterDataFile(
                        Group.get(
                          assetSelectorState.selection["MasterDataFile"].groups
                        )
                      )
                    }
                    disabled={
                      assetSelectorState.selection["MasterDataFile"] &&
                      assetSelectorState.selection["MasterDataFile"].groups &&
                      !assetSelectorState.selection["MasterDataFile"].groups
                        .length
                    }
                    startIcon={<DownloadIcon />}
                  >
                    {Language.translate("Generate File").toUpperCase()}
                  </CustomButton>
                </Grid>
                <Grid container item xs={1} justifyContent="flex-end">
                  <IconButton disableRipple={true} onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid container item xs={12}>
                  <CustomAssetWrapper
                    selection={{ groups: [], devices: [] }}
                    hideDevices={true}
                    openMain={true}
                    name="MasterDataFile"
                    editMode={true}
                    showGroupRadioButtons={true}
                  />
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </React.Fragment>
    )
  );
}

export default MasterDataFile;
