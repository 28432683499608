import React, { useState } from "react";

// External
import _ from "lodash";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

// Internal
import Device from "sccDevice";
import Language from "sccLanguage";
import CustomPagination from "@Components/CustomPagination";
import CustomDropdown from "@Components/CustomDropdown";
import CustomTextField from "@Components/CustomTextField";
import { customAssetSelector } from "@Styles/CustomAssetSelector";

export default function AssetDevice(props) {
  const devices = props.devices;
  const numberOfItems = devices && devices.length;
  const [search, setSearch] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState(10);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: itemsPerPage,
    numberOfItems: numberOfItems,
    maxPages: 5,
    startIndex: 0,
    endIndex: numberOfItems > itemsPerPage ? itemsPerPage : numberOfItems,
  });

  // on Page Item change
  const arritemsPerPage = [
    {
      value: 10,
      label: `10 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 20,
      label: `20 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 50,
      label: `50 ${Language.translate("PER PAGE")}`,
    },
    {
      value: 100,
      label: `100 ${Language.translate("PER PAGE")}`,
    },
  ];

  const onItemPageChange = (e) => {
    setitemsPerPage(e.target.value);
    setPagination((prevs) => ({
      ...prevs,
      currentPage: 1, //reset the current page to 1
      itemsPerPage: e.target.value, //based on the selection
      numberOfItems: filterDevices.length, //based on the items in the list right now
      startIndex: 0,
      endIndex:
        filterDevices.length > e.target.value
          ? e.target.value
          : filterDevices.length,
    }));
  };

  const onPageChange = (currentPage, startIndex, endIndex) => {
    //change list display to show the current index of records
    setPagination((prevs) => ({
      ...prevs,
      currentPage: currentPage,
      startIndex,
      endIndex,
    }));
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const displayTrimmedText = (str) => {
    let st = str;
    if (str.length >= 14) {
      st = st.substring(0, 13) + "...";
    }
    return st;
  };

  // Search Filter for devices
  const filterDevices =
    devices &&
    devices.filter((deviceId) => {
      if (_.isUndefined(Device.get(deviceId))) return;
      const name = Device.get(deviceId).name;
      if (name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        return deviceId;
      }
    });

  const useStyles = makeStyles((theme) => ({
    ...customAssetSelector(theme),
  }));
  const classes = useStyles();

  const showDeviceList = devices ? (
    filterDevices
      .slice(pagination.startIndex, pagination.endIndex)
      .map((deviceId) => {
        return (
          <React.Fragment key={deviceId}>
            <ListItem
              className="assetDeviceLabel"
              key={deviceId}
              role={undefined}
              dense
              button
            >
              <ListItemText
                id={`AssetDevice-${deviceId}`}
                primary={`${displayTrimmedText(Device.get(deviceId).name)}`}
                className="listItemText"
              />
            </ListItem>
          </React.Fragment>
        );
      })
  ) : (
    <div className="noDevices">{Language.translate("No Devices")}</div>
  );
  return (
    <ul className={classes.assetDevice}>
      <div className="assetDeviceFilterWrapper">
        {devices.length > 10 && (
          <CustomTextField
            id="txtAssetSelectorSearch"
            name="search"
            placeholder={Language.translate("Search")}
            className="AssetSelectorSearch"
            value={search}
            disableUnderline={true}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  id="clearSearch"
                  onClick={handleClearSearch}
                  style={{ cursor: "pointer" }}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        <div className={devices.length > 10 ? "pagingAndFilterContainer" : ""}>
          {filterDevices.length > 10 ? (
            <div className="pagingContainer">
              <CustomDropdown
                id="pagingSelector"
                className="dropdown"
                value={itemsPerPage}
                onChange={onItemPageChange}
              >
                {arritemsPerPage.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomDropdown>
            </div>
          ) : null}
        </div>
      </div>
      {filterDevices.length === 0 ? (
        <div className="noDevices">{Language.translate("No Devices")}</div>
      ) : (
        <div className="deviceListContainer">
          {showDeviceList}
          {filterDevices.length > 10 && (
            <div className="NRassetPagination">
              <CustomPagination
                pagination={pagination}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
      )}
    </ul>
  );
}
