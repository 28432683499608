import React, { useContext } from "react";
import clsx from "clsx";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import BarChartIcon from "@material-ui/icons/BarChart";
import MenuIcon from "@material-ui/icons/Menu";

// Internal
import Language from "sccLanguage";
import { ReportContext } from "./ReportContext";
import Notification from "./Notification";
import LeftPanel from "../components/LeftPanel/";
import CustomDrawer from "../../../reusable-components/Drawer/CustomDrawer";
import DataTable from "./DataTable";

const useStyles = makeStyles((theme) => ({
  show: {
    visibility: "visible",
  },
  paper: {
    position: "fixed",
    zIndex: 50000,
    top: 20,
    left: "0",
    width: "102%",
    height: "100%",
    backgroundColor: "#293239",
    padding: theme.spacing(8),
    color: "#fff",
  },

  logo: {
    width: "200px",
  },
  openerButton: {
    border: "1px solid #eee",
    borderRadius: 0,
    backgroundColor: "white",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    position: "absolute",
    zIndex: 10,
    top: 0,
    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
  },
  openerLeftPanel: {
    left: 0,
  },
  openerRightPanel: {
    right: 8,
  },
  hide: {
    visibility: "hidden",
  },
  badge: {
    "& .MuiBadge-badge": {
      fontSize: "1.25rem",
      color: "white",
      backgroundColor: "green",
    },
  },
}));

export default function Report() {
  const [state] = useContext(ReportContext);

  const leftPanelDrawerOpen = state.leftPanelDrawerOpen;
  const notificationOptions = state.notificationOptions;
  const showNotification = state.showNotification;
  const updateKeysInState = state.updateKeysInState;

  const classes = useStyles();

  document.body.style = "background: #FFFFFF";
  document.body.style.transition = "0s";

  return (
    <>
      <CustomDrawer
        titleIcon={<BarChartIcon fontSize="large" />}
        title={Language.translate("Reports")}
        open={leftPanelDrawerOpen}
        onCloseClick={() => updateKeysInState({ leftPanelDrawerOpen: false })}
        component={<LeftPanel />}
        width="300px"
      />
      <IconButton
        className={clsx(
          classes.openerButton,
          classes.openerLeftPanel,
          leftPanelDrawerOpen && classes.hide
        )}
        onClick={() => {
          updateKeysInState({ leftPanelDrawerOpen: true });
        }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <DataTable />
      <Notification
        options={notificationOptions}
        closeNotification={() => updateKeysInState({ showNotification: false })}
        showNotification={showNotification}
      ></Notification>
    </>
  );
}
