import React, { useState, useContext } from "react";

// External
import _ from "lodash";

// Material-UI
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

// Internal
import AssetDisplay from "../AssetDisplay";
import { HistoryContext } from "../../HistoryContext";
import Language from "sccLanguage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 350,
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
    flex: 1,
    opacity: 1,
    fontSize: 16,
  },
  searchField: {
    maxHeight: 42,
  },
  textField: {
    borderRadius: 0,
    width: 360,
    fontSize: 16,
    backgroundColor: theme.palette.colors.white.main,
    "& .MuiOutlinedInput-input": {
      padding: "12px",
      "& .MuiOutlinedInput-input:focus": {
        border: "1px solid red",
      },
    },
  },
}));

const FilteredAssets = (props) => {
  const [state, setState] = useContext(HistoryContext);
  const [searchFilter, setSearchFilter] = useState("");

  const Historic = state.Historic;
  const Device = state.Device;

  const filteredAssets = Object.keys(Historic.playOptions.device)
    .filter((index) => {
      if (searchFilter == null) {
        return index;
      } else if (
        Device.get()
          [index].name.toLowerCase()
          .includes(searchFilter.toLowerCase())
      ) {
        return index;
      }
    })
    .map((index) => {
      return state.hideNoEvents &&
        Historic.playOptions.device[Device.get()[index].id].noEvents ? null : (
        <AssetDisplay
          key={Device.get()[index].id}
          id={Device.get()[index].id}
          name={Device.get()[index].name}
        />
      );
    });

  function handleClearSearch() {
    setSearchFilter("");
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      {Object.keys(Historic.playOptions.device).map((index) => {}).length >
      1 ? (
        <Grid container item xs={12} className={classes.searchField}>
          <TextField
            label={Language.translate("Search")}
            variant="outlined"
            className={classes.textField}
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  id="clearSearch"
                  onClick={handleClearSearch}
                  style={{ cursor: "pointer" }}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : null}
      <Grid className={classes.root}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          {!_.isEmpty(filteredAssets)
            ? filteredAssets
            : Language.translate("No results!")}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FilteredAssets;
