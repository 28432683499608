import { default as Module } from "sccModule";
import { Users } from "../../language/scripts/dict";
var _= require("lodash");
window.$= require("jquery");
window.jQuery= window.$;
// window.angular= require("angular");

/**
 * The base class for User Settings
 * 
 * @class UserSettingModule
 */
 class UserSettingModule extends Module.Module{
	constructor(){
		var options= {
			moduleName: "user_setting"
		};
		super(options);
	}

	static thePublicInstance = null;

	static getThePublicInstance(){
		if (UserSettingModule.thePublicInstance == null) {
			UserSettingModule.thePublicInstance = new UserSettingModule();
		}
		return UserSettingModule.thePublicInstance;
	}
}

export const getUserSettings = UserSettingModule.getThePublicInstance;

UserSettingModule.prototype.onSocketUpdate= function(url, data){
	const DeviceOverlay= require("sccDeviceOverlay").default;
	const PoiOverlay = require("sccPoiOverlay").default;
	const HistoryOverlay = require("sccHistoryOverlay").default;


	if(!data.id) throw new Error("Socket received data must have 'id'.");	
	
	var oldAssetLabel = this.get("asset_label");
	var oldAssetAnnotation = this.get("asset_annotation");
	var oldPoiLabel = this.get("poi_label");
	var oldPoiAnnotation = this.get("poi_annotation");
	var oldReportAge = this.get("report_age");
	var oldLanguageId = this.get("language_id");
	var oldPanicAudio= this.get("panic_audio");
		
	_.merge(this._data, data);	


	//Temporary solution for refresh maps and update alert tabs as user settings change	
	var newAssetLabel = this.get("asset_label");
	var newAssetAnnotation = this.get("asset_annotation");
	var newPoiLabel = this.get("poi_label");
	var newPoiAnnotation = this.get("poi_annotation");
	var newReportAge = this.get("report_age");
	var newPanicAudio= this.get("panic_audio");


	// The following code updates the language session variable and loads it to the frontend
	// It should be removed when all php files using language session variable are converted to Angular.
	var newLanguageId= this.get("language_id"); 
	
	if(newLanguageId !== oldLanguageId){
		window.location.reload(true);	
	}
	
	if(newAssetAnnotation !== oldAssetAnnotation || 
		newAssetLabel !== oldAssetLabel)
	{
		DeviceOverlay.refresh();
		HistoryOverlay.refresh();
	}

	if(newPoiAnnotation !== oldPoiAnnotation || 
		newPoiLabel !== oldPoiLabel)
	{
		PoiOverlay.refresh();
	}

	if(newReportAge !== oldReportAge){
		DeviceOverlay.reset(true);
		DeviceOverlay.hideClusterPopup();
	}
	
	if(oldPanicAudio !== newPanicAudio){
		const AlertMenu= require("sccAlertMenu").default;
		AlertMenu.manageSoundForEmergencyAlerts(false);
	}
};

export default new UserSettingModule()