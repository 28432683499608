import AlertMenuMap from "./AlertMenuMap";
import _ from "lodash";
const olLayerTile = require("ol/layer/Tile").default;
const olSourceOsm = require("ol/source/OSM").default;
const olSourceXYZ = require("ol/source/XYZ").default;
const MapboxVector = require("ol/layer/MapboxVector").default;
const log = require("loglevel");

class AlertMapBaseLayer {
  constructor(options) {
    this.map = options.map;
    this.baseLayers = options.baseLayers;
    this.settingDefaultMapAsOL = false;
    this.osmLayerGivenInitialAccess = false;
  }

  init() {
    const UserSetting = require("sccUserSetting").default;

    return Promise.resolve();
  }

  add(options) {
    let layer;
    const UserSetting = require("sccUserSetting").default;
    const olAttribution = require("ol/control/Attribution").default;
    const mapLayerVisibility = UserSetting.get("map_layers");
    switch (options.code) {
      case "gphy":
        layer = new olLayerTile({
          title: "Google Physical",
          type: "base",
          visible: mapLayerVisibility["Google Physical"],
        });
        break;
      case "gsat":
        layer = new olLayerTile({
          title: "Google Satellite",
          type: "base",
          visible: mapLayerVisibility["Google Satellite"],
        });
        break;
      case "ghyb":
        layer = new olLayerTile({
          title: "Google Hybrid",
          type: "base",
          visible: mapLayerVisibility["Google Hybrid"],
        });
        break;
      case "gmap":
        layer = new olLayerTile({
          title: "Google Street",
          type: "base",
          visible: mapLayerVisibility["Google Street"],
        });
        break;
      case "osm":
        layer = new olLayerTile({
          title: "OSM",
          type: "base",
          visible: mapLayerVisibility["OSM"],
          source: new olSourceOsm({
            wrapX: false,
          }),
        });
        break;
      case "custom_xyz":
        layer = new olLayerTile({
          title: options.name,
          type: "base",
          visible:
            mapLayerVisibility[options.name] != null
              ? mapLayerVisibility[options.name]
              : false,
          source: new olSourceXYZ({
            wrapX: false,
            url: options.url,
          }),
        });
        break;
      case "mapbxsat":
        layer = new olLayerTile({
          title: "Mapbox Satellite",
          type: "base",
          visible: mapLayerVisibility["Mapbox Satellite"],
          source: new olSourceXYZ({
            url:
              "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=" +
              getMapboxToken(),
            attributions: "Mapbox",
          }),
        });
        break;
      case "mapbxstr":
        layer = new MapboxVector({
          visible: mapLayerVisibility["Mapbox Street"],
          title: "Mapbox Street",
          type: "base",
          styleUrl: "mapbox://styles/mapbox/streets-v11",
          accessToken: getMapboxToken(),
        });
        // Hacking the title in forcibly, since it won't take nicely.
        layer.values_.title = "Mapbox Street";
        break;
      default:
        log.warn("Wrong map layer code provided:", options.code);
        return;
    }

    const baseLayers = this.baseLayers.getLayers();
    baseLayers.push(layer);
  }

  setDefaultMap() {
    //Loops through the array of BaseLayers/maps to display, sets a default map to display if necessary
    const baseLayers = this.baseLayers.getLayers();
    window.alertMap.BaseLayer.settingDefaultMapAsOL = true;

    var olLayerfound = false;
    for (let i = 0; i < baseLayers.array_.length; i++) {
      if (!baseLayers.array_[i].values_.title.includes("Google")) {
        baseLayers.array_[i].setVisible(true);
        window.alertMap.BaseLayer.settingDefaultMapAsOL = true;
        olLayerfound = true;
        break;
      }
    }
    if (!olLayerfound) {
      //no ol layer found, user is given access to OSM layer which is also made visible
      window.alertMap.BaseLayer.settingDefaultMapAsOL = true;
      this.giveOSMlayerAccessAndMakeVisible();
    }
  }

  giveOSMlayerAccessAndMakeVisible() {
    //called if google maps API authentication failed; adds osm layer and makes it visible
    var osmLayerFound = false;
    const baseLayers = this.baseLayers.getLayers();

    for (let i = 0; i < baseLayers.array_.length; i++) {
      if (baseLayers.array_[i].values_.title == "OSM") {
        osmLayerFound = true;
        baseLayers.array_[i].setVisible(true);
      } else {
        baseLayers.array_[i].setVisible(false);
      }
    }
    if (!osmLayerFound) {
      log.debug("OSM layer not found. Giving access to OSM layer..");
      this.add({ code: "osm", name: "OSM" });
      this.giveOSMlayerAccessAndMakeVisible();
    }
  }
}

function getMapboxToken() {
  const EnvVar = require("sccEnvVar").default;
  return EnvVar.get("MAPBOX_API_KEY");
}

export default AlertMapBaseLayer;
