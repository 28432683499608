import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import { makeStyles, Grid, Button, MenuItem } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Popover from "@material-ui/core/Popover";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

// Components
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomDropdown from "@Components/CustomDropdown";
import CustomListItemCollapse from "@Components/CustomListItemCollapse";
import CustomTextField from "@Components/CustomTextField";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";
import theme from "@Styles/theme";

// Internal
import Device from "sccDevice";
import Language from "sccLanguage";
import Group from "sccGroup";
import Permission from "sccPermission";
import Profile from "sccProfile";
import User from "sccUser";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  formContainer: {
    ...moduleForm(theme).formContainer,
    "& .parentGroup": {
      margin: 0,
    },
    "& .parentGroupsButton": {
      padding: 4,
      minWidth: "150px !important",
    },
    "& .saEnabled": {
      background: theme.palette.colors.yellow.pure,
      padding: 4,
    },
    "& .filter": {
      marginTop: 10,
      width: "calc(50% - 5px)",
      float: "left",
    },
    "& .search": {
      marginTop: 10,
      width: "calc(50% - 5px)",
      float: "right",
    },
    "& .assetsList": {
      marginTop: 30,
      width: "100%",
      maxHeight: 300,
      overflowX: "hidden",
      overflowY: "auto",
    },
    "& .assetsListItem": {
      height: 30,
      borderBottom: `1px solid ${theme.palette.colors.gray.pure}`,
      margin: "5px 5px",
      "& .MuiFormControlLabel-root": {
        marginRight: 5,
      },
      "& .listItemText": {
        width: 275,
        "& span": {
          width: "100%",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      },
      "& .MuiListItemIcon-root": {
        minWidth: 20,
      },
    },
    "& .usersListItem": {
      height: 30,
      borderBottom: `1px solid ${theme.palette.colors.gray.pure}`,
      margin: "5px 5px",
      "& .MuiFormControlLabel-root": {
        marginRight: 0,
      },
      "& .MuiListItemIcon-root": {
        minWidth: 20,
      },
      "& .listItemText": {
        width: 275,
        "& span": {
          width: "100%",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      },
    },
  },
}));

export default function GroupForm(props) {
  const { onUserChange, ...other } = props;

  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const formik = useFormikContext();
  const moduleItemData = formik.values;

  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const [membersCount, setMembersCount] = useState(0);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [value, setValue] = useState(0);
  const [selectGroupText, setSelectGroupText] = useState("");

  const [groupAssets, setGroupAssets] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);

  const [checkedGroupAssets, setCheckedGroupAssets] = useState([]);
  const [checkedGroupUsers, setCheckedGroupUsers] = useState([]);
  const [itemsOnPage, setItemsOnPage] = useState("all");
  const [search, setSearch] = useState("");

  //to be used in add edit
  const [anchorElGroups, setAnchorElGroups] = React.useState(null);
  const [anchorElUsers, setAnchorElUsers] = React.useState(null);
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;
  const handleFormInputCheck = mwState.handleFormInputCheck;
  const moduleFormValidations = mwState.moduleFormValidations;
  const openGroups = Boolean(anchorElGroups);
  const openUsers = Boolean(anchorElUsers);
  const idGroups = openGroups ? "assetsPopover" : undefined;
  const idUsers = openUsers ? "userPopover" : undefined;
  const filteredUsers = usersList();

  const moduleItemDataGroups = {
    title: "",
    parent_id: mwState.moduleData[0]?.id ? mwState.moduleData[0].id : 1,
    devices: [],
    users: [],
    sync: { devices: [] },
    formType: "Group", //required for the form to be identified as a Group form
  };

  useEffect(() => {
    setMwState((p) => ({
      ...p,
      moduleItemData: { ...moduleItemDataGroups, ...p.moduleItemData },
      moduleItemDataOriginal: { ...moduleItemDataGroups, ...p.moduleItemData },
      routeUrl: "/api/v1/group", //need to specifically set this to group
    }));

    setSelectGroupText(Group.get(moduleItemData.parent_id).title);
  }, []);

  useEffect(() => {
    formik.setFieldValue(
      "subscribers",
      assetSelectorState.selection["subscribers"]
    );
    if (assetSelectorState.selection["ParentGroup"]) {
      formik.setFieldValue(
        "parent_id",
        assetSelectorState.selection["ParentGroup"].groups[0]
      );
      setSelectGroupText(
        Group.get(assetSelectorState.selection["ParentGroup"].groups[0])?.title
      );
    }

    if (!_.isEmpty(moduleItemData.subscribers)) {
      setSubscriberCount(
        Group.getDeviceCount(assetSelectorState.selection["subscribers"])
      );
    }
  }, [assetSelectorState.selection]);

  const displayTrimmedText = (str) => {
    let st = str;
    if (str.length >= 22) {
      st = st.substring(0, 19) + "...";
    }
    return st;
  };

  useEffect(() => {
    setGroupAssets(
      Group.get(moduleItemData.parent_id)?.devices ||
        Group.get(moduleItemData.id)?.devices
    );
    setGroupUsers();
  }, [moduleItemData.parent_id]);

  useEffect(() => {
    setCheckedGroupUsers(moduleItemData.users);
  }, [moduleItemData.users]);

  useEffect(() => {
    setCheckedGroupAssets(moduleItemData.devices);
  }, [moduleItemData.devices]);

  const handleGroupAssets = (id) => {
    const currentIndex = checkedGroupAssets.indexOf(id);
    const newChecked = [...checkedGroupAssets];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedGroupAssets(newChecked);
    formik.setFieldValue("devices", newChecked);
  };

  const handleGroupUsers = (id) => {
    const currentIndex = checkedGroupUsers.indexOf(id);
    const newChecked = [...checkedGroupUsers];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedGroupUsers(newChecked);
    formik.setFieldValue("users", newChecked);
  };

  const handleClickGroups = (event) => {
    setAnchorElGroups(event.currentTarget);
  };
  const handleCloseGroups = () => {
    setAnchorElGroups(null);
  };

  const handleClickUsers = (event) => {
    setAnchorElUsers(event.currentTarget);
  };
  const handleCloseUsers = () => {
    setAnchorElUsers(null);
  };
  function handleToggle() {}

  function usersList() {
    const users = (User.get() && Object.values(User.get())) || [];
    var output = users.filter((user) => user.username !== null);
    return output;
  }
  function usersLength() {
    const ids = [];
    _.each(filteredUsers, function (key) {
      if (key.username) {
        ids.push(key.id);
      }
    });
    return ids.length;
  }

  const handleClearSearch = () => {
    setSearch("");
  };

  const onItemsOnPageChange = (e) => {
    setItemsOnPage(e.target.value);
  };
  const arrItemsOnPage = [
    {
      value: "all",
      label: Language.translate("All"),
    },
    {
      value: "checked",
      label: Language.translate("Checked"),
    },
    {
      value: "unchecked",
      label: Language.translate("Unchecked"),
    },
  ];

  // Search Filter for devices
  const filterDevices =
    groupAssets &&
    groupAssets.filter((deviceId) => {
      if (_.isUndefined(Device.get(deviceId))) return;
      const name = Device.get(deviceId).name;
      if (name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        if (itemsOnPage === "all") {
          return deviceId;
        }
        if (itemsOnPage === "checked") {
          return checkedGroupAssets.indexOf(deviceId) !== -1 && deviceId;
        }
        if (itemsOnPage === "unchecked") {
          return checkedGroupAssets.indexOf(deviceId) === -1 && deviceId;
        }
      }
    });

  useEffect(() => {
    getDisabledUsers({ group: true, checked: true });
  }, []);

  const [disabledUsers, setDisabledUsers] = useState([]);

  // Disable users not allowed
  const getDisabledUsers = function (checkObj) {
    var notPermittedUsers = _.filter(User.get(), function (user) {
      return user.role_id == null;
    }).map(function (user) {
      return user.id;
    });
    setDisabledUsers(_.union(disabledUsers, notPermittedUsers));

    // should only trigger if group selection is changed
    if (!checkObj.group) return disabledUsers;

    // adding current user to the disabled user list
    setDisabledUsers(_.union(disabledUsers, [Profile.get("id")]));

    if (!moduleItemData.parent_id) return disabledUsers;

    var parent = Group.get(moduleItemData.parent_id);

    setDisabledUsers(_.union(disabledUsers, Group.getPermittedUsers(parent)));

    return disabledUsers;
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <Grid container spacing={2}>
          <Grid container item xs={6} direction="column">
            <CustomTextField
              label={`${Language.translate("Title")}*`}
              value={
                moduleItemData.title != mwState.mainGroupName
                  ? String(moduleItemData.title)
                  : Language.translate(mwState.mainGroupName)
              }
              name="title"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={Language.translate(
                formik.touched.title && formik.errors.title
              )}
              disabled={!moduleItemData.parent_id}
            />
          </Grid>
          <Grid container item xs={6} direction="column">
            {moduleItemData.parent_id && (
              <React.Fragment>
                <p className="parentGroup">
                  {Language.translate("Parent Group")}
                </p>
                <Button
                  aria-describedby={idGroups}
                  variant="outlined"
                  onClick={handleClickGroups}
                  className="parentGroupsButton"
                  disabled={moduleItemData.id}
                >
                  {typeof Group.get(moduleItemData.parent_id) === "undefined"
                    ? Group.get(moduleItemData.id).title
                    : (Group.get(moduleItemData.parent_id).title === mwState.mainGroupName
                      ? Language.translate(mwState.mainGroupName)
                      : Group.get(moduleItemData.parent_id).title)
                      }
                  <ArrowDropDownIcon />
                </Button>
                <Popover
                  id={idGroups}
                  open={openGroups}
                  anchorEl={anchorElGroups}
                  onClose={handleCloseGroups}
                  style={{
                    position: "absolute",
                    top: 2,
                    left: 0,
                    borderRadius: 0,
                  }}
                  PaperProps={{
                    style: {
                      width: 450,
                      maxHeight: 300,
                      borderRadius: 0,
                      borderTop: `1px solid ${theme.palette.colors.gray.pure}`,
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <CustomAssetWrapper
                    selection={{
                      groups: [moduleItemData.parent_id],
                      devices: [],
                    }}
                    hideDevices={true}
                    openMain={true}
                    name="ParentGroup"
                    editMode={true}
                    showGroupRadioButtons={true}
                  />
                </Popover>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        <p className="saEnabled">
          {Language.translate(
            "For SA Enabled users only - Editing an existing rule may invalidate it within other modules. Please check your other modules using this group after saving."
          )}
        </p>
        <Grid container spacing={2}>
          <Grid container item xs={6} direction="column">
            <CustomListItemCollapse
              title={Language.translate("Assets").toUpperCase()}
              color="primary"
              collapse={true}
              count={checkedGroupAssets && checkedGroupAssets.length}
            >
              {groupAssets && groupAssets.length != 0 && (
                <CustomDropdown
                  id="AssetSelectorGroupFormDropdown"
                  className="filter"
                  value={itemsOnPage}
                  select
                  InputProps={{ disableUnderline: true }}
                  onChange={onItemsOnPageChange}
                >
                  {arrItemsOnPage.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomDropdown>
              )}
              {groupAssets && groupAssets.length > 10 && (
                <CustomTextField
                  id="txtAssetGroupFormSearch"
                  name="search"
                  placeholder={Language.translate("Search")}
                  className="search"
                  value={search}
                  disableUnderline={true}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        id="clearSearch"
                        onClick={handleClearSearch}
                        style={{ cursor: "pointer" }}
                      >
                        <ClearIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <List className="assetsList">
                {groupAssets && groupAssets.length != 0 ? (
                  <React.Fragment>
                    {filterDevices.map((deviceId) => (
                      <ListItem
                        className="assetsListItem"
                        key={deviceId}
                        disablePadding
                        onClick={handleToggle}
                      >
                        {checkedGroupAssets && moduleItemData.parent_id ? (
                          <>
                            <ListItemIcon>
                              <CustomCheckbox
                                key={`groupAssets-${deviceId}`}
                                name="devices"
                                onChange={() => handleGroupAssets(deviceId)}
                                color="primary"
                                checked={
                                  checkedGroupAssets.indexOf(deviceId) > -1
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              className="listItemText"
                              primary={Device.get(deviceId).name}
                            />
                          </>
                        ) : (
                          <ListItemText primary={Device.get(deviceId).name} />
                        )}
                      </ListItem>
                    ))}
                  </React.Fragment>
                ) : (
                  Language.translate("No Assets Available")
                )}
              </List>
            </CustomListItemCollapse>
          </Grid>
          <Grid container item xs={6} direction="column">
            <CustomListItemCollapse
              title={Language.translate("Users").toUpperCase()}
              color="primary"
              collapse={true}
              count={checkedGroupUsers && checkedGroupUsers.length}
            >
              {Permission.verify("user", "view") ? (
                usersLength() === 0 ? (
                  Language.translate("Currently there are no Users")
                ) : (
                  <List>
                    {Object.values(filteredUsers).map((option) => (
                      <CustomTooltip
                        title={option.first_name + " " + option.last_name}
                        placement="left"
                      >
                        <ListItem
                          className="usersListItem"
                          key={option.id}
                          disablePadding
                          onClick={handleToggle}
                        >
                          <ListItemIcon>
                            {checkedGroupUsers && (
                              <CustomCheckbox
                                key={`groupUsers-${option.id}`}
                                name="users"
                                onChange={() => handleGroupUsers(option.id)}
                                color="primary"
                                checked={
                                  checkedGroupUsers.indexOf(option.id) > -1
                                }
                                disabled={disabledUsers.indexOf(option.id) > -1}
                                defaultChecked={true}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            className="listItemText"
                            primary={option.username}
                          />
                        </ListItem>
                      </CustomTooltip>
                    ))}
                  </List>
                )
              ) : (
                Language.translate("You do not have Permission to view Users")
              )}
            </CustomListItemCollapse>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
