import React, { useState } from "react";

// External 
import _ from "lodash";

// Internal 
import Language from "sccLanguage";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: 0,
    minHeight: 40,
    zIndex: 1000,
    maxHeight: 750,
    top: 80,
    opacity: 0,
    backgroundColor: theme.palette.colors.white.main,
    transition: [["all", "450ms"]],
  },
  rootOpened: {
    opacity: 1,
    position: "absolute",
    width: 390,
    minHeight: 40,
    zIndex: 1000,
    maxHeight: 750,
    top: 80,
    backgroundColor: theme.palette.colors.white.main,
    transition: [["all", "450ms"]],
  },
  button: {
    height: 30,
    marginRight: 10,
  },
  searchInput: {
    width: 0,
    height: 40,
    fontSize: "1rem",
    paddingLeft: 10,
    transition: [["all", "450ms"]],
  },
  searchInputOpened: {
    width: 320,
    height: 40,
    fontSize: "1rem",
    paddingLeft: 10,
    transition: [["all", "450ms"]],
  },
  searchIcon: {
    left: -3,
    position: "relative",
    display: "inline",
    zIndex: 1001,
    top: 65,
    backgroundColor: theme.palette.colors.blue.dark,
    color: theme.palette.colors.white.main,
    transition: [["all", "450ms"]],
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.175) !important",
  },
  searchIconOpened: {
    left: 347,
    position: "relative",
    display: "inline",
    zIndex: 1001,
    top: 65,
    transition: [["all", "450ms"]],
    "&:hover": {
      backgroundColor: theme.palette.colors.blue.dark,
      color: theme.palette.colors.white.main,
    },
  },
  errorMessage: {
    position: "relative",
    boxSizing: "border-box",
    fontSize: 12,
    fontWeight: "bold",
    backgroundColor: theme.palette.textbox.error.main,
    color: theme.palette.colors.white.main,
    height: 25,
    height: "max-content",
    width: "330px",
    padding: "4px",
    zIndex: 1000,
  },
  availFormatsPopper: {
    display: "block",
    zIndex: 1001,
    backgroundColor: theme.palette.colors.white.main,
    marginTop: 30,
    width: "max-content",
  },
  clearSearchBtn: {
    paddingTop: 5,
    paddingBottom: 6,
    "&:hover": {
      backgroundColor: theme.palette.colors.white.main,
    },
  },
  tableContainer: {
    width: 330,
  },
  tableHead: {
    backgroundColor: theme.palette.colors.blue.dark,
    "& .MuiTableCell-root": {
      color: theme.palette.colors.white.main,
      padding: "10px",
    },
  },
  tableBody: {
    backgroundColor: theme.palette.colors.gray.main,
    "& .MuiTableRow-root": {
      borderTop: `2px solid ${theme.palette.colors.gray.light}`,
      borderBottom: `1px solid ${theme.palette.colors.gray.dark}`,
      "& .MuiTableCell-root": {
        padding: "10px",
      },
    },
  },
}));

export default function CoordSearchbar(props) {
  const [coordsInput, setCoordsInput] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchBarOpen, setSearchBarOpen] = React.useState(false);

  function searchWithCoordinates() {
    const coordinatesInput = coordsInput;
    if (!coordinatesInput) return;

    const degDecOrEPSG900913regexV1 =
      /^ *-?[0-9]*(\.[0-9]+)? *([d](eg)?|[d]egree[s]?|°)? *, *-?[0-9]*(\.[0-9]+)? *([d](eg)?|[d]egree[s]?|°)? *$/gim;
    const degDecOrEPSG900913regexV2 =
      /^ *[0-9]*(\.[0-9]+)? *([d](eg)?|[d]egree[s]?|°)? *([N]|[S]|[N]orth|[S]outh) *, *[0-9]*(\.[0-9]+)? *([d](eg)?|[d]egree[s]?|°)? *([E]|[W]|[E]ast|[W]est) *$/gim;
    const degDecOrEPSG900913regexV3 =
      /^ *([N]|[S]|[N]orth|[S]outh) *[0-9]*(\.[0-9]+)? *([d](eg)?|[d]egree[s]?|°)? *, *([E]|[W]|[E]ast|[W]est) *[0-9]*(\.[0-9]+)? *([d](eg)?|[d]egree[s]?|°)? *$/gim;
    const DMSregexV1 =
      /^ *[0-9]+ *([d](eg)?|[d]egree[s]?|°)? *[0-9]+ *'? *([0-9]+(\.[0-9]+)?)? *"? *([N]|[S]|[N]orth|[S]outh) *, *[0-9]+ *([d](eg)?|[d]egree[s]?|°)? *[0-9]+ *'? *([0-9]+(\.[0-9]+)?)? *"? *([E]|[W]|[E]ast|[W]est) *$/gim;
    const DMSregexV2 =
      /^ *([N]|[S]|[N]orth|[S]outh) *[0-9]+ *([d](eg)?|[d]egree[s]|°)? *[0-9]+ *'? *([0-9]+(\.[0-9]+)?)? *"? *, *([E]|[W]|[E]ast|[W]est) *[0-9]+ *([d](eg)?|[d]egree[s]?|°)? *[0-9]+ *'? *([0-9]+(\.[0-9]+)?)? *"? *$/gim;
    const minDecRegexV1 =
      /^ *[0-9]+ *([d](eg)?|[d]egree[s]?|°)? *([0-9]+(\.[0-9]+)?)? *'? *([N]|[S]|[N]orth|[S]outh) *, *[0-9]+ *([d](eg)?|[d]egree[s]?|°)? *([0-9]+(\.[0-9]+)?)? *'? *([E]|[W]|[E]ast|[W]est) *$/gim;
    const minDecRegexV2 =
      /^ *([N]|[S]|[N]orth|[S]outh) *[0-9]+ *([d](eg)?|[d]egree[s]?|°)? *([0-9]+(\.[0-9]+)?)? *'? *, *([E]|[W]|[E]ast|[W]est) *([0-9]+ *([d](eg)?|[d]egree[s]?|°)?)? *[0-9]+(\.[0-9]+)? *'? *$/gim;
    const mgrsRegex = /^ *[0-9]{1,2} *[a-z] *[a-z]{2} *[0-9]+ *[0-9]+ *$/gim;

    var coord = []; //in degDec format
    var degDecOrEPSG900913regexPass =
      degDecOrEPSG900913regexV1.test(coordinatesInput) ||
      degDecOrEPSG900913regexV2.test(coordinatesInput) ||
      degDecOrEPSG900913regexV3.test(coordinatesInput);
    var DMSregexPass =
      DMSregexV1.test(coordinatesInput) || DMSregexV2.test(coordinatesInput);
    var minDecRegexPass =
      minDecRegexV1.test(coordinatesInput) ||
      minDecRegexV2.test(coordinatesInput);

    //degDec or EPSG:900913 format detected in user input
    if (degDecOrEPSG900913regexPass) {
      var coordinates = coordinatesInput.split(",", 2); //split by lat and long
      var coordSignage = [1, 1];

      //one "S" found denoting "South", another in "degreeS"
      if ((coordinates[0].toUpperCase().match(/S/g) || []).length == 2) {
        coordSignage[0] = -1;
      }

      //one "S" found denoting "South" only
      else if (
        (coordinates[0].toUpperCase().match(/S/g) || []).length == 1 &&
        !coordinates[0].toUpperCase().match(/DEGREES/g)
      ) {
        coordSignage[0] = -1;
      }

      if (coordinates[1].toUpperCase().includes("W")) {
        coordSignage[1] = -1;
      }
      coordinates[0] = coordinates[0].match(/(-?[0-9]+(.[0-9]+)?)/gm);
      coordinates[1] = coordinates[1].match(/(-?[0-9]+(.[0-9]+)?)/gm);
      coordinates[0] = Number(coordinates[0]) * coordSignage[0];
      coordinates[1] = Number(coordinates[1]) * coordSignage[1];

      if (Math.abs(coordinates[0]) > 90 || Math.abs(coordinates[1]) > 180) {
        //EPSG:900913 identified
        coord[0] = window.olMap.convertLonlatUser2Map(
          coordinates,
          1,
          "EPSG:900913"
        );
        coord[1] = window.olMap.convertLonlatUser2Map(
          coordinates,
          0,
          "EPSG:900913"
        );
      } else {
        //DegDec format identified
        coord = coordinates;
      }
      if (!coord) {
        showCoordSearchErrorMsg(
          Language.translate(
            "Invalid coordinate(s) for DegDec or EPSG:900913 format"
          )
        );
        return;
      }
    } else if (DMSregexPass || minDecRegexPass) {
      //DMS or MinDec format detected in user input
      var splitCoordinates = coordinatesInput.split(","); //split by lat and long
      var coordData = [];

      for (let i = 0; i < 2; i++) {
        //only 2 coordinates possible (lat and long)
        coordSignage = 1;
        if (
          (splitCoordinates[i].toUpperCase().match(/S/g) || []).length == 2 ||
          ((splitCoordinates[i].toUpperCase().match(/S/g) || []).length == 1 &&
            !splitCoordinates[i].toUpperCase().match(/DEGREES/g)) ||
          splitCoordinates[i].toUpperCase().includes("W")
        ) {
          coordSignage = -1;
        }

        var numbers = splitCoordinates[i].split(/\D/); //number strings extracted and put in an array
        numbers = _.filter(numbers, (element) => element.length); //empty elements in numbers array discarded

        if (splitCoordinates[i].includes(".")) {
          numbers[1] = String(Number(numbers[1]) + "." + String(numbers[2])); //minutes numstring concatenated to include numbers after decimal
        }
        coordData.push({ numbers: numbers, coordSignage: coordSignage });
      }

      if (
        Math.abs(coordData[0].numbers[0]) > 90 ||
        Math.abs(coordData[1].numbers[0]) > 180
      ) {
        showCoordSearchErrorMsg(
          Language.translate(
            "Degree coordinate(s) outside valid range (latitude: +90° to -90°, longitude: +180° to -180°)"
          )
        );
        return;
      }
      //converting to degree decimal coordinates

      if (DMSregexPass) {
        //DMS format identified
        _.each(coordData, (coordinate) => {
          var ddCoord =
            (Number(coordinate.numbers[0]) +
              Number(coordinate.numbers[1] / 60.0)) *
            coordinate.coordSignage;
          if (coordinate.numbers[2]) {
            ddCoord +=
              +Number(coordinate.numbers[2] / 3600.0) * coordinate.coordSignage;
          }

          coord.push(ddCoord);
        });
      } else {
        //minDec format identified
        _.each(coordData, (coordinate) => {
          if (!coordinate.numbers[1]) coordinate.numbers[1] = 0;

          var ddCoord =
            (Number(coordinate.numbers[0]) +
              Number(coordinate.numbers[1] / 60.0)) *
            coordinate.coordSignage;
          coord.push(ddCoord);
        });
      }

      if (!coord) {
        showCoordSearchErrorMsg(
          Language.translate("Invalid coordinate(s) for DMS or MinDec format")
        );
        return;
      }
    } else if (mgrsRegex.test(coordinatesInput)) {
      var mgrsNumbers = coordinatesInput.split(/\D/); //extracting number strings from user input
      mgrsNumbers = _.filter(mgrsNumbers, (element) =>
        !Number(element) ? (element === "0" ? true : false) : true
      );
      var mgrsLetters = coordinatesInput.split(/\d/); //extracting letter strings from user input
      mgrsLetters = _.map(mgrsLetters, (element) =>
        element.replaceAll(/\s/g, "")
      );
      mgrsLetters = _.filter(mgrsLetters, (element) => element.length);
      if (!mgrsNumbers[1]) {
        mgrsNumbers[1] = 0;
      }
      if (!mgrsNumbers[2]) {
        mgrsNumbers[2] = 0;
      }

      if (
        Number(mgrsNumbers[0]) < 1 || //MGRS coordinate system's numeric component of grid zone designator ranges from 1-60 inclusive.
        Number(mgrsNumbers[0]) > 60 ||
        mgrsLetters[0][1].match("[IOio]") || //letters I or O not allowed for 100m square identifier in MGRS
        mgrsLetters[0][2].match("[IOio]") ||
        (mgrsNumbers[2]
          ? mgrsNumbers[1].length != mgrsNumbers[2].length
          : mgrsNumbers[1]
          ? mgrsNumbers[1].length % 2 != 0
          : false) //x and y precision to match
      ) {
        showCoordSearchErrorMsg(Language.translate("Invalid coordinate(s)"));
        return;
      } else {
        var mgrsFormatted = mgrsNumbers[0] + mgrsLetters[0] + mgrsNumbers[1];
        if (mgrsNumbers[2]) mgrsFormatted += mgrsNumbers[2];

        coord = window.olMap.convertLonlatUser2Map([mgrsFormatted], 0, "MGRS");

        if (!coord) {
          showCoordSearchErrorMsg(
            Language.translate(
              "Invalid coordinate(s) for USNG (MGRS) coordinate format"
            )
          );
          return;
        }

        var temp = coord[0];
        coord[0] = coord[1];
        coord[1] = temp;
      }
    } else {
      showCoordSearchErrorMsg(Language.translate("No format identified"));
      return;
    }

    if (Math.abs(coord[1]) > 180) {
      showCoordSearchErrorMsg(
        Language.translate("Longitudinal Coordinate outside valid range")
      );
      return;
    } else if (Math.abs(coord[0]) > 85) {
      showCoordSearchErrorMsg(
        Language.translate(
          "Outside Mercator Projection bounds (+85° to -85° latitude)"
        )
      );
      return;
    } else if (isNaN(coord[0]) || isNaN(coord[1])) {
      showCoordSearchErrorMsg(Language.translate("No format identified"));
      return;
    }

    //ensures that the marker is placed at left border of map extent (-180 deg long.)
    //instead of right border of map extent (180 deg long) since context menu always opens right-ward if marker clicked
    if (coord[1] == 180) {
      coord[1] = -180;
    }
    showMarkerAtSearchCoordinate(coord);
  }

  function showMarkerAtSearchCoordinate(coord) {
    var temp = coord[0];
    coord[0] = coord[1];
    coord[1] = temp;
    window.olMap.showSearchCoordMarker([coord], coord, 16);
  }

  const showCoordSearchErrorMsg = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  const clearCoordSearch = () => {
    setCoordsInput("");
    window.olMap.removeSearchCoordMarkerAndErrorMsg();
    setErrorMessage(null);
  };

  const handleKeyDown = (e) => {
    setErrorMessage(null);
    window.olMap.removeSearchCoordMarkerAndErrorMsg();
    if (e.key == "Enter") {
      e.preventDefault();
      searchWithCoordinates();
    }
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const handleSearchbarOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setSearchBarOpen(true);
  };
  const handleSearchbarClose = () => {
    setSearchBarOpen(false);
    handlePopperClose();
    setErrorMessage(null);
  };

  const classes = useStyles();
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Paper
        className={searchBarOpen ? classes.rootOpened : classes.root}
        style={{ top: props.forExtendedMap ? 13 : 80 }}
        component="form"
        square="true"
        onMouseEnter={handleSearchbarOpen}
        onMouseLeave={!coordsInput ? handleSearchbarClose : null}
      >
        <InputBase
          className={
            searchBarOpen ? classes.searchInputOpened : classes.searchInput
          }
          id="coord-searchbar-input"
          sx={{ ml: 1, flex: 1 }}
          placeholder={Language.translate("Enter Coordinates...")}
          value={coordsInput}
          onChange={(event) => {
            setCoordsInput(event.target.value);
          }}
          type="text"
          spellCheck="false"
          name="coordsInput"
          onKeyDown={handleKeyDown}
          aria-owns={open ? "avail-formats-popper" : undefined}
          aria-haspopup="true"
          onFocus={handleSearchbarOpen}
          autoComplete="off"
        />
        <IconButton
          aria-label="clear"
          onClick={clearCoordSearch}
          className={classes.clearSearchBtn}
          style={{ display: searchBarOpen && coordsInput ? "inline" : "none" }}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </Paper>
      <IconButton
        type="submit"
        className={
          searchBarOpen ? classes.searchIconOpened : classes.searchIcon
        }
        style={{ top: props.forExtendedMap ? -2 : 72 }}
        aria-label="search"
        size="large"
        onClick={searchWithCoordinates}
        onMouseEnter={handleSearchbarOpen}
        onMouseLeave={!coordsInput ? handleSearchbarClose : null}
      >
        <SearchIcon fontSize="large" />
      </IconButton>
      <div
        id="coord-searchbar-errorMessage"
        className={classes.errorMessage}
        style={{
          display: errorMessage ? "block" : "none",
          top: props.forExtendedMap ? -11 : 71,
        }}
      >
        {errorMessage}
      </div>

      <Popper
        id="avail-formats-popper"
        open={open}
        onClose={handlePopperClose}
        disableRestoreFocus
        className={classes.availFormatsPopper}
        style={{
          position: "relative",
          top: props.forExtendedMap ? -27 : 41,
          left: 0,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography>
          <Table className={classes.tableContainer}>
            <TableHead className={classes.tableHead}>
              <TableRow style={{ textAlign: "left" }}>
                <TableCell>{Language.translate("Type")}</TableCell>
                <TableCell>{Language.translate("Example")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              <TableRow>
                <TableCell>DegDec</TableCell>
                <TableCell>45.4089, -75.757</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>DMS</TableCell>
                <TableCell>45d 24' 32&quot; N, 75d 45' 25&quot; W </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>MinDec</TableCell>
                <TableCell>45d 24.533' N, 75d 45.42' W </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>EPSG:900913</TableCell>
                <TableCell>-8433230.66, 5686125.78</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>USNG (MGRS)</TableCell>
                <TableCell>18T VR 40762 28654</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Typography>
      </Popper>
    </React.Fragment>
  );
}
