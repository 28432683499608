import { default as Module } from "sccModule";

/**
 * The base object for User module
 *
 */
class UserModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "user",
    };
    super(options);
  }
}

export default new UserModule();
