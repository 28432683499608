import React, { useContext } from "react";

// External
import _ from "lodash";
import moment from "moment";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EventIcon from "@material-ui/icons/Event";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";

// Internal
import { HistoryContext } from "../../HistoryContext";
import Device from "sccDevice";
import Images from "sccImage";
import OlMapHistory from "sccOlMapHistory";
import Language from "sccLanguage";
import olMap from "sccOlMapNew";

const useStyles = makeStyles((theme) => ({
  eventAlerts: {
    padding: "3px",
    color: theme.palette.colors.white.main,
    textAlign: "center",
    marginBottom: "6px",
    fontSize: "15px",
    " & .emergency": {
      background: `${theme.palette.colors.secondary.main} !important`,
    },

    "& .geofence": {
      background: `${theme.palette.colors.orange.main} !important`,
    },

    "& .report": {
      background: `${alpha(theme.palette.colors.black.main, 0.85)} !important`,
    },

    "& .speed": {
      background: `${theme.palette.colors.purple.main} !important`,
    },

    "& .non_report": {
      background: `${theme.palette.colors.green.main} !important`,
      padding: "2px 6px",
      color: theme.palette.colors.white.main,
    },

    "& .cargo": {
      background: `${theme.palette.alerts.cargo.main} !important`,
      padding: "2px 6px",
      color: theme.palette.colors.white.main,
    },

    "& .vehicle": {
      background: `${theme.palette.alerts.vehicle.main} !important`,
      padding: "2px 6px",
      color: theme.palette.colors.white.main,
    },
  },

  moreInfo: {
    padding: "5px 0 0 10px",
  },

  eventAssetName: {
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    "& .data-display-box-img": {
      height: "25px",
      float: "left",
      width: "20px",
      marginRight: "5px",
      display: "flex",
      "& img": {
        width: "100%",
      },
    },
  },

  eventTime: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "& .data-display-box-img": {
      height: "25px",
      width: "20px",
      marginRight: "5px",
      display: "flex",
      alignItems: "center",
      "& img": {
        width: "100%",
      },
    },
  },

  eventLocation: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "& .data-display-box-img": {
      height: "25px",
      width: "20px",
      marginRight: "5px",
      display: "flex",
      alignItems: "center",
      "& img": {
        width: "100%",
      },
    },
  },

  historyLocateEvent: {
    "& .menu-list-btn": {
      marginRight: "4px",
    },
  },

  typography: {
    padding: theme.spacing(2),
  },

  locationBox: {
    height: 25,
    width: 20,
    marginRight: "5px",
    display: "flex",
    alignItems: "center",
  },
}));

function EventDisplay(props) {
  const event = props.event;
  const eventType = event.event;
  const deviceId = event.device_id;
  const deviceDetails = Device.get(deviceId);
  const deviceName = deviceDetails.name;
  const deviceTypeId = deviceDetails.type_id;
  const eventTime = event.event_timestamp;
  const [state] = useContext(HistoryContext);
  const { capitalizeFirstLetter, Historic } = state;
  const classes = useStyles();

  const getReportAlerts = (report) => {
    let alerts = [];
    if (Historic.hasAlert(report, "emergency")) {
      alerts.push("Emergency");
    } else if (Historic.hasAlert(report, "geofence")) {
      alerts.push("Geofence");
    } else if (Historic.hasAlert(report, "speed")) {
      alerts.push("Speed");
    } else if (Historic.hasAlert(report, "vehicle")) {
      alerts.push("Vehicle");
    } else if (Historic.hasAlert(report, "cargo")) {
      alerts.push("Cargo");
    } else if (Historic.hasAlert(report, "vehicle")) {
      alerts.push("Vehicle");
    } else if (Historic.hasAlert(report, "non_report")) {
      alerts.push("Non-Report");
    }

    return alerts;
  };

  return (
    <React.Fragment>
      <div className={classes.eventAlerts}>
        {getReportAlerts(event).length > 0 ?
          getReportAlerts(event).map((ev) => (
            <div className={`${ev.toLowerCase()}`} key={ev}>
              {Language.translate(capitalizeFirstLetter(ev))}
            </div>
          )) : (
            <div className={`${eventType}`} key={eventType}>
              {Language.translate(capitalizeFirstLetter(eventType))}
            </div>
          )}
      </div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        className={classes.moreInfo}
      >
        <Grid item className={classes.eventAssetName}>
          <span className="data-display-box-img">
            <img
              alt={Images.get(Device.getDeviceType(deviceTypeId).image_id).name}
              src={Images.getImageSource(
                Device.getDeviceType(deviceTypeId).image_id
              )}
            />
          </span>
          {deviceName}
        </Grid>
        <Grid item className={classes.eventTime}>
          <span className="data-display-box-img">
            <EventIcon />
          </span>
          {moment.unix(eventTime).format("MMM D, YYYY, HH:mmA")}
        </Grid>
        {event.latitude || event.longitude ? (
          <Grid
            container
            item
            className={classes.eventLocation}
            justifyContent="space-between"
            xs={12}
          >
            <Grid item container xs={10} alignItems="center">
              <span className={classes.locationBox}>
                <LocationOnIcon />
              </span>
              {OlMapHistory.formatCoordinate([event.longitude, event.latitude])}
            </Grid>
            <Grid
              container
              item
              xs={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                disableRipple={true}
                onClick={() => zoomToCoord(event.longitude, event.latitude)}
              >
                <MyLocationIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}

function zoomToCoord(longitude, latitude) {
  const zoom = olMap.mapQuickZoomInLevel;
  const orgCoord = [longitude, latitude];
  const coord = olMap.transformToMapCoordinate(orgCoord);
  OlMapHistory.setCenterAndZoom(coord, zoom);
}

export default function EventReport(props) {
  return (
    <React.Fragment>
      {<EventDisplay event={props.currentEvent} />}
    </React.Fragment>
  );
}
