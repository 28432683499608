import React from "react";

// Material-UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import CustomTooltip from "@Components/CustomTooltip";
import IconButton from "@material-ui/core/IconButton";

// Internal
import Language from "sccLanguage";
import "./CustomDialogYesNo.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomDialogYesNo(props) {
  const handleYESClick = () => {
    props.onYesAction();
  };
  const handleNOClick = () => {
    props.onNoAction();
  };

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      className="CustomDialog"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div style={{ top: 0, right: 0, position: "absolute" }}>
            <CustomTooltip
              title={Language.translate("Close Menu")}
              placement="bottom"
            >
              <IconButton onClick={handleNOClick} color="inherit">
                <CloseIcon />
              </IconButton>
            </CustomTooltip>
          </div>
          <div style={{ color: "black", fontSize: "1rem" }}>{props.text}</div>
          {props.children}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={handleYESClick}
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
        >
          {Language.translate("YES")}
        </Button>
        <Button
          onClick={handleNOClick}
          variant="contained"
          color="default"
          startIcon={<CloseIcon />}
        >
          {Language.translate("NO")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
