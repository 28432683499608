import React from "react";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";

//Internal
import AssetGroup from "./AssetGroup";

export default function AssetGroupSelector(props) {
  const groups = props.collection;
  const {
    selection,
    setSelection,
    disabledGroups,
    setDisabledGroups,
    indeterminateGroups,
    setIndeterminateGroups,
    mainGroupName,
  } = props;

  const useStyles = makeStyles((theme) => ({
    assetGroupSelector: {
      marginLeft: 35,
    },
  }));
  const classes = useStyles();

  return (
    <div
      className={
        groups &&
        groups.length > 0 &&
        groups[0].title !== mainGroupName &&
        classes.assetGroupSelector
      }
    >
      {groups && (
        <AssetGroup
          collection={groups}
          selection={selection}
          setSelection={setSelection}
          disabledGroups={disabledGroups}
          setDisabledGroups={setDisabledGroups}
          indeterminateGroups={indeterminateGroups}
          setIndeterminateGroups={setIndeterminateGroups}
          name={props.name}
          mainGroupName={mainGroupName}
        />
      )}
    </div>
  );
}
