import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import Alert from "sccAlert";
import Language from "sccLanguage";

const AppContext = React.createContext([{}, () => {}]);

const useStyles = makeStyles({
  root: {
    maxWidth:300,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: 6,
    display: "inline-flex",
  },
  message: {
    fontSize: 15,
    paddingBottom: 10,
    display: "inline-flex",
    fontWeight: 700,
  },
  subMessage: {
    fontSize: 13,
  },
  ackButton: {
    width: 140,
    height: 30,
    backgroundColor: "#4bff4c",
    color: "#000",
    display: "flex",
    alignItems: "center",
    marginTop: 10,

    "&:hover": {
      backgroundColor: "#293239",
      color: "#fff",
    }
  },
  ackIcon: {
    paddingRight: "4px",
  },
});

const AppContextProvider = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  const displaySnackbarMessage = (obj) => {
    const title = obj.title && Language.translate(obj.title);
    const message = obj.message && Language.translate(obj.message);
    const subMessage = obj.subMessage && Language.translate(obj.subMessage);
    const variant = obj.variant;
    const acknowledgeAlertId = obj.acknowledgeAlertId;

    const options = obj.options || {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      variant: variant,
      autoHideDuration: acknowledgeAlertId?.length > 0 ? null : 5000,
    };
    enqueueSnackbar(
      <div className={classes.root}>
        {title ? (
          <React.Fragment>
            <span className={classes.title}>{title}</span>
            <br />
          </React.Fragment>
        ) : null}
        <span className={classes.message}>{message}</span>
        {subMessage ? (
          <React.Fragment>
            <br />
            <span className={classes.subMessage}>{subMessage}</span>
          </React.Fragment>
        ) : null}
        {acknowledgeAlertId?.length > 0 ? (
          <Button
            className={classes.ackButton}
            onClick={() => {
              Alert.acknowledge(acknowledgeAlertId).then(() => closeSnackbar());
            }}
          >
            <CheckCircleIcon className={classes.ackIcon} />
            {Language.translate("ACKNOWLEDGE")}
          </Button>
        ) : null}
      </div>,
      options
    );
  };

  const [appState, setAppState] = useState({
    //libraries
    //common reusable functions for this module only
    displaySnackbarMessage,
    //shared flags and variables
    //objects
  });

  return (
    <AppContext.Provider value={[appState, setAppState]}>
      {props.children}
    </AppContext.Provider>
  );
};
export { AppContext, AppContextProvider };
