import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { toLower } from "lodash";
import moment from "moment";
import clsx from "clsx";

// Material-UI
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import EditIcon from "@material-ui/icons/Edit";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import BatteryCharging60Icon from "@material-ui/icons/BatteryCharging60";
import OpacityIcon from "@material-ui/icons/Opacity";

// Internal
import Language from "sccLanguage";
import Device from "sccDevice";
import Alert from "sccAlert";
import TimeUtils from "sccTimeUtils";
import Permission from "sccPermission";
import Utils from "sccUtils";
import Profile from "sccProfile";
import CustomIcon from "@Components/CustomIcon";
import CustomTooltip from "@Components/CustomTooltip";
import CustomButton from "@Components/CustomButton";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import AlertDetail from "./AlertDetail";
import { moduleListing } from "@Styles/ModuleListing";

const useStyles = makeStyles((theme) => ({
  ...moduleListing(theme),
  listContainer: {
    ...moduleListing(theme).listContainer,
    "& .Emergency": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.emergency.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.emergency.main,
      },
    },
    "& .Geofence": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.geofence.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.geofence.main,
      },
    },
    "& .Report": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.report.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.report.main,
      },
    },
    "& .Speed": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.speed.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.speed.main,
      },
      "& .geofence": {
        color: theme.palette.alerts.geofence.main,
        fontSize: ".6rem",
      },
    },
    "& .Non-Report": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.nonreport.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.nonreport.main,
      },
    },
    "& .Cargo": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.cargo.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.cargo.main,
      },
    },
    "& .Vehicle": {
      marginLeft: 1,
      borderLeft: `5px solid ${theme.palette.alerts.vehicle.main} !important`,
      "& .CustomIcon": {
        color: theme.palette.alerts.vehicle.main,
      },
    },
  },

  cargoIcon: {
    color: theme.palette.alerts.cargo.main,
    height: "2em",
    width: "2em",
  },
}));

function ModuleWrapperAlertsBody(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);
  const classes = useStyles();

  const handleClick = (id) => {
    openDetailsFor !== id ? setOpenDetailsFor(id) : setOpenDetailsFor(null);
  };

  function zoomToCoord(longitude, latitude) {
    const olMap = require("sccOlMapNew").default;
    const zoom = olMap.mapQuickZoomInLevel;
    const orgCoord = [longitude, latitude];
    const coord = olMap.transformToMapCoordinate(orgCoord);
    olMap.setCenterAndZoom(coord, zoom);
  }

  //updating the data with the device_title to be displayed in listing and used in search
  let filteredModuleData = mwState.filteredModuleData;

  const moduleData = mwState.moduleData;
  _.each(Object.values(moduleData), (alert) => {
    alert.device_title = Device.get(alert.device_id).name;
  });
  _.each(Object.values(filteredModuleData), (alert) => {
    alert.device_title = Device.get(alert.device_id).name;
  });
  //to be used in the listing page
  filteredModuleData = mwState.convertAndSort(
    filteredModuleData,
    mwState.searchField
  );

  // sort Alerts by time from earliest to lasted
  const sortedFilteredModuleData = _.isEmpty(filteredModuleData)
    ? []
    : filteredModuleData.sort(
        (a, b) => b?.info?.start_timestamp - a?.info?.start_timestamp
      );

  // get Cargo Alert Icon
  const getCargoAlertIcon = (alert) => {
    const cargoType = Alert.getCargoAlertType(
      alert.info.cargo_alert_type_id
    ).type;

    switch (cargoType) {
      case "Door":
        return <MeetingRoomIcon className={classes.cargoIcon} />;
      case "Temperature":
        return <WhatshotIcon className={classes.cargoIcon} />;
      case "Humidity":
        return <OpacityIcon className={classes.cargoIcon} />;
      default:
      case "Battery":
        return <BatteryCharging60Icon className={classes.cargoIcon} />;
    }
  };

  return (
    <ul className={classes.listContainer}>
      {!_.isEmpty(sortedFilteredModuleData) ? (
        sortedFilteredModuleData
          .slice(mwState.pagination.startIndex, mwState.pagination.endIndex)
          .map((data, index) => {
            const deviceInfo = Device.get(data.device_id);

            return (
              <React.Fragment key={data.id}>
                <ListItem
                  className={clsx(
                    classes.listItem,
                    Alert.getAlertType(data.alert_type_id).type
                  )}
                  key={data.id}
                  onClick={() => handleClick(data.id)}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <CustomTooltip
                      title={Alert.getAlertType(data.alert_type_id).type}
                      placement="left"
                    >
                      <span>
                        {data.type === "Cargo" ? (
                          getCargoAlertIcon(data)
                        ) : (
                          <CustomIcon
                            type={toLower(data.type)}
                            id={`icon-${toLower(data.type)}`}
                            fontSize="small"
                          />
                        )}
                      </span>
                    </CustomTooltip>
                  </ListItemIcon>
                  <ListItemText
                    id={data.id}
                    className={classes.itemPrimaryText}
                    primary={
                      <Grid container xs={12}>
                        <Grid item container xs={8}>
                          <Grid item xs={9}>
                            {data.device_title}
                          </Grid>
                          {data.info.geofence_id &&
                          Alert.getAlertType(data.alert_type_id)?.type ==
                            "Speed" ? (
                            <Grid item xs={1}>
                              <CustomTooltip
                                title={Language.translate("Geofence")}
                                placement="top"
                              >
                                <div>
                                  <CustomIcon
                                    type="geofence"
                                    id={`icon-geofence`}
                                    fontSize="small"
                                  />
                                </div>
                              </CustomTooltip>
                            </Grid>
                          ) : (
                            <Grid item xs={1}>
                              &nbsp;
                            </Grid>
                          )}
                          <Grid item xs={2}>
                            <CustomTooltip
                              title={Language.translate("Locate")}
                              placement="top"
                            >
                              <IconButton
                                className="zoomToCoord"
                                edge="end"
                                aria-label="locate"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    deviceInfo.latitude &&
                                    deviceInfo.longitude
                                  ) {
                                    zoomToCoord(
                                      deviceInfo.longitude,
                                      deviceInfo.latitude
                                    );
                                  }
                                }}
                              >
                                <MyLocationIcon />
                              </IconButton>
                            </CustomTooltip>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <CustomTooltip
                            title={Language.translate("Age")}
                            placement="left"
                          >
                            <span>
                              {TimeUtils.getAge(data.info.start_timestamp)}
                            </span>
                          </CustomTooltip>
                        </Grid>
                      </Grid>
                    }
                  />
                  <ListItemSecondaryAction
                    className={classes.itemActionButtonsContainer}
                  >
                    {_.isEmpty(
                      _.find(data.acknowledgements, { id: Profile.get().id })
                    ) ? (
                      <CustomTooltip
                        title={Language.translate("Acknowledge")}
                        placement="left"
                      >
                        <CustomButton
                          disabled={data.acknowledgeDisabled}
                          onClick={() => Alert.acknowledge([data.id])}
                          size="medium"
                          color="command"
                          variant="contained"
                          startIcon={<EditIcon />}
                        >
                          {Language.translate("Acknowledge")}
                        </CustomButton>
                        {/* part of the onclick on button above $event.stopPropagation(); */}
                      </CustomTooltip>
                    ) : (
                      `${
                        Object.keys(data.acknowledgements).length
                      } ${Language.translate("Users")}`
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse
                  in={openDetailsFor === data.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <AlertDetail data={data} deviceInfo={deviceInfo} />
                </Collapse>
              </React.Fragment>
            );
          })
      ) : (
        <div className="noResults">{Language.translate("No Results!")}</div>
      )}
    </ul>
  );
}

export default ModuleWrapperAlertsBody;
