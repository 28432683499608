import React, { useState, useContext, useEffect } from "react";

//External
import _ from "lodash";

//Material UI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AttachmentIcon from "@material-ui/icons/Attachment";
import LaptopIcon from "@material-ui/icons/Laptop";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Popover from "@material-ui/core/Popover";

// Components
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomDropdown from "@Components/CustomDropdown";
import CustomIcon from "@Components/CustomIcon";
import CustomTextField from "@Components/CustomTextField";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleMessage } from "@Styles//ModuleMessage";
import theme from "@Styles/theme";

//Internal
import Device from "sccDevice";
import Group from "sccGroup";
import Language from "sccLanguage";
import Message from "sccMessage";
import Permission from "sccPermission";
import User from "sccUser";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.colors.gray.main,
    height: 30,
    boxShadow: "none",
    marginLeft: 40,
  },
  chip: {
    height: 15,
    backgroundColor: theme.palette.colors.green.dark,
    marginLeft: 5,
  },
  chipRecipient: {
    height: 25,
    marginRight: 10,
    backgroundColor: theme.palette.colors.gray.main,
    fontSize: 10,
  },
  item: {
    height: "30px",
    borderBottom: `1px solid ${theme.palette.colors.gray.main}`,
    whitespace: "nowrap",
    "& .MuiListItemText-root": {
      "& .MuiListItemText-primary": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        margin: "5px 5px",
      },
    },
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
      "& .MuiCheckbox-root": {
        marginLeft: 0,
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: 20,
    },
  },
  viewMessageRecipients: {
    margin: 0,
    marginBottom: 10,
    padding: 10,
    minHeight: 20,
    maxHeight: 300,
    overflowX: "hidden",
    overflowY: "auto",
    border: `1px solid ${theme.palette.colors.red.dark}`,
  },
  viewMessageAttachment: {
    padding: 0,
    fontSize: 12,
  },
  btn: {
    padding: 7,
    marginRight: 10,
    marginBottom: 10,
    minWidth: "50px !important",
    height: 30,
    color: theme.palette.colors.black.main,
    background: theme.palette.colors.gray.main,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    verticalAlign: "unset",
  },
}));

function Recipients(props) {
  // const {name, label, value, color="default", checked, indeterminate, labelPlacement="end", actions, onCheckAllChange, handleAction, ...other} = props;
  const {
    recipients,
    handleRecipientDelete,
    updateAssetRecipients,
    onUserChange,
    ...other
  } = props;
  const [assetSelectorState, setAssetSelectorState] = useContext(
    CustomAssetSelectorContext
  );

  const classes = useStyles();

  const filteredUsers = usersList();

  const [anchorElAssets, setAnchorElAssets] = React.useState(null);
  const [anchorElUsers, setAnchorElUsers] = React.useState(null);
  const handleClickAssets = (event) => {
    setAnchorElAssets(event.currentTarget);
  };
  const handleCloseAssets = () => {
    setAnchorElAssets(null);
  };
  const handleClickUsers = (event) => {
    setAnchorElUsers(event.currentTarget);
  };
  const handleCloseUsers = () => {
    setAnchorElUsers(null);
  };
  function handleToggle() {}
  function usersList() {
    const users = (User.get() && Object.values(User.get())) || [];
    console.log("aaausers", users);
    var output = users.filter((user) => user.username !== null);
    console.log("aaaoutput", output);
    return output;
  }

  function usersLength() {
    const ids = [];
    _.each(filteredUsers, function (key) {
      if (key.username) {
        ids.push(key.id);
      }
    });
    return ids.length;
  }

  const openAssets = Boolean(anchorElAssets);
  const openUsers = Boolean(anchorElUsers);
  const idAssets = openAssets ? "assetsPopover" : undefined;
  const idUsers = openUsers ? "userPopover" : undefined;

  function displayRecipients() {
    let recipientChips = [];
    recipients &&
      recipients.users.map((user) =>
        recipientChips.push(
          <Chip
            className={classes.chipRecipient}
            icon={<LaptopIcon />}
            label={User.get(user).first_name + " " + User.get(user).last_name}
            onDelete={() => handleRecipientDelete("users", user)}
          />
        )
      );
    recipients &&
      recipients.devices.map((device) =>
        recipientChips.push(
          <Chip
            className={classes.chipRecipient}
            icon={<PhoneAndroidIcon />}
            label={Device.get(device).name}
            variant="outlined"
            onDelete={() => handleRecipientDeleteLocal(device)}
          />
        )
      );
    return recipientChips;
  }

  function handleRecipientDeleteLocal(device) {
    //this function copied and modified from the AS module. The idea is to update the correct groups and devices in AS module from outside
    const name = "recipients";
    let arrGroups = assetSelectorState.selection[name].groups;
    const arrDevices = _.xor(assetSelectorState.selection[name].devices, [
      device,
    ]);
    // Get the groups associated to this device
    const groups = Device.get(device).groups;

    // Loop through each group to get total devices
    groups.forEach((groupId) => {
      arrGroups = _.xor(
        arrGroups,
        checkGroups(groupId, _.uniq(arrDevices.concat(device)))
      );
    });
    setAssetSelectorState((p) => ({
      ...p,
      selection: {
        recipients: {
          ...p.selection.recipients,
          devices: arrDevices,
          groups: arrGroups,
        },
      },
    }));

    function checkGroups(groupId, arrDevices) {
      //Get all devices in group
      const devices = Group.get(groupId).devices;
      if (_.intersection(devices, arrDevices).length === devices.length) {
        return [groupId];
      }
      return [];
    }
  }

  useEffect(() => {
    if (assetSelectorState.selection["recipients"]) {
      //each selection change would update the local recipients
      updateAssetRecipients(assetSelectorState.selection["recipients"]);
    }
  }, [assetSelectorState.selection]);
  console.log("rec", recipients);
  return (
    <div>
      <Button
        aria-describedby={idAssets}
        variant="contained"
        onClick={handleClickAssets}
        className={classes.btn}
      >
        {Language.translate("Assets")} ({Object.keys(Device.get()).length}){" "}
        {recipients && recipients.devices.length > 0 ? (
          <Chip
            size="small"
            color="primary"
            label={recipients.devices.length}
            className={classes.chip}
          />
        ) : null}{" "}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        id={idAssets}
        open={openAssets}
        anchorEl={anchorElAssets}
        onClose={handleCloseAssets}
        style={{ position: "absolute", top: 0, left: 0, borderRadius: 0 }}
        PaperProps={{
          style: {
            width: 450,
            maxHeight: 300,
            borderRadius: 0,
            borderTop: `1px solid ${theme.palette.colors.gray.pure}`,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CustomAssetWrapper
          selection={recipients}
          openMain={true}
          name="recipients"
          editMode={true}
        />
      </Popover>
      <Button
        aria-describedby={idUsers}
        variant="contained"
        onClick={handleClickUsers}
        className={classes.btn}
      >
        {Language.translate("Users")} ({usersLength()}){" "}
        {recipients && recipients.users.length > 0 ? (
          <Chip
            size="small"
            color="primary"
            label={recipients.users.length}
            className={classes.chip}
          />
        ) : null}{" "}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        id={idUsers}
        open={openUsers}
        anchorEl={anchorElUsers}
        onClose={handleCloseUsers}
        style={{ position: "absolute", top: 0, left: 0, borderRadius: 0 }}
        PaperProps={{
          style: {
            width: 250,
            maxHeight: 300,
            borderRadius: 0,
            borderTop: `1px solid ${theme.palette.colors.gray.pure}`,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {Permission.verify("user") ? (
          usersLength() === 0 ? (
            Language.translate("Currently there are no Users")
          ) : (
            <List>
              {Object.values(filteredUsers).map((option) => (
                <CustomTooltip
                  title={option.first_name + " " + option.last_name}
                  placement="right"
                >
                  <ListItem
                    className={classes.item}
                    key={option.id}
                    disablePadding
                    onClick={handleToggle}
                  >
                    <ListItemIcon>
                      <CustomCheckbox
                        edge="start"
                        checked={
                          recipients && recipients.users.indexOf(option.id) > -1
                        }
                        onChange={onUserChange}
                        tabIndex={-1}
                        value={option.id}
                        inputProps={{ "aria-labelledby": option.id }}
                      />
                    </ListItemIcon>
                    <ListItemText id={option.id} primary={option.username} />
                  </ListItem>
                </CustomTooltip>
              ))}
            </List>
          )
        ) : (
          Language.translate("You do not have Permission to view Users")
        )}
      </Popover>
      <div className={classes.viewMessageRecipients}>
        {displayRecipients().map((r) => r)}
      </div>
    </div>
  );
}

export default Recipients;
