import * as Yup from "yup";
import baseSchema from "./BaseValidation";
import Language from "sccLanguage";

const schema = Yup.object().shape({
  title: baseSchema(Language).requiredStringMinMax,
  note: baseSchema(Language).requiredStringMinMax,
  shape: baseSchema(Language).dropdownSelectStr,
  min_speed: baseSchema(Language).notRequiredMax8DigitsNumberWithMax2Decimals,
  max_speed: Yup.number().when("min_speed", {
    is: (num) => num === undefined || num === 0,
    then: baseSchema(Language).notRequiredMax8DigitsNumberWithMax2Decimals,
    otherwise: baseSchema(Language).notRequiredMax8DigitsNumberWithMax2Decimals.moreThan(
      Yup.ref("min_speed"),
      Language.translate("Max speed should be more than min speed")
    ),
  }),
});

export default schema;
