import React, { useState, useContext } from "react";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { MenuItem } from "@material-ui/core";
import ARList from "./ARList";
import ARForm from "./ARForm";
import { CustomAssetSelectorContext } from "../../../reusable-components/CustomAssetWrapper/CustomAssetSelectorContext";
import { moduleListing } from "@Styles/ModuleListing";
import { makeStyles } from "@material-ui/core/styles";

const _ = require("lodash");
const Language = require("sccLanguage").default;

const MW_LIST_MODE = process.env.REACT_APP_MW_LIST_MODE;

function ModuleWrapperARBody(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const [openDetailsFor, setOpenDetailsFor] = useState(null);
  const moduleData = Object.values(mwState.moduleData);
  const filteredModuleData = mwState.convertAndSort(
    mwState.filteredModuleData,
    mwState.searchField
  );
  const moduleItemData = mwState.moduleItemData;

  const handleClick = (id) => {
    openDetailsFor !== id ? setOpenDetailsFor(id) : setOpenDetailsFor(null);
  };

  const useStyles = makeStyles((theme) => ({
    ...moduleListing(theme),
  }));
  const classes = useStyles();

  return mwState.wrapperDisplayMode === MW_LIST_MODE ? (
    <ul className={classes.listContainer}>
      {!_.isEmpty(filteredModuleData) ? (
        filteredModuleData
          .slice(mwState.pagination.startIndex, mwState.pagination.endIndex)
          .map((data) => {
            return (
              <ARList
                key={data.id}
                data={data}
                handleClick={handleClick}
                openDetailsFor={openDetailsFor}
              />
            );
          })
      ) : (
        <div className="noResults">{Language.translate("No Results!")}</div>
      )}
    </ul>
  ) : (
    <ARForm />
  );
}

export default ModuleWrapperARBody;
