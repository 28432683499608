import React, { useState, useEffect } from "react";

// External
import _ from "lodash";

// Material-UI

import Popper from "@material-ui/core/Popper";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from '@material-ui/core/Chip';
import FilterAltIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";

// Components
import CustomButton from "@Components/CustomButton";
import CustomSearch from "@Components/CustomSearch";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomTooltip from "@Components/CustomTooltip";

// Internal
import Language from "sccLanguage";
import Client from "sccClient";

function AdminDeviceFilter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {name, label, data, onChange, clearFilterSelection, filterSelection, updateFilteredData, ...other} = props;

	const [open, setOpen] = useState(Boolean(anchorEl));
	const [clientSearchFilter, setClientSearchFilter] = useState("");
	const [deviceSearchFilter, setDeviceSearchFilter] = useState("");
	var [filteredDeviceTypes, setFilteredDeviceTypes] = useState([]);
	var [clients, setClients] = useState([]);
	var [filteredClients, setFilteredClients] = useState([]);
	const id = open ? "filterPopper" : undefined;

	var selection = _.isEmpty(filterSelection)? {client_id: [], type_id: []} : filterSelection
	
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setOpen((prev)=>!prev);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};
	const handleClearDeviceSearch = () => {
		setDeviceSearchFilter("");
		setFilteredDeviceTypes(Object.values(AdminDevice.getDeviceType()));
	};
	const handleClearClientSearch = () => {
		setClientSearchFilter("");
		setFilteredClients(Object.values(Client.get()));
	};
	const handleClearClientAndDeviceSearch = () => {
		clearFilterSelection();
		selection={client_id: [], type_id: []};
		setFilteredDeviceTypes(Object.values(AdminDevice.getDeviceType()));
		setFilteredClients(Object.values(Client.get()));
	};
	function updateFilteredDeviceSearchDataLocal(data){
		setFilteredDeviceTypes(Object.values(data))
	}
	function updateFilteredClientSearchDataLocal(data){
		setFilteredClients(Object.values(data))
	}

	const useStyles = makeStyles({
		root: {
			position: "absolute",
			minWidth: 300,
			maxWidth: 600,
			zIndex: 1000,
			maxHeight: 750,
		},
		button: {
			height: 30,
			marginRight: 10,
		},
		filter: {
			width: '100%',
			overflowY: 'auto',
			maxHeight: 550
		},
		search: {
			marginLeft: 10,
			marginRight: 10,
		},
		deviceType: {
			fontSize: 20,
			margin: 3,
			marginTop: -20,
			paddingLeft: 20
		},
		client: {
			fontSize: 20,
			margin: 3,
			marginTop: -20,
			paddingLeft: 20
		},
		clear: {
			marginLeft: 10,
			padding: 3,
			"& .MuiChip-label": {
				fontSize: 16,
			},
		}
	});

  const classes = useStyles();
	const AdminDevice = require("sccAdminDevice").default;
	useEffect(() => {
		setFilteredDeviceTypes(Object.values(AdminDevice.getDeviceType()));
		setClients(Object.values(Client.get()));
		setFilteredClients(Object.values(Client.get()));
	}, [])

	return (
    <React.Fragment>
			<CustomTooltip title={label} arrow placement="top">
				<CustomButton className={classes.button} onClick={handleClick} size="medium" color="command" variant="contained" startIcon={<FilterAltIcon />} endIcon={<ArrowDropDownIcon />}>
          			{label}	{(selection.type_id.length>0||selection.client_id.length>0)? <Chip label={selection.type_id.length+selection.client_id.length} size="small"/> : null}
        		</CustomButton>
			</CustomTooltip>
			<Popper id={id} disablePortal={false} open={open} anchorEl={anchorEl} className={classes.root} onClose={handleClose} placement="left-start">
				<Paper elevation={8}>
					<ClickAwayListener onClickAway={handleClose}>
						<Grid container xs={12} spacing={2}>
							<Grid item xs={11}>
								{(selection.type_id.length>0||selection.client_id.length>0)?
									<Chip className={classes.clear} label={Language.translate('Clear')} onClick={handleClearClientAndDeviceSearch} onDelete={handleClearClientSearch} />
									: null}
							</Grid>
							<Grid container item xs={1} justifyContent="flex-end">
								<IconButton disableRipple={true} onClick={handleClose}>
									<CloseIcon />
								</IconButton>
							</Grid>
							<Grid container item xs={12} spacing={2}>
								<Grid item xs={6}>
								
									<h5 className={classes.deviceType}>By Client {selection.client_id.length>0?<span>({selection.client_id.length})</span>: null}</h5>
									{clients && Object.values(clients).length>10?
									<div>
										<CustomSearch
											id="txtSearch"
											name="search"
											placeholder={Language.translate("Search")+" "+Language.translate("Clients")}
											value={clientSearchFilter}
											onChange={(e) => setClientSearchFilter(e.target.value)}
											data={clients && Object.values(clients)}
											filteredData={filteredClients}
											updateFilteredData={updateFilteredClientSearchDataLocal}
											searchField={['company']}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment
														position="end"
														id="clearSearch"
														onClick={handleClearClientSearch}
														style={{ cursor: "pointer" }}>
														<ClearIcon />
													</InputAdornment>
												)
											}}
										/>
									</div>
									:null}
									<div className={classes.filter}>
											<List>
											{filteredClients.map(client => {
												const exists = _.values(_.filter(AdminDevice.get(), {client_id:client.id})).length; 
												return exists ? <ListItem>
												<CustomCheckbox
													name={client.company}
													value={client.id}
													checked={selection.client_id.indexOf(client.id) > -1}
													onChange={e=>onChange(e, 'client_id', data)}
													color="primary" />
													{client.company+" "}
													<span>
														<Chip
															label={exists}
															size="small"
														/>
													</span>
												</ListItem> : null
												})
											}
											</List>
									</div>
								</Grid>

								<Grid item xs={6}> 
									<h5 className={classes.deviceType}>{Language.translate("By Device Type")} {selection.type_id.length>0?<span>({selection.type_id.length})</span>: null}</h5>
									{AdminDevice.getDeviceType() && Object.values(AdminDevice.getDeviceType()).length>10?
									<div>
										<CustomSearch
											id="txtSearch"
											name="search"
											placeholder={Language.translate("Search Devices")}
											value={deviceSearchFilter}
											onChange={(e) => setDeviceSearchFilter(e.target.value)}
											data={AdminDevice.getDeviceType() && Object.values(AdminDevice.getDeviceType())}
											filteredData={filteredDeviceTypes}
											updateFilteredData={updateFilteredDeviceSearchDataLocal}
											searchField={['title']}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment
														position="end"
														id="clearSearch"
														onClick={handleClearDeviceSearch}
														style={{ cursor: "pointer" }}>
														<ClearIcon />
													</InputAdornment>
												)
											}}
										/>
									</div>
									:null}
									<div className={classes.filter}>
										<List>
										{filteredDeviceTypes.map(type => {
											const exists = _.values(_.filter(AdminDevice.get(), {type_id:type.id})).length; 
											return exists ? <ListItem>
											<CustomCheckbox
												name={type.title}
												value={type.id}
												checked={selection.type_id.indexOf(type.id) > -1}
												onChange={e=>onChange(e, 'type_id', data)}
												color="primary" />
												{type.title+" "}
												<span>
													<Chip
														label={exists}
														size="small"
													/>
												</span>
											</ListItem> : null
											})
										}
										</List>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</ClickAwayListener>
				</Paper>
			</Popper>
    </React.Fragment>
    );
}

export default AdminDeviceFilter;