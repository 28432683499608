import React, {useEffect, useState, useContext} from 'react'
import Register from '../../login/components/Register'

var _= require("lodash");

function RegisterMain() {
	return (
		<div>
				<Register />
		</div>
	)
}

export default RegisterMain;