import React, { useState, useContext } from "react";

// Material-UI
import { makeStyles, alpha } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Battery20Icon from "@material-ui/icons/Battery20";
import Battery90Icon from "@material-ui/icons/Battery90";
import BatteryCharging80Icon from "@material-ui/icons/BatteryCharging80";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import EditIcon from "@material-ui/icons/Edit";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import HistoryIcon from "@material-ui/icons/History";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import RssFeedIcon from "@material-ui/icons/RssFeed";

// Components
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Internal
import Chat from "sccChat";
import Device from "sccDevice";
import DeviceOverlay from "sccDeviceOverlay";
import Language from "sccLanguage";
import Permission from "sccPermission";
import { MinimizeContext } from "../../context/MinimizeContext";

const MW_ASSETS_MODULE_MODE = process.env.REACT_APP_MW_ASSETS_MODULE_MODE;

const useStyles = makeStyles((theme) => ({
  rootGroup: {
    "& .dataDisplayButtonGroup": {
      borderRadius: "0 !important",
      width: "100% !important",

      "& .NoPointer": {
        cursor: "initial",
      },

      "& .dataDisplayButton": {
        padding: "5px 10px",
        borderRadius: "0",
        width: "30px",
        minWidth: "30px",
        height: "30px",
        margin: "0 !important",
        border: `1px solid ${theme.palette.colors.gray.main} !important`,
        backgroundColor: theme.palette.colors.gray.pure,

        "&:hover": {
          backgroundColor: theme.palette.colors.gray.pure,
        },

        "& .svg": {
          fontSize: "16px",
        },

        "&.locked": {
          background: "yellow",
        },
      },
    },

    "& .devicePopUpHeaderRedBattery": {
      backgroundColor: `${theme.palette.colors.red.main} !important`,
    },

    "& .devicePopUpHeaderBattery": {
      color: `${theme.palette.colors.gray.pure} !important`,
    },
  },
}));

export default function DataDisplayButtonGroup({
  device,
  isDeviceLocked,
  updateDeviceIsLocked,
}) {
  const [chatBoxOpen, setChatBoxOpen] = useState();
  const { state } = useContext(MinimizeContext);
  const [mwState] = useContext(ModuleWrapperContext);
  const pollCommands = mwState.pollCommands;

  const obj = device;

  const deviceObject = {
    tab: "Asset",
    shortTitle: "Assets",
    title: "Assets",
    moduleName: "Device",
    module: Device,
    imageClass: "tablet",
    extLink: false,
    isList: true,
    wrapperDisplayMode: MW_ASSETS_MODULE_MODE,
    showFilter: true,
    showPagination: false,
    showSearch: true,
    searchField: ["name"],
    filterField: [
      {
        title: "Device Type",
        list: Device._deviceType,
        id: "type_id",
        displayField: "title",
        searchField: "title",
      },
    ],
    showFooter: true,
    verifyAddPermission: Permission.verify("group", "add"),
    verifyShownPermission: Permission.verify("device", "view"),
  };

  const handleDialogOpenClose = (e) => {
    mwState.setDialogOkAction(() => okActionClose(e));
    mwState.setDialogCancelAction(() => cancelAction());
    mwState.setDialogOpen(true);
  };
  function okActionClose(e) {
    handleEdit(e);
    mwState.setDialogOpen(false);
  }
  function cancelAction() {
    mwState.setDialogOpen(false);
  }

  const handleLock = () => {
    updateDeviceIsLocked(!isDeviceLocked);
    DeviceOverlay.toggleFeatureLock();
  };
  const handleHistory = () => {
    DeviceOverlay.openAssetInHistory(obj);
  };
  const handleEdit = () => {
    const deviceId = DeviceOverlay.getSelectedId();
    DeviceOverlay.deselectFeature();
    const deviceData = Device.get(deviceId);
    mwState.openModuleWrapperPopper(deviceObject);
    mwState.handleItemEdit(deviceData);
  };
  const handleChat = () => {
    Chat.showChatBox(DeviceOverlay.getSelectedId());
    setChatBoxOpen(!chatBoxOpen);
  };
  const handleZoom = () => {
    DeviceOverlay.centerAndZoomTo(DeviceOverlay.getSelectedId());
  };
  const handlePoll = () => {
    pollCommands(obj, "poll_gps");
  };

  const lockIcon = isDeviceLocked ? <LockIcon /> : <LockOpenIcon />;

  const { initialized, chatmessage } = Chat;

  const classes = useStyles();

  const renderBatteryIcon = (device) => {
    if (device?.battery_charge === true) {
      return (
        <>
          <CustomTooltip
            title={
              Language.translate("Battery") +
              Device.get(device.id).battery_level
                ? Device.get(device.id).battery_level + "%"
                : ""
            }
            arrow
            placement="top"
          >
            <Button className="dataDisplayButton NoPointer devicePopUpHeaderRedBattery">
              <BatteryCharging80Icon className="devicePopUpHeaderBattery" />
            </Button>
          </CustomTooltip>
        </>
      );
    } else if (Number(device?.battery_level) <= 10) {
      return (
        <>
          <CustomTooltip
            title={
              Language.translate("Battery") +
              Device.get(device.id).battery_level
                ? Device.get(device.id).battery_level + "%"
                : ""
            }
            arrow
            placement="top"
          >
            <Button className="dataDisplayButton NoPointer devicePopUpHeaderRedBattery">
              <Battery20Icon className="devicePopUpHeaderBattery" />
            </Button>
          </CustomTooltip>
        </>
      );
    } else {
      return (
        <>
          <CustomTooltip
            title={
              Language.translate("Battery") +
              Device.get(device.id).battery_level
                ? Device.get(device.id).battery_level + "%"
                : ""
            }
            arrow
            placement="top"
          >
            <Button className="dataDisplayButton NoPointer">
              <Battery90Icon />
            </Button>
          </CustomTooltip>
        </>
      );
    }
  };

  const showButtonGroup = !state.minimize ? (
    <div className={classes.rootGroup}>
      <ButtonGroup
        className="dataDisplayButtonGroup"
        aria-label="outlined primary button group"
      >
        <CustomTooltip
          title={
            !isDeviceLocked
              ? Language.translate("Lock and Center")
              : Language.translate("Unlock")
          }
          arrow
          placement="top"
        >
          <Button
            onClick={handleLock}
            className={`dataDisplayButton ${isDeviceLocked ? "locked" : null}`}
          >
            {lockIcon}
          </Button>
        </CustomTooltip>

        <CustomTooltip
          title={Language.translate("History")}
          arrow
          placement="top"
        >
          <Button className="dataDisplayButton" onClick={handleHistory}>
            <HistoryIcon />
          </Button>
        </CustomTooltip>

        {Permission.verify("device", "poll") ? (
          <CustomTooltip
            title={Language.translate("Poll GPS")}
            arrow
            placement="top"
          >
            <Button className="dataDisplayButton" onClick={handlePoll}>
              <RssFeedIcon />
            </Button>
          </CustomTooltip>
        ) : null}

        {/* DeviceMenu.menuInitialized &&  */}
        {Permission.verify("device", "edit") ? (
          <CustomTooltip
            title={Language.translate("Edit")}
            arrow
            placement="top"
          >
            <Button
              className="dataDisplayButton"
              onClick={(e) => {
                !mwState.moduleFormHasChanges
                  ? handleEdit(e)
                  : handleDialogOpenClose();
              }}
            >
              <EditIcon />
            </Button>
          </CustomTooltip>
        ) : null}

        {/* Chat.initialized &&   && Device.getDeviceType(device.type_id).components.messaging)  */}
        {initialized && Permission.verify("message", "view") ? (
          <CustomTooltip
            title={Language.translate("Message")}
            arrow
            placement="top"
          >
            <Button className="dataDisplayButton" onClick={handleChat}>
              <ChatBubbleIcon />
            </Button>
          </CustomTooltip>
        ) : null}

        {/* {chatBoxOpen ? <ChatBox></ChatBox> : null} */}

        <CustomTooltip
          title={Language.translate("Zoom In")}
          arrow
          placement="top"
        >
          <Button className="dataDisplayButton" onClick={handleZoom}>
            <GpsFixedIcon />
          </Button>
        </CustomTooltip>

        {Device.getDeviceType(device.type_id)
          ?.title?.toLowerCase()
          ?.includes("wave") ||
        Device.getDeviceType(device.type_id)
          ?.title?.toLowerCase()
          ?.includes("nortac orion")
          ? renderBatteryIcon(Device.get(device.id))
          : null}
      </ButtonGroup>
    </div>
  ) : (
    ""
  );
  return <div>{showButtonGroup}</div>;
}
