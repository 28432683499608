import React, { useState, useContext, useEffect } from "react";
// External
import { useFormikContext } from "formik";

// Material-UI
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import MenuItem from "@material-ui/core/MenuItem";

// Internal
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";
import { UserSettingContext } from "../context/UserSettingContext";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomSwitch from "@Components/CustomSwitch";
import Language from "sccLanguage";
import Permission from "sccPermission";

function MapSettings(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const { userSettingData, setUserSettingData } = useContext(UserSettingContext);

  function updateAndStay(e, routeUrl, message) {
    handleFormInput(e);
    let val = e.target.value;
    if (val == "false") val = true;
    else if (val == "true") val = false;
    const newData = { ...userSettingData, [e.target.name]: val };
    updateModuleData(newData, routeUrl, message).then((data) => {
      if (!data.error && data.result !== null) {
        setUserSettingData(data.result);
      }
    });
  }

  const formik = useFormikContext();
  const updateModuleData = mwState.updateModuleData;

  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;


  const coordFormatSetting = {
    languageTag: "Coordinate Format",
    settingKey: "lonlat_format",
    optionData: [
      { value: "EPSG:4326", title: Language.translate("DegDec (EPSG:4326)") },
      { value: "DMS", title: Language.translate("DMS") },
      { value: "EPSG:900913", title: Language.translate("EPSG:900913") },
      { value: "MinDec", title: Language.translate("MinDec") },
      { value: "MGRS", title: Language.translate("USNG (MGRS)") },
    ],
  };

  const reportAgeSetting = {
    languageTag: "Reports Age",
    settingKey: "report_age",
    optionData: [
      { value: 1, title: "1 " + Language.translate("Hour") },
      { value: 12, title: "12 " + Language.translate("Hours") },
      { value: 24, title: "24 " + Language.translate("Hours") },
      { value: 48, title: "48 " + Language.translate("Hours") },
      { value: 72, title: "72 " + Language.translate("Hours") },
      { value: 168, title: "1 " + Language.translate("Week") },
      { value: 720, title: "1 " + Language.translate("Month") },
      { value: -1, title: Language.translate("NONE") },
    ],
  };

  const message = {
    title: Language.translate("Settings"),
    text: Language.translate("Successfully Updated"),
    noRefresh: true,
  };

  return (
    <div className="SettingsBody">
      <h4
        style={{
          fontWeight: "normal",
          fontSize: "24px",
          marginTop: "0px",
          marginBottom: "10px",
        }}
      >
        {Language.translate("Map Settings")}
      </h4>
      <ul className="listing">
        {!Permission.verify("client", "view") && (
          <ListItem className="listItem">
            <ListItemText primary={Language.translate("Asset Annotations")} />
            <ListItemSecondaryAction>
              <CustomSwitch
                name="asset_annotation"
                value={userSettingData.asset_annotation}
                onChange={(e) =>
                  updateAndStay(e, mwState.routeUrl, message)
                }
                checked={userSettingData.asset_annotation}
                color="primary"
                labelPlacement="top"
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {!Permission.verify("client", "view") &&
          Permission.verify("poi", "view") && (
            <ListItem className="listItem">
              <ListItemText primary={Language.translate("POI Annotations")} />
              <ListItemSecondaryAction>
                <CustomSwitch
                  name="poi_annotation"
                  value={userSettingData.poi_annotation}
                  onChange={(e) =>
                    updateAndStay(e, mwState.routeUrl, message)
                  }
                  checked={userSettingData.poi_annotation}
                  color="primary"
                  labelPlacement="top"
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        {!Permission.verify("client", "view") && (
          <ListItem className="listItem">
            <ListItemText primary={Language.translate("Asset Labels")} />
            <ListItemSecondaryAction>
              <CustomSwitch
                name="asset_label"
                value={userSettingData.asset_label}
                onChange={(e) =>
                  updateAndStay(e, mwState.routeUrl, message)
                }
                checked={userSettingData.asset_label}
                color="primary"
                labelPlacement="top"
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {!Permission.verify("client", "view") &&
          Permission.verify("poi", "view") && (
            <ListItem className="listItem">
              <ListItemText primary={Language.translate("POI Labels")} />
              <ListItemSecondaryAction>
                <CustomSwitch
                  name="poi_label"
                  value={userSettingData.poi_label}
                  onChange={(e) =>
                    updateAndStay(e, mwState.routeUrl, message)
                  }
                  checked={userSettingData.poi_label}
                  color="primary"
                  labelPlacement="top"
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        {!Permission.verify("client", "view") && (
          <ListItem className="listItem">
            <ListItemText
              primary={Language.translate(reportAgeSetting.languageTag)}
            />
            <ListItemSecondaryAction>
              <CustomDropdown
                id="report_age"
                name="report_age"
                className="dropdown"
                value={String(moduleItemData.report_age)}
                onChange={(e) =>
                  updateAndStay(e, mwState.routeUrl, message)
                }
              >
                {reportAgeSetting.optionData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </CustomDropdown>
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {!Permission.verify("client", "view") && (
          <ListItem className="listItem">
            <ListItemText
              primary={Language.translate(coordFormatSetting.languageTag)}
            />
            <ListItemSecondaryAction>
              <CustomDropdown
                id="lonlat_format"
                name="lonlat_format"
                className="dropdown"
                value={String(moduleItemData.lonlat_format)}
                onChange={(e) =>
                  updateAndStay(e, mwState.routeUrl, message)
                }
              >
                {coordFormatSetting.optionData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </CustomDropdown>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </ul>
    </div>
  );
}

export default MapSettings;
