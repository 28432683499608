import { Feed } from "../../language/scripts/dict";
import { default as Module } from "sccModule";

var Permission= require("sccPermission");
const log= require("loglevel");

/**
 * The base class for FeedModule
 * 
 * @class FeedModule
 */
class FeedModule extends Module.Module{
	constructor(){
		super({
			moduleName: "feed"
		});
	}

	init(){
		log.debug("User  for Feed module", Permission.default.verify("feed", "add"));
		if(!Permission.default.verify("feed", "view")){
			log.debug("User is not permitted for Feed module");
			return Promise.resolve();
		} 	
		
		return Module.Module.prototype.init.call(this);
	}
}


//module.exports= New FeedModule();
export default new FeedModule();
